import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FORGOT_PASSWORD } from "../../graphql/mutations";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();
  const [forgotPassword, { loading, error, data }] = useMutation(FORGOT_PASSWORD);

  const handleForgotPassword = async (data) => {
    await forgotPassword({
      variables: {
        email: data.email,
      },
    })
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isSubmitSuccessful && data?.forgotPassword) {
      reset({
        email: "",
      });
    }
  }, [isSubmitSuccessful]);

  return (
    <div className="flex flex-col lg:px-0 px-10 justify-center items-center  h-screen mt-20">
      {/* <div className="border "> */}
      <h1 className="text-3xl font-gilroy-Light text-formFont mb-5">
        Reset Password
      </h1>
      <p className="font-gilroy-Light mb-5">
        To reset your password, please enter your email address below
      </p>
      <div className="lg:w-3/12 md:w-5/12">
        <p className=" font-gilroy-Light text-red-600">
          {data?.forgotPassword && "Reset password link has been sent to your email"}
        </p>
      </div>

      <div className="border-b w-full lg:w-3/12 md:w-5/12 border-gray-400  pb-2 flex flex-row">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 md:h-8 md:w-8 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
        <input
          className="font-gilroy-Light flex-1 text-primary placeholder-primary focus:outline-none px-2 "
          type="email"
          defaultValue={""}
          {...register("email", {
            required: "Email required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid Email Standard",
            },
          })}
          placeholder="sunilkargwal@gmail.com"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 lg:h-8 lg:w-8 text-primary relative ml-auto"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="lg:w-3/12 md:w-5/12">
        <p className=" font-gilroy-Light text-red-600  mb-16 ">
          {errors.email?.message}
        </p>
      </div>
      <button
        onClick={handleSubmit(handleForgotPassword)}
        className="border font-gilroy-Light bg-primary text-white py-2 px-5 rounded-full"
      >
        {loading ? "Resetting Password" : "RESET MY PASSWORD"}
      </button>
      {/* </div> */}
    </div>
  );
};

export default ForgotPassword;
