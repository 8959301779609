import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { creator_nav } from "../../../../config/utilities";
import { ProfileViewHandler } from "../AgentPortal";
import { useLocation } from "react-router-dom";

const CreatorProfile = () => {
  // const context = useContext(PackageContext);
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState({});
  const { selectedUser } = location?.state || {};

  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser);
    } else {
      history.push("/");
      window.location.reload();
    }
  }, []);



  return (
    <div className="w-full  mt-24 py-10 flex flex-col items-center">
      <ProfileViewHandler user={user} nav={creator_nav} />
    </div>
  );
};

export default CreatorProfile;
