import React, { useEffect, useContext, useState } from "react";
import PackageContext from "../../../context/context";
import _ from "lodash";
import { useHistory } from "react-router-dom";


const About = () => {
  const context = useContext(PackageContext);
  const history = useHistory();
  const [aboutTalent] = useState(JSON.parse(context.talent.professionalDetails?.casting_africa_talent_details));
  const [talentDetails, setTalentDetails] = useState({});


  useEffect(() => {
    let talent_id = sessionStorage.getItem("talent_");
    if (talent_id === "") {
      history.push("/talents")
    }
  }, []);

  useEffect(() => {
    let obj = {};
    obj.about = {
      physical_attributes: {
        buld: aboutTalent.build,
        skin_color: aboutTalent.skinColor,
        eye_color: aboutTalent.eyeColor,
        hair_color: aboutTalent.hairColor,
        hair_type: aboutTalent.hairType,
        dress_size: aboutTalent.dressSize,
        height: aboutTalent.height,
        hips: aboutTalent.hips,
        shoe_size: aboutTalent.shoe,
        waist: aboutTalent.waist,
      },
      credit_and_skills: {
        credit: [...aboutTalent.credits],
        language_spoken: aboutTalent.language,
        accent: aboutTalent.accent,
        modelling_category: aboutTalent.modelCategory.toString(),
        modelling_experience: aboutTalent.modelExperience.toString(),
        dancing_ability: aboutTalent.dancerExperience.toString(),
        dancing_style: aboutTalent.dancerCategory.toString(),
      },
      awards: [...aboutTalent.awards],
      job_preference: {
        acting: aboutTalent.actorInterest.toString(),
        modelling: aboutTalent.modelInterest.toString(),
        dancing: aboutTalent.dancerInterest.toString(),
        influencer: aboutTalent.influencerInterest.toString(),
      },
      license_and_passport: {
        driving_license: aboutTalent.hasID
          .filter((f) => f.includes("driver"))
          .toString(),
        passport: aboutTalent.hasID
          .filter((f) => f.includes("passport"))
          .toString(),
      },
    };

    obj.social_details = [];

    setTalentDetails(obj);
  }, []);

  const r = (type) => {
    let a = null;
    if (!Array.isArray(type)) {
      return Object.entries(type).map(([content, keys], key) => (
        <div key={key} className="mb-2">
          {!Array.isArray(keys) && (
            <div className="flex mb-3 md:text-sm text-xs font-gilroy-Light">
              <span className=" font-gilroy-Medium ">
                {_.capitalize(_.split(content, "_").join(" "))}
              </span>
              <span className="mr-4">:</span>
              <span>{_.capitalize(keys)}</span>
            </div>
          )}
          {Array.isArray(keys) && (
            <div className="flex mb-3 w-full lg:text-sm text-xs font-gilroy-Light">
              {r_a(keys, keys[0], null)}
            </div>
          )}
        </div>
      ));
    } else {
      return (
        <div className="flex w-full">
          {Array.isArray(type) && (
            <div className="flex mb-3 w-full lg:text-sm text-xs font-gilroy-Light">
              {r_a(type, type[0], null)}
            </div>
          )}
        </div>
      );
    }
  };

  const r_a = (data, headings, type) => {
    return data?.length ? (
      <table className=" table-auto  w-full">
        <thead className="bg-blue-300 h-11">
          {Object.keys(headings).map((headers, key) => (
            <th className="pl-2 text-left" key={key}>
              {" "}
              {_.capitalize(
                headers
                  .split("_")
                  .join(" ")
                  .substring(0, headers.split("_").join(" ").length - 1)
              )}
            </th>
          ))}
        </thead>
        <tbody>
          {data.map((body, key) => (
            <tr className="py-4  h-11" key={key}>
              {Object.entries(body).map(([key, value], index) => (
                <td className="pl-2" key={index}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      ""
    );
  };

  const social = (data) => {
    return (
      <div className="flex justify-start items-center">
        {data?.map((handle, key) => (
          <img className="w-10 h-10" src={handle} alt={key} key={key} />
        ))}
      </div>
    );
  };

  return (
    <div className="w-full">
      {
        !_.isEmpty(talentDetails)
          ? Object.entries(talentDetails?.about).map(([index, values], key) => (
              <div key={key}>
                <h1
                  key={key + 1}
                  className={`text-2xl mb-3 font-gilroy-Extrabold ${
                    !["social_details"].includes(index) ? "text-blue-500" : ""
                  }`}
                >
                  {_.capitalize(_.split(index, "_").join(" "))}
                </h1>
                <div
                  key={key + 2}
                  className={`${
                    !["social_details"].includes(index)
                      ? "border w-full shadow-md px-6 py-6"
                      : ""
                  } ${
                    _.size(values) > 9 ? " h-96" : ""
                  } flex flex-col flex-wrap    mb-3 `}
                >
                  {!["social_details"].includes(index) && r(values)}
                  {["social_details"].includes(index) && social(values)}
                </div>
              </div>
            ))
          : "No Personal Information found"
        // aboutTalent?.professionalDetails ? "Coming soon": "No Personal Information found"
      }
    </div>
  );
};

export default About;
