import React from "react";
import { useHistory } from "react-router-dom";

const PostJobForms = ({ next, prev, mode }) => {
  const history = useHistory();
  const handlePrev = () => {
    if (mode === "Edit") {
        history.goBack();
        return;
    }
    prev();
  };

  const handleNext = () => {
    next();
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center">
        <div className="flex justify-between items-center p-5 w-full lg:w-3/6 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => handlePrev()}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          <h1 className="font-gilroy-Light text-xl">Complete Job Details</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-red-600 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="w-full flex flex-col items-center">
          <div className="  w-full lg:w-3/6 mb-7 px-3 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Project Title"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Project Title
              </label>
              <input
                className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
                type="text"
                placeholder="Your project title "
              />
            </div>
          </div>

          <div className=" w-full lg:w-3/6 mb-7 px-4 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Project Category"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Project Category
              </label>
              <select className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 ">
                <option value="Feature Film">Feature Film</option>
              </select>
            </div>
          </div>

          <div className="  w-full lg:w-3/6 mb-7 px-3 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Project Description"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Project Description
              </label>
              <textarea
                rows="5"
                className="border rounded-2xl border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
                type="text"
                placeholder="Your project title "
              >
                {" "}
              </textarea>
            </div>
          </div>

          <div className="  w-full lg:w-3/6 mb-7 px-3 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Roles"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Roles
              </label>
              <textarea
                rows="5"
                className="border rounded-2xl border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
                type="text"
                placeholder="Your project title "
              >
                {" "}
              </textarea>
            </div>
          </div>

          <div className=" w-full lg:w-3/6 mb-7 px-4 md:px-4">
            <label
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              htmlFor="Gender"
            >
              Gender
            </label>
            <div className="flex lg:flex-nowrap flex-wrap">
              {["All", "Male", "Female", "Child", "Teen"].map((gender, key) => (
                <span
                  key={key}
                  className="px-6 py-2  lg:w-32 flex justify-center cursor-pointer lg:mb-0 mb-2 hover:bg-primary hover:text-white border border-primary bg-white rounded-full text-black font-gilroy-Light mr-2"
                >
                  {gender}
                </span>
              ))}
            </div>
          </div>

          <div className="w-full lg:w-3/6 mb-7 px-3 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Location Range"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Location Range
              </label>

              <div className="flex lg:flex-nowrap flex-wrap">
                {["Local", "Remote", "Nationwide", "Africa", "Worldwide"].map(
                  (location, key) => (
                    <span
                      key={key}
                      className="px-6 py-2 lg:w-32 flex justify-center cursor-pointer lg:mb-0 mb-2 hover:bg-primary hover:text-white border border-primary bg-white rounded-full text-black font-gilroy-Light mr-2"
                    >
                      {location}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>

          <div className=" w-full lg:w-3/6 mb-7 px-4 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Country"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Country
              </label>
              <select
                className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
                name=""
                id=""
              >
                <option value="Ghana">Ghana</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Kenya">Kenya</option>
                <option value="South Africa">South Africa</option>
                <option value="Namibia">Namibia</option>
              </select>
            </div>
          </div>

          <div className=" w-full lg:w-3/6 mb-7 px-4 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Town/City"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Town/City
              </label>
              <select
                className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
                name=""
                id=""
              >
                <option value="Ghana">Accra</option>
                <option value="Nigeria">East Legon</option>
              </select>
            </div>
          </div>

          <div className="  w-full lg:w-3/6 mb-7 px-3 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Submission Instruction"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Submission Instruction
              </label>
              <textarea
                rows="5"
                className="border rounded-2xl border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
                type="text"
                placeholder="Enter Submission Instruction"
              >
                {" "}
              </textarea>
            </div>
          </div>

          {/* <div className=" w-full lg:w-3/6 mb-7 px-4 md:px-4">
            <label
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              htmlFor="Gender"
            >
              Media Requirment
            </label>
            <div className="flex ">
              <div className=" flex items-center mr-2 w-1/6">
                <input className="w-5 h-5" type="checkbox" name="" id="" />
                <span className="ml-1 mr-3 font-gilroy-Light text-sm md:text-lg  text-formFont">
                  Headshot
                </span>
              </div>
              <div className=" flex items-center mr-2 w-1/6">
                <input className="w-5 h-5" type="checkbox" name="" id="" />
                <span className="ml-1 mr-3 font-gilroy-Light text-sm md:text-lg  text-formFont">
                  Video Reel
                </span>
              </div>
              <div className=" flex items-center mr-2 w-1/6">
                <input className="w-5 h-5" type="checkbox" name="" id="" />
                <span className="ml-1 mr-3 font-gilroy-Light text-sm md:text-lg  text-formFont">
                  Monologue
                </span>
              </div>
            </div>
          </div> */}

          <div className="  w-full lg:w-3/6 mb-7 px-3 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Remuneration"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Remuneration
              </label>

              <div className="flex lg:flex-nowrap flex-wrap">
                {["No Pay", "Deffered", "Stipend", "Paid"].map(
                  (remuneration, key) => (
                    <span
                      key={key}
                      className="px-6 py-2 lg:w-36 cursor-pointer lg:mb-0 mb-2 hover:bg-primary hover:text-white border border-primary bg-white rounded-full text-black font-gilroy-Light mr-2"
                    >
                      {remuneration}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="  w-full lg:w-3/6 mb-7 px-3 md:px-2">
            <div className="flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Media"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Media
              </label>

              <div className="flex lg:flex-nowrap flex-wrap">
                {["Brief", "Sides", "Flier"].map((media, key) => (
                  <div
                    key={key}
                    className="px-6 py-2 lg:mb-0 mb-2 flex justify-center items-center cursor-pointer bg-primary text-white border border-primary  rounded-full lg:w-36 font-gilroy-Light mr-2"
                  >
                    <span className="mr-2">{media}</span>
                    <svg
                      className="w-5 h-5"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 6.94C15.9896 6.84813 15.9695 6.75763 15.94 6.67V6.58C15.8919 6.47718 15.8278 6.38267 15.75 6.3L9.75 0.3C9.66734 0.222216 9.57282 0.158081 9.47 0.11C9.44015 0.10576 9.40985 0.10576 9.38 0.11C9.27841 0.0517412 9.16622 0.0143442 9.05 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V7C16 7 16 7 16 6.94ZM10 3.41L12.59 6H11C10.7348 6 10.4804 5.89464 10.2929 5.70711C10.1054 5.51957 10 5.26522 10 5V3.41ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H8V5C8 5.79565 8.31607 6.55871 8.87868 7.12132C9.44129 7.68393 10.2044 8 11 8H14V17ZM10 12H9V11C9 10.7348 8.89464 10.4804 8.70711 10.2929C8.51957 10.1054 8.26522 10 8 10C7.73478 10 7.48043 10.1054 7.29289 10.2929C7.10536 10.4804 7 10.7348 7 11V12H6C5.73478 12 5.48043 12.1054 5.29289 12.2929C5.10536 12.4804 5 12.7348 5 13C5 13.2652 5.10536 13.5196 5.29289 13.7071C5.48043 13.8946 5.73478 14 6 14H7V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V14H10C10.2652 14 10.5196 13.8946 10.7071 13.7071C10.8946 13.5196 11 13.2652 11 13C11 12.7348 10.8946 12.4804 10.7071 12.2929C10.5196 12.1054 10.2652 12 10 12Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className=" w-full lg:w-3/6 mb-7  flex justify-center">
            <button
              onClick={handleNext}
              className="px-16 py-3 text-lg text-white rounded-full bg-primary"
            >
              Review
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostJobForms;
