import React, { useState, useContext, useEffect } from "react";
import VERIFICATION from "../../../../assets/images/verification_bade.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import PackageContext from "../../../../context/context";
import DEFAULT from "../../../../assets/images/default.jpg";
import PROFILEAVATAR from "../../../../assets/images/profile_avatar.jpg";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TALENT } from "../../../../graphql/queries";
import { FOLLOW_TALENT, UNFOLLOW_TALENT } from "../../../../graphql/mutations";
import { NotificationManager } from "react-notifications";

const TalentItemCard = ({ talent, width, hash, feature_type }) => {
  const context = useContext(PackageContext);
  const history = useHistory();

  const [followingList, setFollowingList] = useState([]);
  const [profilePictue, setProfilePicture] = useState({});
  const {
    loading: logged_loading,
    error: logged_error,
    data: logged_data,
    refetch,
    startPolling,
    stopPolling
  } = useQuery(GET_TALENT)

  useEffect(() => {
    const thumb = talent.photos?.find((photo) => photo.includes("thumbnail"));
    setProfilePicture({
      img: `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${talent?.talent_media_path}/pictures/${thumb}`,
      imageHash: hash
    })
  }, [talent])
 


  useEffect(() => {
   
    const id = JSON.parse(sessionStorage.getItem("AUTH_USER"))?.user?.talent?.casting_africa_talent_id
    console.log("id ", id)
    if (id) {
       console.log("Here ", id);
      refetch({
        variables: {
          talentID: id,
        },
        onCompleted: (d) => {
          const thumb = talent.photos?.find((photo) => photo.includes("thumbnail"));
          setProfilePicture({
            img: `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${talent?.talent_media_path}/pictures/${thumb}`,
            imageHash: hash
          })
          setFollowingList(
            d?.talent?.talentFollowings?.map(
              (f) => f?.following?.casting_africa_talent_id
            )
          );
        }
      })
    }
    
    // startPolling(500)

    return () => {
        stopPolling()
    }
  }, [])


 

  const [talentPath, setTalentPath] = useState('/talentDetails')
  const talentFeatures = [
    {
      feature_type: "follow_feature",
      component: (
        <FollowFeatures
          followingList={followingList}
          setFollowingList={setFollowingList}
          talent={talent}
        />
      ),
    },
    {
      feature_type: "talent_feature",
      component: <TalentFeatures />,
    },
    {
      feature_type: "applicant_feature",
      component: <ApplicantFeatures />,
    },
    {
      feature_type: "edittalent_feature",
      component: <EditTalentFeatures />,
    },
  ];

  

  const segueToTalentDetails = (item) => {
    if (feature_type !== "talent_feature") {
      return;
    }
    context.updateTalent(item);
    context.updateTalentLocalState(item);
    sessionStorage.setItem("talent_", talent?.casting_africa_talent_id);

    history.replace('/talentDetails', {
      id: talent?.casting_africa_talent_id,
    });
  };

  const talentTypeFormatter = (data) => {
    let splitter = JSON.parse(data).talent_type.toString().split(",");
    if (splitter.length == 2) {
      splitter = splitter.join(" & ");
    } else if (splitter.length > 2) {
      splitter = splitter.join(",");
    } 

    return splitter;
  }

  return (
    <div
      className={`w-80 mb-3 md:w-60 lg:${width} ${
        width === "w-64" ? "mr-6" : "mr-4"
      } md:mb-4 shadow-lg rounded-br-2xl rounded-bl-2xl`}
    >
      <div
        onClick={() => segueToTalentDetails(talent)}
        className={`bg-white cursor-pointer
        } flex flex-col justify-start items-start  w-full h-72`}
      >
        <img
          style={{
            objectFit: "cover",
            backgroundSize: "100%",
          }}
          className="h-screen overflow-hidden  w-full"
          src={profilePictue.img}
          alt=""
          onError={(e) => {
            e.target.src = DEFAULT;
          }}
        />
       
      </div>
      {talent?.verification_status === "VERIFIED" ? (
          <img className="absolute -mt-7" src={VERIFICATION} alt="" />
        ) : (
          " "
        )}
      <div className="border-b pb-3 lg:px-0 px-0 md:px-2 mt-2 flex flex-col justify-center items-center border-gray-200">
        <h1
          onClick={() => segueToTalentDetails(talent)}
          className={`font-gilroy-Medium cursor-pointer
          } hover:text-blue-900 text-primary text-3xl md:text-lg lg:text-2xl`}
        >
          {talent?.talent_first_name.split(" ")[0]}
        </h1>
        <p className="text-xs md:text-center lg:text-left text-left font-gilroy-Light">
          <span className="mr-1">
            {moment().diff(talent?.talent_dob, "years", false)} years,
          </span>
          {/* {talent?.talentAddress?.city && (
            <span className="mr-1">{talent?.talentAddress?.city},</span>
          )} */}
          <span>{talent?.talentAddress?.country}</span>
        </p>
        <p className=" font-gilroy-Extraboldtext-sm md:text-xs lg:text-sm lg:text-left text-left md:text-center text-formFont ">
          {/* {talentTypeFormatter(talent.professionalDetails?.casting_africa_talent_details)} */}
        </p>
      </div>
      {
        talentFeatures.find((feature) => feature.feature_type === feature_type)
          .component
      }
    </div>
  );
};

const EditTalentFeatures = () => {
  return (
    <div className="flex justify-evenly items-center py-2">
      <div className="flex group  py-1 cursor-pointer  justify-center items-center">
        <svg
          className="w-5 h-5"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.4154 5.7316C17.416 5.62741 17.396 5.52413 17.3566 5.42767C17.3172 5.33122 17.2592 5.24349 17.1858 5.16952L13.8291 1.81285C13.7552 1.73948 13.6674 1.68143 13.571 1.64203C13.4745 1.60263 13.3712 1.58266 13.267 1.58327C13.1629 1.58266 13.0596 1.60263 12.9631 1.64203C12.8667 1.68143 12.7789 1.73948 12.705 1.81285L10.4645 4.05327L1.81163 12.7062C1.73826 12.7802 1.68021 12.8679 1.64081 12.9643C1.60141 13.0608 1.58144 13.1641 1.58204 13.2683V16.6249C1.58204 16.8349 1.66545 17.0363 1.81392 17.1847C1.96238 17.3332 2.16375 17.4166 2.37371 17.4166H5.73038C5.84115 17.4226 5.95196 17.4053 6.05561 17.3657C6.15926 17.3262 6.25344 17.2653 6.33204 17.187L14.9375 8.5341L17.1858 6.33327C17.2581 6.25654 17.3169 6.16823 17.36 6.07202C17.3676 6.00891 17.3676 5.94512 17.36 5.88202C17.3637 5.84516 17.3637 5.80803 17.36 5.77118L17.4154 5.7316ZM5.40579 15.8333H3.16538V13.5928L11.0266 5.7316L13.267 7.97202L5.40579 15.8333ZM14.3833 6.85577L12.1429 4.61535L13.267 3.4991L15.4995 5.7316L14.3833 6.85577Z"
            fill="#E5E5E5"
            fillOpacity="0.9"
          />
        </svg>

        <span className="text-xs lg:block block md:hidden font-gilroy-Light text-gray-300 group-hover:text-primary">
          Edit profile
        </span>
      </div>
    </div>
  );
};

const FollowFeatures = ({ followingList, setFollowingList, talent }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(sessionStorage.getItem("AUTH_USER"))
  );

  const [
    followTalent,
    { loading: follow_loading, error: follow_error, data: follow_response },
  ] = useMutation(FOLLOW_TALENT);

  const [
    unfollowTalent,
    {
      loading: unfollowing_loading,
      error: unfollow_error,
      data: unfollow_response,
    },
  ] = useMutation(UNFOLLOW_TALENT);

  const handleFollow = (followingID, talentID) => {
    if (!isLoggedIn) {
      NotificationManager.error(
        `Log in to Casting Africa to follow ${talent?.talent_first_name}`,
        "Casting Africa",
        5000
      );
      return;
    }

    followTalent({
      variables: {
        talentID,
        followingID,
      },
    })
      .then((res) => {
        setFollowingList([...res?.data?.followTalent]);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUnfollow = (followingID, talentID) => {
    unfollowTalent({
      variables: {
        talentID,
        followingID,
      },
    })
      .then((resp) => {
        setFollowingList(resp?.data?.unFollowTalent);
      })
      .catch((err) => {
        // console.log("unfollow error ", err);
      });
  };
  return (
    <div className="flex justify-evenly items-center py-2">
      <div className="flex">
        {talent?.casting_africa_talent_id ===
        isLoggedIn?.user?.talent?.casting_africa_talent_id ? (
          ""
        ) : followingList?.includes(talent?.casting_africa_talent_id) ? (
          <div
            onClick={() =>
              handleUnfollow(
                talent?.casting_africa_talent_id,
                isLoggedIn?.user?.talent?.casting_africa_talent_id
              )
            }
            className="flex group  py-1 cursor-pointer  justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1 fill-current"
              fill="#E5E5E5"
              viewBox="0 0 24 24"
              stroke="#E5E5E5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"
              />
            </svg>
            <span className="text-xs lg:block block md:hidden font-gilroy-Light text-gray-300 group-hover:text-primary">
              Unfollow
            </span>
          </div>
        ) : (
          <div
            onClick={() =>
              handleFollow(
                talent?.casting_africa_talent_id,
                isLoggedIn?.user?.talent?.casting_africa_talent_id
              )
            }
            className="flex group  py-1 cursor-pointer  justify-center items-center"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 12.5537C12.2546 12.5537 14.4626 10.3171 14.4626 7.52684C14.4626 4.73663 12.2546 2.5 9.5 2.5C6.74543 2.5 4.53737 4.73663 4.53737 7.52684C4.53737 10.3171 6.74543 12.5537 9.5 12.5537ZM9.5 15.0152C5.45422 15.0152 2 15.6621 2 18.2464C2 20.8298 5.4332 21.5 9.5 21.5C13.5448 21.5 17 20.8531 17 18.2687C17 15.6844 13.5668 15.0152 9.5 15.0152ZM19.8979 9.58786H21.101C21.5962 9.58786 22 9.99731 22 10.4995C22 11.0016 21.5962 11.4111 21.101 11.4111H19.8979V12.5884C19.8979 13.0906 19.4952 13.5 18.999 13.5C18.5038 13.5 18.1 13.0906 18.1 12.5884V11.4111H16.899C16.4027 11.4111 16 11.0016 16 10.4995C16 9.99731 16.4027 9.58786 16.899 9.58786H18.1V8.41162C18.1 7.90945 18.5038 7.5 18.999 7.5C19.4952 7.5 19.8979 7.90945 19.8979 8.41162V9.58786Z"
                fill="#E5E5E5"
                fillOpacity="0.9"
              />
            </svg>
            <span className="text-xs lg:block block md:hidden font-gilroy-Light text-gray-300 group-hover:text-primary">
              Follow
            </span>
          </div>
        )}
      </div>

      <div className="flex group  cursor-pointer   justify-center items-center">
        <svg
          className="lg:w-8 w-8  mr-1 md:w-7"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.11304 4.5H11.9051C14.3271 4.5 16.0181 6.16904 16.0181 8.56091V15.4391C16.0181 17.831 14.3271 19.5 11.9051 19.5H6.11304C3.69102 19.5 2 17.831 2 15.4391V8.56091C2 6.16904 3.69102 4.5 6.11304 4.5ZM19.958 6.87898C20.397 6.65563 20.912 6.67898 21.331 6.94294C21.75 7.20589 22 7.66274 22 8.16223V15.8384C22 16.3389 21.75 16.7947 21.331 17.0577C21.102 17.2008 20.846 17.2739 20.588 17.2739C20.373 17.2739 20.158 17.2231 19.957 17.1206L18.476 16.3734C17.928 16.0952 17.588 15.5369 17.588 14.9165V9.08305C17.588 8.46173 17.928 7.90335 18.476 7.62721L19.958 6.87898Z"
            fill="#E5E5E5"
            fillOpacity="0.9"
          />
        </svg>
        <span className="text-xs lg:block block md:hidden   font-gilroy-Light text-gray-300 group-hover:text-primary">
          Discover
        </span>
      </div>
    </div>
  );
};

const TalentFeatures = () => {
  return (
    <div className="flex justify-evenly items-center">
      <div className="flex group flex-col py-1 cursor-pointer  justify-center items-center">
        <svg
          className="w-4 mt-2 mb-1"
          viewBox="0 0 16 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.9 0H11.07C13.78 0 15.97 1.07 16 3.79V18.97C16 19.14 15.96 19.31 15.88 19.46C15.75 19.7 15.53 19.88 15.26 19.96C15 20.04 14.71 20 14.47 19.86L7.99 16.62L1.5 19.86C1.351 19.939 1.18 19.99 1.01 19.99C0.45 19.99 0 19.53 0 18.97V3.79C0 1.07 2.2 0 4.9 0ZM4.22 7.62H11.75C12.18 7.62 12.53 7.269 12.53 6.83C12.53 6.39 12.18 6.04 11.75 6.04H4.22C3.79 6.04 3.44 6.39 3.44 6.83C3.44 7.269 3.79 7.62 4.22 7.62Z"
            fill="#E8E8E8"
          />
        </svg>
        <span className="text-xs lg:block block md:hidden font-gilroy-Light text-gray-300 group-hover:text-primary">
          Shortlist
        </span>
      </div>
      <div className="flex group flex-col cursor-pointer   justify-center items-center">
        <svg
          className="lg:w-8 w-8 md:w-7"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.11304 4.5H11.9051C14.3271 4.5 16.0181 6.16904 16.0181 8.56091V15.4391C16.0181 17.831 14.3271 19.5 11.9051 19.5H6.11304C3.69102 19.5 2 17.831 2 15.4391V8.56091C2 6.16904 3.69102 4.5 6.11304 4.5ZM19.958 6.87898C20.397 6.65563 20.912 6.67898 21.331 6.94294C21.75 7.20589 22 7.66274 22 8.16223V15.8384C22 16.3389 21.75 16.7947 21.331 17.0577C21.102 17.2008 20.846 17.2739 20.588 17.2739C20.373 17.2739 20.158 17.2231 19.957 17.1206L18.476 16.3734C17.928 16.0952 17.588 15.5369 17.588 14.9165V9.08305C17.588 8.46173 17.928 7.90335 18.476 7.62721L19.958 6.87898Z"
            fill="#E5E5E5"
            fillOpacity="0.9"
          />
        </svg>
        <span className="text-xs lg:block block md:hidden   font-gilroy-Light text-gray-300 group-hover:text-primary">
          Discover
        </span>
      </div>
      <div className="flex group flex-col cursor-pointer  justify-center items-center">
        <svg
          className="lg:w-8 w-8 md:w-6  "
          viewBox="0 0 24 24 "
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7044 3.51898C10.034 3.51898 9.46373 3.9848 9.30365 4.61265H14.6863C14.5263 3.9848 13.956 3.51898 13.2856 3.51898H10.7044ZM16.2071 4.61264H18.1881C20.2891 4.61264 22 6.34428 22 8.47085C22 8.47085 21.94 9.3711 21.92 10.6248C21.918 10.724 21.8699 10.8212 21.7909 10.88C21.3097 11.2354 20.8694 11.5291 20.8294 11.5493C19.1686 12.6632 17.2386 13.447 15.1826 13.8369C15.0485 13.8632 14.9165 13.7934 14.8484 13.6739C14.2721 12.6754 13.1956 12.0253 11.995 12.0253C10.8024 12.0253 9.71586 12.6683 9.12256 13.6678C9.05353 13.7853 8.92346 13.8531 8.7904 13.8278C6.75138 13.4369 4.82141 12.6541 3.17059 11.5594L2.21011 10.8911C2.13007 10.8405 2.08004 10.7493 2.08004 10.6481C2.05003 10.1316 2 8.47085 2 8.47085C2 6.34428 3.71086 4.61264 5.81191 4.61264H7.78289C7.97299 3.1443 9.2036 2 10.7044 2H13.2856C14.7864 2 16.017 3.1443 16.2071 4.61264ZM21.6598 12.8152L21.6198 12.8355C19.5988 14.1924 17.1676 15.0937 14.6163 15.4684C14.2561 15.519 13.8959 15.2861 13.7959 14.9216C13.5758 14.0912 12.8654 13.5443 12.015 13.5443H12.005H11.985C11.1346 13.5443 10.4242 14.0912 10.2041 14.9216C10.1041 15.2861 9.74387 15.519 9.38369 15.4684C6.83242 15.0937 4.4012 14.1924 2.38019 12.8355C2.37019 12.8254 2.27014 12.7646 2.1901 12.8152C2.10005 12.8659 2.10005 12.9874 2.10005 12.9874L2.17009 18.1519C2.17009 20.2785 3.87094 22 5.97199 22H18.018C20.1191 22 21.8199 20.2785 21.8199 18.1519L21.9 12.9874C21.9 12.9874 21.9 12.8659 21.8099 12.8152C21.7599 12.7849 21.6999 12.795 21.6598 12.8152ZM12.7454 17.0583C12.7454 17.4836 12.4152 17.8177 11.995 17.8177C11.5848 17.8177 11.2446 17.4836 11.2446 17.0583V15.7519C11.2446 15.3367 11.5848 14.9924 11.995 14.9924C12.4152 14.9924 12.7454 15.3367 12.7454 15.7519V17.0583Z"
            fill="#E8E8E8"
          />
        </svg>

        <span className="text-xs lg:block block md:hidden   font-gilroy-Light text-gray-300 group-hover:text-primary ">
          Book Me
        </span>
      </div>
    </div>
  );
};

const ApplicantFeatures = () => {
  return (
    <div className="flex justify-evenly items-center">
      <div className="flex group flex-col py-1 cursor-pointer  justify-center items-center">
        <svg
          className="w-5 h-5"
          viewBox="0 0 21 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.9235 7.6C17.9035 2.91 14.1035 0 10.0035 0C5.90348 0 2.10348 2.91 0.0834848 7.6C0.0284215 7.72617 0 7.86234 0 8C0 8.13766 0.0284215 8.27383 0.0834848 8.4C2.10348 13.09 5.90348 16 10.0035 16C14.1035 16 17.9035 13.09 19.9235 8.4C19.9785 8.27383 20.007 8.13766 20.007 8C20.007 7.86234 19.9785 7.72617 19.9235 7.6ZM10.0035 14C6.82348 14 3.83348 11.71 2.10348 8C3.83348 4.29 6.82348 2 10.0035 2C13.1835 2 16.1735 4.29 17.9035 8C16.1735 11.71 13.1835 14 10.0035 14ZM10.0035 4C9.21236 4 8.439 4.2346 7.7812 4.67412C7.12341 5.11365 6.61072 5.73836 6.30797 6.46927C6.00522 7.20017 5.926 8.00444 6.08034 8.78036C6.23468 9.55628 6.61565 10.269 7.17506 10.8284C7.73447 11.3878 8.4472 11.7688 9.22312 11.9231C9.99905 12.0775 10.8033 11.9983 11.5342 11.6955C12.2651 11.3928 12.8898 10.8801 13.3294 10.2223C13.7689 9.56448 14.0035 8.79113 14.0035 8C14.0035 6.93913 13.5821 5.92172 12.8319 5.17157C12.0818 4.42143 11.0644 4 10.0035 4ZM10.0035 10C9.60792 10 9.22124 9.8827 8.89234 9.66294C8.56345 9.44318 8.3071 9.13082 8.15573 8.76537C8.00435 8.39991 7.96474 7.99778 8.04191 7.60982C8.11908 7.22186 8.30957 6.86549 8.58927 6.58579C8.86898 6.30608 9.22534 6.1156 9.6133 6.03843C10.0013 5.96126 10.4034 6.00087 10.7689 6.15224C11.1343 6.30362 11.4467 6.55996 11.6664 6.88886C11.8862 7.21776 12.0035 7.60444 12.0035 8C12.0035 8.53043 11.7928 9.03914 11.4177 9.41421C11.0426 9.78929 10.5339 10 10.0035 10Z"
            fill="#E5E5E5"
            fillOpacity="0.9"
          />
        </svg>

        <span className="text-xs lg:block block md:hidden font-gilroy-Light text-gray-300 group-hover:text-primary">
          Profile
        </span>
      </div>
      <div className="flex group flex-col cursor-pointer   justify-center items-center">
        <svg
          className="w-5 h-5"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.646 10.7155C14.6264 9.94415 15.342 8.88642 15.6933 7.68944C16.0445 6.49246 16.014 5.21576 15.6058 4.03696C15.1977 2.85817 14.4323 1.83589 13.4161 1.11235C12.3999 0.388815 11.1835 0 9.93603 0C8.68858 0 7.47215 0.388815 6.45596 1.11235C5.43978 1.83589 4.67438 2.85817 4.26624 4.03696C3.85811 5.21576 3.82754 6.49246 4.17879 7.68944C4.53004 8.88642 5.24564 9.94415 6.22603 10.7155C4.54611 11.3885 3.08032 12.5048 1.98492 13.9454C0.88953 15.386 0.205595 17.0968 0.00603184 18.8955C-0.00841357 19.0268 0.00314838 19.1597 0.0400573 19.2866C0.0769662 19.4134 0.138499 19.5317 0.221143 19.6348C0.388051 19.843 0.630815 19.9763 0.896032 20.0055C1.16125 20.0347 1.42719 19.9573 1.63536 19.7904C1.84352 19.6235 1.97686 19.3807 2.00603 19.1155C2.22562 17.1607 3.15772 15.3553 4.62425 14.0443C6.09078 12.7333 7.98893 12.0085 9.95603 12.0085C11.9231 12.0085 13.8213 12.7333 15.2878 14.0443C16.7543 15.3553 17.6864 17.1607 17.906 19.1155C17.9332 19.3612 18.0505 19.5882 18.2351 19.7525C18.4198 19.9169 18.6588 20.007 18.906 20.0055H19.016C19.2782 19.9753 19.5178 19.8428 19.6826 19.6367C19.8474 19.4307 19.9241 19.1679 19.896 18.9055C19.6955 17.1017 19.0079 15.3865 17.9069 13.9437C16.8059 12.5009 15.3329 11.385 13.646 10.7155ZM9.93603 10.0055C9.14491 10.0055 8.37155 9.7709 7.71375 9.33137C7.05595 8.89185 6.54326 8.26713 6.24051 7.53623C5.93776 6.80533 5.85855 6.00106 6.01289 5.22513C6.16723 4.44921 6.54819 3.73648 7.1076 3.17707C7.66701 2.61766 8.37975 2.2367 9.15567 2.08235C9.93159 1.92801 10.7359 2.00723 11.4668 2.30998C12.1977 2.61273 12.8224 3.12542 13.2619 3.78321C13.7014 4.44101 13.936 5.21437 13.936 6.0055C13.936 7.06636 13.5146 8.08378 12.7645 8.83392C12.0143 9.58407 10.9969 10.0055 9.93603 10.0055Z"
            fill="#E5E5E5"
            fillOpacity="0.9"
          />
        </svg>

        <span className="text-xs lg:block block md:hidden   font-gilroy-Light text-gray-300 group-hover:text-primary">
          Watch Clip
        </span>
      </div>
    </div>
  );
};

export default TalentItemCard;
