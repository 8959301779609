import { set } from "lodash";
import React, { useState } from "react";
import { priceRange } from "../../config/utilities";
import DISCOUNTBADGE from "../../assets/images/discountbadge.png";
import { useHistory } from "react-router-dom";

const Pricing = () => {
  const [plan, setPlan] = useState(0);
  const history = useHistory();

  const goToPlan = () => {
      
      history.replace('/login');
  }
  return (
    <div className=" w-full flex flex-col justify-center items-center bg-blue-100 mt-24 px-4 py-20">
      <h1 className="font-bold text-xl lg:text-4xl text-primary text-center">
        The Right Plan for Your Career
      </h1>
      <p className="font-gilroy-Light text-lg lg:text-xl text-primary text-center">
        Upgrade to get discovered and apply to castings worldwide
      </p>

      <div className="mt-20">
        <div className="flex bg-white  rounded-full shadow-lg justify-between items-center">
          {/* <span onClick={()=>setPlan(0)} className={`${plan===0?'text-white bg-primary rounded-full': ''} h-14 font-gilroy-Light text-sm p-4 px-8 `}>MONTHLY</span> */}
          <span
            onClick={() => setPlan(0)}
            className={`${
              plan === 0 ? "text-white bg-primary rounded-full" : ""
            } h-14 font-gilroy-Light text-sm text-black p-4 px-8 rounded-full cursor-pointer`}
          >
            6 MONTHS
          </span>
          <span
            onClick={() => setPlan(1)}
            className={`${
              plan === 1 ? "text-white bg-primary rounded-full" : ""
            } h-14 font-gilroy-Light text-sm text-black p-4 px-8 rounded-full cursor-pointer`}
          >
            YEARLY
          </span>
        </div>
      </div>

      <div className="w-full px-6 flex lg:hidden flex-col items-center justify-center mt-10">
        <div className="border border-primary rounded-3xl w-96  h-5/6 mb-10 py-10 ">
          <h1 className="text-center text-4xl font-gilroy-Light py-2 text-primary">
            Premium
          </h1>
          <h1 className="text-center text-5xl  flex justify-center">
            <span className="mr-2">${priceRange[plan]?.premium}</span>
            {
              plan === 1 && (
                <img className=" w-12" src={DISCOUNTBADGE} alt="" />
              )
            }
          </h1>
          <div className="w-full flex flex-col px-6 justify-center">
            <div className="py-4 flex justify-between">
              <p className="font-gilroy-Light flex-1 text-lg text-black ">
                Photo Upload
              </p>
              <p className="font-gilroy-Light  w-28 text-lg   text-black ">
                Unlimited
              </p>
            </div>
            <div className=" py-4 flex justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Video Upload
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black ">
                1 only
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Resume Upload
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black ">
                <svg
                  class=" w-7 h-7"
                  fill="#3A77FF"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Audio Upload
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                Unlimited
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Apply For Jobs
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                Unlimited
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Notification & Suggestion
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                <svg
                  class=" w-7 h-7"
                  fill="#3A77FF"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Recommendation
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                Unlimited
              </p>
            </div>
            <div className=" flex justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Message, Follow & Unfollow
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black text-center">
                <svg
                  class=" w-7 h-7"
                  fill="#3A77FF"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  w-full flex px-6 py-4 justify-around">
              <button onClick={() => goToPlan()} className="text-white text-xl w-full border-full bg-primary rounded-full px-5 py-4 font-gilroy-Light">
                {" "}
                Choose plan
              </button>
            </div>
          </div>
        </div>

        <div className=" shadow-2xl bg-white rounded-3xl w-96   h-5/6 mb-10 py-10 ">
          <h1 className="text-center text-4xl font-gilroy-Light py-2 text-primary">
            Pro
          </h1>
          <h1 className="text-center text-5xl  flex justify-center">
            <span className="mr-2">${priceRange[plan]?.pro}</span>
            {
              plan === 1 && (
                <img className=" w-12" src={DISCOUNTBADGE} alt="" />
              )
            }
          </h1>
          <div className="w-full flex flex-col px-6 justify-center">
            <div className="py-4 flex justify-between">
              <p className="font-gilroy-Light flex-1 text-lg text-black ">
                Photo Upload
              </p>
              <p className="font-gilroy-Light  w-28 text-lg   text-black ">
                3 Only
              </p>
            </div>
            <div className=" py-4 flex justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Video Upload
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black ">
                1 only
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Resume Upload
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black ">
                <svg
                  class=" w-7 h-7"
                  fill="#3A77FF"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Audio Upload
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                1 Only
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Apply For Jobs
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                10 Per Month
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Notification & Suggestion
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                <svg
                  class="w-7 h-7"
                  fill="#3A77FF"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Recommendation
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                3 Only
              </p>
            </div>
            <div className=" flex justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Message, Follow & Unfollow
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black text-center">
                <svg
                  class=" w-7 h-7"
                  fill="#3A77FF"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-around">
              <button onClick={() => goToPlan()} className="text-white text-xl w-full border-full bg-primary rounded-full px-5 py-4 font-gilroy-Light">
                {" "}
                Choose plan
              </button>
            </div>
          </div>
        </div>

        <div className="border border-primary rounded-3xl w-96   h-5/6 mb-10 py-10 ">
          <h1 className="text-center text-4xl font-gilroy-Light py-2 text-primary">
            Basic
          </h1>
          <h1 className="text-center text-5xl  flex justify-center">
            <span className="mr-2">FREE</span>
          </h1>
          <div className="w-full flex flex-col px-6 justify-center">
            <div className="py-4 flex justify-between">
              <p className="font-gilroy-Light flex-1 text-lg text-black ">
                Photo Upload
              </p>
              <p className="font-gilroy-Light  w-28 text-lg   text-black ">
                1 Only
              </p>
            </div>
            <div className=" py-4 flex justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Video Upload
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black ">
                <svg
                  class=" w-7 h-7 "
                  fill="red"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Resume Upload
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black ">
                <svg
                  class=" w-7 h-7 "
                  fill="red"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Audio Upload
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                <svg
                  class=" w-7 h-7 "
                  fill="red"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Apply For Jobs
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                <svg
                  class=" w-7 h-7 "
                  fill="red"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Notification & Suggestion
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                <svg
                  class=" w-7 h-7 "
                  fill="red"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Recommendation
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black">
                <svg
                  class=" w-7 h-7 "
                  fill="red"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className=" flex justify-between">
              <p className="font-gilroy-Light flex-1 text-lg  text-black ">
                Message, Follow & Unfollow
              </p>
              <p className="font-gilroy-Light w-28  text-lg  text-black text-center">
                <svg
                  class=" w-7 h-7 "
                  fill="red"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </p>
            </div>
            <div className="  flex  py-4 justify-around">
              <button onClick={() => goToPlan()} className="text-white text-xl w-full border-full bg-primary rounded-full px-5 py-4 font-gilroy-Light">
                {" "}
                Choose plan
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:flex hidden flex-row flex-wrap w-full h-full mt-20 justify-evenly justify-items-center px-4">
        <div className="flex flex-col h-5/6  space-y-2 md:space-y-6 mt-8 lg:mt-40 hidden lg:block">
          <p className="font-gilroy-Light text-sm md:text-lg text-black text-center">
            Photo Upload
          </p>
          <p className="font-gilroy-Light text-sm md:text-lg text-black text-center">
            Video Upload
          </p>
          <p className="font-gilroy-Light text-sm md:text-lg text-black text-center">
            Resume Upload
          </p>
          <p className="font-gilroy-Light text-sm md:text-lg text-black text-center">
            Audio Upload
          </p>
          <p className="font-gilroy-Light text-sm md:text-lg text-black text-center">
            Apply for Jobs
          </p>
          <p className="font-gilroy-Light text-sm md:text-lg text-black text-center">
            Notifications & Suggestions
          </p>
          <p className="font-gilroy-Light text-sm md:text-lg text-black text-center">
            Recommendations
          </p>
          <p className="font-gilroy-Light text-sm md:text-lg text-black text-center">
            Messages, Follow and Unfollow
          </p>
        </div>
        <div className="flex flex-col h-5/6 p-2  space-y-2 md:space-y-6 md:p-10 justify-center items-center ">
          <h1 className="font-gilroy-Light text-sm md:text-lg text-primary text-center">
            Basic
          </h1>
          <h1 className="font-bold text-sm md:text-lg text-black text-center">
            FREE
          </h1>
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center">
            1 only
          </p>
          <svg
            class="hidden lg:block w-7 h-7 "
            fill="red"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            class="hidden lg:block w-7 h-7 "
            fill="red"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            class="hidden lg:block w-7 h-7 "
            fill="red"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            class="hidden lg:block w-7 h-7 "
            fill="red"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            class="hidden lg:block w-7 h-7 "
            fill="red"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            class="hidden lg:block w-7 h-7 "
            fill="red"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            class="hidden lg:block w-7 h-7 "
            fill="red"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <button onClick={() => goToPlan()} className="w-32 h-8 bg-primary text-white text-sm font-gilroy-Light rounded-full shadow-lg ">
            Choose plan
          </button>
        </div>
        <div className="flex flex-col h-5/6 space-y-2 md:space-y-6 p-2 md:p-10 px-2 md:px-14 bg-white rounded-3xl shadow-2xl justify-center items-center">
          <h1 className="font-gilroy-Light text-sm md:text-lg text-primary text-center">
            Pro
          </h1>
          <h1 className="text-sm md:text-lg  text-center flex justify-center">
            <span className="mr-2">${priceRange[plan]?.pro}</span>
            {
              plan === 1 && (
                <img className=" w-12" src={DISCOUNTBADGE} alt="" />
              )
            }
          </h1>
         
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center">
            3 only
          </p>
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center">
            2 per month
          </p>
          <svg
            class="hidden lg:block w-7 h-7"
            fill="#3A77FF"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center ">
            1 only
          </p>
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center">
            5 per month
          </p>
          <svg
            class="hidden lg:block w-7 h-7"
            fill="#3A77FF"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center">
            3 only
          </p>
          <svg
            class="hidden lg:block w-7 h-7"
            fill="#3A77FF"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <button onClick={() => goToPlan()} className="w-32 h-8 bg-primary text-white text-sm font-gilroy-Light rounded-full shadow-lg">
            Choose plan
          </button>
        </div>
        <div className="flex flex-col h-5/6 p-2 space-y-2  md:space-y-6 md:p-10 justify-center items-center">
          <h1 className="font-gilroy-Light text-sm md:text-lg text-primary text-center">
            Premium
          </h1>
          <h1 className="text-sm md:text-lg  text-center flex justify-center">
            <span className="mr-2">${priceRange[plan]?.premium}</span>
            {
              plan === 1 && (
                <img className=" w-12" src={DISCOUNTBADGE} alt="" />
              )
            }
          </h1>
          <p className="hidden lg:block fon t-gilroy-Light text-sm md:text-lg text-black text-center">
            Unlimited
          </p>
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center">
            Unlimited
          </p>
          <svg
            class="hidden lg:block w-7 h-7"
            fill="#3A77FF"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center">
            Unlimited
          </p>
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center">
            Unlimited
          </p>
          <svg
            class="hidden lg:block w-7 h-7"
            fill="#3A77FF"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <p className="hidden lg:block font-gilroy-Light text-sm md:text-lg text-black text-center">
            Unlimited
          </p>
          <svg
            class="hidden lg:block w-7 h-7"
            fill="#3A77FF"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <button onClick={() => goToPlan()} className="w-32 h-8 bg-primary text-white text-sm font-gilroy-Light rounded-full shadow-lg ">
            Create plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
