import GHANA from "../assets/images/ghana.png";
import NIGERIA from "../assets/images/nigeria.png";
import SA from "../assets/images/sa.png";
import KENYA from "../assets/images/kenya.png";
import NAMIBIA from "../assets/images/namibia.png";

const countries = {
  Ghana: [
    "Ashanti Region",
    "Brong-Ahafo Region",
    "Central Region",
    "Eastern Region",
    "Greater Accra Region",
    "Northern Region",
    "Upper East Region",
    "Upper West Region",
    "Volta Region",
    "Western Region",
  ],
  Nigeria: [
    "Abia",
    "Abuja Federal Capital Territory",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ],
  Kenya: [
    "Baringo County",
    "Bomet County",
    "Bungoma County",
    "Busia County",
    "Central Province",
    "Coast Province",
    "Eastern Province",
    "Elgeyo-Marakwet County",
    "Embu County",
    "Garissa County",
    "Homa Bay County",
    "Isiolo County",
    "Kajiado County",
    "Kakamega County",
    "Kericho County",
    "Kiambu County",
    "Kilifi County",
    "Kirinyaga County",
    "Kisii County",
    "Kisumu County",
    "Kitui County",
    "Kwale County",
    "Laikipia County",
    "Lamu County",
    "Machakos County",
    "Makueni County",
    "Mandera County",
    "Marsabit County",
    "Meru County",
    "Migori County",
    "Mombasa County",
    "Muranga County",
    "Nairobi",
    "Nakuru District",
    "Nandi District",
    "North Eastern Province",
    "Nyamira District",
    "Nyandarua County",
    "Nyanza Province",
    "Nyeri County",
    "Rift Valley Province",
    "Samburu County",
    "Siaya County",
    "Taita–Taveta County",
    "Tana River County",
    "Tharaka Nithi County",
    "Trans-Nzoia District",
    "Turkana County",
    "Uasin Gishu District",
    "Vihiga District",
    "Wajir County",
    "West Pokot County",
    "Western Province ",
  ],
  "South Africa": [
    "Eastern Cape",
    "Free State",
    "Gauteng",
    "KwaZulu-Natal",
    "Limpopo",
    "Mpumalanga",
    "North West",
    "Northern Cape",
    "Western Cape",
  ],
  Namibia: [
    "Erongo Region",
    "Hardap Region",
    "Karas Region",
    "Kavango East Region",
    "Kavango West Region",
    "Khomas Region",
    "Kunene Region",
    "Ohangwena Region",
    "Omaheke Region",
    "Omusati Region",
    "Oshana Region",
    "Oshikoto Region",
    "Otjozondjupa Region",
    "Zambezi Region",
  ],
  Uganda: [
    "Kampala",
    "Mbale",
    "Arua",
    "Tororo",
    "Gulu",
    "Lira",
    "Mbarara",
    "Bombo",
    "Jinja",
    "Entebbe",
    "Kasese",
    "Masaka",
    "Kitgum",
    "Busia",
    "Iganga",
    "Kabale",
    "Fort Portal",
    "Mityana",
    "Adjumani",
    "Masindi",
    "Pallisa",
    "Nebbi",
    "Moyo",
    "Kayunga",
    "Mubende",
    "Pakwach",
    "Ntungamo",
    "Kiboga",
    "Sironko",
    "Kumi",
    "Kisoro",
    "Kamuli",
    "Mpigi",
    "Katakwi",
    "Nakasongola",
    "Kalangala",
    "Kaberamaido",
    "Kaabong",
    "Soroti",
    "Moroto",
    "Koboko",
    "Bugiri",
    "Kapchorwa",
    "Bundibugyo",
    "Hoima",
    "Mukono",
    "Yumbe",
    "Kasaali",
    "Bududa",
    "Wakiso",
    "Lyantonde",
    "Mayuge",
    "Bulambuli",
    "Maracha",
    "Luwero",
    "Namayingo",
    "Luuka Town",
    "Kibuku",
    "Bupoto",
    "Apac",
    "Rubirizi",
    "Busesa",
    "Buikwe",
    "Kagadi",
    "Bushenyi",
    "Kaliro",
    "Butebo",
    "Namutumba",
    "Manafwa",
    "Rukungiri",
    "Gombe",
    "Kyenjojo",
    "Mitoma",
    "Kalungu",
    "Bukomansimbi",
    "Rubanda",
    "Butaleja",
    "Serere",
    "Amuria",
    "Nsiika",
    "Kibiito",
    "Kibingo",
    "Bukedea",
    "Ntara",
    "Binyin",
    "Mparo",
    "Kinoni",
    "Kole",
    "Zombo",
    "Rakai",
    "Lwengo",
    "Bukwo",
    "Kasanda",
    "Dokolo",
    "Kamwenge",
    "Ngora",
    "Alebtong",
    "Kanungu",
    "Kanoni",
    "Oyam",
    "Sembabule",
    "Kakumiro",
    "Buyende",
    "Kalaki",
    "Isingiro",
    "Kyegegwa",
    "Butalangu",
    "Amolatar",
    "Lamwo",
    "Palenga",
    "Kotido",
    "Pader",
    "Agago",
    "Bulisa",
    "Amudat",
    "Nabilatuk",
    "Napak",
    "Kiryandongo",
    "Kiruhura",
    "Abim",
    "Ntoroko",
    "Otuke",
    "Amuru",
    "Nakapiripirit",
    "Kyankwanzi",
    "Nwoya",
    "Kitamilo",
  ],
};

const phonecodes = {
  233: GHANA,
  234: NIGERIA,
  245: KENYA,
  27: SA,
  264: NAMIBIA,
};

export { countries, phonecodes };
