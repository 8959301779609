import React, {useEffect, useState} from "react";
import PROFILEAVATAR from "../../../../../assets/images/profile_avatar.jpg";
import Profileforms from "./profileForms";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

const Editprofilepage = () => {
  const history = useHistory();
  const [loggedUser, setLoggedUser] = useState({});
  const {
    register,
    handleSubmit
  } = useForm();

  useEffect(() => {
    const isLogged = sessionStorage.getItem("AUTH_USER");
    if (!isLogged) {
      history.push("/");
      return;
    } 
      const parsedLgged = JSON.parse(isLogged);
      setLoggedUser(parsedLgged);
  }, []);

    const onSubmit = (data) => {};

  

  return (
    <div className="w-full flex lg:flex-row flex-col items-center lg:items-start lg:justify-center py-16 mt-24">
      <div>
        {/* Profile picture placeholder */}
        <div
          style={{
            backgroundImage: `url(${
              loggedUser?.company_details?.company_logo || PROFILEAVATAR
            })`,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            objectFit: "cover",
          }}
          className="border mr-4 flex justify-center lg:mb-0 mb-2 items-end w-56 h-64 rounded-xl"
        >
          <div className="w-full cursor-pointer text-white py-2 overflow-auto rounded-br-xl flex flex-col justify-center items-center rounded-bl-xl bg-primary">
            <span className=" cursor-pointer text-center font-gilroy-Light w-full  ">
              Add/Remove Photo
            </span>
            <input
              type="file"
              className="absolute overflow-hidden  w-56 opacity-0  cursor-pointer "
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center w-full lg:w-5/12">
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          <Profileforms loggedUser={loggedUser} onSubmit={onSubmit} register={register} />
          <div className=" flex justify-center mt-6">
            <button
              // type="submit"
              // value="Update profile"
              onClick={handleSubmit(onSubmit)}
              className="bg-primary font-gilroy-Light text-white w-44 rounded-full px-6 py-1"
            >
            Update profile
            </button>
          </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default Editprofilepage;
