import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PackageContent from "../../../../context/context";
import Loader from "../../Loader";
const UserCompletions = ({ talentForm, accountCreation }) => {
  const history = useHistory();
  const context = useContext(PackageContent);

  const goToLogin = () => {
    context.updatePath("/login");
    history.replace("/login");
  };

  return (
    <>
      <div className="shadow-2xl pb-6 bg-white rounded-2xl md:rounded-3xl w-full md:w-9/12 lg:w-5/12  ">
        {/* Header */}
        <div className="mb-6  h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
          <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
            Thanks {talentForm.talentDetails.talent_first_name}{" "}
            {talentForm.talentDetails.talent_last_name}!
          </h1>
          {accountCreation === "Setting up your account..." ? (
            <span className=" font-gilroy-Medium text-red-500 cursor-pointer">
              {accountCreation}
            </span>
          ) : (
            <span
              onClick={() => goToLogin()}
              className=" font-gilroy-Light text-primary cursor-pointer"
            >
              {accountCreation}
            </span>
          )}
        </div>

        {/* Completion Body */}
        {accountCreation === "Setting up your account..." ? (
          <Loader screen="h-full" />
        ) : (
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-center md:w-3/6 text-2xl font-gilroy-Light mb-6">
              Please confirm your email to complete your registration.
            </h1>
            <p className="text-center md:w-3/6 mb-9 text-sm font-gilroy-Light">
              Click the button in the email we've just sent to
              {talentForm.talentUserData.email} and you'll be on your way!
            </p>
            <div className="w-4/6 border border-black mb-4"></div>
            <p className="text-center text-sm font-gilroy-Light mb-4">
              Didn't get the email?
            </p>
            <p className="text-center text-sm w-5/6 md:w-3/6 font-gilroy-Light">
              Check your spam folder for the email,{" "}
              <span className="text-primary cursor-pointer ">
                send the email again
              </span>
              , or{" "}
              <span className="text-primary cursor-pointer ">
                change your email address
              </span>
              .
            </p>
          </div>
        )}

        {/* End of Completion body */}
      </div>
    </>
  );
};

export default UserCompletions;
