import React from "react";

import { casting_africa_partners } from "../../../../config/utilities";


const TrustedBadge = () => {
    return (
      <div className="bg-white overflow-x-auto mt-10 md:mt-0 flex justify-center  lg:h-48  h-28 w-full">
        <div className="flex  md:flex-row flex-col items-center md:justify-around md:items-center mb-4 w-full overflow-x lg:w-4/5">
          <span className="w-96 text-center py-4   text-formFont text-xs md:text-xl  lg:block lg:text-4xl ">
            Creators who <br /> cast with us
          </span>
          <div className={`flex px-4 lg:px-0 lg:ml-10 items-center`}>
            {casting_africa_partners.map((partners, key) => (
              <img
                className=" mr-4 w-16 lg:w-32 "
                key={key}
                src={partners.icon}
                alt={partners.company_name}
              />
            ))}
          </div>
        </div>
      </div>
    );
}


export default TrustedBadge;