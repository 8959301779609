import { gql } from "@apollo/client";

export const ON_BOARD_CREATOR_AGENT = gql`
  mutation OnBoardCreatorAgent(
    $details: CreatorAgencyData
    $userData: CreatorAgentUserData
    $companyDetails: CreatorAgencyCompanyData
  ) {
    onboardCreatorAgent(
      details: $details
      userData: $userData
      companyDetails: $companyDetails
    )
  }
`;

export const UPDATE_CREATOR_AGENT = gql`
  mutation UPDATECREATORAGENT(
    $creatorAgentId: ID
    $details: CreatorAgencyData
    $userData: CreatorAgentUserData
    $companyDetails: CreatorAgencyCompanyData
  ) {
    updateCreatorAgent(
      creatorAgentId: $creatorAgentId
      details: $details
      userData: $userData
      companyDetails: $companyDetails
    )
  }
`;

export const ON_BOARD_TALENT = gql`
  mutation OnBoardTalent(
    $talentDetails: CastingAfricaTalentData
    $talentAddress: CastingAfricaTalentAddressData
    $talentUserData: CastingAfricaTalentUserData
    $castingAfricaTalentDetails: String
    $talentSocials: CastingAfricaTalentSocialData
  ) {
    onboardTalent(
      talentDetails: $talentDetails
      talentAddress: $talentAddress
      talentUserData: $talentUserData
      castingAfricaTalentDetails: $castingAfricaTalentDetails
      talentSocials: $talentSocials
    )
  }
`;
export const UPDATE_TALENT = gql`
  mutation UpdateTalent(
    $talentID: ID
    $talentDetails: CastingAfricaTalentData
    $talentAddress: CastingAfricaTalentAddressData
    $talentUserData: CastingAfricaTalentUserData
    $castingAfricaTalentDetails: String
    $talentSocials: CastingAfricaTalentSocialData
  ) {
    updateTalent(
      talentID: $talentID
      talentDetails: $talentDetails
      talentAddress: $talentAddress
      talentUserData: $talentUserData
      castingAfricaTalentDetails: $castingAfricaTalentDetails
      talentSocials: $talentSocials
    )
  }
`;

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      access_token
      refresh_token
      user {
        email
        email_verified
        talent {
          casting_africa_talent_id
          talent_first_name
          talent_last_name
          talent_media_path
          account_type
          account_status
          photos
          talentFollowings {
            following {
              casting_africa_talent_id
            }
          }
        }
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email })
  }
`;

export const PASSWORD_RESET = gql`
  mutation passwordReset($password: String!, $code: String!) {
    passwordReset(data: { password: $password, code: $code }) {
      talent_last_name
    }
  }
`;

export const UPLOAD_PROFILE_PIC = gql`
  mutation uploadProfilePicture(
    $profilePic: Upload
    $talentID: ID
    $media: String
    $photoType: String
  ) {
    uploadProfilePhoto(
      profilePic: $profilePic
      talentID: $talentID
      media: $media
      photoType: $photoType
    )
  }
`;

export const FOLLOW_TALENT = gql`
  mutation followTalent($talentID: ID, $followingID: ID) {
    followTalent(talentID: $talentID, followingID: $followingID)
  }
`;

export const UNFOLLOW_TALENT = gql`
  mutation unfollowTalent($talentID: ID, $followingID: ID) {
    unFollowTalent(talentID: $talentID, followingID: $followingID)
  }
`;

export const SEND_CLIENT_EMAIL = gql`
  mutation sendClientRequest($contactData: ContactUSInput) {
    contactUsEmail(contactData: $contactData)
  }
`;

export const TALENT_PAYMENT = gql`
  mutation talentPayment(
    $talentData: Data
    $reference: String
    $payment_duration: Int
    $payment_amount: Float
  ) {
    talentPayment(
      talentData: $talentData
      reference: $reference
      payment_duration: $payment_duration
      payment_amount: $payment_amount
    )
  }
`;

export const TALENT_PAYMENT_RENEWAL = gql`
  mutation renewPayment(
    $talentID: ID
    $reference: String
    $payment_duration: String
    $account_type: String
    $payment_amount: Float
  ) {
    talentRenewPayment(
      talentID: $talentID
      reference: $reference
      payment_duration: $payment_duration
      account_type: $account_type
      payment_amount: $payment_amount
    )
  }
`;

export const BOOK_TALENT_JOB = gql`
  mutation bookTalent($jobDetails: BookTalentData) {
    bookTalentForJob(jobDetails: $jobDetails) {
      project_title
      company_name
    }
  }
`;

export const USER_RESET_PASSWORD = gql`
  mutation userPasswordReset($data: UserPasswordReset) {
    userPasswordReset(data: $data)
  }
`;

export const DELETE_PROFILE_PHOTO = gql`
  mutation deleteProfilePhoto($talentID: ID $mediaType: String $fileName: String) {
    deleteProfilePicture(talentID: $talentID mediaType: $mediaType fileName: $fileName)
  }
`;
