import React, { useState, useEffect } from "react";
import ProfileViewHandler from "./ProfileViewHandler";
import { useHistory } from "react-router-dom";
import { agent_nav } from "../../../../config/utilities";

const AgentProfile = () => {
  // const context = useContext(PackageContext)
  const history = useHistory();
  const [user, setUser] = useState({});

  useEffect(() => {
    // context.updateCompanyDetails(user)
    const parsedUser = JSON.parse(sessionStorage.getItem("profile_set"));
    if (parsedUser) {
      setUser(parsedUser);
    } else {
      history.push("/");
      window.location.reload();
    }
  }, []);

  // useEffect(() => {
  //   setPosition(position);
  //   context.updateCompanyDetails(user)
  // }, []);

  return (
    <div className="w-full  mt-24 py-10 flex flex-col items-center">
      <ProfileViewHandler user={user} nav={agent_nav} />
    </div>
  );
};

export default AgentProfile;
