import React, { Fragment, useContext, useRef, useState } from "react";
import { Link } from "react-router-dom"
import PackageContext from "../../../../context/context";
import { callingCodeFlags } from "../../../../config/utilities";
import { useForm } from "react-hook-form";



const ProfessionalDetails = ({
  next,
  prev,
  professionalForm,
  setProfessionalForm,
  type
}) => {
  const context = useContext(PackageContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  const [callingCode, setCallingCode] = useState(professionalForm.details.calling_code ||  "233");
  const [flag, setFlag] = useState(
    callingCodeFlags[professionalForm.details.calling_code || callingCode].flag
  );

  const [genderSelected, setGenderSelected] = useState(
    professionalForm.details.gender || "MALE"
  );

   const handleSubmitToNextPage = (data) => {
     const info = {
       ...data,
       gender: genderSelected,
       calling_code: callingCode,
     };
     for (let key in info) {
       professionalForm[type][key] = info[key];
        if (key === "email") {
          professionalForm["userData"][key] = info[key];
          delete professionalForm[type][key];
        }
        if (key === "password") {
          professionalForm["userData"][key] = info[key];
          delete professionalForm[type][key];
        }
     }
    
     delete professionalForm["details"]["confirm_password"];
     setProfessionalForm((p) => ({ ...p, ...professionalForm }));
     next();
   };



  const handlePhoneSwitch = (e) => {
    setCallingCode(e.target.value);
    setFlag(callingCodeFlags[e.target.value].flag);
  };

  return (
    <Fragment>
      <div className="shadow-2xl pb-6 bg-white rounded-2xl md:rounded-3xl w-full md:w-9/12 lg:w-5/12  ">
        {/* Header */}
        <div className="mb-6 h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
          <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
            Personal Info.
          </h1>
          <p className=" w-5/6  md:w-4/6 text-center text-xs md:text-sm mb-4 text-formFont font-gilroy-Light">
            Please tell us who you are
          </p>
        </div>

        {/* Form Body */}
        <div className="flex flex-col px-3 md:flex-row md:px-14">
          <div className=" md:flex-1 md:pr-8 px-1 py-1 flex flex-col">
            <label
              htmlFor="First Name"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              First Name
            </label>
            <input
              className="border shadow-none rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light  focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your First Name"
              defaultValue={professionalForm.details.first_name}
              {...register("first_name", {
                required: "First name is required",
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.first_name?.message}
            </p>
          </div>
          <div className=" md:flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Last Name"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Last Name
            </label>
            <input
              className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your last name"
              defaultValue={professionalForm.details.last_name}
              {...register("last_name", { required: "Last Name is required" })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.last_name?.message}
            </p>
          </div>
        </div>

        <div className="px-3 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="E-mail"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              E-mail
            </label>
            <input
              className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="email"
              placeholder="Your e-mail address"
              defaultValue={professionalForm.details.email}
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid Email Standard",
                },
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.email?.message || (errors.email && "email is required")}
            </p>
          </div>
        </div>

        <div className="px-3 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Password"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Password
            </label>
            <input
              className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="password"
              placeholder="Password"
              name="password"
              {...register("password", {
                required: true,
                minLength: {
                  value: 8,
                  message: "Password must have at least 8 characters",
                },
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.password?.message ||
                (errors.password && "Password is required")}
            </p>
          </div>
        </div>

        <div className="px-3 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Confirm Password"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Confirm Password
            </label>
            <input
              className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="password"
              placeholder="Confirm Password"
              name="confirm_password"
              {...register("confirm_password", {
                required: "Confirm Password",
                validate: (value) =>
                  value === passwordRef.current || "Password does not match",
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.confirm_password?.message ||
                (errors.confirm_password && "Confirm password")}
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center flex-row mb-3 ">
          <div className="flex flex-col w-full px-6 md:px-14">
            <label
              htmlFor="Phone number"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Phone number
            </label>
            <div className="flex">
              <div className="py-2 md:py-3 border-r-0 rounded-tr-none rounded-br-none rounded-lg pl-2 pr-4 flex flex-row items-center justify-between border border-gray-300 font-gilroy-Light ">
                <img
                  className="w-7 lg:w-10 h-7 lg:h-6"
                  src={flag}
                  alt="Ghana Flag"
                />
                <select
                  className="w-20 md:w-20 mr-2 md:mr-4  focus:outline-none md:px-1"
                  name=""
                  id=""
                  onChange={handlePhoneSwitch}
                >
                  <option value="233">+233</option>
                  <option value="234">+234</option>
                  <option value="245">+245</option>
                  <option value="27">+27</option>
                  <option value="264">+264</option>
                </select>
              </div>
              <input
                className="border placeholder-secondary w-full rounded-tl-none rounded-bl-none  rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 md:py-3 "
                type="Text"
                placeholder=""
                defaultValue={professionalForm.details.phone_number}
                {...register("phone_number", {
                  required: "Phone Number is required",
                  minLength: {
                    value: 9,
                    message: "Phone number needs to be complete",
                  },
                })}
              />
            </div>
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.phone_number?.message}
            </p>
          </div>
        </div>

        <div className="px-3 md:px-14">
          <label
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            htmlFor="Gender"
          >
            Gender
          </label>
          <div className="flex ">
            <div className=" flex items-center mr-2 w-1/6">
              <input
                className="border-0 opacity-0 absolute w-5 h-5"
                type="checkbox"
                name=""
                id=""
                checked={genderSelected == "MALE"}
                onChange={() => setGenderSelected("MALE")}
              />
              <div
                onClick={() => setGenderSelected("MALE")}
                className="bg-white border rounded-full border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black"
              >
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#1F73F1"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="ml-1 mr-3 font-gilroy-Light text-sm md:text-lg  text-formFont">
                Male
              </span>
            </div>
            <div className=" flex items-center w-2/6">
              <input
                type="checkbox"
                className="absolute opacity-0 w-5 h-5"
                name=""
                id=""
                checked={genderSelected == "FEMALE"}
                onChange={() => setGenderSelected("FEMALE")}
              />
              <div
                onClick={() => setGenderSelected("FEMALE")}
                className="bg-white border rounded-full border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black"
              >
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#1F73F1"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="ml-1 font-gilroy-Light text-sm md:text-lg  text-formFont">
                Female
              </span>
            </div>
          </div>
        </div>

        <div className="px-3 md:px-14 mt-7 flex justify-center items-center flex-col">
          <p className=" text-center text-xs md:text-xs  font-bold">
            By signing up you agree to our{" "}
            <Link to="/" className="text-primary">
              Terms of Service
            </Link>{" "}
            and
            <Link to="/" className="text-primary">
              {" "}
              Privacy Policy
            </Link>
          </p>
          <button
            onClick={handleSubmit(handleSubmitToNextPage)}
            className=" shadow-2xl  bg-primary opacity-60 hover:opacity-100 tranition ease-out duration-700 m-4 md:m-2 flex items-center justify-center font-bold text-white rounded-full py-2  px-28 "
          >
            Next
          </button>
          <p className="text-xs  text-formFont font-gilroy-Light">
            Already have an account?
            <Link
              onClick={() => context.updatePath("/login")}
              to="/login"
              className="text-primary font-bold"
            >
              {" "}
              Login
            </Link>
          </p>
        </div>

        {/* End of form body */}
      </div>
    </Fragment>
  );
};

export default ProfessionalDetails;
