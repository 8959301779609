import React, { useEffect, useState } from "react";
import { Transition } from "@tailwindui/react";
import {
  talent_filters,
  sample_ads,
  sample_talents,
} from "../../../config/utilities";
import TalentItemCard from "../Communities/Comps/talentItemCard";
import HORIZONTALAD from "../../../assets/images/horizontalad.png";
import SQUAREAD from "../../../assets/images/squaread.png";
import VERTICALAD from "../../../assets/images/verticalad.png";
import VERTICALAD1 from "../../../assets/images/verticalad1.png";
import _, { indexOf } from "lodash";
import { Pagination } from "../Pagination";
import { useQuery } from "@apollo/client";
import { TALENTS } from "../../../graphql/queries";
import Loader from "../Loader";
import { countries } from "../../../config/countries";
import { useHistory } from "react-router-dom";


const TalentDirectory = () => {
  const [region, setRegion] = useState(
    countries[sessionStorage.getItem("community") || "Ghana"]
  );
  const [category, setCategories] = useState([]);
  const [filters, setFilters] = useState({});

  const history = useHistory();

    const linkToMonologue = () => {
        history.replace('/monologue');
    }

  const { loading, error, data } = useQuery(TALENTS, {
    variables: {
      country: sessionStorage.getItem("community") || "Ghana",
    },
    // pollInterval: 500,
  });
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);
  const [setNext, setSetNext] = useState(
    sessionStorage.getItem("CURRENT_POS") || 0
  );
  const [directoryContents, setDirectoryContents] = useState([]);
  let pagesize = 15;

  const loadusers = () => {
    if (data?.talents) {
      const directoryListing = _.chunk(data?.talents, pagesize)[setNext];
      setDirectoryContents(directoryListing);
      sessionStorage.setItem("CURRENT_POS", setNext);
    }
  };


  useEffect(() => {
    sessionStorage.setItem("CURRENT_POS", 0)
    setSetNext(sessionStorage.getItem("CURRENT_POS"));
    console.log(setNext);

  }, [])

  useEffect(() => {
    if (_.isEmpty(filters)) return;
    let directoryListing = data?.talents.filter(
      (tal) =>
        tal.talent_first_name.toLowerCase() === filters.name?.toLowerCase() ||
        tal.gender?.toLowerCase() === filters.gender?.toLowerCase()
    );
    // directoryListing = _.chunk(data?.talents, pagesize)[setNext];

    setDirectoryContents(directoryListing);
  }, [filters]);

  useEffect(() => {
    loadusers();

  }, [setNext, loading]);

  const handleCategorySelection = (cat) => {
    if (category.includes(cat)) {
      const index = category.indexOf(cat);
      const temp = [...category];
      temp.splice(index, 1);
      setCategories(temp);
    } else {
      setCategories([cat, ...category]);
    }
  };



  if (loading && data) <Loader />;

  return (
    <div
      className={`w-full flex  justify-center  py-14 mt-16 h-full overflow-y-scroll`}
    >
      <div className="w-full h-full md:px-0 px-2 md:w-4/5 py-4">
        <h1 className="text-xl md:text-2xl lg:text-4xl mb-4">
          Talents Directory
        </h1>
        <div className="flex md:flex-nowrap flex-wrap md:overflow-x-hidden  overflow-x-auto  ">
          {data &&
            Array.from(
              new Set(
                data?.talents
                  .map(
                    (tablet) =>
                      JSON.parse(
                        tablet.professionalDetails.casting_africa_talent_details
                      ).talent_type
                  )
                  .flatMap((a) => a)
              )
            ).filter(n => n).map((filter, key) => (
              <div
                key={key}
                onClick={() => handleCategorySelection(filter)}
                className={`border ${
                  category.includes(filter) ? "bg-primary text-white" : ""
                } cursor-pointer font-gilroy-Light md:text-sm md:mb-0 mb-2 mr-2  flex justify-center rounded-full px-2 md:px-4 lg:px-8 md:py-1  text-black hover:font-bold`}
              >
                <span>{filter}</span>
                {/* <span>({filter.count.toLocaleString()})</span> */}
              </div>
            ))
            }
          <div
            onClick={() => setShowAdvanceFilters(!showAdvanceFilters)}
            className="flex items-center cursor-pointer  justify-between px-2 "
          >
            <span className="font-gilroy-Light text-sm mr-1">More filters</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
        {/* More filters */}
        <MoreFilters
          showAdanceFilters={showAdvanceFilters}
          state={region}
          setFilters={setFilters}
          category={category}
        />

        <div className="w-full h-full flex flex-col mt-4">
          <div className="mb-10 flex flex-wrap ">
            {data ? (
              data?.talents.length ? (
                // _.chunk(data?.talents, 5)
                // .slice(setNext - pagesize, setNext)
                _.chunk(directoryContents, 5).map((part, key) => (
                  <div key={key} className="w-full  flex flex-col">
                    <div className="flex md:flex-nowrap items-center flex-wrap justify-center lg:justify-start w-full">
                      {part.map((talent, index) => (
                        <TalentItemCard
                          key={index}
                          width="w-80"
                          talent={talent}
                          hash={Date.now()}
                          feature_type="talent_feature"
                        />
                      ))}
                    </div>
                    {key > 0 && key % 2 == 1 ? (
                      <div onClick={() => linkToMonologue()}  className="w-full cursor-pointer py-10 flex justify-center">
                        <img src={VERTICALAD} alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))
              ) : (
                <div className="h-screen">
                  <h1>
                    Talents in {sessionStorage.getItem("community")} coming up
                    real soon..
                  </h1>
                </div>
              )
            ) : (
              <Loader />
            )}
          </div>
          <div className="">
            <Pagination
              data={_.chunk(data?.talents, pagesize)}
              pagesize={pagesize}
              nextSet={setSetNext}
              currentPos={setNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MoreFilters = ({ showAdanceFilters, state, setFilters, category }) => {
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [gender, setGender] = useState("");

  const [filtersShown, setFiltersShown] = useState([]);

  const filterResults = () => {
    let filters = {};
    if (name) filters.name = name;
    if (region) filters.region = region;
    if (gender) filters.gender = gender;
    setFilters(filters);
    setFiltersShown(Object.values(filters));
    setName("");
    setRegion("");
    setGender("");
  };

  return (
    <Transition
      show={showAdanceFilters}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="w-full border flex flex-col items-center justify-center py-5 rounded-xl bg-blue-100 mt-4">
        <div className="flex md:flex-row flex-col w-full px-5 mb-4 items-center">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border w-full rounded-xl px-4 outline-none bg-blue-200 placeholder-gray-600 text-lg py-2 font-gilroy-Light md:mr-4 mb-2"
            placeholder="Keyword"
          />
          <div
            onClick={() => filterResults()}
            className=" border cursor-pointer  w-full  md:w-36 px-3 flex justify-between md:justify-around  items-center py-2 rounded-lg bg-primary text-white"
          >
            <span className="text-lg font-gilroy-Light">Search</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>

        <div className="flex md:flex-row flex-col mb-2 md:mb-4 w-full px-5 items-center">
          <select
            value={region}
            onChange={(e) => {
              setRegion(e.target.value);
              // setFiltersShown([e.target.value, ...filtersShown]);
            }}
            className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
            name=""
            id=""
          >
            <option value="">State/Region</option>
            {state.map((region, key) => (
              <option key={key} value={region}>
                {region}
              </option>
            ))}
          </select>

          <select
            className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
            name=""
            value={gender}
            onChange={(e) => {
              setGender(e.target.value);
              // setFiltersShown([e.target.value, ...filtersShown]);
            }}
          >
            <option value="">Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>

          <div
            className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
            name=""
            id=""
          >
            <input type="range" name="" id="" />
            {/* <option value="">Age Range</option> */}
          </div>

          <div
            className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
            name=""
            id=""
          >
            <input type="range" className=" bg-primary" name="" id="" />
            {/* <option value="">Height Range</option> */}
          </div>
        </div>

        <div className="flex md:flex-row flex-col w-full px-5 items-center">
          {category.includes("Actor") && (
            <select
              className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
              name=""
              id=""
            >
              {/* <option value="">Actor Category</option> */}
              {["Film/Tv", "Stage", "Commercial", "Voice", "Presenter"].map(
                (actor, key) => (
                  <option key={key} value={actor}>
                    {actor}
                  </option>
                )
              )}
            </select>
          )}

          {category.includes("Model") && (
            <select
              className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
              name=""
              id=""
            >
              {/* <option value="">Model Category</option> */}
              {[
                "Runway",
                "Body",
                "Commercial",
                "Music Video",
                "Ushering",
                "Print",
                "Hair",
              ].map((model, key) => (
                <option key={key} value={model}>
                  {model}
                </option>
              ))}
            </select>
          )}

          {category.includes("Dancer") && (
            <select
              className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
              name=""
              id=""
            >
              {/* <option value="">Dancer Category</option> */}
              {[
                "Ballet",
                "Ballroom",
                "Contemporary",
                "Hip Hop",
                "Jazz",
                "Tap",
                "Folk",
                "Irish",
                "Modern",
                "Swing",
              ].map((category, key) => (
                <option key={key} value={category}>
                  {category}
                </option>
              ))}
            </select>
          )}

          {category.includes("Influencer") && (
            <select
              className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
              name=""
              id=""
            >
              {/* <option value="">Influencer Category</option> */}
              {[
                "Mega Influencer - 1M + followers",
                "Macro Influencer - 500K to 1M followers",
                "Micro Influencer -  20K to 500K followers",
                "Nano Influencer - 1K to 20K followers",
              ].map((influence, key) => (
                <option key={key} value={influence}>
                  {influence}
                </option>
              ))}
            </select>
          )}

          {/* <div className="flex items-center  justify-between px-2 ">
            <span className="font-bold text-sm mr-1">More </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div> */}
        </div>
      </div>
      {/* Filter output */}
      <div className="flex md:flex-row flex-col items-center justify-between  mt-4">
        <div className="flex justify-center items-center">
          {filtersShown?.map((fs, key) => (
            <span
              key={key}
              className="flex text-lg  mr-2 font-gilroy-Light justify-center items-center"
            >
              <span className="mr-2">{fs}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          ))}
          {/* <span className="flex text-lg  mr-2 font-gilroy-Light justify-center items-center">
            <span className="mr-2">Accra</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
          <span className="flex mr-2 text-lg font-gilroy-Light justify-center items-center">
            <span className="mr-2">25- 35</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
          <span className="flex mr-2 text-lg font-gilroy-Light justify-center items-center">
            <span className="mr-2">Male</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span> */}
        </div>
        <div>
          <select
            className="py-2  rounded-xl px-2 outline-none font-gilroy-Light text-lg bg-blue-200"
            name=""
            id=""
          >
            <option value="">Best match first</option>
          </select>
        </div>
      </div>
    </Transition>
  );
};

export default TalentDirectory;
