import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import Provider from "./context/provider";
import client from "./graphql";
import { ApolloProvider } from "@apollo/client";

ReactDOM.render(
  <Router>
    <Provider>
      {/* <AuthProvider> */}
        <ApolloProvider client={client}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </ApolloProvider>
      {/* </AuthProvider> */}
    </Provider>
  </Router>,
  document.getElementById("root")
);


