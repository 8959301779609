import React, { useContext } from "react";
import ReactPlayer from "react-player";
import MEMBERS from "../../assets/images/Casting Africa_files/members.png";
import JOBS from "../../assets/images/Casting Africa_files/jobs.png";
import PAYOUTS from "../../assets/images/Casting Africa_files/payout.png";
import TALENTPERFORMER from "../../assets/images/Casting Africa_files/talentperformer.png";
import CASTINGPROFESSIONAL from "../../assets/images/Casting Africa_files/casting.png";
import TALENTAGENCY from "../../assets/images/Casting Africa_files/talentagency.png";
import ACHIAA from "../../assets/images/Casting Africa_files/achiaa.png";
import { team } from "../../config/utilities";
import PLAYBACK from "../../assets/videos/media.mp4";
import { Link } from "react-router-dom";
import PackageContext from "../../context/context";

const AboutUs = () => {
  const context = useContext(PackageContext);
  
  return (
    <div className="mt-24 w-full">
      <div className="w-full h-80 lg:h-102 bg-primary  flex flex-col justify-center items-center ">
        <h1 className="text-center font-gilroy-Light text-white text-xl lg:text-2xl mb-5 lg:mb-10">
          WHO ARE WE?
        </h1>
        <h1 className="text-center text-white text-5xl  lg:text-6xl  lg:mb-5">
          We're Africa's
        </h1>
        <h1 className="text-center text-white text-5xl  lg:text-6xl">
          Home of Casting
        </h1>
        {/* <video src={PLAYBACK} className="w-full h-full  z-0 " autoPlay  /> */}
      </div>

      <div className="py-8 lg:py-10">
        <h1 className=" text-2xl lg:text-4xl font-gilroy-Medium text-center mb-4 lg:mb-7">
          What We Do
        </h1>
        <p className="text-center font-gilroy-Light text-lg lg:text-2xl">
          We connect African talent and global content creators by providing a
          robust peer
        </p>
        <p className="text-center font-gilroy-Light text-lg lg:text-2xl">
          to peer (p2p) marketplace, endowed with cutting-edge casting,
          upskilling and
        </p>
        <p className="text-center font-gilroy-Light text-lg lg:text-2xl">
          video sharing tools that makes it a top-tier resource for the
          discovery and
        </p>
        <p className="text-center font-gilroy-Light text-lg lg:text-2xl">
          procurement of talent globally.{" "}
        </p>
      </div>

      <div className="py-7 lg:py-10">
        <h1 className="text-2xl lg:text-4xl font-gilroy-Medium text-center mb-7">
          In Just One Year
        </h1>
        <p className="text-center font-gilroy-Light text-lg lg:text-2xl">
          We have grown our network of partners and creators and
        </p>
        <p className="text-center font-gilroy-Light text-lg lg:text-2xl">
          have significantly impacted the Ghanaian Film Industry
        </p>
      </div>

      <div className="py-10 flex justify-center">
        <div className=" flex  lg:flex-row flex-col mr-5 px-5">
          <div className="mr-2">
            <img src={MEMBERS} alt="" />
          </div>
          <div className="flex flex-col items-center">
            <span className="text-3xl font-gilroy-Extrabold">1,000</span>
            <span className="font-gilroy-Light">Members</span>
            <span className="font-gilroy-Light">Joined</span>
          </div>
        </div>

        <div className=" flex lg:flex-row flex-col mr-5 px-5">
          <div className="mr-2">
            <img src={JOBS} alt="" />
          </div>
          <div className="flex flex-col items-center">
            <span className="text-3xl font-gilroy-Extrabold">1,200</span>
            <span className="font-gilroy-Light">Jobs</span>
            <span className="font-gilroy-Light">Provided</span>
          </div>
        </div>

        <div className=" flex lg:flex-row flex-col lg:mr-5 px-5">
          <div className="mr-2">
            <img src={PAYOUTS} alt="" />
          </div>
          <div className="flex flex-col items-center">
            <span className="text-3xl font-gilroy-Extrabold">$100k</span>
            <span className="font-gilroy-Light">Payout</span>
            <span className="font-gilroy-Light">To Talents</span>
          </div>
        </div>
      </div>

      <div className="py-10 px-2  flex  justify-center items-center">
        <div
          style={{
            backgroundImage: `url(${TALENTPERFORMER})`,
            objectFit: "contain",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
          className=" w-52 h-52  lg:w-72 lg:h-96 rounded-xl mr-5 lg:mr-20"
        ></div>
        <div>
          <h1 className=" text-xl lg:text-3xl mb-5">Talents & Performers</h1>
          <p className="font-gilroy-Light text-sm lg:text-lg ">
            Our cutting edge tools provide newbies and rising talents{" "}
          </p>
          <p className="font-gilroy-Light text-sm lg:text-lg ">
            living in Africa the opportunities to create professional
          </p>
          <p className="font-gilroy-Light text-sm lg:text-lg ">
            talent profiles showing the best of them in just one click
          </p>
          <p className="font-gilroy-Light text-sm lg:text-lg ">
            making it easier for casting professionals and content
          </p>
          <p className="font-gilroy-Light text-sm lg:text-lg  mb-5">
            creators to find them when casting.{" "}
          </p>
          <Link
            onClick={() => context.updatePath("/signup")}
            to="/signup"
            className="bg-primary rounded-full  text-white px-3 text-sm lg:text-lg  lg:px-5 py-1"
          >
            Join Now
          </Link>
        </div>
      </div>

      <div className="py-10 px-2 flex justify-center">
        <div className="flex flex-col items-end justify-center">
          <h1 className="text-xl lg:text-3xl mb-5">Casting Professionals</h1>
          <p className="font-gilroy-Light text-right  text-sm lg:text-lg">
            Talent scoting can be duanting. However with our robust
          </p>
          <p className="font-gilroy-Light text-right  text-sm lg:text-lg">
            casting tools you can save time, energy and money. From
          </p>
          <p className="font-gilroy-Light text-right  text-sm lg:text-lg">
            the comfort of your home or office post and manage
          </p>
          <p className="font-gilroy-Light text-right  text-sm lg:text-lg">
            jobs, browse, shortlist, manage applications and book
          </p>
          <p className="font-gilroy-Light text-right  text-sm lg:text-lg mb-5">
            talent with just clicks.{" "}
          </p>
          <Link
            onClick={() => context.updatePath("/signup")}
            to="/signup"
            className="bg-primary rounded-full  text-white px-3 text-sm lg:text-lg  lg:px-5 py-1"
          >
            Join Now
          </Link>
        </div>
        <div
          style={{
            backgroundImage: `url(${CASTINGPROFESSIONAL})`,
            objectFit: "contain",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
          className="w-52 h-52  lg:w-72 lg:h-96 rounded-xl ml-5 lg:ml-20"
        ></div>
      </div>

      <div className="py-10 px-2 flex justify-center items-center">
        <div
          style={{
            backgroundImage: `url(${TALENTAGENCY})`,
            objectFit: "contain",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
          className="w-52 h-52  lg:w-72 lg:h-96 rounded-xl mr-5 lg:mr-20"
        ></div>
        <div>
          <h1 className="text-xl lg:text-3xl mb-5">Talents Agency</h1>
          <p className="font-gilroy-Light text-sm lg:text-lg">
            Manging your talent rooster just got easier.
          </p>
          <p className="font-gilroy-Light text-sm lg:text-lg">
            create and manage your talent profiles, apply for jobs for
          </p>
          <p className="font-gilroy-Light text-sm lg:text-lg">
            them and mange the applications. With tons of
          </p>
          <p className="font-gilroy-Light text-sm lg:text-lg">
            unsigned talent, CatsingAfrica makes it easy to find your
          </p>
          <p className="font-gilroy-Light text-sm lg:text-lg mb-5">
            next great client.{" "}
          </p>
          <Link
            onClick={() => context.updatePath("/signup")}
            to="/signup"
            className="bg-primary rounded-full  text-white px-3 text-sm lg:text-lg  lg:px-5 py-1"
          >
            Join Now
          </Link>
        </div>
      </div>

      <div className="flex flex-col items-center px-2 py-10">
        <p className="text-sm lg:text-3xl font-gilroy-Light">
          "It was amazing how quick it was to cast
        </p>
        <p className="text-sm lg:text-3xl font-gilroy-Light">
          my file in Ghana even though I Lived in the UK"
        </p>
        <div className="flex justify-center items-center mt-7">
          <div
            style={{
              backgroundImage: `url(${ACHIAA})`,
              backgroundPosition: "center",
              objectFit: "contain",
            }}
            className="w-20 h-20 rounded-full border mr-5"
          ></div>
          <div className="flex flex-col">
            <span>Achiaa Amoah</span>
            <span className="font-gilroy-Light">Film Director</span>
          </div>
        </div>
      </div>

      <div
        id="theteam"
        className="flex w-full flex-col items-center justify-center py-10"
      >
        <h1 className="text-3xl">Our Team</h1>
        <div className=" grid grid-cols-3 gap-2 lg:gap-5 mt-10 mb-10">
          {team.map((core, key) => (
            <div className="flex flex-col items-center w-32 lg:w-60">
              <div
                style={{
                  backgroundImage: `url(${core.image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
                className=" w-32 lg:w-60 rounded-2xl border  h-36 lg:h-72 mb-5"
              ></div>
              <span className="lg:text-lg text-xs">{core.name}</span>
              <span className="font-gilroy-Light lg:text-lg text-xs text-center">
                {core.position}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
