import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { countries } from "../../../../config/countries";

const ProfessionalLocation = ({ next, prev, agentForm, setAgentForm, type }) => {
  const [occupation, setOccupation] = useState(agentForm.details.occupation || "");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [countrySelected, setCountrySelected] = useState(agentForm.companyAddress.country || "Ghana");
  const [cities, setCities] = useState(countries[countrySelected]);
  const [citySelected, setCitySelected] = useState("");

  const handleCountrySwitch = (e) => {
    setCountrySelected(e.target.value);
    setCities(countries[e.target.value]);
  };


  const handleSubmitToNextPage = (data) => {
    const info = { ...data, city: citySelected };
    for (let key in info) {
      agentForm[type][key] = info[key];
    }
    agentForm["details"]["occupation"] = occupation;
    agentForm["details"]["job_title"] = info["job_title"];
  
    setAgentForm((p) => ({ ...p, ...agentForm }));
    next();
  };

  return (
    <Fragment>
      <div className="shadow-2xl pb-36 bg-white rounded-2xl md:rounded-3xl w-full md:w-9/12 lg:w-5/12  ">
        {/* Header */}
        <div className="mb-6 h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
          <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
            Professional Info.
          </h1>
          <p className=" w-5/6  md:w-4/6 text-center text-xs md:text-sm mb-4 text-formFont font-gilroy-Light">
            Please tell us what you do
          </p>
        </div>

        {/* Form Body */}
        <div className="flex flex-row px-6 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="First Name"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Occupation
            </label>
            <div className="flex flex-wrap m-0 p-0">
              {["Agent", "Manager", "Agency"].map((talent, key) => (
                <div
                  onClick={() => setOccupation(talent)}
                  key={key}
                  className={`border ${
                    talent === occupation ? "bg-primary" : "text-formFont"
                  } hover:bg-primary cursor-pointer hover:text-white text-white font-gilroy-Light px-6 py-1  rounded-3xl border-primary m-2`}
                >
                  {talent}
                </div>
              ))}
            </div>
            <p className=" font-gilroy-Light text-red-600 ">{occupation === "" && "Select your occuptation type"}</p>
          </div>
        </div>

        <div className="px-6 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Zip Code"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Job Title
            </label>
            <input
              className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your Job Title"
              defaultValue={agentForm.details.job_title}
              {...register("job_title", { required: "Job title is required" })}
            />
            <p className=" font-gilroy-Light text-red-600 ">{errors.job_title?.message}</p>
          </div>
        </div>

        <div className="px-6 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Country"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Country
            </label>
            <select
              className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
              name=""
              id=""
              defaultValue={agentForm.companyAddress.country}
              onClick={handleCountrySwitch}
              {...register("country", { required: "Country is required" })}
            >
              <option value="Ghana">Ghana</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Kenya">Kenya</option>
              <option value="South Africa">South Africa</option>
              <option value="Namibia">Namibia</option>
            </select>
            <p className=" font-gilroy-Light text-red-600 ">{errors.country?.message}</p>
          </div>
        </div>

        <div className="px-6 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Town/City"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Town/City
            </label>
            <select
              className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
              name=""
              id=""
              defaultValue={agentForm.companyAddress.city}
              onClick={(e) => setCitySelected(e.target.value)}
              {...register("city", { required: "Select a city" })}
            >
              {cities.map((city, key) => (
                <option key={key} value={city}>
                  {city}
                </option>
              ))}
            </select>
            <p className=" font-gilroy-Light text-red-600 ">{errors.city?.message}</p>
          </div>
        </div>

        <div className="px-6 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Zip Code"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Zip Code
            </label>
            <input
              className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your Zip Code"
              defaultValue={agentForm.companyAddress.zip_code}
              {...register("zip_code", { required: "Zip Code is required" })}
            />
            <p className=" font-gilroy-Light text-red-600 ">{errors.zip_code?.message}</p>
          </div>
        </div>

        <div className="px-14 mt-11 flex  flex-row justify-center items-center">
          <button
            onClick={() => prev()}
            className="bg-primary opacity-60  hover:opacity-100 tranition ease-out duration-700 m-2 flex items-center justify-center font-bold text-white rounded-full py-2 px-14"
          >
            Back
          </button>

          <button
            onClick={handleSubmit(handleSubmitToNextPage)}
            className="bg-primary opacity-60  hover:opacity-100 tranition ease-out duration-700 m-2 flex items-center justify-center font-bold text-white rounded-full py-2  px-14"
          >
            Next
          </button>
        </div>

        {/* End of form body */}
      </div>
    </Fragment>
  );
};

export default ProfessionalLocation;
