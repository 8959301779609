import React, { useContext, useEffect, useState } from "react";
import { ProfileFooter } from ".";
import PackageContext from "../../../context/context";
import { Pagination } from "../Pagination";
import _ from "lodash";

const Photos = () => {
  const context = useContext(PackageContext);
  const talent = context?.talent;
  const [setNext, setSetNext] = useState(0);
  const [pictures, setPictures] = useState([])
  let pagesize = 9;

  useEffect(() => {
    if (talent?.photos) {
      const pictures = _.chunk(talent?.photos, pagesize)[setNext]
      setPictures(pictures);
    } 
  }, [setNext]);

  return (
    <div className="flex flex-col lg:px-0 w-full ">
      <div className=" grid grid-cols-2 w-full  md:grid-cols-3 gap-4 lg:gap-2 lg:mb-7">
        {talent?.photos
          ? pictures?.map((image, key) => (
              !image.includes('thumbnail') ? 
                <div
                onClick={() => context.setPhotoViewer({source: `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${talent?.talent_media_path}/pictures/${image}`, state: true })}
                  key={key}
                  className="h-52 w-full borde lg:w-96 lg:h-96"
                >
                  <img
                    style={{
                      objectFit: "cover",
                      objectPosition: "0% 0%",
                      backgroundSize: '100%'
                    }}
                    className="h-full w-full overflow-hidden"
                    src={`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${talent?.talent_media_path}/pictures/${image}`}
                    alt={image}
                  />
                </div>
                : ""
              ))
          : "No Photos Found at this time"}
      </div>
      <Pagination
        data={_.chunk(talent?.photos, pagesize) || []}
        pagesize={pagesize}
        nextSet={setSetNext}
        currentPos = {setNext}
      />
    </div>
  );
};

export default Photos;
