import React from "react";
import { SearchJob, Spotlight, Starter, FeaturedJobs, Stats, JobAlertBadge, TrustedBadge, Review, Prices, NumberStats, Ad, AdsBanner } from "../Comps";
import BANNER from "../../../../assets/images/banner_gh1.png";
import STAT from "../../../../assets/images/notify.png"
import STAT1 from "../../../../assets/images/Rectangle627.png"
import STAT2 from "../../../../assets/images/Rectangle462.png"
import ADS_BANNER from "../../../../assets/images/ad-1.png"


const GhanaHome = () => {
    return (
      <div className="w-full mt-24">
        <Starter color="bg-orange" btncolor="bg-primary" starter_image={BANNER} />
        <SearchJob color="bg-primary" defaultCountry="Ghana" />
        <Spotlight color="bg-primary"/>
        {/* <FeaturedJobs /> */}
        <AdsBanner color={"bg-white"} ads_image={ADS_BANNER}/>
        {/* <Stats stat_image={STAT} /> */}
        {/* <NumberStats  stat_image={STAT1} stat_image2={STAT2}/> */}
        <TrustedBadge />
        <JobAlertBadge color="bg-primary" btncolor="bg-primary" community={false} />
        <div className="py-4 bg-adsBanner">
          <AdsBanner color={"bg-adsBanner"} ads_image={ADS_BANNER}/>
        </div>
        {/* <Review /> */}
      </div>
    );
}

export default GhanaHome;