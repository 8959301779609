import React, { useState } from "react";
import PackageContext from "../../../../context/context";
import { Link } from "react-router-dom";
import CASTINGLOGOICON from "../../../../assets/images/castingafrica_icon_white.png";
import {
  AgentDetails,
  AgentLocation,
  AgentJobDetails,
  AgentCompletion,
} from "./";

const AgentRegistration = () => {
  const [step, setStep] = useState(1);
  const [agentForm, setAgentForm] = useState({
    details: {
      first_name: "",
      last_name: "",
      calling_code: "",
      phone_number: "",
      occupation: "",
      gender: "",
      type: "AGENT",
      job_title: "",
    },
    userData: {
      email: "",
      password: "",
    },
    companyAddress: {
      country: "",
      state: "",
      city: "",
      zip_code: "",
    },
    companyDetails: {
      company_name: "",
      company_location: "",
      company_website: "",
      union_association: "",
      roster_size: "",
    },
  });


  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    if (step < 1) return null;
    setStep(step - 1);
  };

  const multiStep = () => {
    switch (step) {
      case 1:
        return (
          <AgentDetails
            step={step}
            next={nextStep}
            agentForm={agentForm}
            setAgentForm={setAgentForm}
            type="details"
          />
        );
      case 2:
        return (
          <AgentLocation
            step={step}
            next={nextStep}
            prev={prevStep}
            agentForm={agentForm}
            setAgentForm={setAgentForm}
            type="companyAddress"
          />
        );
      case 3:
        return (
          <AgentJobDetails
            step={step}
            next={nextStep}
            prev={prevStep}
            agentForm={agentForm}
            setAgentForm={setAgentForm}
            type="companyDetails"
          />
        );
      case 4:
        return (
          <AgentCompletion
            step={step}
            next={nextStep}
            prev={prevStep}
            agentForm={agentForm}
            setAgentForm={setAgentForm}
          />
        );
      default:
        return null;
    }
  };

  return (
    <PackageContext.Consumer>
      {(context) => (
        <div className={`bg-primary h-screen w-full pb-40  overflow-scroll `}>
          <div className="pt-10">
            <Link
              onClick={() => context.updatePath("/")}
              className="w-full flex justify-center "
              to="/"
            >
              <img
                className=" w-3/12 md:w-1/12 h-1/6"
                src={CASTINGLOGOICON}
                alt="Casting.Africa Logo"
              />
            </Link>
          </div>

          <div className="w-full  px-3 flex justify-center  md:h3/5 ">
            {multiStep()}
          </div>
        </div>
      )}
    </PackageContext.Consumer>
  );
};

export default AgentRegistration;
