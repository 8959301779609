import React from "react";
import moment from "moment";

const Recommendationitem = ({ recommend }) => {
  return (
    <div className=" w-4/6 px-4 py-2  flex items-center justify-center">
      <div className="flex pt-1 mr-4 flex-col items-center justify-center">
        <div
          style={{
            backgroundImage: `url(${recommend?.thumbnail})`,
            backgroundPosition: "bottom center",
            backgroundRepeat: "no-repeat",
            objectFit: "cover",
            backgroundSize: "100%",
          }}
          className="w-11 mr-2 h-11 mb-2 border rounded-full flex items-center justify-center "
        ></div>
        <div className="w-0 h-8 border border-gray-200"></div>
      </div>
      <div>
        <div>
          <span className="mr-2">{recommend?.name}</span>
          <span className="font-gilroy-Light">
            {moment(recommend?.posted_at).fromNow()}
          </span>
        </div>
        <p className="font-gilroy-Light">{recommend?.comment}</p>
      </div>
      <svg
        width="24"
        height="24"
        className="cursor-pointer"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.761 20.8538C9.5904 19.5179 7.57111 17.9456 5.73929 16.1652C4.45144 14.8829 3.47101 13.3198 2.8731 11.5954C1.79714 8.25031 3.05393 4.42083 6.57112 3.28752C8.41961 2.69243 10.4384 3.03255 11.9961 4.20148C13.5543 3.03398 15.5725 2.69398 17.4211 3.28752C20.9383 4.42083 22.2041 8.25031 21.1281 11.5954C20.5302 13.3198 19.5498 14.8829 18.2619 16.1652C16.4301 17.9456 14.4108 19.5179 12.2402 20.8538L12.0051 21L11.761 20.8538Z"
          stroke="#200E32"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7393 7.05322C16.8046 7.39352 17.5615 8.34992 17.6561 9.47521"
          stroke="#200E32"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Recommendationitem;
