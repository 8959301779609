import React, { useState, useEffect } from "react";
import {
  heightRange,
  talent_physical_attributes,
} from "../../../../config/utilities";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { UPDATE_TALENT } from "../../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { NotificationManager } from "react-notifications";

const UserPhysicalAttributes = ({
  next,
  prev,
  handleFormChange,
  setTalentForm,
  talentForm,
  signupmode,
  type,
  step,
  setStep,
}) => {
  const [build, setBuild] = useState(
    talentForm.castingAfricaTalentDetails.build
  );
  const [hairColor, setHairColor] = useState(
    talentForm.castingAfricaTalentDetails.hairColor
  );
  const [eyeColor, setEyeColor] = useState(
    talentForm.castingAfricaTalentDetails.eyeColor
  );
  const [hairType, setHairType] = useState(
    talentForm.castingAfricaTalentDetails.hairType
  );

  const [skinColor, setSkinColor] = useState(
    talentForm.castingAfricaTalentDetails.skinColor
  );

  const [dressSize, setDressSize] = useState(
    talentForm.castingAfricaTalentDetails.dressSize
  );
  const [height, setHeight] = useState(
    talentForm.castingAfricaTalentDetails.height
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [logged] = useState(sessionStorage.getItem("AUTH_USER"));

  const [
    updateTalent,
    { data: talent_data, loading: talent_loading, error: talent_error },
  ] = useMutation(UPDATE_TALENT);


  const handleSubmitToNextPage = (data) => {
    talentForm[type]["dressSize"] = dressSize;
    talentForm[type]["height"] = height;
    talentForm[type]["build"] = build;
    talentForm[type]["hairColor"] = hairColor;
    talentForm[type]["eyeColor"] = eyeColor;
    talentForm[type]["hairType"] = hairType;
    talentForm[type]["waist"] = data["waist"];
    talentForm[type]["skinColor"] = skinColor;
    talentForm[type]["shoe"] = data["shoe"];
    talentForm[type]["hips"] = data["hips"];

    setTalentForm((p) => ({ ...p, ...talentForm }));
    if (step && signupmode === "TALENTEDIT") {
      setStep(step + 1);
    } else {
      next();
    }
  };

  const checkPhyiscals = (methodName, value) => {
    eval(methodName)(value);
  };

  const retract = () => {
    prev();
  };

   // Refactor
   const handleSubmitToUpdate = (data) => {
    let temp = talentForm;
    temp['castingAfricaTalentDetails']['build'] = build
    temp['castingAfricaTalentDetails']['hairColor'] = hairColor
    temp['castingAfricaTalentDetails']['hairType'] = hairType
    temp['castingAfricaTalentDetails']['eyeColor'] = eyeColor
    temp['castingAfricaTalentDetails']['waist'] =  data["waist"]
    temp['castingAfricaTalentDetails']['skinColor'] = skinColor
    temp['castingAfricaTalentDetails']['shoe'] = data["shoe"]
    temp['castingAfricaTalentDetails']['hips'] =  data["hips"]
    temp['castingAfricaTalentDetails']['dressSize'] = dressSize
    temp['castingAfricaTalentDetails']['height'] = height
   

    // let tempDetails = new Object();
    // Object.entries(updateInfo).forEach(([key, value]) => {
    //     tempDetails[key] = value;
    // });

    const socials = Object.entries(temp["talentSocials"]);
    let social_asm = new Object();
    socials.forEach(([key, value]) => {
      social_asm[value["social_media_type"].toLowerCase()] =
        value["social_media_handle"];
    });

    // temp["talentAddress"] = tempDetails;
    temp["castingAfricaTalentDetails"] = JSON.stringify(
      talentForm["castingAfricaTalentDetails"]
    );
    temp["talentSocials"] = social_asm;
    delete temp["photos"];
    delete temp["resume"];
    delete temp["videos"];
    delete temp['talentAddress']['__typename']
    delete temp['talentAddress']['region_or_state']
    delete temp["talentAddress"]["talent_phonenumbers"];

    console.log("temp ", temp);

    updateTalent({
      variables: {
        talentID: JSON.parse(logged)?.user?.talent?.casting_africa_talent_id,
        ...temp,
      },
    })
      .then((data) => {
        NotificationManager.success("Updated successfully");
        window.location.reload();
      })
      .catch((err) => {
        NotificationManager.warning("Profile update failed");
      });
  };

  return (
    <>
      <div
        className={`pb-6 md:rounded-3xl w-full
       ${
         signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT"
           ? "md:w-9/12 lg:w-5/12 shadow-2xl  bg-white rounded-2xl"
           : ""
       }
      `}
      >
        {/* Header */}
        {signupmode === "AGENTSIGNUP" ||
          (signupmode !== "TALENTEDIT" && (
            <div className="mb-6  h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
              <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
                Physical Attributes
              </h1>
              <p className=" w-5/6  md:w-4/6 text-center text-xs md:text-sm mb-4 text-formFont font-gilroy-Light">
                Filling in all sections will increase your chances of being
                noticed by casting professionals.
              </p>
            </div>
          ))}

        {/* Form Body */}
        <div className="flex flex-wrap px-3 md:px-14">
          {talent_physical_attributes.map((attr, key) => (
            <div key={key} className="w-3/6 mb-4">
              <label
                className="font-gilroy-Light text-sm text-formFont"
                htmlFor={attr.label}
              >
                {attr.label}
              </label>
              <div className="flex flex-wrap">
                {attr.categories.map((category, position) => (
                  <div key={position} className="flex mr-6 mb-3 w-2/6 ">
                    <input
                      className="mr-2 p-1 md:p-14 opacity-0 absolute h-8 w-8"
                      type="checkbox"
                      name={category}
                      id=""
                      checked={eval(_.camelCase(attr.label)) === category}
                      onChange={() =>
                        checkPhyiscals(
                          `set${_.upperFirst(_.camelCase(attr.label))}`,
                          category
                        )
                      }
                    />
                    <div
                      onClick={() =>
                        checkPhyiscals(
                          `set${_.upperFirst(_.camelCase(attr.label))}`,
                          category
                        )
                      }
                      className="bg-white border rounded-md border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black"
                    >
                      <svg
                        className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                        version="1.1"
                        viewBox="0 0 17 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <g
                            transform="translate(-9 -11)"
                            fill="#1F73F1"
                            fillRule="nonzero"
                          >
                            <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="text-xs font-gilroy-Light">
                      {category}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="flex px-2 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Waist"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Waist
            </label>
            <input
              className="border shadow-none rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light  focus:outline-none px-2 py-3 "
              type="number"
              placeholder="Numeric Value  from 20 - 150"
              min={20}
              defaultValue={talentForm.castingAfricaTalentDetails.waist}
              {...register("waist", {
                min: {
                  value: 20,
                  message: "Waist should start from 20",
                },
                max: {
                  value: 150,
                  message: "Maximum waist should be 150",
                },
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.waist?.message}
            </p>
          </div>

          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Eye Color"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Skin Color
            </label>
            <select
              className="border rounded-md font-gilroy-Light border-secondary focus:outline-none px-2 py-3 "
              onClick={(e) => setSkinColor(e.target.value)}
              defaultValue={skinColor}
            >
              {["Black", "Brown", "Tanned", "White", "Fair", "Other"].map(
                (skin, key) => (
                  <option className=" font-gilroy-Light" key={key} value={skin}>
                    {skin}
                  </option>
                )
              )}
            </select>
          </div>
        </div>

        <div className="flex px-2 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Shoe"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Shoe
            </label>
            <input
              className="border shadow-none rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light  focus:outline-none px-2 py-3 "
              type="number"
              min={30}
              max={50}
              placeholder="Numeric Value  from 30 - 50"
              defaultValue={talentForm.castingAfricaTalentDetails.shoe}
              {...register("shoe", {
                min: {
                  value: 30,
                  message: "Shoe size should start from 30",
                },
                max: {
                  value: 50,
                  message: "Maximum shoe should be 50",
                },
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.shoe?.message}
            </p>
          </div>

          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Hips"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Hips
            </label>
            <input
              className="border shadow-none rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light  focus:outline-none px-2 py-3 "
              type="number"
              min={20}
              max={150}
              placeholder="Numeric Value  from 20 - 150"
              defaultValue={talentForm.castingAfricaTalentDetails.hips}
              {...register("hips", {
                min: {
                  value: 20,
                  message: "Hips should start from 20",
                },
                max: {
                  value: 150,
                  message: "Maximum hips should be 150",
                },
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.hips?.message}
            </p>
          </div>
        </div>

        <div className="flex px-2 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Dress Size"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Dress Size
            </label>
            <select
              className="border rounded-md border-secondary font-gilroy-Light focus:outline-none px-2 py-3 "
              defaultValue={dressSize}
              onChange={(e) => setDressSize(e.target.value)}
            >
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="XXL">XXL</option>
              <option value="XXXL">XXXL</option>
            </select>
          </div>

          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Height"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Height
            </label>
            <select
              className="border rounded-md border-secondary font-gilroy-Light focus:outline-none px-2 py-3 "
              defaultValue={height}
              onChange={(e) => setHeight(e.target.value)}
            >
              {heightRange.map((height, key) => (
                <option key={key} value={height}>
                  {height}
                </option>
              ))}
            </select>
          </div>
        </div>

        {["AGENTSIGNUP", "TALENTEDIT", "TALENTSIGNUP"].includes(signupmode) ? (
          <div className="px-14 mt-7 flex  flex-row justify-center items-center">
            {!logged ? (
              <div className="w-full flex  flex-row justify-center items-center">
                <button
                  onClick={() => retract()}
                  className="hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3 px-16 "
                >
                  Back
                </button>

                <button
                  onClick={handleSubmit(handleSubmitToNextPage)}
                  className="hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3  px-16 "
                >
                  Next
                </button>
              </div>
            ) : (
              <button
                onClick={handleSubmit(handleSubmitToUpdate)}
                className=" shadow-2xl  bg-primary opacity-60 hover:opacity-100 tranition ease-out duration-700 m-4 md:m-2 flex items-center justify-center font-bold text-white rounded-full py-4  px-28 "
              >
                {talent_loading ? "Please wait.." : "Update"}
              </button>
            )}
          </div>
        ) : (
          ""
        )}

        {/* End of form body */}
      </div>
    </>
  );
};

export default UserPhysicalAttributes;
