import React, { useState, useEffect } from "react";
import PackageContext from "../../../../context/context";
import { Link } from "react-router-dom";
import CASTINGLOGOICON from "../../../../assets/images/castingafrica_icon_white.png";
import {
  UserDetails,
  UserLocation,
  UserProfessionalDetails,
  UserJobPreference,
  UserPhysicalAttributes,
  UserCreditAndSkills,
  UserMoreInfo,
  UserCompletions,
  UserPricingPlan,
} from "./";
import { useLocation, useHistory } from "react-router-dom";

const TalentRegistration = () => {
  const location = useLocation();
  const history = useHistory();
  const { signupmode } = location?.state || "";
  const [step, setStep] = useState(1);
  const [createAccount, setCreateAccount] = useState(true)
  const [accountCreation, setAccountCreation] = useState('Setting up your account...')

  const [talentForm, setTalentForm] = useState({
    talentDetails: {
      talent_first_name: "",
      talent_last_name: "",
      talent_dob: "",
      calling_code: "",
      talent_phonenumbers: "",
      account_type: "",
    },
    talentUserData: {
      email: "",
      password: "",
    },

    talentAddress: {
      street_address: "",
      country: "",
      city: "",
      zip_code: "",
    },
    castingAfricaTalentDetails: {
      talent_type: [],
      actorCategory: [],
      modelCategory: [],
      dancerCategory: [],
      influencerCategory: "",
      build: "",
      hairColor: "",
      eyeColor: "",
      hairType: "",
      waist: "",
      shoe: "",
      hips: "",
      dressSize: "",
      height: "",
      actorInterest: [],
      modelInterest: [],
      dancerInterest: [],
      influencerInterest: [],
      influencerSocialInterest: [],
      credits: [],
      awards: [],
      actorExperience: "",
      dancerExperience: "",
      modelExperience: "",
      hasAgent: "",
      agentName: "",
      agentContact: "",
      language: "",
      accent: "",
      extraSkills: "",
      aboutMe: "",
      hasID: [],
    },
    talentSocials: {
      facebook: "",
      instagram: "",
      youtube: "",
      tiktok: "",
      imdb: "",
    },
  });

  const nextStep = () => {
    const user = JSON.parse(sessionStorage.getItem("AUTH_USER"));
    if (user?.access_token == "" && step == 2) {
      setStep(4);
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step < 1) return null;
    const user = JSON.parse(sessionStorage.getItem("AUTH_USER"));

    if (user?.access_token == "" && step == 4) {
      setStep(2);
    } else {
      setStep(step - 1);
    }
  };

  const handleFormChange = (input, extra) => (e) => {
    setTalentForm((prev) => ({
      ...prev,
      [input]: !extra ? e.target.value : extra,
    }));
  };

  useEffect(() => {
    if (!signupmode) {
      history.goBack();
    }

    return () => {
      const d = JSON.parse(sessionStorage.getItem("AUTH_USER"));
      if (!d?.access_token) {
        sessionStorage.removeItem("AUTH_USER");
      }
    };
  }, []);

  const multiStep = () => {
    switch (step) {
      case 1:
        return (
          <UserDetails
            handleFormChange={handleFormChange}
            talentForm={talentForm}
            setTalentForm={setTalentForm}
            step={step}
            next={nextStep}
            signupmode={signupmode}
            type="talentDetails"
          />
        );
      case 2:
        return (
          <UserLocation
            handleFormChange={handleFormChange}
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            next={nextStep}
            signupmode={signupmode}
            prev={prevStep}
            type="talentAddress"
          />
        );

      case 3:
        return (
          <UserPricingPlan
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            next={nextStep}
            signupmode={signupmode}
            prev={prevStep}
          />
        );
      case 4:
        return (
          <UserProfessionalDetails
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            next={nextStep}
            signupmode={signupmode}
            prev={prevStep}
            type="castingAfricaTalentDetails"
          />
        );
      case 5:
        return (
          <UserPhysicalAttributes
            handleFormChange={handleFormChange}
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            next={nextStep}
            signupmode={signupmode}
            prev={prevStep}
            type="castingAfricaTalentDetails"
          />
        );
      case 6:
        return (
          <UserJobPreference
            handleFormChange={handleFormChange}
            step={step}
            next={nextStep}
            signupmode={signupmode}
            prev={prevStep}
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            type="castingAfricaTalentDetails"
          />
        );
      case 7:
        return (
          <UserCreditAndSkills
            handleFormChange={handleFormChange}
            step={step}
            next={nextStep}
            signupmode={signupmode}
            prev={prevStep}
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            type="castingAfricaTalentDetails"
          />
        );
      case 8:
        return (
          <UserMoreInfo
            handleFormChange={handleFormChange}
            step={step}
            next={nextStep}
            signupmode={signupmode}
            prev={prevStep}
            talentForm={talentForm}
            setTalentForm={setTalentForm}
            type="castingAfricaTalentDetails"
            setCreatingAccount={setCreateAccount}
          />
        );
      case 9:
        return (
          <UserCompletions
            handleFormChange={handleFormChange}
            step={step}
            next={nextStep}
            signupmode={signupmode}
            prev={prevStep}
            talentForm={talentForm}
            accountCreation={accountCreation}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    console.log("handled ", createAccount);
    if (!createAccount)
    {
      setAccountCreation("Log in to complete set up")
    }
  }, [createAccount]);

  return (
    <PackageContext.Consumer>
      {(context) => (
        <div
          className={`${
            signupmode !== "AGENTSIGNUP" ? "bg-primary" : "mt-24"
          } h-screen w-full pb-40  overflow-scroll`}
        >

          <div className="  pt-10">
            {signupmode !== "AGENTSIGNUP" ? (
              <Link
                onClick={() => context.updatePath("/")}
                className="w-full flex justify-center "
                to="/"
              >
                <img
                  className=" w-3/12 md:w-1/12 h-1/6"
                  src={CASTINGLOGOICON}
                  alt="Casting Africa Logo"
                />
              </Link>
            ) : (
              ""
            )}
          </div>

          <div className="w-full  px-3 flex justify-center ">{multiStep()}</div>
        </div>
      )}
    </PackageContext.Consumer>
  );
};

export default TalentRegistration;
