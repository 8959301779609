import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sample_jobs } from "../../../../../config/utilities";
import Jobcard from "../../../Communities/Comps/jobCard";
import { Pagination } from "../../../Pagination";

const Managejobs = () => {
  const [setNext, setSetNext] = useState(5);
  let pagesize = 5;
  return (
    <div className="w-full flex flex-col items-center justify-start mt-32 overflow-auto  h-full">
      <div className="mb-4 lg:w-4/6">
        <div className="flex justify-between lg:px-0 px-2">
          <h1 className="font-gilroy-Light text-2xl">Manage My Jobs</h1>
          <Link
            to="/postjob"
            className="px-4 py-1 font-gilroy-Light rounded-full bg-primary text-white"
          >
            Post Casting Call
          </Link>
        </div>
        <div className="flex px-2 lg:flex-row flex-col justify-between py-2 mt-4 mb-4">
          <div className="flex lg:mb-0 mb-2 shadow-sm items-center border w-full rounded-3xl mr-2">
            <input
              className="w-full px-3  py-2 rounded-tl-3xl outline-none font-gilroy-Light rounded-bl-3xl"
              type="text"
              placeholder="Search Your Jobs Directory"
            />
            <span className="w-20 cursor-pointer border-primary bg-primary py-2 flex justify-center rounded-tr-3xl rounded-br-3xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#FFF"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </span>
          </div>
          <select
            className=" lg:w-44 rounded-3xl px-3 font-gilroy-Light "
            name=""
            id=""
          >
            <option value="">Filter by</option>
            <option value="Recent">Recent</option>
            <option value="Premium">Premium</option>
            <option value="Expired">Expired</option>
          </select>
        </div>
        <div>
          {sample_jobs.slice(setNext - pagesize, setNext).map((job, key) => (
            <div key={key}>
              <Jobcard job={job} type={false} />
            </div>
          ))}
        </div>
      </div>
      <Pagination data={sample_jobs} pagesize={5} nextSet={setSetNext} />
      <div className="mt-4 mb-4">
        <button className="px-4 py-1 font-gilroy-Light rounded-full bg-primary text-white">
          Post Casting Call
        </button>
      </div>
    </div>
  );
};

export default Managejobs;
