import React from "react";
import { NotificationManager } from "react-notifications";
import { FindJobs } from "../..";
import { useHistory } from "react-router-dom";

const Jobcard = ({ job, type=true }) => {
  const history = useHistory();

  const handleJobApplicationFeature = () => {
    const logged = JSON.parse(sessionStorage.getItem("AUTH_USER"));
    const name = job.job_title;
    if (logged) {
      NotificationManager.success(
        `You will be applying for ${name}`,
        "Casting Africa",
        3000
      );
    } else {
      NotificationManager.info(
        `You need to be logged in to apply for ${name}`,
        "Casting Africa",
        3000
      );
    }
  };

  const handleJobApplicants = (selectedJob) => {
      history.push("/jobApplicants", {
        selectedJob,
      });
  }

   const handleJobDetails = (selectedJob) => {
     history.push("/jobDetails", {
       selectedJob,
     });
   };

  const handleJobEdit = () => {
    history.push("/postJob", {
      mode: "Edit"
    });
  };

  return (
    <div
      className={`w-full bg-white py-6 md:px-5 md:mr-10 shadow-lg md:shadow-lg mb-5`}
    >
      <div className="px-2">
        {job.tags.map((tag, key) => (
          <span
            key={key}
            className={`bg-${tag.color} font-gilroy-Light rounded-tr-lg rounded-bl-lg px-3 py-1 ${tag.text_color} text-sm mr-2`}
          >
            {tag.tag_name}
          </span>
        ))}
      </div>
      <div className="flex justify-end px-3 cursor-pointer mb-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
          />
        </svg>
      </div>

      <div className="flex md:flex-row flex-col px-1 md:justify-around">
        <div
          style={{
            backgroundImage: `url(${job.thumbnail})`,
            backgroundRepeat: "no-repeat",
            objectFit: "contain",
            backgroundSize: "100%",
          }}
          className="md:h-14 h-14 md:ml-0 ml-2 w-14 md:w-14 border rounded-2xl shadow-xl mr-6"
        ></div>

        <div className="flex flex-col md:m-0 m-2 md:flex-grow">
          <h1
            onClick={() => handleJobDetails(job)}
            className="font-gilroy-Light cursor-pointer hover:text-gray-600 text-2xl"
          >
            {job.job_title}
          </h1>
          <p className="font-gilroy-Light text-sm ">
            {job.job_short_description}
          </p>
          <div className="flex md:flex-row flex-col">
            <span className="mr-3 md:mb-0 mb-2 font-gilroy-Light">
              {job.company_name}
            </span>
            <div className="flex items-center mb-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </span>
              <span className="font-gilroy-Light text-sm mr-3">
                {job.region}, {job.country}
              </span>
            </div>
            <span className="font-gilroy-Light text-sm">14 mins ago</span>
          </div>
        </div>
        <div>
          {type ? (
            <button
              onClick={handleJobApplicationFeature}
              className="shadow-lg font-gilroy-Light text-sm w-full md:w-28 h-9 rounded-full flex justify-center items-center border-none bg-primary text-white"
            >
              Apply
            </button>
          ) : (
            <div>
              <button
                onClick={() => handleJobApplicants(job)}
                className="shadow-lg mb-2 font-gilroy-Light text-sm w-full md:w-36 h-9 rounded-full flex justify-center items-center border-none bg-primary text-white"
              >
                View Applicants
              </button>
              <button
                onClick={handleJobEdit}
                className="shadow-lg font-gilroy-Light text-sm w-full md:w-36 h-9 rounded-full flex justify-center items-center border-none bg-primary text-white"
              >
                Edit Job
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Jobcard;
