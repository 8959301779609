import React from "react"
import JOBALERT from "../../../../assets/images/job_alert_banner.png"
import ILLUSTRATION from "../../../../assets/images/Illustration.png";
import { NumberStats } from ".";
import SUPPORT from "../../../../assets/images/support.png"
import TUTORIAL from "../../../../assets/images/tutorial.png";


const JobAlertBadge = ({ color, btncolor, community }) => {
    return (
      <div className="w-full flex justify-center  px-4 py-10">
        <div className={`flex md:flex-row flex-col justify-center items-center py-5 px-5 ${color} w-full lg:w-4/5 lg:h-72 md:mr-5 lg:ml-0 md:ml-5 lg:mr-0  rounded-xl `}>
          <div className=" md:w-96  md:mr-5  flex flex-col lg:items-start justify-center mg:items-center h-60">
            <h1 className="md:text-5xl mb-3 text-4xl text-white">
              Got question?
            </h1>
            <hr className={`h-1 mb-7 border-red-500 w-20 ${btncolor}`} />
            <p className="font-gilroy-Light text-sm text-white">
              Customer success is our highest priority. We're here to answer all
              your questions via our Support Docs, Videos Demos, and 24/7 live
              Chat.
            </p>
          </div>

          <div className=" flex flex-col items-center justify-around w-full rounded-md md:w-52 py-20 px-2 md:mb-0 mb-4 md:mr-4 h-56 bg-white">
            <img className=" w-44 " src={TUTORIAL} alt="" />
            <a target="_blank" href="https://youtube.com/playlist?list=PLA4YomORHOOiP5ybr0F3PYfuNByRrJdIH" className={`text-white border w-full  py-3 px-10 ${btncolor} rounded-lg`}>
              Watch Tutorial
            </a>
          </div>

          <div className=" flex flex-col  justify-around py-10 items-center w-full rounded-md md:w-52 px-2  h-56 bg-white">
            <img className=" md:w-72 md:mb-2 " src={SUPPORT} alt="" />
            <a target="_blank" href="https://wa.me/233505943694" className={`text-white border py-3 w-full px-10 ${btncolor} rounded-lg`}>
              Start Chat
            </a>
          </div>
        </div>
      </div>
    );
}

export default JobAlertBadge;