import React from "react";
import CONSTRUCTION from "../../assets/images/underconstruction.jpg";

const Construction = () => {
  return (
    <div className=" w-full h-screen flex flex-col justify-center items-center">
      <h1 className="text-black">Coming Soon!</h1>
      <img src={CONSTRUCTION} alt="Under Construction" />
    </div>
  );
};

export default Construction;
