import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { landing_data } from "../../../../config/utilities";
import { useHistory } from "react-router-dom";

const MainLanding = ({ title = "What talent do you want to add to your roster?", step, next }) => {
  const [talentChoice] = useState(landing_data);
  const [choosen, setChoosen] = useState(new Array(landing_data.length).fill(false));
  const history = useHistory();


  const handleTalentChoice = (key) => {
    const choosenState = choosen.map((choose, index) => index === key ? !choose : choose);
    setChoosen(choosenState);
  }

  
  const proceedNext = () => {

    const loggedUser = JSON.parse(
      sessionStorage.getItem("AUTH_USER")
    ).account_type;

    if (!choosen.includes(true)) {
      NotificationManager.warning("Please choose a talent category to proceed");
      return;
    } 

    if (loggedUser === "AGENT") {
      history.push("/talentAgentSignUp", {
        signupmode: "AGENTSIGNUP",
      });
      return;
    }

    next()
  }
  // useEffect(() => {
  //   //  handleTalentChoice();
  // }, [positions]);


  const something = (position) => {
    const temp = choosen;
    if (temp.includes(position)) {
      const temp_ = temp.filter((tem) => tem !== position);
      setChoosen(temp_);
    } else {
      setChoosen((prev) => [...prev, position]);
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center mt-24 py-10s h-full overflow-scroll">
      <h1 className="text-center text-2xl w-102 md:text-4xl">{title}</h1>
      <div className=" lg:grid lg:grid-cols-4 lg:gap-6 flex md:flex-wrap flex-col  md:justify-center md:flex-row  mt-10">
        {talentChoice.map((landing, key) => (
          <div
            key={key}
            style={{
              backgroundImage: `url(${landing.background})`,
            }}
            className="flex flex-col lg:mb-0 mb-2 lg:mr-0 md:mr-2 justify-between items-start  py-4 px-5 w-60 h-96 rounded-xl "
          >
            <input
              type="checkbox"
              checked={choosen[key]}
              onChange={() => handleTalentChoice(key)}
            />
            <button
              onClick={() => handleTalentChoice(key)}
              className="border py-1 w-full rounded-full border-primary bg-primary text-xl text-white"
            >
              {landing.type}
            </button>
          </div>
        ))}
      </div>
      <p
        onClick={proceedNext}
        className="mt-10 cursor-pointer text-2xl mb-10 underline text-blue-300"
      >
        Next &gt; &gt;
      </p>
    </div>
  );
};

export default MainLanding;
