import React from "react";
import { FindJobs } from "../..";

const FeatJobCard = ({ job }) => {
  return (
    <div
      className={`border w-full px-2 lg:w-2/5 bg-white py-2 lg:px-5 lg:mr-10 shadow-lg mb-5`}
    >
      <div>
        {job.tags.map((tag, key) => (
          <span
            key={key}
            className={`font-gilroy-Light bg-${tag.color} rounded-tr-lg rounded-bl-lg px-3 py-1 ${tag.text_color} text-sm mr-2`}
          >
            {tag.tag_name}
          </span>
        ))}
      </div>
      <div className="flex justify-end mr-4 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
          />
        </svg>
      </div>
      <div className="flex lg:justify-between">
        <h1 className="font-gilroy-Light text-xl lg:text-2xl lg:w-72">
          {job.job_title}
        </h1>
        <div className="flex">
          <div
            style={{
              backgroundImage: `url(${job.thumbnail})`,
              backgroundRepeat: "no-repeat",
              objectFit: "contain",
              backgroundSize: "100%",
            }}
            className="w-14 h-12 lg:h-14 lg:w-14 border rounded-lg shadow flex justify-center items-center mr-2"
          ></div>
          <div className="flex flex-col">
            <span className="font-gilroy-Light lg:text-2xl lg:w-32">
              {job.company_name}
            </span>
            <span className="font-gilroy-Light text-sm">14 mins ago</span>
          </div>
        </div>
      </div>
      <div className="flex">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </span>
        <span className="font-gilroy-Light text-sm">
          {job.region}, {job.country}
        </span>
      </div>
    </div>
  );
};

export default FeatJobCard;
