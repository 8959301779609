import React from 'react';
import { Link } from 'react-router-dom';

const PostJobComplete = () => {
    return (
      <div className="w-full flex flex-col justify-center items-center">
        <div className="flex flex-col items-center">
          <h1 className="text-3xl mb-3">Job Post Pending Review</h1>
          <p className="w-96 text-center font-gilroy-Light text-xl mb-4">
            You casting call is currently being reviewed. Kindly be patient it
            will be posted once approved. Thank you.{" "}
          </p>
          <hr className="w-full border-2 border-black mb-4 px-4" />
          <p className="font-gilroy-Light">Looking for more talents?</p>
          <p>
            <Link className="font-gilroy-Light text-blue-400" to="/talents">
              Browse Talent Directory,{" "}
            </Link>
            <span className="font-gilroy-Light">or </span>
            <Link className="font-gilroy-Light text-blue-400" to="/discover">
              Browse Discova
            </Link>
          </p>
        </div>
      </div>
    );
};





export default PostJobComplete;
