import moment from "moment";
import React, { useContext, useState } from "react";
import { ProfileFooter } from ".";
import PackageContext from "../../../context/context";
import { Recommendationitem } from "../Communities/Comps";
import { Pagination } from "../Pagination";

const Recommendations = () => {
  const context = useContext(PackageContext);
  const talent = context.talent;
  const [setNext, setSetNext] = useState(5);
  let pagesize = setNext;

  return (
    <div className="h-full">
      <div className="flex flex-col items-center">
        {
          talent?.recommendations ? talent?.recommendations
          .slice(setNext - pagesize, setNext)
          .map((recommend, key) => (
            <Recommendationitem  recommend={recommend} />
            )) : "No recommendations found"
        }
      </div>
      <Pagination
        data={talent?.recommendations || []}
        pagesize={5}
        nextSet={setSetNext}
      />
    </div>
  );
};

export default Recommendations;
