import React from "react";
import {
  SearchJob,
  Spotlight,
  Starter,
  FeaturedJobs,
  Stats,
  JobAlertBadge,
  TrustedBadge,
  Review,
  Prices,
  NumberStats,
  AdsBanner,
} from "../Comps";
import BANNER from "../../../../assets/images/ky.png";
import STAT from "../../../../assets/images/stat.png";
import ADS_BANNER from "../../../../assets/images/ad-1.png";


const KenyaHome = () => {
    return (
      <div className="w-full mt-24">
        <Starter color="bg-black" btncolor="bg-green-600" starter_image={BANNER} />
        <SearchJob color="bg-green-600"defaultCountry="Abuja" />
        {/* <Stats stat_image={STAT} /> */}
        {/* <FeaturedJobs /> */}
        <AdsBanner ads_image={ADS_BANNER} />
        <Spotlight color="bg-green-600"/>
        <TrustedBadge />
        <JobAlertBadge color="bg-black" btncolor="bg-green-600" community={true} />
        <AdsBanner ads_image={ADS_BANNER}/>
        {/* <Review /> */}
      </div>
    );
}

export default KenyaHome;