import React from "react";
import { Link } from "react-router-dom";
import CASTINGLOGOICON from "../../../assets/images/castingafrica_icon_white.png";
import TALENTS from "../../../assets/images/talent.png";
import PROFESSIONALS from "../../../assets/images/professional.png";
import AGENTS from "../../../assets/images/agent.png";
import TOPBG from "../../../assets/images/top_bg.png";
import BTBG from "../../../assets/images/bt_bg.png";
import PackageContext from "../../../context/context";
import { useHistory } from "react-router-dom";

const SignUp = () => {

  const history = useHistory();
  const handleTalentSignUp = () => {
    history.push("/talentSignUp", {
      signupmode: "TALENTSIGNUP"
    });
  }

  return (
    <PackageContext.Consumer>
      {(context) => (
        <div className=" bg-primary w-full h-screen  pb-10 ">
          <div className="mb-0 pt-10">
            <Link
              onClick={() => context.updatePath("/")}
              className="w-full flex justify-center "
              to="/"
            >
              <img
                className="lg:w-2/12 w-32 lg:h-1/6"
                src={CASTINGLOGOICON}
                alt="Casting Africa Logo"
              />
            </Link>
          </div>

          <div
            style={{
              backgroundImage: `url(${TOPBG})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top left",
              backgroundSize: "300px",
            }}
            className="flex justify-center z-30 flex-wrap bg-primary z-30 "
          >
            {/* Job selection card */}
            <div className=" md:w-96 bg-white flex justify-center  items-center flex-col rounded-3xl px-5 py-5 m-6 ">
              <img className="h-3/6" src={TALENTS} alt="" />
              <h1 className="text-4xl font-gilroy-Light ">
                Find <span className=" font-gilroy-Extrabold">Work</span>
              </h1>
              <p className=" text-center font-gilroy-Light mb-4 mt-4 ">
                An Actor, Model, Dancer or Influencer? Experience not required
              </p>
              <span
                onClick={handleTalentSignUp}
                // to="/talentSignUp"
                className="bg-primary cursor-pointer flex items-center justify-center text-white rounded-full py-1 px-4"
              >
                Signup as a Talent
              </span>
            </div>
            {/* End card */}

            {/* Job selection card */}
            <div className="bg-white md:w-96 flex justify-center  items-center flex-col rounded-3xl px-5 py-5 m-6 ">
              <img className="h-3/6" src={PROFESSIONALS} alt="" />
              <h1 className="text-4xl font-gilroy-Light ">
                Find <span className=" font-gilroy-Extrabold">Talent</span>
              </h1>
              <p className=" text-center font-gilroy-Light mb-4 mt-4 ">
                Acess over 4,000 talents accros Africa. Post jobs for FREE
              </p>
              <Link
                to="/professionalSignUp"
                className="bg-primary  flex items-center justify-center text-white rounded-full py-1 px-4"
              >
                Signup as a Creator
              </Link>
            </div>
            {/* End card */}
          </div>

          <div
            style={{
              backgroundImage: `url(${BTBG})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom right",
              backgroundSize: "35%",
            }}
            className="flex justify-center bg-primary w-full flex-wrap  pb-16 "
          >
            {/* Job selection card */}
            <div className="md:w-96 bg-white flex justify-center  items-center flex-col rounded-3xl px-5 py-5 m-6 ">
              <img className="h-3/6" src={AGENTS} alt="" />
              <h1 className="text-4xl font-gilroy-Light ">
                Manage <span className="font-bold">Talent</span>
              </h1>
              <p className=" text-center font-gilroy-Light">
                Create and manage your talent
              </p>
              <p className="mb-2 font-gilroy-Light">rooster digitally.</p>
              <Link
                to="/agentSignUp"
                className="bg-primary flex items-center justify-center text-white rounded-full py-1 px-4"
              >
                Signup as an Agent/Agency
              </Link>
            </div>
            {/* End card */}
          </div>
        </div>
      )}
    </PackageContext.Consumer>
  );
};

export default SignUp;
