import React, { useContext } from "react";
import PackageContext from "../../../../context/context";
import { useHistory } from "react-router-dom";
import moment from "moment";
import VERIFICATION from "../../../../assets/images/verification_bade.png";
import { NotificationManager } from "react-notifications";

const ClientItem = ({ talent, width, hasType }) => {
  const context = useContext(PackageContext);
  const history = useHistory();

  const segueToTalentDetails = (item) => {
    if (!hasType) {
      return;
    }
    context?.updateTalent(item);
    // context?.updateTalentLocalState(item);
    history.push("/creatorProfile", {
      selectedUser: item
    });
  };

  return (
    <div
      className={`w-80 mb-3 md:w-60 lg:${width} ${
        width === "w-64" ? "mr-6" : "mr-4"
      } md:mb-4 shadow-lg rounded-br-2xl rounded-bl-2xl `}
    >
      <div
        style={{
          backgroundImage: `url(${talent.img})`,
          backgroundRepeat: "no-repeat",
          objectFit: "contain",
          backgroundSize: "100%",
        }}
        onClick={() => segueToTalentDetails(talent)}
        className={`bg-white ${
          hasType ? "cursor-pointer md:h-36" : ""
        } flex justify-start items-end w-full h-80  lg:h-80`}
      >
        <img className="" src={VERIFICATION} alt="" />
      </div>
      <div className="border-b pb-3 lg:px-0 px-0 md:px-2 mt-2 flex flex-col justify-center items-center border-gray-200">
        <h1
          onClick={() => segueToTalentDetails(talent)}
          className={`font-gilroy-Medium  ${
            hasType ? "cursor-pointer" : ""
          } hover:text-blue-900 text-primary text-3xl md:text-lg lg:text-3xl`}
        >
          {talent?.first_name ? talent?.first_name : talent?.company_name}
        </h1>
        <p className="text-xs md:text-center lg:text-left text-left font-gilroy-Light">
          <span className="mr-1">{talent.region},</span>
          <span>{talent.country}</span>
        </p>
      </div>
      <FollowFeatures talent={talent} />
    </div>
  );
};

const FollowFeatures = ({ talent }) => {
  const handleAgentFollowFeature = () => {
    const logged = JSON.parse(sessionStorage.getItem("AUTH_USER"));
    const name = talent?.first_name ? talent?.first_name : talent?.company_name;
    if (logged) {
      NotificationManager.success(
        `You are following ${name}`,
        "Casting Africa",
        3000
      );
    } else {
      NotificationManager.info(
        `You need to be logged in to follow ${name}`,
        "Casting Africa",
        3000
      );
    }
  };
  return (
    <div className="flex justify-evenly items-center py-2">
      <div
        onClick={() => handleAgentFollowFeature()}
        className="flex group  py-1 cursor-pointer  justify-center items-center"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 12.5537C12.2546 12.5537 14.4626 10.3171 14.4626 7.52684C14.4626 4.73663 12.2546 2.5 9.5 2.5C6.74543 2.5 4.53737 4.73663 4.53737 7.52684C4.53737 10.3171 6.74543 12.5537 9.5 12.5537ZM9.5 15.0152C5.45422 15.0152 2 15.6621 2 18.2464C2 20.8298 5.4332 21.5 9.5 21.5C13.5448 21.5 17 20.8531 17 18.2687C17 15.6844 13.5668 15.0152 9.5 15.0152ZM19.8979 9.58786H21.101C21.5962 9.58786 22 9.99731 22 10.4995C22 11.0016 21.5962 11.4111 21.101 11.4111H19.8979V12.5884C19.8979 13.0906 19.4952 13.5 18.999 13.5C18.5038 13.5 18.1 13.0906 18.1 12.5884V11.4111H16.899C16.4027 11.4111 16 11.0016 16 10.4995C16 9.99731 16.4027 9.58786 16.899 9.58786H18.1V8.41162C18.1 7.90945 18.5038 7.5 18.999 7.5C19.4952 7.5 19.8979 7.90945 19.8979 8.41162V9.58786Z"
            fill="#E5E5E5"
            fill-opacity="0.9"
          />
        </svg>
        <span className="text-xs lg:block block md:hidden font-gilroy-Light text-gray-300 group-hover:text-primary">
          Follow
        </span>
      </div>
    </div>
  );
};

export default ClientItem;
