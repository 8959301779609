import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { countries } from "../../../config/countries";
import { BOOK_TALENT_JOB } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";

const project_categories = [
  'Feature Films', 'Theatre & Musicals', 'TV Series',
  'Web Series', 'Commercial - Tv', 'Voiceover & Radio',
  'Short/Web Films', 'Runway', 'Commercial -Print', 'Music Video',
  'Influencer'
]

const BookTalent = ({ talentId, setBookingResponse, context }) => {
  const [renumerationSelected, setRenumerationSelected] = useState("NoPay");
  const [countrySelected, setCountrySelected] = useState("Ghana");
  const [bookTalentJob, {loading, data, error}] = useMutation(BOOK_TALENT_JOB);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const handleSendBookMe = (data) => {
    data['renumeration'] = renumerationSelected;
    data['casting_africa_talentscasting_africa_talent_id'] = talentId;
    bookTalentJob({
      variables: {
        jobDetails: data
      }
    }).then(resp => {
      reset(data)
      setBookingResponse(true);
      context?.setBookMeViewer(false)
    })
    .catch(err => console.log(err))
  }

  return (
    <div className="w-full h-full fixed py-2 top-0 left-0 z-50  bg-modalBackground flex justify-center items-center">
      <div className=" bg-white overflow-y-scroll overflow-x-hidden flex flex-col items-center  lg:w-3/6 h-full  ">
        <div className="w-full flex justify-end mr-4 mt-2">
          <svg
            onClick={() => context?.setBookMeViewer(false)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#f00"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="h-screen w-full lg:px-24 px-4">
          <div className="flex flex-col w-full  mb-8">
            <span className=" font-gilroy-Light text-xl mb-2">
              Project Title
            </span>
            <input
              className="border h-12 text-lg font-gilroy-Light px-2 outline-none"
              type="text"
              placeholder="Beauty & The Beat Part 2"
              {...register("project_title", {
                required: "Enter title of the project",
              })}
            />
            <p className=" font-gilroy-Light text-red-600 text-sm ">
              {errors?.project_title?.message}
            </p>
          </div>

          <div className="flex flex-col w-full  mb-8">
            <span className=" font-gilroy-Light text-xl mb-2">
              Project Category
            </span>
            <select  
            {...register("project_category", {
                required: "Select a project category",
              })} className="border h-12 text-lg font-gilroy-Light px-2 outline-none">
                {
                  project_categories.map( (category, key) => (
                    <option key={key} value={category}>{category}</option>
                  ))
                }
            </select>
            <p className=" font-gilroy-Light text-red-600 text-sm ">
              {errors?.project_category?.message}
            </p>
          </div>

          <div className="flex flex-col w-full  mb-8">
            <span className=" font-gilroy-Light text-xl mb-2">
              Company Name
            </span>
            <input
            {...register("company_name", {
              required: "Enter company name",
            })}
              className="border h-12 text-lg font-gilroy-Light px-2 outline-none"
              type="text"
              placeholder="Old Films Production"
            />
            <p className=" font-gilroy-Light text-red-600 text-sm ">
              {errors?.company_name?.message}
            </p>
          </div>

          <div className="flex flex-col w-full  mb-8">
            <span className=" font-gilroy-Light text-xl mb-2">
              Email Address
            </span>
            <input
             {...register("email_address", {
              required: "Enter company email",
            })}
              className="border h-12 text-lg font-gilroy-Light px-2 outline-none"
              type="text"
              placeholder="oldfilms@gmail.com"
            />
            <p className=" font-gilroy-Light text-red-600 text-sm ">
              {errors?.email_address?.message}
            </p>
          </div>

          <div className="flex flex-col w-full  mb-8">
            <span className=" font-gilroy-Light text-xl mb-2">
              Phone Number
            </span>
            <input
            {...register("phone_number", {
              required: "Enter company contact",
            })}
              className="border h-12 text-lg font-gilroy-Light px-2 outline-none"
              type="text"
              placeholder="233 (0552711321)"
            />
            <p className=" font-gilroy-Light text-red-600 text-sm ">
              {errors?.phone_number?.message}
            </p>
          </div>

          <div className="flex flex-col w-full  mb-8">
            <span className=" font-gilroy-Light text-xl mb-2">
              Project Location
            </span>
            <select
            {...register("project_location", {
              required: "Select project location",
            })}
              onChange={(e) => setCountrySelected(e.target.value)}
              className="border h-12 text-xl font-gilroy-Light px-2 outline-none"
            >
              <option value="Ghana">Ghana</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Kenya">Kenya</option>
              <option value="South Africa">South Africa</option>
              <option value="Namibia">Namibia</option>
            </select>
            <p className=" font-gilroy-Light text-red-600 text-sm ">
              {errors?.project_location?.message}
            </p>
          </div>

          <div className="flex flex-col w-full  mb-8">
            <span className=" font-gilroy-Light text-xl mb-2">Town/City</span>
            <select {...register("town_city", {
              required: "Select town/city",
            })} className="border h-12 text-xl font-gilroy-Light px-2 outline-none">
              {countries[countrySelected].map((towns, key) => (
                <option key={key} value={towns}>
                  {towns}
                </option>
              ))}
            </select>
            <p className=" font-gilroy-Light text-red-600 text-sm ">
              {errors?.town_city?.message}
            </p>
          </div>

          <div className="flex flex-col w-full  mb-8">
            <span className=" font-gilroy-Light text-xl mb-2">
              Project Description
            </span>
            <textarea
            {...register("project_description", {
              required: "Provide some context of the project",
            })}
              className="border text-xl font-gilroy-Light px-2 outline-none rounded-3xl"
              cols="30"
              rows="5"
            ></textarea>
            <p className=" font-gilroy-Light text-red-600 text-sm ">
              {errors?.project_description?.message}
            </p>
          </div>

          {/* <div className="flex flex-col w-full  mb-8">
            <span className=" font-gilroy-Light text-xl mb-2">
              Submission Instruction
            </span>
            <textarea
              className="border text-xl font-gilroy-Light px-2 outline-none rounded-3xl"
              cols="30"
              rows="5"
            ></textarea>
          </div> */}

          <div className="flex flex-col  w-full  mb-10">
            <span className=" font-gilroy-Light text-xl mb-2">
              Renumeration
            </span>
            <div className="flex">
              {["NoPay", "Deffered", "Stipend", "Paid"].map(
                (renumeration, key) => (
                  <div
                    onClick={() => setRenumerationSelected(renumeration)}
                    key={key}
                    className={`${
                      renumerationSelected === renumeration
                        ? "bg-primary text-white"
                        : ""
                    } border-primary border hover:bg-primary hover:text-white  cursor-pointer  flex justify-center  font-gilroy-Light rounded-full mr-2 lg:py-2  px-2 lg:px-5`}
                  >
                    {renumeration}
                  </div>
                )
              )}
            </div>
          </div>

          <div className="flex justify-center w-full pb-10  mb-8">
            <button onClick={handleSubmit(handleSendBookMe)} className="bg-primary text-white font-gilroy-Light text-xl rounded-full px-10 py-2">
              {
                loading ? 'Sending..' : 'Send'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookTalent;
