import React from "react";
import { TalentDetails } from "../../Agent";


const ViewTalentProfile = () => {
    return (
        <div className="h-screen w-full mt-24 overflow-y-scroll">
            <TalentDetails />
        </div>
    )
}

export default ViewTalentProfile;