import React, { useState } from "react";
import PackageContext from "../../../../context/context";
import { Link } from "react-router-dom";
import CASTINGLOGOICON from "../../../../assets/images/castingafrica_icon_white.png";
import {
  ProfessionalDetails,
  ProfessionalLocation,
  ProfessionalJobDetails,
  ProfessionalCompletion,
} from ".";

const ProfessionalRegistration = () => {
  const [step, setStep] = useState(1);
  const [professionalForm, setProfessionalForm] = useState({
    details: {
      first_name: "",
      last_name: "",
      calling_code: "",
      phone_number: "",
      occupation: "",
      gender: "",
      type: "CREATOR",
      job_title: "",
    },
    userData: {
      email: "",
      password: "",
    },
    companyAddress: {
      country: "",
      state: "",
      city: "",
      zip_code: "",
    },
    companyDetails: {
      company_name: "",
      company_location: "",
      company_website: "",
      union_association: "",
      roster_size: "",
    },
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    if (step < 1) return null;
    setStep(step - 1);
  };

  const multiStep = () => {
    switch (step) {
      case 1:
        return (
          <ProfessionalDetails
            step={step}
            next={nextStep}
            professionalForm={professionalForm}
            setProfessionalForm={setProfessionalForm}
            type="details"
          />
        );
      case 2:
        return (
          <ProfessionalLocation
            step={step}
            next={nextStep}
            prev={prevStep}
            professionalForm={professionalForm}
            setProfessionalForm={setProfessionalForm}
            type="companyAddress"
          />
        );
      case 3:
        return (
          <ProfessionalJobDetails
            step={step}
            next={nextStep}
            prev={prevStep}
            professionalForm={professionalForm}
            setProfessionalForm={setProfessionalForm}
            type="companyDetails"
          />
        );
      case 4:
        return (
          <ProfessionalCompletion
            step={step}
            next={nextStep}
            prev={prevStep}
            professionalForm={professionalForm}
            setProfessionalForm={setProfessionalForm}
          />
        );
      default:
        return null;
    }
  };

  return (
    <PackageContext.Consumer>
      {(context) => (
        <div className={`bg-primary h-screen w-full pb-40  overflow-scroll`}>
          <div className="pt-10">
            <Link
              onClick={() => context.updatePath("/")}
              className="w-full flex justify-center "
              to="/"
            >
              <img
                className=" w-3/12 md:w-1/12 h-1/6"
                src={CASTINGLOGOICON}
                alt="Casting Africa Logo"
              />
            </Link>
          </div>

          <div className="w-full  px-3 flex justify-center  md:h3/5 ">
            {multiStep()}
          </div>
        </div>
      )}
    </PackageContext.Consumer>
  );
};

export default ProfessionalRegistration;
