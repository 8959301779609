import React, { useContext, useEffect, useState } from "react";
import ProfileFooter from "../profileFooter";
import PackageContext from "../../../../context/context";
import { company_more_information } from "../../../../config/utilities";
import TalentItemCard from "../../Communities/Comps/talentItemCard";
import { Pagination } from "../../Pagination";

const Following = () => {
  const context = useContext(PackageContext);
  const [companyDetails, setCompanyDetails] = useState({});
  const [setNext, setSetNext] = useState(8);
  let pagesize = setNext;

  useEffect(() => {
    setCompanyDetails(context.companyDetails);
  }, []);

  return (
    <div className=" flex flex-col">
      <div className="flex flex-col items-center  md:grid md:grid-cols-3 lg:grid-cols-4">
        {companyDetails &&
          companyDetails?.following?.map((roster, key) => (
            <TalentItemCard
              talent={roster}
              key={key}
              hasType={false}
              feature_type="follow_feature"
            />
          ))}
      </div>
      <Pagination
        data={companyDetails?.following || []}
        pagesize={8}
        nextSet={setSetNext}
      />
      <ProfileFooter count={6000} />
    </div>
  );
};

export default Following;
