import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import VERIFICATION from "../../../assets/images/verification_bade.png";
import TIKTOK from "../../../assets/images/tik_tok_square.png";
import YOUTUBE from "../../../assets/images/youtube_square.png";
import IMDB from "../../../assets/images/IMDb_square.png";
import FACEBOOK from "../../../assets/images/facebook_square.png";
import INSTAGRAM from "../../../assets/images/icon_instagram.png";
import { talent_nav } from "../../../config/utilities";
import PackageContext from "../../../context/context";
import { toggleViews } from "../../../services/service";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TALENT } from "../../../graphql/queries";
import DEFAULT from "../../../assets/images/default.jpg";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { FOLLOW_TALENT, UNFOLLOW_TALENT } from "../../../graphql/mutations";
import { ProfileFooter } from ".";
import Loader from "../Loader";
import BookTalent from "./bookTalent";
import { UserAnalyticsEventTracker } from "../../Temporary";

const TalentDetails = () => {
  const context = useContext(PackageContext);
  const location = useLocation();
  const history = useHistory();
  const { talent_type, talent_id } = location?.state;
  const [bookingResponse, setBookingResponse] = useState(false);

  const gaEventTracker = UserAnalyticsEventTracker("Talent Profile");

  const [id, setId] = useState(
    talent_id ? talent_id : sessionStorage.getItem("talent_")
  );

  useEffect(() => {
    if (bookingResponse) {
      NotificationManager.success("Booking success", "Casting Africa", 1000);
    }
  }, [bookingResponse]);

  const [
    followTalent,
    { loading: follow_loading, error: follow_error, data: follow_response },
  ] = useMutation(FOLLOW_TALENT);
  const { loading, error, data, refetch } = useQuery(GET_TALENT, {
    variables: {
      talentID: id,
    },
    fetchPolicy: "network",
    onCompleted: (d) => {
      const thumb = data?.talent.photos?.find((photo) => photo.includes("thumbnail"));
      setProfilePicture(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${talent?.talent_media_path}/pictures/${thumb}`);
    }
    // pollInterval: 500
  });

  const [followingList, setFollowingList] = useState([]);

  const [
    unfollowTalent,
    {
      loading: unfollowing_loading,
      error: unfollow_error,
      data: unfollow_response,
    },
  ] = useMutation(UNFOLLOW_TALENT);

  const [position, setPosition] = useState(0);
  const [talents, setTalents] = useState(data?.talent);
  const [showMessage, setShowMessage] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(sessionStorage.getItem("AUTH_USER"))
  );
  const [profilePicture, setProfilePicture] = useState("");

  const {
    loading: following_loading,
    error: following_error,
    data: following_data,
  } = useQuery(GET_TALENT, {
    variables: {
      talentID: isLoggedIn?.user?.talent?.casting_africa_talent_id,
    },
    onCompleted: (data) => {
      setFollowingList(
        data?.talent?.talentFollowings.map(
          (e) => e.following.casting_africa_talent_id
        )
      );
    },
    onError: (err) => console.log(err),
    fetchPolicy: "network-only",
    // pollInterval: 500
  });

  const [reportProfileState, setReportProfileState] = useState(false);

  const talent = useMemo(() =>  data?.talent, [data]);
  const something = () => {
    const talent_obj = talent
      ? talent
      : JSON.parse(sessionStorage.getItem("profile_set") || "{}");
    context.updateTalent(talent_obj);
  };

  


  const [talentType, setTalentType] = useState("");

  const handleProfileEdit = () => {
    if (talent_type !== "TALENT") {
      sessionStorage.setItem("PROFILE_DATA", talent_id);
      history.push("/talentDashboard", {
        signupmode: "TALENTEDIT",
      });
    } else {
      history.push("/editprofile");
    }
  };

  useEffect(() => {
    const talent_type = JSON.parse(
      talent?.professionalDetails?.casting_africa_talent_details || "{}"
    ).talent_type;
    setTalentType(talent_type?.toString().split(",").join(" & "));
  }, [data]);

  useEffect(() => {
    toggleViews(position);
    something();
    let index = sessionStorage.getItem("current_position");
    if (index === null) {
      index = 0;
    }
    setPosition(index);
  }, [data]);

  useEffect(() => {
    sessionStorage.setItem("current_position", position);
  }, [position]);

  const handleFollow = (followingID, talentID) => {
    if (!isLoggedIn) {
      NotificationManager.error(
        `Log in to Casting Africa to follow ${talent?.talent_first_name}`,
        "Casting Africa",
        5000
      );
      return;
    }

    followTalent({
      variables: {
        talentID,
        followingID,
      },
    })
      .then((res) => {
        setFollowingList([...res?.data?.followTalent]);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUnfollow = (followingID, talentID) => {
    unfollowTalent({
      variables: {
        talentID,
        followingID,
      },
    })
      .then((resp) => {
        setFollowingList(resp?.data?.unFollowTalent);
      })
      .catch((err) => {
        // console.log("unfollow error ", err);
      });
  };

  const copyProfileLink = () => {
    gaEventTracker(
      "Copy Profile Link",
      "Copy Profile Link Button action",
      "Copy Profile Link"
    );
    const talent_id = ":sk:" + talent?.casting_africa_talent_id + ":sk:";
    const encoded = Buffer.from(talent_id, "utf-8").toString("hex");
    const talent_name =
      talent?.talent_first_name.split(" ").join("-") +
      "-" +
      talent?.talent_last_name.split(" ").join("-") +
      "-" +
      encoded;

    const url =
      process.env.REACT_APP_PROTOCOL +
      process.env.REACT_APP_MAIN_DOMAIN +
      "ca?t=" +
      talent_name;
    navigator.clipboard.writeText(url);
    NotificationManager.success("Profile Link Copied", "Casting Africa", 6000);
  };

  if (loading && !data) return <Loader />;

  return (
    <div className="w-full h-auto overflow-y-scroll py-8 lg:py-16 flex flex-col items-center lg:px-0 px-2 ">
      <div className="mt-20">
        <div
          onClick={() => history.replace("/talents")}
          className="border w-10 h-10 flex justify-center items-center rounded-full bg-primary cursor-pointer shadow-2xl"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="#fff"
          >
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="flex flex-col lg:flex-row items-center md:justify-center  lg:mb-6">
          {/* Image place holder */}
          <div className="w-64 h-80 flex flex-col items-end justify-end p-2">
            <img
              style={{
                objectFit: "cover",
                backgroundSize: "100%",
              }}
              className="h-full w-full"
              src={profilePicture}
              alt=""
              onError={(e) => {
                e.target.src = DEFAULT
              }}
            />
            <div
              onClick={() => {
                context.setVideoViewer({
                  source: `${process.env.REACT_APP_PROTOCOL}${
                    process.env.REACT_APP_DOMAIN
                  }storage/${talent?.talent_media_path}/videos/${
                    talent?.videos.length ? talent?.videos[0] : ""
                  }`,
                  state: true,
                });
              }}
              className="border cursor-pointer -mt-14 mr-2 p-2 bg-white rounded-full"
            >
              <svg
                className="lg:w-8 w-8 md:w-7 fill-current text-blue-700"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.11304 4.5H11.9051C14.3271 4.5 16.0181 6.16904 16.0181 8.56091V15.4391C16.0181 17.831 14.3271 19.5 11.9051 19.5H6.11304C3.69102 19.5 2 17.831 2 15.4391V8.56091C2 6.16904 3.69102 4.5 6.11304 4.5ZM19.958 6.87898C20.397 6.65563 20.912 6.67898 21.331 6.94294C21.75 7.20589 22 7.66274 22 8.16223V15.8384C22 16.3389 21.75 16.7947 21.331 17.0577C21.102 17.2008 20.846 17.2739 20.588 17.2739C20.373 17.2739 20.158 17.2231 19.957 17.1206L18.476 16.3734C17.928 16.0952 17.588 15.5369 17.588 14.9165V9.08305C17.588 8.46173 17.928 7.90335 18.476 7.62721L19.958 6.87898Z"
                  fill="text-blue-500"
                  fillOpacity="0.9"
                />
              </svg>
            </div>
          </div>
          {/* End of image place holder */}
          {/* Talent information */}
          <div className="lg:h-80   flex flex-col  lg:px-16 cursor-pointer  items-center  lg:items-start lg:justify-center">
            <div className="flex items-center">
              <h1 className="text-2xl lg:text-3xl">
                {talent?.talent_first_name} {talent?.talent_last_name}
              </h1>
              {talent?.verification_status === "VERIFIED" ? (
                <img className="" src={VERIFICATION} alt="" />
              ) : (
                " "
              )}
            </div>
            <h1 className="font-gilroy-Light text-sm lg:text-xl  lg:mb-3">
              {talentType}
            </h1>
            <div className="flex items-center mb-3">
              <span className="mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>

              {talent?.talentAddress?.city && (
                <span className="mr-1 font-gilroy-Light text-sm lg:text-lg">
                  {talent?.talentAddress?.city},
                </span>
              )}

              <span className="mr-2 font-gilroy-Light text-sm lg:text-lg">
                {talent?.talentAddress?.country}
              </span>
              <span className="mr-2 font-gilroy-Light text-sm lg:text-lg">
                {talent?.gender}
              </span>
              <span className="mr-1 font-gilroy-Light text-sm lg:text-lg">
                {moment().diff(talent?.talent_dob, "years", false)} years,
              </span>
            </div>
            {/* socials */}
            <div className="flex  w-full lg:flex-nowrap md:justify-start justify-center items-center flex-wrap mb-3">
              {talent?.talentSocials[3].social_media_handle !== "" && (
                <a
                  target="_blank"
                  href={`https://www.tiktok.com/@${talent?.talentSocials[3].social_media_handle}`}
                  className="mr-1 mb-1"
                >
                  <img src={TIKTOK} alt="" />
                </a>
              )}

              {talent?.talentSocials[2].social_media_handle !== "" && (
                <a
                  target="_blank"
                  href={`https://www.youtube.com/c/${talent?.talentSocials[2].social_media_handle}`}
                  className="mr-1 mb-1"
                >
                  <img src={YOUTUBE} alt="" />
                </a>
              )}
              {talent?.talentSocials[2].social_media_handle !== "" && (
                <a
                  target="_blank"
                  href={`https://www.instagram.com/${talent?.talentSocials[1].social_media_handle}`}
                  className="mr-1 mb-1"
                >
                  <img className="w-7" src={INSTAGRAM} alt="" />
                </a>
              )}
              {talent?.talentSocials[2].social_media_handle !== "" && (
                <a
                  target="_blank"
                  href={`https://www.imdb.com/name/${talent?.talentSocials[4].social_media_handle}`}
                  className="mr-1 mb-1"
                >
                  <img src={IMDB} alt="" />
                </a>
              )}
              {talent?.talentSocials[2].social_media_handle !== "" && (
                <a
                  target="_blank"
                  href={`https://www.facebook.com/${talent?.talentSocials[0].social_media_handle}`}
                  className="mr-1 mb-1"
                >
                  <img src={FACEBOOK} alt="" />
                </a>
              )}
              {isLoggedIn && (
                <div>
                  <Link
                  to="/billing"
                  className={`${
                    talent?.account_status === "ACTIVE"
                      ? "bg-green-500"
                      : "bg-red-400"
                  } font-gilroy-Medium  text-xs px-3 py-1 mr-2 rounded-full text-white`}
                >
                  {talent?.account_status}
                </Link>
                <span className="bg-primary px-2 rounded-full text-white font-gilroy-Light text-xs py-1">
                  {talent?.account_type}
                </span>
                </div>
              )}
            </div>
            {/* end of socials */}
            {/* Contacts interaction */}
            <div className="flex lg:flex-row lg:justify-center lg:items-start items-center lg:flex-nowrap flex-wrap mb-2 justify-center ">
              {/* <div
                // onClick={() => setShowMessage(!showMessage)}
                onClick={() => {}}
                className="border mb-2 flex cursor-pointer group px-4 py-2 mr-1 bg-primary rounded-lg justify-between"
              >
                <span className="mr-2">
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.636719 11.1515C0.636719 5.65202 5.03183 0.696167 11.0973 0.696167C17.027 0.696167 21.5161 5.55792 21.5161 11.1202C21.5161 17.5711 16.2545 21.5756 11.0764 21.5756C9.3643 21.5756 7.46428 21.1155 5.94008 20.2164C5.40766 19.8922 4.95875 19.6518 4.38457 19.84L2.27575 20.4673C1.74333 20.6346 1.2631 20.2164 1.4197 19.6518L2.11916 17.3098C2.23399 16.9856 2.21311 16.6406 2.04608 16.3688C1.14826 14.7168 0.636719 12.908 0.636719 11.1515ZM9.71959 11.1515C9.71959 11.8939 10.3146 12.4898 11.0559 12.5003C11.7971 12.5003 12.3921 11.8939 12.3921 11.162C12.3921 10.4197 11.7971 9.82371 11.0559 9.82371C10.3251 9.81326 9.71959 10.4197 9.71959 11.1515ZM14.5333 11.162C14.5333 11.8939 15.1284 12.5003 15.8696 12.5003C16.6108 12.5003 17.2059 11.8939 17.2059 11.162C17.2059 10.4197 16.6108 9.8237 15.8696 9.8237C15.1284 9.8237 14.5333 10.4197 14.5333 11.162ZM6.24241 12.5003C5.51163 12.5003 4.90613 11.8939 4.90613 11.162C4.90613 10.4197 5.50119 9.82371 6.24241 9.82371C6.98363 9.82371 7.57869 10.4197 7.57869 11.162C7.57869 11.8939 6.98363 12.4898 6.24241 12.5003Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span className="text-white lg:text-sm font-gilroy-Light">
                  Message
                </span>
              </div> */}
              {isLoggedIn && location.pathname === "/talentProfile" ? (
                <div className="w-full flex">
                  <div
                    onClick={handleProfileEdit}
                    className="border mb-2 flex cursor-pointer border-primary items-center mr-1 px-4 py-2 rounded-lg justify-between"
                  >
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path
                          fillRule="evenodd"
                          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span className="text-primary lg:text-sm font-gilroy-Light">
                      Edit Profile
                    </span>
                  </div>

                  <div
                    onClick={() => copyProfileLink()}
                    className="border mb-2 flex cursor-pointer border-primary items-center mr-1 px-4 py-2 rounded-lg justify-between"
                  >
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#3A77FF"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                        />
                      </svg>
                    </span>
                    <span className="text-primary lg:text-sm font-gilroy-Light">
                      Profile Link
                    </span>
                  </div>
                </div>
              ) : (
                <div className="flex">
                  {talent?.casting_africa_talent_id ===
                  isLoggedIn?.user?.talent?.casting_africa_talent_id ? (
                    ""
                  ) : (
                    <div className="flex mb-2">
                      {followingList?.includes(
                        talent?.casting_africa_talent_id
                      ) ? (
                        <div
                          onClick={() =>
                            handleUnfollow(
                              talent?.casting_africa_talent_id,
                              isLoggedIn?.user?.talent?.casting_africa_talent_id
                            )
                          }
                          className="border group hover:bg-primary hover:text-white flex cursor-pointer border-primary items-center mr-1 px-4 py-2  rounded-lg justify-between"
                        >
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 fill-current"
                              fill="#f00"
                              viewBox="0 0 24 24"
                              stroke="#3A77FF"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"
                              />
                            </svg>
                          </span>
                          <span className="text-primary  group-hover:text-white  lg:text-sm font-gilroy-Light">
                            Unfollow
                          </span>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            handleFollow(
                              talent?.casting_africa_talent_id,
                              isLoggedIn?.user?.talent?.casting_africa_talent_id
                            )
                          }
                          className="border group hover:bg-primary hover:text-white  flex cursor-pointer border-primary items-center mr-1 px-4 py-2  rounded-lg justify-between"
                        >
                          <span className="mr-2">
                            <svg
                              className="w-5 h-5"
                              viewBox="0 0 25 26"
                              fill="#3A77FF"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.89563 13.5998C12.765 13.5998 15.065 11.1768 15.065 8.15408C15.065 5.13135 12.765 2.70834 9.89563 2.70834C7.02629 2.70834 4.72623 5.13135 4.72623 8.15408C4.72623 11.1768 7.02629 13.5998 9.89563 13.5998ZM9.89551 16.2665C5.68116 16.2665 2.08301 16.9672 2.08301 19.767C2.08301 22.5656 5.65926 23.2917 9.89551 23.2917C14.1088 23.2917 17.708 22.5909 17.708 19.7911C17.708 16.9914 14.1318 16.2665 9.89551 16.2665ZM20.7266 10.3869H21.9798C22.4956 10.3869 22.9162 10.8304 22.9162 11.3744C22.9162 11.9185 22.4956 12.362 21.9798 12.362H20.7266V13.6374C20.7266 14.1814 20.3071 14.625 19.7902 14.625C19.2743 14.625 18.8537 14.1814 18.8537 13.6374V12.362H17.6027C17.0857 12.362 16.6662 11.9185 16.6662 11.3744C16.6662 10.8304 17.0857 10.3869 17.6027 10.3869H18.8537V9.11259C18.8537 8.56857 19.2743 8.125 19.7902 8.125C20.3071 8.125 20.7266 8.56857 20.7266 9.11259V10.3869Z"
                                // fill="#3A77FF"
                              />
                            </svg>
                          </span>
                          <span className="text-primary  group-hover:text-white  lg:text-sm font-gilroy-Light">
                            Follow
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {/* <div className="border mb-2 flex cursor-pointer border-primary items-center mr-1 px-4 py-2 rounded-lg justify-between">
                    <span className="mr-2">
                      <svg
                        className="w-5 h-5"
                        viewBox="0 0 17 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.12671 0.867554H11.3203C14.0406 0.867554 16.239 1.94164 16.2691 4.67202V19.91C16.2691 20.0806 16.2289 20.2513 16.1486 20.4018C16.0181 20.6427 15.7973 20.8234 15.5263 20.9037C15.2653 20.984 14.9742 20.9439 14.7332 20.8034L8.22851 17.551L1.71373 20.8034C1.56416 20.8827 1.39251 20.9339 1.22186 20.9339C0.659725 20.9339 0.208008 20.4721 0.208008 19.91V4.67202C0.208008 1.94164 2.41641 0.867554 5.12671 0.867554ZM4.44491 8.51664H12.0036C12.4353 8.51664 12.7866 8.1643 12.7866 7.72363C12.7866 7.28195 12.4353 6.93061 12.0036 6.93061H4.44491C4.01326 6.93061 3.66193 7.28195 3.66193 7.72363C3.66193 8.1643 4.01326 8.51664 4.44491 8.51664Z"
                          fill="#3A77FF"
                        />
                      </svg>
                    </span>
                    <span className="text-primary lg:text-sm font-gilroy-Light">
                      Shortlist
                    </span>
                  </div>*/}
                  <div
                    onClick={() => context.setBookMeViewer(true)}
                    className="border mb-2 flex cursor-pointer border-primary items-center mr-1 px-4 py-2  rounded-lg justify-between"
                  >
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#3A77FF"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                        />
                      </svg>
                    </span>
                    <span className="text-primary  lg:text-sm font-gilroy-Light">
                      Book Me
                    </span>
                  </div>
                  <div
                    onClick={() => copyProfileLink()}
                    className="border mb-2 flex cursor-pointer border-primary items-center mr-1 px-4 py-2 rounded-lg justify-between"
                  >
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#3A77FF"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                        />
                      </svg>
                    </span>
                    <span className="text-primary lg:text-sm font-gilroy-Light">
                      Profile Link
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* End of contact interaction */}
          </div>

          {/* End of talent information */}
        </div>

        <div className="flex md:flex-nowrap flex-wrap  justify-center items-center  mb-6">
          {talent_nav.map((tabs, key) => (
            <div
              onClick={() => {
                setPosition(key);
                context.updateTalentLocalState(talent);
              }}
              className={` mr-2  mb-1 lg:mr-4 ${
                position == key
                  ? "text-blue-600 font-gilroy-Extrabold underline"
                  : "text-gray-500"
              } text-sm lg:text-lg cursor-pointer font-gilroy-Light`}
              key={key}
            >
              <span className="mr-2">{tabs.tab_name}</span>
              {/* <span>{"0".toLocaleString()}</span> */}
            </div>
          ))}
        </div>
        <div className="overflow-hidden">
          {toggleViews(talent_nav, position)}
          <ProfileFooter
            count={""}
            copyProfileLink={copyProfileLink}
            isLoggedIn={isLoggedIn}
            setReportState={setReportProfileState}
          />
        </div>
      </div>
      {showMessage && (
        <ChatPopUp
          setShowMessage={setShowMessage}
          showMessage={setShowMessage}
        />
      )}

      {reportProfileState && (
        <ReportProfile
          talentName={talent?.talent_first_name}
          setReportState={setReportProfileState}
        />
      )}

      {context?.pdfViewerState && (
        <PDFViewer context={context} talent={talent} />
      )}

      {context?.photoViewer.state && <PhotoViewer context={context} />}
      {context?.videoViewer.state && <VideoViewer context={context} />}

      {context?.bookMeViewer && (
        <BookTalent
          talentId={talent?.casting_africa_talent_id}
          setBookingResponse={setBookingResponse}
          context={context}
        />
      )}
    </div>
  );
};

const PhotoViewer = ({ context }) => {
  return (
    <div
      onClick={() =>
        context?.setPhotoViewer({ ...context.photoViewer, state: false })
      }
      className="w-full h-full  bg-modalBackground  lg:px-0 px-6 py-16 flex justify-center items-center fixed top-0 z-50 left-0"
    >
      <div className=" bg-transparent flex flex-col items-center  lg:w-3/6 h-full   ">
        <div className="w-full flex justify-end mr-4 mt-2">
          <svg
            onClick={() =>
              context?.setPhotoViewer({ ...context.photoViewer, state: false })
            }
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#f00"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>

        <img
          className="h-full object-contain w-full"
          src={context.photoViewer.source}
          alt="Preview"
        />
      </div>
    </div>
  );
};

const VideoViewer = ({ context }) => {
  return (
    <div
      className="w-full h-full  bg-modalBackground  lg:px-0 px-6 py-16 flex justify-center items-center fixed top-0 z-50 left-0"
    >
      <div className=" bg-transparent flex flex-col items-center  lg:w-3/6  ">
        <div className="w-full flex justify-end mr-4 mt-2">
          <svg
            onClick={() =>
              context?.setVideoViewer({ ...context.videoViewer, state: false })
            }
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#f00"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <video
          className="w-full object-contain h-full"
          controls
          src={context.videoViewer.source}
          typeof="video/mp4"
        />
      </div>
    </div>
  );
};

const ReportProfile = ({ talentName, setReportState }) => {
  return (
    <div
      onClick={() => setReportState(false)}
      className="w-full h-full lg:px-0 px-5  bg-modalBackground z-50 top-0  left-0 flex justify-center items-center fixed"
    >
      <div className=" bg-white flex flex-col items-center w-full  lg:w-3/6 lg:h-3/6 ">
        <div className="w-full flex justify-end mr-4 mt-2">
          <svg
            onClick={() => setReportState(false)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#f00"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className=" mt-10 lg:w-4/6">
          <h1 className=" mb-2 lg:mb-5 text-sm lg:text-2xl font-gilroy-Medium ">
            Report {talentName}
          </h1>
          {[
            "Sexual Harrasment",
            "Disrepectfulness and Foul Language",
            "Spamming",
            "Cyber Bullying",
            "Nudity",
            "Others",
          ].map((haras, key) => (
            <div key={key} className="flex items-center mr-6 mb-3">
              <input
                className="mr-2 p-1 md:p-14 opacity-0 absolute h-8 w-8"
                type="checkbox"
                name={""}
                id=""
              />
              <div className="bg-white border rounded-md border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black">
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#1F73F1"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className=" text-sm lg:text-xl font-gilroy-Light">
                {haras}
              </span>
            </div>
          ))}
          <div className="flex  mt-10 flex-col">
            <span className="mb-6 font-gilroy-Light text-2xl">Details</span>
            <textarea
              className="border font-gilroy-Light px-4 rounded-3xl focus:outline-none "
              cols="20"
              rows="5"
            ></textarea>
          </div>
        </div>

        <div className="w-full flex justify-center py-10">
          <button className="border px-6 h-12 rounded-2xl bg-primary text-white font-gilroy-Light ">
            Report Profile
          </button>
        </div>
      </div>
    </div>
  );
};

const PDFViewer = ({ context, talent }) => {
  return (
    <div
      onClick={() => context?.setPdfViewerState(false)}
      className="w-full h-full  bg-modalBackground  lg:px-0 px-6 py-16 flex justify-center items-center fixed top-0 z-50 left-0"
    >
      <div className=" bg-white flex flex-col items-center  lg:w-3/6 h-full   ">
        <div className="w-full flex justify-end mr-4 mt-2">
          <svg
            onClick={() => context?.setPdfViewerState(false)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#f00"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>

        <object
          className="w-full h-full"
          data={`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${talent?.talent_media_path}/resume/resume.pdf`}
          type="application/pdf"
        >
          <iframe
            src={`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${talent?.talent_media_path}/resume/resume.pdf`}
          ></iframe>
        </object>
      </div>
    </div>
  );
};

const ChatPopUp = ({ setShowMessage, showMessage }) => {
  return (
    <div className=" w-96 h-102 bg-white shadow-2xl fixed bottom-0 right-4">
      <div className="flex border justify-center items-center">
        <div className="flex-1 py-1 border cursor-pointer font-gilroy-Light text-xl flex justify-center">
          <div className="border rounded-full bg-pricingColor  w-10 h-10 "></div>
          <div>
            <p>Olivia Gallagher</p>
            <p>Active now</p>
          </div>
        </div>
        <div
          onClick={() => setShowMessage(!showMessage)}
          className="  font-gilroy-Medium cursor-pointer flex  text-4xl justify-end mr-4"
        >
          x
        </div>
      </div>
      <div className="border h-full overflow-scroll">content</div>
    </div>
  );
};

const MessagePopUp = ({ setShowMessage, showMessage }) => {
  return (
    <div className=" w-96 h-102 bg-white shadow-2xl fixed bottom-0 right-4">
      <div className="flex border justify-center items-center">
        <div className="flex-1 py-1 cursor-pointer font-gilroy-Light border-b-4 border-primary text-xl flex justify-center">
          Chat
        </div>
        <div
          onClick={() => setShowMessage(!showMessage)}
          className="flex-1 text-red-600 cursor-pointer flex justify-end mr-4"
        >
          x
        </div>
      </div>
      <div className="border h-full overflow-scroll">content</div>
    </div>
  );
};

export default TalentDetails;
