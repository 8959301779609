import React from "react";
import { NotificationManager } from "react-notifications";

const ProfileFooter = ({ count,  setReportState, isLoggedIn, copyProfileLink }) => {

  const handleReport = () => {

    if (!isLoggedIn) {
      NotificationManager.error("Log in to file a report on talent", "Casting Africa", 3000);
      return;
    } 

    setReportState(true)
  }
  return (
    <div className="flex lg:flex-nowrap flex-wrap justify-center mt-10">
      <div className="font-gilroy-Light flex items-center mr-2 mb-3 lg:mr-3 cursor-pointer hover:text-primary">
        <svg
          className="mr-1 w-3 lg:w-5 h-5"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.97037 9.46002C6.2519 9.46002 4.86001 8.07657 4.86001 6.37624C4.86001 4.66818 6.2519 3.28473 7.97037 3.28473C9.68107 3.28473 11.073 4.66818 11.073 6.37624C11.073 8.07657 9.68107 9.46002 7.97037 9.46002ZM15.8939 6.14437C15.0619 4.21991 13.9033 2.68188 12.5425 1.63077C11.1817 0.571929 9.61879 0 7.9703 0H7.96252C4.67332 0 1.7107 2.29545 0.0466554 6.14437C-0.0155518 6.29122 -0.0155518 6.46125 0.0466554 6.60037C1.7107 10.4493 4.67332 12.7525 7.96252 12.7525H7.9703C11.2673 12.7525 14.2299 10.4493 15.8939 6.60037C15.9561 6.46125 15.9561 6.29122 15.8939 6.14437Z"
            fill="#3A77FF"
          />
        </svg>
        <span className="text-xs lg:text-sm">Profile views {count}</span>
      </div>
      <div onClick={() => copyProfileLink()} className="font-gilroy-Light flex items-center mr-2 mb-3 lg:mr-3 cursor-pointer hover:text-primary">
        <svg
          className="mr-1 w-3 lg:w-5 h-5"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5483 0.465586C15.1481 0.0548785 14.5559 -0.0981303 14.0037 0.0629316L1.1264 3.80762C0.543759 3.96949 0.13079 4.43415 0.0195441 5.02444C-0.0941024 5.6252 0.30286 6.38783 0.821472 6.70673L4.84792 9.18145C5.26089 9.43512 5.79391 9.3715 6.13565 9.02683L10.7463 4.38744C10.9784 4.14585 11.3626 4.14585 11.5947 4.38744C11.8268 4.62098 11.8268 4.99948 11.5947 5.24107L6.97599 9.88126C6.63345 10.2251 6.56943 10.7607 6.82153 11.1762L9.28174 15.243C9.56985 15.7262 10.0661 16 10.6103 16C10.6743 16 10.7463 16 10.8104 15.9919C11.4346 15.9114 11.9308 15.4846 12.1149 14.8806L15.9325 2.01983C16.1005 1.47222 15.9485 0.876294 15.5483 0.465586Z"
            fill="#3A77FF"
          />
        </svg>

        <span className="text-xs lg:text-sm">Share this profile</span>
      </div>
      <div
        onClick={() =>
          NotificationManager.error("Member Blocked", "Casting Africa", 3000)
        }
        className="font-gilroy-Light mb-3 flex items-center mr-3 cursor-pointer hover:text-primary"
      >
        <svg
          className="mr-1 w-3 lg:w-5 h-5"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2012 3.42691C15.7908 3.62098 16.1892 4.16517 16.1892 4.77069L16.2291 10.0255C16.237 11.6169 15.6554 13.1618 14.5877 14.3736C14.1016 14.9325 13.4721 15.4061 12.6753 15.833L9.84669 17.3468C9.75904 17.3934 9.66343 17.4167 9.56781 17.4167C9.4722 17.4167 9.36861 17.3934 9.28096 17.3468L6.42842 15.8719C5.62366 15.4527 4.99418 14.9869 4.50017 14.4357C3.41652 13.2402 2.81095 11.6953 2.80299 10.1039L2.77111 4.84754C2.76315 4.24203 3.16155 3.69939 3.74321 3.49677L8.97818 1.66547C9.28893 1.55601 9.63156 1.55601 9.95028 1.66547L15.2012 3.42691ZM11.4163 11.1519C11.6474 10.9268 11.6474 10.5542 11.4163 10.329L10.3407 9.28024L11.4163 8.23301C11.6474 8.00788 11.6474 7.64302 11.4163 7.40935C11.1853 7.18422 10.8028 7.18422 10.5717 7.40935L9.49605 8.45814L8.42037 7.40935C8.1893 7.18422 7.8148 7.18422 7.57576 7.40935C7.34469 7.64302 7.34469 8.00788 7.57576 8.23301L8.65144 9.28024L7.57576 10.329C7.34469 10.5542 7.34469 10.9268 7.57576 11.1519C7.69528 11.2684 7.84667 11.3219 7.99806 11.3219C8.15742 11.3219 8.30882 11.2684 8.42037 11.1519L9.49605 10.1039L10.5717 11.1519C10.6912 11.2684 10.8426 11.3219 10.994 11.3219C11.1454 11.3219 11.3048 11.2684 11.4163 11.1519Z"
            fill="#3A77FF"
          />
        </svg>

        <span className="text-xs lg:text-sm">Block member</span>
      </div>
      <div
        onClick={() => handleReport()}
        className="font-gilroy-Light flex items-center mr-2 mb-3 lg:mr-3 cursor-pointer hover:text-primary"
      >
        <svg
          className="mr-1 w-3 lg:w-5 h-5"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5835 9.49943C1.5835 5.13022 5.13016 1.58276 9.50016 1.58276C13.8781 1.58276 17.4168 5.13022 17.4168 9.49943C17.4168 13.8702 13.8781 17.4161 9.50016 17.4161C5.13016 17.4161 1.5835 13.8702 1.5835 9.49943ZM8.80347 6.49902C8.80347 6.11981 9.12014 5.80236 9.50014 5.80236C9.88014 5.80236 10.1889 6.11981 10.1889 6.49902V9.99819C10.1889 10.379 9.88014 10.6869 9.50014 10.6869C9.12014 10.6869 8.80347 10.379 8.80347 9.99819V6.49902ZM9.5081 13.2052C9.12019 13.2052 8.81144 12.8885 8.81144 12.5085C8.81144 12.1285 9.12019 11.8198 9.50019 11.8198C9.8881 11.8198 10.1969 12.1285 10.1969 12.5085C10.1969 12.8885 9.8881 13.2052 9.5081 13.2052Z"
            fill="#3A77FF"
          />
        </svg>

        <span className="text-xs lg:text-sm">Report profile</span>
      </div>
    </div>
  );
};

export default ProfileFooter;
