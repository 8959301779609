import React, { useContext, useState, useEffect } from "react";
import GROUP_IMG from "../../assets/images/Group_202.png";
import GOOGLE from "../../assets/images/gmail.png";
import FACEBOOK from "../../assets/images/facebook.png";
import TWITTER from "../../assets/images/twitter.png";
import { Link } from "react-router-dom";
import PackageContext from "../../context/context";
import  AuthContext  from "../../context/AuthProvider";
import { useHistory } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { mock_login } from "../../config/utilities";
import { useForm } from "react-hook-form";
import {LOGIN } from "../../graphql/mutations"
import { useMutation } from "@apollo/client";

const Login = () => {

  const context = useContext(PackageContext);
  // const { auth, setAuth } = useContext(AuthContext);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loginUser, {data, loading, error}] = useMutation(LOGIN);
  const [isBack, setIsBack] = useState(false);
  const [hasError, setHasError] = useState("")


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(true);
  let a ;
  const handleLogin = async (data) => {
    setHasError("")
   let a = await loginUser({
      variables: {
        username: data['email'],
        password: data['password']
      }
    }).then(resp => {
      // context.updateAuth(resp);
      // setAuth(resp);
      context.setAuth(resp);
      sessionStorage.setItem("AUTH_USER", JSON.stringify(resp.data?.login));
      
      history.push("/talentProfile", {
        talent_type: resp.data?.login.user.talent.account_type,
        talent_id: resp.data?.login.user.talent?.casting_africa_talent_id,
      });
      setIsBack(true);
      window.location.reload();
    }).catch(err => {
        setHasError(err.message)
    })
  };
 



  return (
    <div className="w-full h-full overflow-y-scroll md:overflow mt-16  flex flex-col justify-start items-center pt-12 p-5 md:p-20 ">
      <h1 className=" font-gilroy-Light text-2xl mb-6  text-gray-600 ">
        Welcome Back!
      </h1>
      <img className="mb-12 " src={GROUP_IMG} alt="Login logo" />
      <div className=" flex justify-start  lg:w-3/12 md:w-5/12 mb-2">
        <p className=" font-gilroy-Light text-red-600 ">
          {errors.email?.message}
          {hasError === "invalid_grant" ? 'Incorrect login credentials. Try again later!!' : ''}
        </p>
      </div>
      <div className="border-b w-full lg:w-3/12 md:w-5/12 border-gray-400 mb-10  pb-2 flex flex-row">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 md:h-8 md:w-8 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
        <input
          className="font-gilroy-Light flex-1 text-primary placeholder-primary focus:outline-none px-2 "
          type="email"
          defaultValue={""}
          placeholder="cole@casting.Africa"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid Email Standard",
            },
          })}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 lg:h-8 lg:w-8 text-primary relative ml-auto"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      <div className="border-b w-full lg:w-3/12 md:w-5/12 pb-2 border-gray-400 mb-1 flex flex-row">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 md:h-8  md:w-8 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
          />
        </svg>
        <input
          className="font-gilroy-Light flex-1 focus:outline-none text-primary px-2"
          type="password"
          defaultValue={""}
          placeholder="Enter password"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Invalid password length",
            },
          })}
        />
        {/* {
           eye ? (<svg
           xmlns="http://www.w3.org/2000/svg"
           className="h-6 w-6 md:h-8  md:w-8 relative ml-auto"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
         >
           <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={1}
             d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
           />
           <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={1}
             d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
           />
         </svg>) : ""
         } */}
      </div>
      <div className=" flex justify-start  lg:w-3/12 md:w-5/12 mb-2">
        <p className=" font-gilroy-Light text-red-600 ">
          {errors.password?.message}
        </p>
      </div>

      <div className="text-right mb-12 w-full  lg:w-3/12 md:w-5/12 font-gilroy-Light text-gray-500  cursor-pointer ">
        <Link to="/forgotPassword">Forgot Password?</Link>
      </div>
      <button
        onClick={handleSubmit(handleLogin)}
        className="btn px-20 lg:py-5 font-gilroy-Light"
      >
        {
          loading && !hasError ? 'submitting..' : 'login'
        }
      </button>
      <p className="mt-8 mb-5 font-gilroy-Light text-gray-500">
        Or via social media
      </p>
      <div className="mt-5 mb-5 flex flex-row justify-evenly w-4/12  md:w-2/12">
        <img src={GOOGLE} alt="" />
        <img src={FACEBOOK} alt="" />
        <img src={TWITTER} alt="" />
      </div>
      <p className="mt-5 mb-5 w-full lg:w-3/12 lg:text-lg text-center text-sm font-gilroy-Light text-gray-500">
        Don't have an account?{" "}
        <Link
          onClick={() => context.updatePath("/signup")}
          to="/signup"
          className="text-primary underline cursor-pointer "
        >
          {" "}
          Register now
        </Link>{" "}
      </p>
      <p className="mt-5 mb-5 w-full lg:w-4/12 lg:text-lg text-center text-sm font-gilroy-Light text-gray-500">
        By signing up, you are agree with our{" "}
        <span className="text-primary underline cursor-pointer ">
          {" "}
          Terms & Conditions
        </span>
      </p>
    </div>
  );
};

export default Login;
