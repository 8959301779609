import React, { useState, useEffect, forwardRef } from "react";
import GHANA from "../../../../../assets/images/ghana.png";

const Profileforms = ({ loggedUser, register }) => {
 
  const GenderOptions = React.forwardRef(({ onChange, register, defaultChecked }, forwardRef) => {
      const [gender, setGender] = useState(defaultChecked);
      useEffect(() => {
        if (onChange) {
          onChange(gender);
        }
      }, [gender]);

      return (
        <div className="px-4 md:px-4">
          <label
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            htmlFor="Gender"
          >
            Gender
          </label>
          <div className="flex ">
            <div className=" flex items-center mr-2 w-1/6">
              <input
                className="border-0 opacity-0 absolute w-5 h-5"
                type="checkbox"
                ref={forwardRef}
                checked={gender === "MALE"}
                {...register("gender")}
                onChange={(e) => setGender("MALE")}
              />
              <div className="bg-white border rounded-full border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black">
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#1F73F1"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="ml-1 mr-3 font-gilroy-Light text-sm md:text-lg  text-formFont">
                Male
              </span>
            </div>
            <div className=" flex items-center w-2/6">
              <input
                type="checkbox"
                className="absolute opacity-0 w-5 h-5"
                ref={forwardRef}
                checked={gender === "FEMALE"}
                {...register("gender")}
                onChange={(e) => setGender("FEMALE")}
              />
              <div className="bg-white border rounded-full border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black">
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#1F73F1"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="ml-1 font-gilroy-Light text-sm md:text-lg  text-formFont">
                Female
              </span>
            </div>
          </div>
        </div>
      );
  });

  const OccupationOptions = forwardRef(({onChange, defaultSelect}, forwardRef) => {
    const [occupation, setOccupation] = useState(defaultSelect);
    return (
      <div className="flex flex-row px-4 md:px-2">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="First Name"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Occupation
          </label>
          <div className="flex flex-wrap m-0 p-0">
            {["Agent", "Manager", "Agency"].map((talent, key) => (
              <div
                onClick={() => setOccupation(talent)}
                key={key}
                className={`border ${
                  occupation === talent
                    ? "bg-primary text-white"
                    : "text-formFont"
                } cursor-pointer font-gilroy-Light px-4 py-2  rounded-3xl border-primary m-2`}
              >
                {talent}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="flex  flex-col px-3 md:flex-row md:px-2">
        <div className=" md:flex-1 md:pr-8 px-1 py-1 flex flex-col">
          <label
            htmlFor="First Name"
            className="mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            First Name
          </label>
          <input
            className="border shadow-none rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light  focus:outline-none px-2 py-3 "
            type="text"
            placeholder="Your first name"
            defaultValue={loggedUser?.first_name}
            {...register("first_name")}
          />
        </div>
        <div className=" md:flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="Last Name"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Last Name
          </label>
          <input
            className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
            type="text"
            placeholder="Your last name"
            defaultValue={loggedUser?.last_name}
            {...register("last_name")}
          />
        </div>
      </div>

      <div className="px-3 md:px-2">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="E-mail"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            E-mail
          </label>
          <input
            className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
            type="email"
            placeholder="Your e-mail address"
            defaultValue={loggedUser?.username}
            {...register("username")}
          />
        </div>
      </div>

      <div className="flex justify-center items-center flex-row mb-3 ">
        <div className="flex flex-col w-full px-4 lg:px-2">
          <label
            htmlFor="Phone number"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Phone number
          </label>
          <div className="flex">
            <div className="py-2 md:py-3 border-r-0 rounded-tr-none rounded-br-none rounded-lg pl-2 pr-4 flex flex-row items-center justify-between border border-gray-300 font-gilroy-Light">
              <img
                className="w-7 lg:w-10 h-7 lg:h-6"
                src={GHANA}
                alt="Ghana Flag"
              />
              <select
                className="w-20 md:w-20 mr-2 md:mr-4  focus:outline-none md:px-1"
                name=""
                id=""
              >
                <option value="+233">+233</option>
                <option value="+234">+234</option>
                <option value="+245">+245</option>
                <option value="+27">+27</option>
                <option value="+264">+264</option>
              </select>
            </div>
            <input
              className="border placeholder-secondary w-full rounded-tl-none rounded-bl-none  rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 md:py-3 "
              type="Text"
              placeholder=""
              defaultValue={loggedUser?.phonenumber}
              {...register("phonenumber")}
            />
          </div>
        </div>
      </div>

      {/* Gender goes here */}
      <GenderOptions defaultChecked={loggedUser?.gender} register={register} />

      {/* Occupation goes here */}
      <OccupationOptions defaultSelect={loggedUser?.occupation} />

      <div className="px-4 md:px-2">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="Country"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Country
          </label>
          <select
            className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
            name=""
            id=""
          >
            <option value="Ghana">Ghana</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Kenya">Kenya</option>
            <option value="South Africa">South Africa</option>
            <option value="Namibia">Namibia</option>
          </select>
        </div>
      </div>

      <div className="px-4 md:px-2">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="Town/City"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Town/City
          </label>
          <select
            className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
            name=""
            id=""
          >
            <option value="Ghana">Accra</option>
            <option value="Nigeria">East Legon</option>
          </select>
        </div>
      </div>

      <div className="px-4 md:px-2">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="Zip Code"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Zip Code
          </label>
          <input
            className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
            type="text"
            placeholder="Your Zip Code"
            defaultValue={loggedUser?.company_details?.address?.zipcode}
            {...register("zipcode")}
          />
        </div>
      </div>

      <div className="px-4 md:px-2">
        <div className="flex-1 px-1 py-1 mb-3 flex flex-col">
          <label
            htmlFor="Company Name"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Company Name
          </label>
          <input
            className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
            type="text"
            placeholder="Your Company Name"
            defaultValue={loggedUser?.company_details?.company_name}
            {...register("company_name")}
          />
        </div>
      </div>

      <div className="px-4 md:px-2">
        <div className="flex-1 px-1 py-1 mb-3 flex flex-col">
          <label
            htmlFor="Company Website"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Company Website
          </label>
          <input
            className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
            type="text"
            placeholder="Your Company Website"
            defaultValue={loggedUser?.company_details?.website}
            {...register("company_website")}
          />
        </div>
      </div>

      <div className="px-4 md:px-2">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="Union/Association"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Union/Association
          </label>
          <input
            className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
            type="text"
            placeholder="Your Union/Association"
          />
        </div>
      </div>

      {/* <div className="px-4 md:px-2">
        <div className="flex-1 px-1 py-1 mb-3 flex flex-col">
          <label
            htmlFor="Roster Size"
            className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Roster Size
          </label>
          <select
            className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
            name=""
            id=""
          >
            <option value=""></option>
            <option value=""></option>
            <option value=""></option>
            <option value=""></option>
            <option value=""></option>
          </select>
        </div>
      </div> */}
    </>
  );
};

export default Profileforms;
