import React, { useState, useEffect } from "react";
import { MainLanding } from "..";
import PostJobComplete from "./postJobComplete";
import PostJobForms from "./postJobForms";
import PreviewJob from "./previewJob";
import { useLocation, useHistory } from "react-router-dom";

const PostJob = () => {
  const location = useLocation();
  // const history = useHistory();
  
  const { mode } = location?.state || "";
  const [step, setStep] = useState(1);
  const [jobDetails, setJobDetails] = useState({
    talentCategory: [],
    project_title: "",
    project_category: "",
    project_description: "",
    gender: [],
    location_range: [],
    country: "",
    town: "",
    company_name: "",
    company_location: "",
    company_website: "",
    union_association: "",
    submission_instruction: "",
    submission_email: "",
    media_requirement: "",
    remuneration: "",
    media: [],
  });

  useEffect(() => {
    if (mode === "Edit") {
      setStep(2);
    }
  }, [step]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    if (step < 1) return null;
    setStep(step - 1);
  };

  const handleFormChange = (input, extra) => (e) => {
    setJobDetails((prev) => ({
      ...prev,
      [input]: !extra ? e.target.value : extra,
    }));
  };

  const multiStep = () => {
    switch (step) {
      case 1:
        return (
          <MainLanding
            handleFormChange={handleFormChange}
            jobDetails={jobDetails}
            step={step}
            next={nextStep}
            title="What talent do you need?"
          />
        );
      case 2:
        return (
          <PostJobForms
            handleFormChange={handleFormChange}
            setJobDetails={setJobDetails}
            jobDetails={jobDetails}
            next={nextStep}
            prev={prevStep}
            mode={mode}
          />
        );
      case 3:
        return (
          <PreviewJob
            setJobDetails={setJobDetails}
            jobDetails={jobDetails}
            next={nextStep}
            prev={prevStep}
          />
        );
      case 4:
        return (
          <PostJobComplete
            handleFormChange={handleFormChange}
            setJobDetails={setJobDetails}
            jobDetails={jobDetails}
            next={nextStep}
            prev={prevStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full flex-col h-full mt-24 flex justify-start overflow-scroll">
      {multiStep()}
    </div>
  );
};

export default PostJob;
