import React from "react";
import ELEARNINGIMG from "../../../assets/images/e_learning_img.png";
import GETSTARTBG from "../../../assets/images/getstartedbg.png";
import {
  casting_africa_communities,
  casting_africa_partners,
  how_it_works,
  what_we_do,
} from "../../../config/utilities";

import PIC_1 from "../../../assets/images/pic_1.png";
import PIC_2 from "../../../assets/images/pic_2.png";
import PIC_3 from "../../../assets/images/pic_3.png";
import PIC_4 from "../../../assets/images/pic_4.png";
import PIC_5 from "../../../assets/images/pic_5.png";
import AVATAR_1 from "../../../assets/images/avatar_1.png";
import AVATAR_2 from "../../../assets/images/avatar_2.png";
import COMMENT_1 from "../../../assets/images/comment_1.png";
import COMMENT_2 from "../../../assets/images/comment_2.png";
import COMMENT_3 from "../../../assets/images/comment_3.png";
import TRAIL from "../../../assets/images/trail.png";

const Academy = () => {
    return (
      <div className="w-full h-screen mt-20 flex flex-col justify-center items-center py-16">
          <h1>Page coming soon</h1>
      </div>
    );
}

export default Academy;

