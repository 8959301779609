import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
// import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
// import { setContext } from "apollo-link-context";

const BASE_URL = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}`;
const inMemoryCache = new InMemoryCache();

const httpLink = createUploadLink({
  uri: `${BASE_URL}graphql`,
});

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors) graphQLErrors.map(({ message }) => message);
//   if (networkError)
//     NotificationManager.error("Network Errors, Come back later");
// });

// const authLink = setContext((_, { headers }) => {
//   const token = "";
//   return {
//       headers: {
//           ...headers,
//           authorization: token ? `Bearer ${token}` : '',
//       }
//   }
// });


// const link = ApolloLink.from([errorLink, httpLink]);
const link = ApolloLink.from([httpLink]);

const client = new ApolloClient({
  // uri: `${BASE_URL}graphql`,
  link,
  cache: inMemoryCache,
});

export default client;