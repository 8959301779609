import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import {
  creditandskills,
  accents,
  languages,
} from "../../../../config/utilities";
import _ from "lodash";
import { UPDATE_TALENT } from "../../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { NotificationManager } from "react-notifications";

const UserCreditAndSkills = ({
  next,
  prev,
  signupmode,
  talentForm,
  setTalentForm,
  type,
  step,
  setStep
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [talentTypeSelected, setTalentTypeSelected] = useState(
    talentForm.castingAfricaTalentDetails.talent_type
  );


  const [credits, setCredits] = useState([...talentForm.castingAfricaTalentDetails?.credits]);
  const [creditCount, setCreditCount] = useState(credits.length);
  const [awards, setAwards] = useState([...talentForm.castingAfricaTalentDetails?.awards]);
  const [awardCount, setAwardCount] = useState(awards.length - 1);
  const [talentLanguage, setTalentLanguage] = useState(
    talentForm.castingAfricaTalentDetails.language
  );
  const [talentAccent, setTalentAccent] = useState(
    talentForm.castingAfricaTalentDetails.accent
  );
  const [actorExperience, setActorExperience] = useState(
    talentForm.castingAfricaTalentDetails.actorExperience
  );
  const [dancerExperience, setDancerExperience] = useState(
    talentForm.castingAfricaTalentDetails.dancerExperience
  );
  const [modelExperience, setModelExperience] = useState(
    talentForm.castingAfricaTalentDetails.modelExperience
  );
  const [hasAgent, setHasAgent] = useState(
    talentForm.castingAfricaTalentDetails.hasAgent
  );

  const [logged] = useState(sessionStorage.getItem("AUTH_USER"));



  const incrementCredit = (number) => {
    setCreditCount(number + 1);
    setCredits([...credits, new Object()]);
  };

  const decrementCredit = () => {
    if (credits.length === 1) {
      setCreditCount(1);
      return;
    }

    setCreditCount(creditCount - 1);
    credits.splice(credits.length -1, 1);
    setCredits(credits);
  };

  const incrementAward = () => {
    setAwardCount(awardCount + 1);
    setAwards([...awards, new Object()]);
  };

  const decrementAward = () => {
    if (awards.length === 1) {
      setAwardCount(1);
      return;
    }

    setAwardCount(awardCount - 1);
    awards.splice(awards.length - 1, 1);
    setAwards(awards);
  };

  const handleSubmitToNextPage = (data) => {
    let credit_content = [];
    let award_content = [];
    credits.forEach((value, index) => {
      let credit_obj = new Object();
      credit_obj[`credit_title_${index}`] = data[`credit_title_${index}`]
      credit_obj[`role_project_${index}`] = data[`role_project_${index}`]
      credit_obj[`year_project_${index}`] = data[`year_project_${index}`]
      credit_content.push(credit_obj);
    })

    
    awards.forEach((value, index) => {
      let award_obj = new Object();
      award_obj[`award_title_${index}`] = data[`award_title_${index}`]
      award_obj[`award_status_${index}`] = data[`award_status_${index}`]
      award_obj[`award_year_${index}`] = data[`award_year_${index}`]
      award_content.push(award_obj);
    })

    talentForm[type]["language"] = talentLanguage;
    talentForm[type]["accent"] = talentAccent;
    talentForm[type]["actorExperience"] = actorExperience;
    talentForm[type]["dancerExperience"] = dancerExperience;
    talentForm[type]["modelExperience"] = modelExperience;
    talentForm[type]["hasAgent"] = hasAgent;
    talentForm[type]["agentName"] = data["agentName"];
    talentForm[type]["agentContact"] = data["agentContact"];
    talentForm[type]["awards"] = award_content;
    talentForm[type]["credits"] = credit_content;


    setTalentForm((p) => ({ ...p, ...talentForm }));
    if (step && signupmode === "TALENTEDIT") {
      setStep(step + 1)
    } else {
      next();
    }
  };

  const checkPhyiscals = (methodName, value) => {
    eval(methodName)(value);
  };

  const [
    updateTalent,
    { data: talent_data, loading: talent_loading, error: talent_error },
  ] = useMutation(UPDATE_TALENT);


    // Refactor
    const handleSubmitToUpdate = (data) => {
      let temp = talentForm;
      temp['castingAfricaTalentDetails']['modelExperience'] = modelExperience
      temp['castingAfricaTalentDetails']['dancerExperience'] = talentTypeSelected
      temp['castingAfricaTalentDetails']['hasAgent'] = hasAgent
      temp['castingAfricaTalentDetails']['language'] = talentLanguage
      temp['castingAfricaTalentDetails']['accent'] = talentAccent
      temp['castingAfricaTalentDetails']['credits'] = credits
      temp['castingAfricaTalentDetails']['awards'] = awards
     
      
    
  
      const socials = Object.entries(temp["talentSocials"]);
      let social_asm = new Object();
      socials.forEach(([key, value]) => {
        social_asm[value["social_media_type"].toLowerCase()] =
          value["social_media_handle"];
      });
  
      // temp["talentAddress"] = tempDetails;
      temp["castingAfricaTalentDetails"] = JSON.stringify(
        talentForm["castingAfricaTalentDetails"]
      );
      temp["talentSocials"] = social_asm;
      delete temp["photos"];
      delete temp["resume"];
      delete temp["videos"];
      delete temp['talentAddress']['__typename']
      delete temp['talentAddress']['region_or_state']
      delete temp["talentAddress"]["talent_phonenumbers"];
  
      console.log("temp ", temp);
  
      updateTalent({
        variables: {
          talentID: JSON.parse(logged)?.user?.talent?.casting_africa_talent_id,
          ...temp,
        },
      })
        .then((data) => {
          NotificationManager.success("Updated successfully");
          window.location.reload();
        })
        .catch((err) => {
          NotificationManager.warning("Profile update failed");
        });
    };


  return (
    <Fragment>
      <div
        className={`pb-6 md:rounded-3xl w-full  ${
          signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT"
            ? "md:w-9/12 lg:w-5/12 shadow-2xl  bg-white rounded-2xl"
            : ""
        }`}
      >
        {/* Header */}
        {signupmode === "AGENTSIGNUP" ||
          (signupmode !== "TALENTEDIT" && (
            <div className="mb-6 h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
              <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
                Credit & Skills
              </h1>
              <p className=" w-5/6  md:w-4/6 text-center text-xs md:text-sm mb-4 text-formFont font-gilroy-Light">
                List your previous experience, skills, interests, or anything
                that will make you stand out for roles in the categories you are
                interested in.
              </p>
            </div>
          ))}

        {/* Form Body */}

        <div className="md:px-6 px-2 mb-4 flex flex-col justify-center ie">
          <label className="mb-3 flex font-gilroy-Light text-sm md:text-lg  text-formFont">
            <span>Credits</span>
            <div className="ml-4">
              <span
                onClick={() => incrementCredit(credits.length - 1)}
                className=" px-3 py-0 font-gilroy-Light bg-green-500 cursor-pointer shadow-lg text-white"
              >
                +
              </span>{" "}
              <span
                onClick={() => decrementCredit()}
                className=" px-3 py-0 shadow-lg font-gilroy-Light cursor-pointer bg-red-500 text-white"
              >
                -
              </span>
            </div>
          </label>
          {credits.map((credit, key) => (
            <div key={key} className="flex mb-4  md:flex-row flex-col w-full">
              <input
                className="flex-1  font-gilroy-Light   mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-1 "
                type="text"
                defaultValue={talentForm.castingAfricaTalentDetails?.credits[key]?.hasOwnProperty(`credit_title_${key}`) ? talentForm.castingAfricaTalentDetails?.credits[key][`credit_title_${key}`] : ""}
                {...register(`credit_title_${key}`)}
                placeholder={`Project title`}
              />
              <select
                className="  font-gilroy-Light  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-1 "
                defaultValue={talentForm.castingAfricaTalentDetails?.credits[key]?.hasOwnProperty(`role_project_${key}`) ? talentForm.castingAfricaTalentDetails?.credits[key][`role_project_${key}`] : ""}
                {...register(`role_project_${key}`)}
              >
                {
                  ['Lead Role', 'Supporting Role', 'Minor Role', 'Extra'].
                  map((role, key ) => (
                    <option key={key} value={role}>{role}</option>
                  ))
                }
              </select>
              <input
                className=" w-20  font-gilroy-Light  text-center  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-1 "
                type="year"
                defaultValue={talentForm.castingAfricaTalentDetails?.credits[key]?.hasOwnProperty(`year_project_${key}`) ? talentForm.castingAfricaTalentDetails?.credits[key][`year_project_${key}`] : ""}
                {...register(`year_project_${key}`)}
                placeholder={`Year`}
              />
            </div>
          ))}
        </div>

        <div className="md:px-6 px-2 flex flex-col justify-center ie">
          <label className="mb-3 flex font-gilroy-Light text-sm md:text-lg  text-formFont">
            <span>Awards</span>
            <div className="ml-4">
              <span
                onClick={() => incrementAward()}
                className=" px-3 font-gilroy-Light bg-green-500 cursor-pointer shadow-lg text-white"
              >
                +
              </span>{" "}
              <span
                onClick={() => decrementAward()}
                className=" px-3 shadow-lg font-gilroy-Light cursor-pointer bg-red-500 text-white"
              >
                -
              </span>
            </div>
          </label>
          {awards.map((award, key) => (
            <div key={key} className="flex mb-4  md:flex-row flex-col w-full">
              <input
                className=" flex-1   font-gilroy-Light  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-1 "
                type="text"
                defaultValue={talentForm.castingAfricaTalentDetails?.awards[key]?.hasOwnProperty(`award_title_${key}`) ? talentForm.castingAfricaTalentDetails?.awards[key][`award_title_${key}`] : ""}
                {...register(`award_title_${key}`)}
                placeholder={`Award Title`}
              />
              <select
                 className="font-gilroy-Light  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-1 "
                 defaultValue={talentForm.castingAfricaTalentDetails?.awards[key]?.hasOwnProperty(`award_status_${key}`) ? talentForm.castingAfricaTalentDetails?.awards[key][`award_status_${key}`] : ""}
                {...register(`award_status_${key}`)}
              >
                <option value="Nominated">Nominated</option>
                <option value="Won">Won</option>
              </select>
              <input
                className=" w-20 text-center font-gilroy-Light  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-1 "
                type="year"
                defaultValue={talentForm.castingAfricaTalentDetails?.awards[key]?.hasOwnProperty(`award_year_${key}`) ? talentForm.castingAfricaTalentDetails?.awards[key][`award_year_${key}`] : ""}
                {...register(`award_year_${key}`)}
                placeholder={`Year`}
              />
            </div>
          ))}

          <div className="flex flex-col mb-3 px-1 md:flex-row md:px-0">
            <div className=" md:flex-1 md:pr-8 px-1 py-1 flex flex-col">
              <label
                htmlFor="Language"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Language
              </label>
              <select
                className="border rounded-sm font-gilroy-Light border-gray-300 focus:outline-none px-2 py-3 "
                defaultValue={talentForm.castingAfricaTalentDetails.language}
                onChange={(e) => setTalentLanguage(e.target.value)}
              >
                {languages.map((lang, key) => (
                  <option key={key} value={lang}>
                    {lang}
                  </option>
                ))}
              </select>
            </div>
            <div className=" md:flex-1 px-1 py-1  flex flex-col">
              <label
                htmlFor="Accent"
                className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
              >
                Accent
              </label>
              <select
                className="border font-gilroy-Light rounded-sm border-gray-300 focus:outline-none px-2 py-3 "
                onChange={(e) => setTalentAccent(e.target.value)}
                defaultValue={talentForm.castingAfricaTalentDetails.accent}
              >
                {accents.map((accent, key) => (
                  <option key={key} value={accent}>
                    {accent}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {creditandskills.map((credit, key) =>
            [...talentTypeSelected, "Agent"].includes(
              credit.label.split(" ")[0]
            ) ? (
              <div className="px-1" key={key}>
                <label
                  className="mb-10 font-gilroy-Light text-xl"
                  htmlFor={credit.label}
                >
                  {credit.label}
                </label>
                <div className="flex mt-5 flex-wrap">
                  {credit.categories.map((category, key) => (
                    <div key={key} className="mb-3 flex w-3/6">
                      <input
                        className="mr-2 opacity-0 absolute w-5 h-5"
                        type="checkbox"
                        checked={
                          eval(
                            credit.label.split(" ")[0] === "Agent"
                              ? "hasAgent"
                              : _.camelCase(
                                  credit.label.split(" ")[0] + "Experience"
                                )
                          ) === category
                        }
                        onChange={() =>
                          checkPhyiscals(
                            credit.label.split(" ")[0] === "Agent"
                              ? "setHasAgent"
                              : `set${_.upperFirst(
                                  _.camelCase(credit.label.split(" ")[0])
                                )}Experience`,
                            category
                          )
                        }
                      />
                      <div
                        onClick={() =>
                          checkPhyiscals(
                            credit.label.split(" ")[0] === "Agent"
                              ? "setHasAgent"
                              : `set${_.upperFirst(
                                  _.camelCase(credit.label.split(" ")[0])
                                )}Experience`,
                            category
                          )
                        }
                        className="bg-white border rounded-md border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black"
                      >
                        <svg
                          className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                          version="1.1"
                          viewBox="0 0 17 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g
                              transform="translate(-9 -11)"
                              fill="#1F73F1"
                              fillRule="nonzero"
                            >
                              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                            </g>
                          </g>
                        </svg>
                      </div>
                      <span className="text-xs md:text-lg font-gilroy-Light">
                        {category}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )
          )}

          {hasAgent === "I have an agent" ? (
            <Fragment>
              <div className=" px-1 md:px-0">
                <div className="flex-1 px-1 py-1  flex flex-col">
                  <label
                    htmlFor="Agent/Agency Name"
                    className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
                  >
                    Agent/Agency Name
                  </label>
                  <input
                    className="border font-gilroy-Light rounded-sm border-gray-300 focus:outline-none px-2 py-3"
                    placeholder="Agent/Agency Name"
                    defaultValue={
                      talentForm.castingAfricaTalentDetails.agentName
                    }
                    type="text"
                    {...register("agentName", {
                      required: "Agent name is required",
                    })}
                  />
                  <p className=" font-gilroy-Light text-red-600 ">
                    {errors.agentName?.message}
                  </p>
                </div>
              </div>

              <div className=" px-1 md:px-0">
                <div className="flex-1 px-1 py-1  flex flex-col">
                  <label
                    htmlFor="Agent/Agency Contact"
                    className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
                  >
                    Agent/Agency Contact
                  </label>
                  <input
                    className="border font-gilroy-Light rounded-sm border-gray-300 focus:outline-none px-2 py-3 "
                    type="text"
                    placeholder="Agent/Agency Contact"
                    defaultValue={
                      talentForm.castingAfricaTalentDetails.agentContact
                    }
                    {...register("agentContact", {
                      required: "Agent contact is required",
                    })}
                  />
                  <p className=" font-gilroy-Light text-red-600 ">
                    {errors.agentContact?.message}
                  </p>
                </div>
              </div>
            </Fragment>
          ) : (
            ""
          )}
        </div>

        {["AGENTSIGNUP", "TALENTEDIT", "TALENTSIGNUP"].includes(signupmode) ? (
          <div className="px-14 mt-7 flex  flex-row justify-center items-center">
            {
              !logged ? (
                <div className="w-full flex  flex-row justify-center items-center">
                  <button
              onClick={() => prev()}
              className="hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3 px-16 "
            >
              Back
            </button>

            <button
              onClick={handleSubmit(handleSubmitToNextPage)}
              className="hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3  px-16 "
            >
              Next
            </button>
                </div>
              ) : (
                <button
                disabled
                onClick={handleSubmit(handleSubmitToUpdate)}
                className=" shadow-2xl  bg-primary opacity-60 hover:opacity-100 tranition ease-out duration-700 m-4 md:m-2 flex items-center justify-center font-bold text-white rounded-full py-4  px-28 "
              >
                {talent_loading ? "Please wait.." : "Update"}
              </button>
              )
            }
            
          </div>
        ) : (
          ""
        )}

        {/* End of form body */}
      </div>
    </Fragment>
  );
};

export default UserCreditAndSkills;
