import React from "react";

const SearchJob = ({ color, defaultCountry }) => {
    return (
      <div className="flex md:mb-5 lg:mb-10 justify-center items-center">
        <div className="flex px-6 md:flex-row  flex-col w-full md:py-0 py-2 md:w-full md:px-5 lg:w-4/5  justify-center items-center md:h-14 lg:h-20 shadow-lg ">
          <div className="md:flex-1 w-full mb-2 border-dashed md:border-r h-full flex items-center ">
            <span className="md:px-4 hidden  mr-2 md:flex justify-center items-center text-2xl font-gilroy-Medium">
              o
            </span>
            <select
              className="w-full rounded-sm md:rounded-none font-gilroy-Light h-10 md:mr-4"
              name=""
              id=""
            >
              <option> Job Opportunities</option>
            </select>
          </div>
          <div className="flex w-full mb-2 justify-center items-center md:flex-1 h-full border-dashed  md:border-r ">
            <span className="md:px-4 hidden md:flex justify-center items-center text-2xl font-thin">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </span>

            <select
              className="w-full rounded-sm md:rounded-none font-gilroy-Light h-10 md:mr-4"
              name=""
              id=""
            >
              <option>{defaultCountry}</option>
            </select>
          </div>
          <div className="md:flex-1 mb-2 w-full flex items-center  h-full">
            <span className="md:px-4  flex justify-center items-center text-2xl font-thin"></span>
            <select
              className="w-full rounded-sm md:rounded-none font-gilroy-Light h-10 md:mr-4"
              name=""
              id=""
            >
              <option>Gender</option>
            </select>
          </div>
          <button className={`md:flex-1 h-10 w-full md:rounded-none rounded-sm  ${color} md:h-full text-white font-gilroy-Light`}>
            Search Job
          </button>
        </div>
      </div>
    );
}

export default SearchJob;