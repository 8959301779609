import React, { useContext, useState } from "react";
import { ProfileFooter } from ".";
import PackageContext from "../../../context/context";
import { Pagination } from "../Pagination";
import PDF from "../../../assets/images/pdf.png"

const Resume = () => {
  const context = useContext(PackageContext);
  const talent = context.talent;

  const [setNext, setSetNext] = useState(8);
  let pagesize = setNext;

  const handlePDFViewer = () => {
    context.setPdfViewerState(true);
  }

  return (
    <div className="h-full">
      <div className="flex mb-11">
        {
          talent?.resume.length ? talent.resume?.slice(setNext - pagesize, setNext).map((cv, key) => (
            <div onClick={() => handlePDFViewer()} className="mr-5 flex flex-col items-center" key={key}>
              <img src={PDF} alt={cv.title} loading="lazy" className="mb-2 cursor-pointer" />
              <h1 className="cursor-pointer">Talent Resume</h1>
            </div>
          )) : "No resumes found at this time"
        }
      </div>
      <Pagination
        data={talent?.resume || []}
        pagesize={8}
        nextSet={setSetNext}
      />
    </div>
  );
};

export default Resume;
