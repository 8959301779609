import { useState, useEffect, useMemo } from "react";
import _ from "lodash";

const Pagination = ({ data, pagesize, nextSet, currentPos }) => {
  const [trackTiles, setTrackTile] = useState(0);
  const numberOfTiles = _.flatMap(data).length / pagesize;
  const tiles = [];
  const [currentTiles, setCurrentTiles] = useState([]);
  let mult = 0;
  for (let i = 0; i < Math.ceil(numberOfTiles); i++) {
    tiles.push(i);
  }

  const f = () => _.chunk(tiles, 5);

  // useEffect(() => {
  //   const nums = _.chunk(tiles, 5)
  //   setCurrentTiles(nums[trackTiles])
  // }, [])

  useEffect(() => {
    const nums = _.chunk(tiles, 5);

    if (currentPos % 5 === 0) {
      setTrackTile((prev) => prev + 1);
    }
    const tilesList = nums[trackTiles];
    setCurrentTiles(tilesList)
    console.log("Number of tiles ", Math.ceil(numberOfTiles));
    console.log("Current Pos ". currentPos + 1);
  }, [currentPos, setCurrentTiles]);

  return (
    <div>
      <ul className="flex justify-center py-1 cursor-pointer w-full">
        <li
          onClick={() => {
            if (currentPos <= 0) return;
            nextSet(currentPos - 1);
          }}
          className="border px-5 mr-2 flex font-gilroy-Light hover:bg-blue-800 justify-around rounded-full bg-blue-600 text-white py-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
          <span className="mr-2">Prev</span>
        </li>
        {/* {currentTiles?.map((tile, key) => (
          <li
            className={`${
              currentPos == key && "bg-blue-600 text-white"
            } w-10 h-10 flex justify-center items-center mr-1 font-gilroy-Light  hover:text-white hover:bg-blue-700 rounded-full`}
            key={key}
            onClick={() => nextSet(key)}
          >
            {tile + 1}
          </li>
        ))} */}
        <li
          onClick={() => {
            if (currentPos >= tiles.length - 1) return;
            nextSet(parseInt(currentPos) + 1);
          }}
          className={`border px-5 flex font-gilroy-Light hover:bg-blue-800 justify-around rounded-full bg-blue-600 text-white py-2`}
        >
          <span className="mr-2">Next</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
