import React, { useState } from "react";
import { sample_jobs } from "../../../../config/utilities";
import Jobcard from "../../Communities/Comps/jobCard";
import { Pagination } from "../../Pagination";

const TalentJobs = () => {
  const [selectedNav, setSelectedNav] = useState(0);
  const [setNext, setSetNext] = useState(5);
  let pagesize = 5; 

  return (
    <div className="h-screen overflow-y-scroll mt-32 flex flex-col lg:px-72 px-5 items-center">
      <div className="flex flex-col lg:px-0 px-5 lg:py-0 py-5 mb-3 lg:mb-10 items-center border border-primary w-full">
        <span>
          <svg
            width="44"
            height="50"
            viewBox="0 0 44 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.5555 4.21027e-07C41.2345 -0.000186903 40.9165 0.0643495 40.6199 0.18992C40.3232 0.31549 40.0537 0.499632 39.8267 0.731815C39.5996 0.963999 39.4196 1.23967 39.2968 1.54307C39.174 1.84647 39.1109 2.17164 39.1111 2.5V4.0918C37.0495 6.70606 34.4438 8.81728 31.4838 10.2718C28.5238 11.7263 25.2839 12.4875 22 12.5H7.33333C5.38906 12.5022 3.52503 13.293 2.15023 14.6991C0.775421 16.1051 0.00212226 18.0115 0 20V25C0.00212226 26.9885 0.775421 28.8948 2.15023 30.3009C3.52503 31.707 5.38906 32.4978 7.33333 32.5H8.51593L2.64259 46.516C2.48298 46.8961 2.41818 47.3109 2.45401 47.723C2.48983 48.1351 2.62517 48.5316 2.84785 48.877C3.07054 49.2224 3.37361 49.5058 3.72986 49.7018C4.0861 49.8978 4.48436 50.0003 4.88889 50H14.6667C15.1452 50.0003 15.6132 49.8567 16.0126 49.5872C16.412 49.3176 16.7251 48.934 16.913 48.4839L23.571 32.595C26.5793 32.8209 29.5103 33.6751 32.1831 35.1048C34.8559 36.5346 37.2138 38.5095 39.1111 40.9077V42.5C39.1111 43.163 39.3686 43.7989 39.8271 44.2678C40.2855 44.7366 40.9072 45 41.5555 45C42.2039 45 42.8256 44.7366 43.284 44.2678C43.7425 43.7989 44 43.163 44 42.5V2.5C44.0002 2.17164 43.9371 1.84647 43.8143 1.54307C43.6915 1.23967 43.5115 0.963993 43.2844 0.731808C43.0574 0.499624 42.7879 0.315483 42.4912 0.189913C42.1946 0.0643441 41.8766 -0.000190193 41.5555 4.21027e-07ZM7.33333 27.5C6.68516 27.4996 6.06366 27.236 5.60533 26.7673C5.147 26.2985 4.88932 25.6629 4.88889 25V20C4.88933 19.3371 5.14701 18.7015 5.60534 18.2327C6.06366 17.764 6.68516 17.5004 7.33333 17.5H9.77778V27.5H7.33333ZM13.0553 45H8.59613L13.8335 32.5H18.2928L13.0553 45ZM39.1111 33.8045C34.2913 29.7435 28.2447 27.5157 22 27.4999H14.6667V17.4999H22C28.2449 17.4835 34.2914 15.2553 39.1111 11.194V33.8045Z"
              fill="#3A77FF"
            />
          </svg>
        </span>
        <span className="text-primary  lg:text-lg text-xs">
          Check for job updates regularly so you don't miss out
        </span>
        <span className=" font-gilroy-Light lg:text-lg text-xs  text-center">
          Hundreds of casting and job opportunities are posted regularly, be the
          first to apply
        </span>
      </div>
      <div className="lg:mb-10 mb-3 flex lg:flex-nowrap flex-wrap lg:justify-start justify-center">
        {[
          "Applied Jobs",
          "Invited Jobs",
          "Matched Jobs",
          "Booked Jobs",
          "Rejected Applications",
        ].map((jobs, key) => (
          <span onClick={() => setSelectedNav(key)} className={` ${key === selectedNav ? 'text-primary' : ''} font-gilroy-Medium cursor-pointer lg:text-lg text-xs lg:mr-2 px-2  lg:px-4`} key={key}>
            {jobs}
          </span>
        ))}
      </div>
      <div className="w-full flex flex-col h-screen mb-20">
      {sample_jobs
                .slice(setNext - pagesize, setNext)
                .map((job, key) => (
                  <div key={key}>
                    <Jobcard job={job} />
                  </div>
                ))}
        <Pagination data={sample_jobs} pagesize={5} nextSet={setSetNext} />
      </div>

    </div>
  );
};

export default TalentJobs;
