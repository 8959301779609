import React, { useState, useEffect, useContext, useRef } from "react";
import { Pagination } from "./Pagination";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TALENT } from "../../graphql/queries";
import Loader from "./Loader";
import { PaystackButton } from "react-paystack";
import { callingCodeFlags } from "../../config/utilities";
import moment from "moment";
import {
  TALENT_PAYMENT_RENEWAL,
} from "../../graphql/mutations";
import PackageContext from "../../context/context";
import { UserChangePricePlan } from "./SignUp/Talents";

const Billing = () => {
  const [payConfig, setPayConfig] = useState({
    text: "Renew",
  });
  const [paymentList, setPaymentList] = useState([]);
  const context = useContext(PackageContext);

  const [talentChangePlan, { loading: re_loading, data: re_data, error: re_error}] =
    useMutation(TALENT_PAYMENT_RENEWAL);
  
  useEffect(() => {
    if (re_loading) {
      window.location.reload();
    }
  }, [re_loading])


  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(sessionStorage.getItem("AUTH_USER"))
  );

  const { loading, error, data } = useQuery(GET_TALENT, {
    variables: {
      talentID: isLoggedIn?.user?.talent?.casting_africa_talent_id,
    },
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setPaymentList(response.talentPayments);
    },
  });

  if (loading && data) <Loader />;

  
  const showUpgrade = () => data?.talent.account_status === "EXPIRED" && data?.talent.account_type === "BASIC";
  const showUpgradeAlt = () => data?.talent.account_status === "ACTIVE" && data?.talent.account_type === "BASIC" && !data?.talent.talentPayments.length;

  const calculatePrice = () => {
    return data?.talent.talentAddress?.country === "Ghana" ? 6.45 : 415.77;
  };

  useEffect(() => {
    const currency =
      callingCodeFlags[data?.talent?.calling_code]?.currency || "GHS";
    const renewal_amount = data?.talent.talentPayments
      ? (
          data?.talent.talentPayments[0]?.amount *
          calculatePrice() *
          100
        ).toFixed(2)
      : 0.0;
    const amount_dollar = data?.talent.talentPayments[0]?.amount;

    const renew_funcs = {
      onSuccess: (reference) => renewalSuccess(reference, amount_dollar),
      onClose: () => {
        //
      },
    };
    setPayConfig({
      ...payConfig,
      ...renew_funcs,
      publicKey:
        data?.talent.talentAddress?.country === "Ghana"
          ? process.env.REACT_APP_KEY_GH
          : process.env.REACT_APP_KEY_NG,
      currency,
      email: data?.talent.userAccount.email,
      amount: renewal_amount,
    });
  }, [data]);


 

  const renewalSuccess = (ref, amount) => {
    talentChangePlan({
      variables: {
        talentID: data?.talent.casting_africa_talent_id,
        reference: ref.reference,
        payment_duration: data?.talent.talentPayments
        ? data?.talent.talentPayments[0].payment_duration
        : "",
        account_type: data?.talent.account_type,
        payment_amount: parseFloat(amount)
      },
    })
      .then((data) => {
        // console.log("Renewal response ", data);
      })
      .catch((error) => console.log(error));
    // console.log("Done calling");
  };

  // if (!data?.talent.talentPayments.length) return <ChangePlan context={context} config={payConfig} data={data} />

  return (
    <div className="w-full h-screen flex flex-col mt-24">
      <div
        className={`${
          data?.talent.account_status === "EXPIRED" ? "h-64" : "lg:h-32 h-24"
        } flex flex-col items-center lg:px-28 px-3`}
      >
        {data?.talent.account_status === "EXPIRED" ? (
          <div className="border w-full lg:w-full lg:h-36 lg:px-20 px-5 flex justify-center flex-col items-center border-blue-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#3477FF"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <p className="text-primary lg:text-left text-center">
              Your profile is not visible in the Talent Directory{" "}
              <span className="bg-primary rounded-full text-white px-2">
                Fix it
              </span>
            </p>
            <p className=" font-gilroy-Light">
              Upgrade now so you can monitor your ranking and be scouted by
              casting professionals
            </p>
          </div>
       ) : (
          ""
        )} 

        <div className="mt-6 w-full flex justify-end ">
          <p className="text-primary">
            Account Status{" "}
            <span className={`${data?.talent.account_status === "EXPIRED" ? "bg-red-500" : "bg-green-300"}  rounded-full font-gilroy-Medium text-white px-3 py-1 text-sm`}>
              {data?.talent.account_status}
            </span>
          </p>
        </div>
      </div>

      <div className="h-full bg-gray-200 px-2 flex flex-col items-center lg:px-20 lg:py-20 py-10">
        <div className="bg-white w-full lg:h-auto py-10 lg:px-10 rounded-2xl">
          {data?.talent?.talentPayments ? (
            <div className="flex flex-col items-center lg:px-10 w-full justify-center">
              <table className="w-screen lg:w-full text-center lg:mt-10 lg:mb-10">
                <tr className="text-primary lg:text-lg text-sm">
                  <td>Date</td>
                  <td>Membership Type</td>
                  <td>Amount</td>
                  <td>Expires On</td>
                </tr>
                {data?.talent?.talentPayments.map((payment, key) => (
                  <tr key={key} className=" font-gilroy-Light lg:text-sm text-xs border-b ">
                    <td>{moment(payment.created_at).format("D/M/YYYY")}</td>
                    <td>
                      {payment.payment_plan.toLowerCase()} ({payment.payment_duration.toLowerCase()})
                    </td>
                    <td>$ {payment.amount}</td>
                    <td>{moment(payment.expires_on).format("D/M/YYYY")}</td>
                  </tr>
                ))}
              </table>
              {/* <Pagination /> */}
            </div>
          ) : (
            <div className="flex justify-center">
              No Payment records found {JSON.stringify(paymentList)}
            </div>
          )}
        </div>
        {showUpgrade() ? (
          <div className="mt-5 flex">
            {
               data?.talent.talentPayments.length ? (
                <span className="bg-primary m-2 flex items-center font-gilroy-Light justify-center font-bold text-white rounded-full py-2  px-8">
                {re_loading ? "Verifying..." : <PaystackButton {...payConfig} />}
                </span>
               ) : ""
            }
            <span
              onClick={() => context.setChangePlan(true)}
              className="cursor-pointer bg-primary m-2 flex items-center justify-center font-gilroy-Light text-white rounded-full py-2  px-8"
            >
              Upgrade
            </span>
          </div>
        ) : (
          ""
        )}

        {
          showUpgradeAlt()  ? (
            <div className="mt-5 flex">
              {/* {
                 data?.talent.talentPayments.length ? (
                  <span className="bg-primary m-2 flex items-center font-gilroy-Light justify-center font-bold text-white rounded-full py-2  px-8">
                  {re_loading ? "Verifying..." : <PaystackButton {...payConfig} />}
                  </span>
                 ) : ""
              } */}
              <span
                onClick={() => context.setChangePlan(true)}
                className="cursor-pointer bg-primary m-2 flex items-center justify-center font-gilroy-Light text-white rounded-full py-2  px-8"
              >
                Upgrade
              </span>
            </div>
          ) : (
            ""
          )
          }
      </div>
      {context.changePlan && (
        <ChangePlan context={context} config={payConfig} data={data} />
      )}
    </div>
  );
};

const ChangePlan = ({ context, config, data }) => {
  const [autoClose, setAutoClose] = useState(false);
  const closeRef = useRef();

  useEffect(() => {
    if (autoClose) {
      context.setChangePlan(false)
      window.location.reload();
    }
  }, [autoClose]);

  return (
    <div
      className="w-full h-full lg:px-0 px-2  bg-modalBackground z-50 top-0  left-0 flex justify-center items-center fixed"
    >
      <div className=" bg-transparent flex flex-col items-center w-full  lg:w-4/6 lg:h-4/6 ">
        <div className="w-full flex justify-end mr-4 mt-2">
          <svg
            ref={closeRef}
            onClick={() => context.setChangePlan(false)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#f00"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>

        <UserChangePricePlan details={config} content={data}  setAutoClose={setAutoClose} />
      </div>
    </div>
  );
};

export default Billing;
