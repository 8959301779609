import React, { useContext, useEffect, useState } from "react";
import ProfileFooter from "../profileFooter";
import PackageContext from "../../../../context/context";
import { company_more_information } from "../../../../config/utilities";
import { Pagination } from "../../Pagination";
import Jobcard from "../../Communities/Comps/jobCard";

const Jobs = () => {
  const context = useContext(PackageContext);
  const [companyDetails, setCompanyDetails] = useState({});
  const [setNext, setSetNext] = useState(5);
  let pagesize = 5;

  useEffect(() => {
    setCompanyDetails(context.companyDetails);
  }, []);

  return (
    <div className=" flex md:px-0 px-4 flex-col">
      <div className="grid">
        {companyDetails &&
          companyDetails?.jobs
            ?.slice(setNext - pagesize, setNext)
            .map((job, key) => <Jobcard job={job} key={key} />)}
      </div>
      <Pagination
        data={companyDetails?.jobs || []}
        pagesize={5}
        nextSet={setSetNext}
      />
      <ProfileFooter count={56000} />
    </div>
  );
};

export default Jobs;
