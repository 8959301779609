import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ON_BOARD_TALENT, UPDATE_TALENT } from "../../../../graphql/mutations";
import _ from "lodash";
import { NotificationManager } from "react-notifications";

const UserMoreInfo = ({
  next,
  prev,
  signupmode,
  talentForm,
  setTalentForm,
  step,
  setStep,
  setCreatingAccount
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [hasId, setHasId] = useState(
    talentForm?.castingAfricaTalentDetails?.hasID
  );
  const [license, setLicense] = useState(
    talentForm?.castingAfricaTalentDetails?.hasID?.length
      ? talentForm?.castingAfricaTalentDetails?.hasID[0]
      : ""
  );
  const [passport, setPassort] = useState(
    talentForm?.castingAfricaTalentDetails?.hasID?.length
      ? talentForm?.castingAfricaTalentDetails?.hasID[1]
      : ""
  );
  const [direction, setDirection] = useState("");
  const [logged] = useState(sessionStorage.getItem("AUTH_USER"));
  const [onBoardingTalent, { data, loading, error }] =
    useMutation(ON_BOARD_TALENT);
    

  const [
    updateTalent,
    { data: talent_data, loading: talent_loading, error: talent_error },
  ] = useMutation(UPDATE_TALENT);

  const handleSubmitToNextPage = (data) => {
    talentForm["castingAfricaTalentDetails"]["hasID"] = [license, passport];
    talentForm["castingAfricaTalentDetails"]["aboutMe"] = data["aboutMe"];

    talentForm["talentSocials"]["facebook"] = data["facebook"];
    talentForm["talentSocials"]["instagram"] = data["instagram"];
    talentForm["talentSocials"]["youtube"] = data["youtube"];
    talentForm["talentSocials"]["tiktok"] = data["tiktok"];
    talentForm["talentSocials"]["imdb"] = data["imdb"];

    let stringified_data = JSON.stringify(
      talentForm["castingAfricaTalentDetails"]
    );
    delete talentForm["castingAfricaTalentDetails"];
    delete [talentForm]['resume'];
    for (let a = 0; a < 5; a ++) {
      delete talentForm["talentSocials"][a];
    }
    talentForm["castingAfricaTalentDetails"] = stringified_data;
    setTalentForm((p) => ({ ...p, ...talentForm }));

    if (logged) {
      updateTalent({
        variables: {
          talentID: JSON.parse(logged)?.user?.talent?.casting_africa_talent_id,
          ...talentForm
        }
      }).then(response => {
        const d = JSON.parse(sessionStorage.getItem('AUTH_USER'));
        if (d.access_token == "") {
          sessionStorage.removeItem('AUTH_USER');
        }
      })
      .catch(err => console.log('update ', err))
    } else {

      onBoardingTalent({
        variables: {
          ...talentForm,
        },
      }).then(resp => {
        setCreatingAccount(false)
        NotificationManager.success("Account setup done, Login to access your account", "Casting Africa");
      }).catch(err => {
        setCreatingAccount(false)
        NotificationManager.error("Something went wrong while setting up your account", "Casting Africa")
      })
    }

    if (step && signupmode === "TALENTEDIT") {
      setStep(1);
    } else {
      if (!loading && data)
          next();
    }
  };

  useEffect(() => {
    console.log(talentForm);
  }, []);

  const handleSubmitToUpdate = (data) => {
    let temp = talentForm;
    delete temp['talentSocials'];
    temp['castingAfricaTalentDetails']['aboutMe'] = data["aboutMe"]
    temp['castingAfricaTalentDetails']['hasID'] = hasId

    temp["talentSocials"]["facebook"] = data["facebook"];
    temp["talentSocials"]["instagram"] = data["instagram"];
    temp["talentSocials"]["youtube"] = data["youtube"];
    temp["talentSocials"]["tiktok"] = data["tiktok"];
    temp["talentSocials"]["imdb"] = data["imdb"];

    // temp["talentAddress"] = tempDetails;
    temp["castingAfricaTalentDetails"] = JSON.stringify(
      talentForm["castingAfricaTalentDetails"]
    );
  
    delete temp["photos"];
    delete temp["resume"];
    delete temp["videos"];
    delete temp['talentAddress']['__typename']
    delete temp['talentAddress']['region_or_state']
    delete temp["talentAddress"]["talent_phonenumbers"];

    console.log("temp ", temp);

    updateTalent({
      variables: {
        talentID: JSON.parse(logged)?.user?.talent?.casting_africa_talent_id,
        ...temp,
      },
    })
      .then((data) => {
        NotificationManager.success("Updated successfully");
        window.location.reload();
      })
      .catch((err) => {
        NotificationManager.warning("Profile update failed");
      });
  };

  return (
    <>
      <div
        className={`pb-6 md:rounded-3xl w-full  ${
          signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT"
            ? "md:w-9/12 lg:w-5/12 shadow-2xl  bg-white rounded-2xl"
            : ""
        }`}
      >
        {/* Header */}
        {signupmode === "AGENTSIGNUP" ||
          (signupmode !== "TALENTEDIT" && (
            <div className="mb-6  h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
              <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
                More Info.
              </h1>
              <p className=" w-5/6  md:w-4/6 text-center text-xs md:text-sm mb-4 text-formFont  font-gilroy-Light">
                List your previous experience, skills, interests, or anything
                that will make you stand out for roles in the categories you are
                interested in.
              </p>
            </div>
          ))}

        {/* Form Body */}

        <div className="flex flex-col mb-3 px-2  md:px-14">
          <label
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
            htmlFor="Extra Skills"
          >
            Extra Skills
          </label>
          <textarea
            className="border  font-gilroy-Light rounded-3xl border-gray-300  focus:outline-none px-2 py-3 "
            {...register("aboutMe")}
            defaultValue={talentForm?.castingAfricaTalentDetails?.aboutMe}
            cols="30"
            rows="3"
          ></textarea>
        </div>

        <div className="px-1 md:px-14 mb-6">
          <label
            className="mb-10  font-gilroy-Light text-xl"
            htmlFor="License & Passport"
          >
            License & Passport
          </label>
          <div className="flex flex-col mt-5 flex-wrap">
            <div className="mb-3 flex items-center w-3/6">
              <input
                className="mr-2 opacity-0 absolute w-5 h-5"
                type="checkbox"
                checked={license !== ""}
                onChange={() => {
                  if (license) setLicense("");
                  else setLicense("I have a driver’s license");
                }}
              />
              <div
                onClick={() => {
                  if (license) setLicense("");
                  else setLicense("I have a driver’s license");
                }}
                className="bg-white border rounded-md border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black"
              >
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#1F73F1"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="text-xs md:text-lg  font-gilroy-Light">
                I have a driver’s license
              </span>
            </div>

            <div className="mb-3 flex items-center w-3/6">
              <input
                className="mr-2 opacity-0 w-5 h-5 absolute"
                type="checkbox"
                checked={passport !== ""}
                onChange={() => {
                  if (passport) setPassort("");
                  else setPassort("I have a passport");
                }}
              />
              <div
                onClick={() => {
                  if (passport) setPassort("");
                  else setPassort("I have a passport");
                }}
                className="bg-white border rounded-md border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black"
              >
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#1F73F1"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="text-xs md:text-lg  font-gilroy-Light">
                I have a passport
              </span>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center items-center mb-4 bg-gray-100 h-14">
          <h1 className="font-bold text-xl md:text-2xl">Social Details</h1>
        </div>

        <div className="flex flex-col px-2 md:px-14">
          <label
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
            htmlFor="Facebook"
          >
            Facebook
          </label>
          <input
            className=" md:w-full  font-gilroy-Light  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-3 "
            type="text"
            defaultValue={talentForm?.talentSocials[0]?.social_media_handle}
            {...register("facebook")}
            placeholder="E.g. topModel23"
          />
        </div>

        <div className="flex flex-col px-2 md:px-14">
          <label
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
            htmlFor="Instagram"
          >
            Instagram
          </label>
          <input
            className=" md:w-full  font-gilroy-Light  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-3 "
            type="text"
            defaultValue={talentForm?.talentSocials[1]?.social_media_handle}
            {...register("instagram")}
            placeholder="E.g. topModel23"
          />
        </div>

        <div className="flex flex-col px-2 md:px-14">
          <label
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
            htmlFor="Youtube"
          >
            Youtube
          </label>
          <input
            className=" md:w-full  font-gilroy-Light  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-3 "
            type="text"
            defaultValue={talentForm?.talentSocials[2]?.social_media_handle}
            {...register("youtube")}
            placeholder="E.g. topModel23"
          />
        </div>

        <div className="flex flex-col px-2 md:px-14">
          <label
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
            htmlFor="Tik Tok"
          >
            Tik Tok
          </label>
          <input
            className=" md:w-full  font-gilroy-Light  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-3 "
            type="text"
            defaultValue={talentForm?.talentSocials[3]?.social_media_handle}
            {...register("tiktok")}
            placeholder="E.g. topModel23"
          />
        </div>

        <div className="flex flex-col px-2 md:px-14">
          <label
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
            htmlFor="Tik Tok"
          >
            Imdb
          </label>
          <input
            className=" md:w-full  font-gilroy-Light  mb-3 md:mr-2 border rounded-sm border-gray-300 focus:outline-none px-2 py-3 "
            type="text"
            defaultValue={talentForm?.talentSocials[4]?.social_media_handle}
            {...register("imdb")}
            placeholder="E.g. topModel23"
          />
        </div>

        {["AGENTSIGNUP", "TALENTEDIT", "TALENTSIGNUP"].includes(signupmode) ? (
          <div className="px-14 mt-7 flex  flex-row justify-center items-center">
            
            {!logged ? (
              <div className="w-full flex  flex-row justify-center items-center">
                <button
              onClick={() => prev()}
              className="hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3 px-16 "
            >
              Back
            </button>
                <button
                onClick={handleSubmit(handleSubmitToNextPage)}
                className="hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3  px-16 "
              >
                {loading ? "Loading..." :  "Complete" }
              </button>
              </div>
            ) : (
              <button
                disabled
                onClick={handleSubmit(handleSubmitToUpdate)}
                className=" shadow-2xl  bg-primary opacity-60 hover:opacity-100 tranition ease-out duration-700 m-4 md:m-2 flex items-center justify-center font-bold text-white rounded-full py-4  px-28 "
              >
                {talent_loading ? "Please wait.." : "Update"}
              </button>
            )}
          </div>
        ) : (
          ""
        )}

        {/* End of form body */}
      </div>
    </>
  );
};

export default UserMoreInfo;
