import { useMutation } from "@apollo/client";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import { PASSWORD_RESET } from "../../graphql/mutations";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [passwordReset, { loading, data, error }] = useMutation(PASSWORD_RESET);
  const location = useLocation();
  const history = useHistory();

  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  const handleUpdatePassword = (data) => {
    passwordReset({
        variables: {
            password: data.password,
            code: location.pathname.split("/")[2],
        }
    }).then(response => {
        history.replace('/login');
    }) .catch (err => {
         console.log(err);
    })

  };

  return (
    <div className="w-full h-screen mt-24 flex flex-col items-center justify-center">
      <div className=" w-full px-4 lg:w-2/6 mb-4">
        <h1 className="font-gilroy-Light mb-2 text-lg">New Password</h1>
        <div className="border flex">
          <input
            className="w-full outline-none py-2 px-4 font-gilroy-Light"
            defaultValue={""}
            type="password"
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must be 8 characters or more",
              },
            })}
            placeholder="Enter New password"
          />
          <span className="w-14 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        <p className=" font-gilroy-Light text-red-600 ">
          {errors?.password?.message}
        </p>
      </div>
      <div className=" w-full px-4 lg:w-2/6 mb-4">
        <h1 className="font-gilroy-Light mb-2 text-lg">Confirm Password</h1>
        <div className="border flex">
          <input
            className="w-full outline-none py-2 px-4 font-gilroy-Light"
            type="password"
            name="confirm_password"
            defaultValue={""}
            placeholder="Confirm Password"
            {...register("confirm_password", {
              required: "Confirm Password",
              validate: (value) =>
                value === passwordRef.current || "Password does not match",
            })}
          />
          <span className="w-14 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        <p className=" font-gilroy-Light text-red-600 ">
          {errors?.confirm_password?.message}
        </p>
      </div>
      <div>
        <button
          onClick={handleSubmit(handleUpdatePassword)}
          className="bg-primary rounded-full py-1 text-white px-4 font-gilroy-Light"
        >
          {
              loading ? 'Updating Password..' : 'Update Password'
          }
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
