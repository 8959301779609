import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const FindTalent = () => {
    const {search} = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(search)

    useEffect(() => {
     const queryString = query.get("t");
     const split_string = queryString.split('-');
     if (!split_string.length) {
        history.replace('/unknown')
        return;
     }

     const id_string = split_string[split_string.length - 1]
     const content = Buffer.from(id_string, 'hex').toString('utf-8');

     if (!content.includes(':sk:')) {
        history.replace('/unknown')
        return;
     }

     const id = content.split(':sk:')
     const t_id = id[Math.floor(id.length/2)];
     sessionStorage.setItem('talent_', t_id)
     history.replace('/talentDetails', {
        id: t_id,
      });
    }, []);

    return (
        <div className="mt-24 h-screen">
            <p>Talent Finding page..</p>
        </div>
    )
}

export default FindTalent;