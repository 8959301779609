import React, { useRef, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { USER_RESET_PASSWORD } from "../../graphql/mutations";
import { useHistory } from "react-router-dom";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm();

  const history = useHistory();

  const [passwordReset, {loading, data, error}] = useMutation(USER_RESET_PASSWORD);
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(sessionStorage.getItem("AUTH_USER"))
  );

  const [flash, setFlash] = useState(false);

  const flashRef = useRef();
  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  const handleChangePassword = (data) => {
    
    const { user } = isLoggedIn;
    const logged  = user.talent.casting_africa_talent_id;
    passwordReset({
      variables: {
        data: {
          password: data['password'],
          confirm_password: data['confirm_password'],
          old_password: data['old_password'],
          user: logged
        }
      }
    }).then(resp => {
        let message = "Success";
        if (!resp.data.userPasswordReset)
          message = "Check credentials and try again"
        setFlash(resp.data.userPasswordReset)
        flashRef.current.innerText = message
        
    }).catch (err => {
      flashRef.current.innerText = "Something went wront try again";
    })


    reset({
      password: "",
      old_password: "",
      confirm_password: ""
    });
  }

  useEffect(() => {
    flashRef.current.innerText = ""
  }, []);

  return (
    <div className="w-full h-screen mt-24 flex flex-col items-center justify-center">
      <div className="w-full px-4 lg:w-2/6 mb-4">
          <span className={`${flash ? 'text-green-500' : 'text-red-600'}`} ref={flashRef}></span>
      </div>
      <div className=" w-full px-4 lg:w-2/6 mb-4">
        <h1 className="font-gilroy-Light mb-2 text-lg">Old Password</h1>
        <div className="border flex">
          <input
            className="w-full outline-none py-2 px-4 font-gilroy-Light"
            type="password"
            name=""
            id=""
            {...register("old_password", {
              required: "Please enter old password",
            })}
            placeholder="Enter old password"
          />
          <span className="w-14 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        <p className=" font-gilroy-Light text-red-600 ">
          {errors?.old_password?.message}
        </p>
      </div>
      <div className=" w-full px-4 lg:w-2/6 mb-4">
        <h1 className="font-gilroy-Light mb-2 text-lg">New Password</h1>
        <div className="border flex">
          <input
            className="w-full outline-none py-2 px-4 font-gilroy-Light"
            type="password"
            name=""
            id=""
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must be 8 characters or more",
              },
            })}
            placeholder="Enter New password"
          />
          <span className="w-14 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        <p className=" font-gilroy-Light text-red-600 ">
          {errors?.password?.message}
        </p>
      </div>
      <div className=" w-full px-4 lg:w-2/6 mb-4">
        <h1 className="font-gilroy-Light mb-2 text-lg">Confirm Password</h1>
        <div className="border flex">
          <input
            className="w-full outline-none py-2 px-4 font-gilroy-Light"
            type="password"
            name=""
            id=""
            {...register("confirm_password", {
              required: "Confirm Password",
              validate: (value) =>
                value === passwordRef.current || "Password does not match",
            })}
            placeholder="Confirm Password"
          />
          <span className="w-14 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        <p className=" font-gilroy-Light text-red-600 ">
          {errors?.confirm_password?.message}
        </p>
      </div>
      <div>
        <button onClick={handleSubmit(handleChangePassword)} className="bg-primary rounded-full py-1 text-white px-4 font-gilroy-Light">
          Update Password
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
