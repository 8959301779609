import SPINNER from "../../assets/images/draw-spinner.gif";
import SMILE from "../../assets/images/smile.jfif";

const Loader = ({screen = "h-screen"}) => {
  return (
    <div className={`w-full ${screen}  flex flex-col justify-center items-center`}>
      <img src={SPINNER} alt="" />
      <div className="flex  items-center">
        {" "}
        <span className="mr-4 text-xl text-secondary">Just a moment </span>
        <img className=" w-14 h-10 lg:w-24 lg:h-20" src={SMILE} alt="" />
      </div>
      <h1 className="text-primary text-2xl  lg:text-5xl mt-4 ">
        Casting.africa
      </h1>
    </div>
  );
};

export default Loader;
