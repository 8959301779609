import React, { useEffect, useContext } from "react";
import "./App.css";
import "react-notifications/lib/notifications.css";
import Navigation from "./pages/navigations/header/navigation";
import { Switch, Route } from "react-router-dom";
import { navigation_content, footer_content } from "./config/utilities";
import { Footer } from "./pages/navigations";
import { Login } from "./pages/components";
import { SignUp } from "./pages/components/SignUp";
import PackageContext from "./context/context";
import TalentRegistration from "./pages/components/SignUp/Talents/TalentRegistration";
import AgentRegistration from "./pages/components/SignUp/Agent/AgentRegistration";
import ProfessionalRegistration from "./pages/components/SignUp/Professionals/ProfessionalRegistration";
import ForgotPassword from "./pages/components/forgotpassword";
import { Academy, ELearning, Webinars } from "./pages/components/Education";
import { GhanaHome, NigeriaHome, SouthAfricaHome, NamibiaHome, KenyaHome, UgandaHome } from "./pages/components/Communities";
import { TalentDetails } from "./pages/components/Agent";
import { NotificationContainer } from "react-notifications";
import {
  MainLanding,
  AgentProfile,
} from "./pages/components/Agent/AgentPortal";
import { Agency } from "./pages/components/Agent/AgencyDirectory";
import CreatorDirectory from "./pages/components/Agent/TalentDirectory/creatorDirectory";
import CreatorProfile from "./pages/components/Agent/TalentDirectory/creatorProfile";
import { Editprofilepage } from "./pages/components/Agent/AgentPortal/EditProfile";
import {
  JobApplicants,
  Managejobs,
  ManageRooster,
  PostJob,
} from "./pages/components/Agent/AgentPortal/PostJob";
import { JobDetails } from "./pages/components/Communities/Comps";
import ChangePassword from "./pages/components/changePassword";
import { TalentDashboard } from "./pages/components/Talent/TalentPortal";
import {useHistory } from 'react-router-dom'
import ResetPassword from "./pages/components/resetPassword";
import ViewTalentProfile from "./pages/components/Talent/TalentPortal/ViewTalentProfile";
import TalentJobs from "./pages/components/Talent/TalentPortal/talentJobs";
import Billing from "./pages/components/billing";
import NotFound from "./pages/NotFound/notfound";
import {  FindTalent } from "./pages/Temporary";
import ReactGA from 'react-ga';

import withClearCache from "./ClearCache";

const TRACKING_ID = "UA-195437512-4";

const MainApp = () => {
  ReactGA.initialize(TRACKING_ID);
  const except = [
    "/signup",
    "/talentSignUp",
    "/agentSignUp",
    "/professionalSignUp",
  ];

  const context = useContext(PackageContext);
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const logged = sessionStorage.getItem('AUTH_USER')
    if (logged && ['/login', ...except].includes(window.location.pathname)) {
      context.updatePath('/')
      history.push("/")
    }
  }, [])

  return (
        <div className="w-full h-full">
          {!except.includes(context.data) && <Navigation />}
          <Switch>
            {navigation_content.map((nav, index) => (
              <Route key={index} exact={nav.exact} path={nav.pathname}>
                {nav.component}
              </Route>
            ))}
            {footer_content.map((link, index) =>
              link.links.map((mount, key) => (
                <Route key={key} exact={mount.exact} path={mount.pathname}>
                  {mount.component}
                </Route>
              ))
            )}
            <Route path="/login" exact={false}>
              <Login />
            </Route>
            <Route path="/signup" exact={false}>
              <SignUp />
            </Route>
            <Route path="/talentSignUp" exact={false}>
              <TalentRegistration />
            </Route>
            <Route path="/talentAgentSignUp" exact={false}>
              <TalentRegistration />
            </Route>
            <Route path="/agentSignUp" exact={false}>
              <AgentRegistration />
            </Route>
            <Route path="/professionalSignUp" exact={false}>
              <ProfessionalRegistration />
            </Route>
            <Route path="/forgotPassword" exact={false}>
              <ForgotPassword />
            </Route>
            <Route path="/academy" exact={false}>
              <Academy />
            </Route>
            <Route path="/e_learning" exact={false}>
              <ELearning />
            </Route>
            <Route path="/webinars" exact={false}>
              <Webinars />
            </Route>
            <Route path="/agencydirectory" exact={false}>
              <Agency />
            </Route>
            <Route path="/creatorsdirectory" exact={false}>
              <CreatorDirectory />
            </Route>
            <Route path="/ghana" exact={false}>
              <GhanaHome />
            </Route>
            <Route path="/nigeria" exact={false}>
              <NigeriaHome />
            </Route>
            <Route path="/southafrica" exact={false}>
              <SouthAfricaHome />
            </Route>
            <Route path="/namibia" exact={false}>
              <NamibiaHome />
            </Route>
            <Route path="/kenya" exact={false}>
              <KenyaHome />
            </Route>
            <Route path="/uganda" exact={false}>
              <UgandaHome />
            </Route>
            <Route path="/talentDetails" exact={false}>
              <TalentDetails />
            </Route>
            <Route path="/talentDetailsD" exact={false}>
              <TalentDetails />
            </Route>
            <Route path="/agentlanding" exact={false}>
              <MainLanding />
            </Route>
            <Route path="/agentprofile" exact={false}>
              <AgentProfile />
            </Route>
            <Route path="/creatorprofile" exact={false}>
              <CreatorProfile />
            </Route>
            <Route path="/editprofile" exact={false}>
              <Editprofilepage />
            </Route>
            <Route path="/managejobs" exact={false}>
              <Managejobs />
            </Route>
            <Route path="/postjob" exact={false}>
              <PostJob />
            </Route>
            <Route path="/jobApplicants" exact={false}>
              <JobApplicants />
            </Route>
            <Route path="/jobDetails" exact={false}>
              <JobDetails />
            </Route>
            <Route path="/manageRooster" exact={false}>
              <ManageRooster />
            </Route>
            <Route path="/changePassword" exact={false}>
              <ChangePassword />
            </Route>
            <Route path="/billing" exact={false}>
              <Billing />
            </Route>
            <Route path="/talentDashboard" exact={false}>
              <TalentDashboard />
            </Route>
            <Route path="/resetPassword/:para" exact={false}>
              <ResetPassword />
            </Route>
            <Route path="/talentProfile" exact={true}>
              <ViewTalentProfile />
            </Route>
            <Route path="/talentJobs" exact={true}>
              <TalentJobs />
            </Route>
            <Route path="/ca" exact={true}>
              <FindTalent />
            </Route>
            <Route component={NotFound} />

          </Switch>
          {!except.includes(context.data) && <Footer />}
          <NotificationContainer />
        </div>
  );
};

const ClearCacheComponent = withClearCache(MainApp)

const App = () => {
  return <ClearCacheComponent />
}





export default App;
