import React, { useContext, useState, useEffect } from "react";
import { ProfileFooter } from ".";
import PackageContext from "../../../context/context";
import { Pagination } from "../Pagination";
import Player from "./AudioPlayer/Player";

const TalentAudio = () => {
  const context = useContext(PackageContext);
  const talent = context.talent;

  const [setnext, setSetnext] = useState(4);
  const [selectedAudio, setSelectedAudio] = useState(null);
  let pagesize = 4;



  return (
    <div className="flex flex-col items-center w-full">
      {
        talent?.audio.length ? (selectedAudio ? (
          <div className="mb-8 w-full border">
            <Player audio={selectedAudio} setSelectedAudio={setSelectedAudio} />
          </div>
        ) : (
          ""
        )) : ""
      }
      <div>
        {
          talent?.audio.length ? talent?.audios?.map((audio, key) => (
            <div
              onClick={() => setSelectedAudio(audio)}
              key={key}
              className={` ${
                audio.title === selectedAudio?.title &&
                "text-primary font-gilroy-Extrabold"
              } mb-2 cursor-pointer font-gilroy-Light flex px-4 lg:px-0`}
            >
              {audio.title === selectedAudio?.title ? (
                <div className="w-7 h-7 cursor-pointer mr-3 rounded-full flex items-center justify-center bg-primary">
                  <svg
                    className=" w-5 h-3"
                    viewBox="0 0 10 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.5 0.125C1.73206 0.125 1.95462 0.217187 2.11872 0.381282C2.28281 0.545376 2.375 0.767936 2.375 1V15C2.375 15.2321 2.28281 15.4546 2.11872 15.6187C1.95462 15.7828 1.73206 15.875 1.5 15.875C1.26794 15.875 1.04538 15.7828 0.881282 15.6187C0.717187 15.4546 0.625 15.2321 0.625 15V1C0.625 0.767936 0.717187 0.545376 0.881282 0.381282C1.04538 0.217187 1.26794 0.125 1.5 0.125V0.125ZM8.5 0.125C8.73206 0.125 8.95462 0.217187 9.11872 0.381282C9.28281 0.545376 9.375 0.767936 9.375 1V15C9.375 15.2321 9.28281 15.4546 9.11872 15.6187C8.95462 15.7828 8.73206 15.875 8.5 15.875C8.26794 15.875 8.04538 15.7828 7.88128 15.6187C7.71719 15.4546 7.625 15.2321 7.625 15V1C7.625 0.767936 7.71719 0.545376 7.88128 0.381282C8.04538 0.217187 8.26794 0.125 8.5 0.125V0.125Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ) : (
                <svg
                  width="27"
                  height="27"
                  className="mr-3"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 0C6.04487 0 0 6.04487 0 13.5C0 20.9551 6.04487 27 13.5 27C20.9551 27 27 20.9551 27 13.5C27 6.04487 20.9551 0 13.5 0ZM17.8423 13.7079L11.261 18.4962C11.225 18.5221 11.1824 18.5376 11.1381 18.5409C11.0938 18.5443 11.0495 18.5353 11.0099 18.5151C10.9704 18.4948 10.9372 18.4641 10.9139 18.4262C10.8907 18.3883 10.8784 18.3448 10.8783 18.3003V8.7298C10.8782 8.68528 10.8904 8.64159 10.9136 8.60357C10.9367 8.56556 10.97 8.53469 11.0096 8.51441C11.0492 8.49413 11.0937 8.48522 11.1381 8.48866C11.1825 8.49211 11.225 8.50777 11.261 8.53393L17.8423 13.3192C17.8734 13.3412 17.8988 13.3703 17.9163 13.4041C17.9338 13.438 17.9429 13.4755 17.9429 13.5136C17.9429 13.5516 17.9338 13.5892 17.9163 13.623C17.8988 13.6568 17.8734 13.6859 17.8423 13.7079Z"
                    fill="#3A77FF"
                  />
                </svg>
              )}
              <span className="mr-2">{audio.title}</span>
              <span>({audio.duration})</span>
            </div>
          )) : "No audios found at this time"
        }
      </div>
      <Pagination
        data={talent?.audios}
        pagesize={pagesize}
        nextSet={setSetnext}
      />
    </div>
  );
};

export default TalentAudio;
