import React, {useContext, useState} from "react";
import { ProfileFooter } from ".";
import PackageContext from "../../../context/context";
import { Pagination } from "../Pagination";
import moment from "moment";


const Vidoes = () => {
  const context = useContext(PackageContext);
  const talent = context.talent;
  const [setNext, setSetNext] = useState(3);
  const [seletectVideo, setSeletectVideo] = useState(talent?.videos);
  let pagesize = 3;

  return (
    <div>
      {/* {
        talent?.videos.length ? (<div>
          <div className="mb-3">
            <div
              style={{
                backgroundImage: `url(${seletectVideo?.banner})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
                backgroundSize: "100%"
              }}
              className="h-40 md:h-56 lg:h-80"
            ></div>
            <h1 className="font-gilroy-Medium mt-2 mb-1">
              {seletectVideo?.title}
            </h1>
            <p className="text-gray-300 font-gilroy-Light">
              {moment(seletectVideo?.posted_at).fromNow()}
            </p>
          </div>
        </div>) : ""
      } */}
      <div className="flex lg:items-start justify-center items-center h-auto md:mb-10 mb-20  lg:justify-center">
        {
          talent?.videos.length ? talent?.videos?.slice(setNext - pagesize, setNext).map((video, key) => (
            <div
            key={key}
            className={`w-full h-full`}
            >
              <div
                style={{
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  objectFit: "contain",
                  
                }}
                className={`lg:h-full cursor-pointer`}
              >
                <video
                className="w-full h-full"
                controls
                src={`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${talent?.talent_media_path}/videos/${video}`}
              />
              </div>
              {/* <h1 className="font-gilroy-Medium mt-2 mb-1">{video.title}</h1> */}
              <h1 className="font-gilroy-Medium mt-2 mb-1">Video Reel</h1>
              <p className="text-gray-300 font-gilroy-Light">
                {/* {moment(video.posted_at).fromNow()} */}
              </p>
            </div>
          )): "No Videos found at this time"
        }
      </div>
      <Pagination
        data={talent?.videos}
        pagesize={pagesize}
        nextSet={setSetNext}
      />
    </div>
  );
};

export default Vidoes;
