import {
  Home,
  Pricing,
  Construction,
  ContactUs,
  FrequentQuetions,
  AboutUs,
} from "../pages/components";
import KWASI from "../assets/images/Casting Africa_files/Kwasi.png"
import COLE from "../assets/images/Casting Africa_files/Cole.png"
import KORI from "../assets/images/Casting Africa_files/kori.png"
import PHILIP from "../assets/images/Casting Africa_files/philip.png"
import DORCAS from "../assets/images/Casting Africa_files/dorcas.png"
import ERIC from "../assets/images/Casting Africa_files/eric.png"

import ANDROID from "../assets/images/android.png";
import APPLE from "../assets/images/apple.png";
import GHANA from "../assets/images/ghana.png";
import UGANDA from "../assets/images/uganda.png";
import NIGERIA from "../assets/images/nigeria.png";
import SA from "../assets/images/sa.png";
import KENYA from "../assets/images/kenya.png";
import NAMIBIA from "../assets/images/namibia.png";
import DSTV from "../assets/images/dstv.png";
import FilmOne from "../assets/images/filmone.png";
import PlayNetwork from "../assets/images/playnetwork.png";
import Inkblot from "../assets/images/inkbloc.png";
import EbonyLife from "../assets/images/EL-logo.png";
import RACHEAL from "../assets/images/racheal.png";
import TYRONNE from "../assets/images/tyronne.png";
import JOEL from "../assets/images/joel.png";
import JOAN from "../assets/images/joan.png";
import C1LOGO from "../assets/images/c1logo.jpg";
import C2LOGO from "../assets/images/c2logo.png";
import C3LOGO from "../assets/images/c3logo.png";
import YAA from "../assets/images/yaa.png";
import OBENG from "../assets/images/obeng.png";
import ANSAH from "../assets/images/ansah.png";
import HORIZONTALAD from "../assets/images/horizontalad.png";
import SQUAREAD from "../assets/images/squaread.png";
import VERTICALAD from "../assets/images/verticalad.png";
import VERTICALAD1 from "../assets/images/verticalad1.png";
import { GhanaHome, NigeriaHome, SouthAfricaHome, NamibiaHome, KenyaHome } from "../pages/components/Communities";
import { CastingCalls } from "../pages/components/Talent";
import { DiscoverPage } from "../pages/components/Discover";
import {
  About,
  TalentAudio,
  Followers,
  Following,
  Photos,
  Recommendations,
  Resume,
  TalentDirectory,
  Videos,
} from "../pages/components/Agent";

import {
  Roster,
  Followers as AgentFollowers,
  Following as AgentFollowing,
  Jobs,
  Recommendations as AgentRecommendations,
} from "../pages/components/Agent/AgentPortal";

import POST_IMG1 from "../assets/images/screen.png";
import POST_IMG2 from "../assets/images/screen2.png";
import POST_IMG3 from "../assets/images/screen3.png";
import POST_IMG4 from "../assets/images/screen4.png";
import POST_IMG5 from "../assets/images/screen5.png";
import THUMBNAIL1 from "../assets/images/thumbnail1.png";
import THUMBNAIL2 from "../assets/images/thumbnail2.png";
import TIKTOK from "../assets/images/tik_tok_square.png";
import YOUTUBE from "../assets/images/youtube_square.png";
import IMDB from "../assets/images/IMDb_square.png";
import FACEBOOK from "../assets/images/facebook_square.png";
import TWITTER from "../assets/images/twitter_square.png";
import INSTAGRAM from "../assets/images/icon_instagram.png";
import PROFILE_1 from "../assets/images/personal/profile_1.jpg";
import PROFILE_2 from "../assets/images/personal/profile_2.jpg";
import PROFILE_3 from "../assets/images/personal/profile_3.jpg";
import PROFILE_4 from "../assets/images/personal/profile_4.jpg";
import PROFILE_5 from "../assets/images/personal/profile_5.jpg";
import PROFILE_6 from "../assets/images/personal/profile_6.jpg";
import PROFILE_7 from "../assets/images/personal/profile_7.jpg";
import PROFILE_8 from "../assets/images/personal/profile_8.jpg";
import PROFILE_9 from "../assets/images/personal/profile_9.jpg";
import VIDEO_1 from "../assets/videos/video_1.png";
import VIDEO_2 from "../assets/videos/video_2.png";
import VIDEO_3 from "../assets/videos/video_3.png";
import VIDEO_4 from "../assets/videos/video_4.png";
import AUDIO_1 from "../assets/audio/audio_1.mp3";
import AUDIO_2 from "../assets/audio/audio_2.mp3";
import AUDIO_3 from "../assets/audio/audio_3.mp3";
import AUDIO_4 from "../assets/audio/audio_4.mp3";
import USER_AVATAR from "../assets/images/user_avatar.png";
import MODEL from "../assets/images/model.png";
import ACTOR from "../assets/images/actor.png";
import DANCER from "../assets/images/dancer.png";
import INFLUENCER from "../assets/images/influencer.png";
import THUMBNAIL_IMAGE from "../assets/images/profile_avatar.png";
import AGENT1 from "../assets/images/agent1.png";
import AGENT2 from "../assets/images/agent2.png";
import AGENT3 from "../assets/images/agent3.png";
import AGENT4 from "../assets/images/agent4.png";
import VIDEOTHUMBNAIL1 from "../assets/images/W7.png";
import VIDEOTHUMBNAIL2 from "../assets/images/W8.png";
import TalentMedia from "../pages/components/Talent/TalentPortal/talentMedia";
import {
  UserCreditAndSkills,
  UserDetails,
  UserJobPreference,
  UserPhysicalAttributes,
  UserProfessionalDetails,
} from "../pages/components/SignUp/Talents";
import { Monologue } from "../pages/Temporary";
import CreatorDirectory from "../pages/components/Agent/TalentDirectory/creatorDirectory";

const navigation_content = [
  {
    menu_title: "Home",
    pathname: "/",
    exact: true,
    component: Home,
    sub_menu: [],
  },
  {
    menu_title: "Directory",
    pathname: "/talents",
    exact: false,
    component: <TalentDirectory />,
    sub_menu: [
      {
        menu_title: "Talent Directory",
        pathname: "/talents",
        exact: false,
        component: <TalentDirectory />,
      },
      // {
      //   menu_title: "Agencies Directory",
      //   pathname: "/agencydirectory",
      //   exact: false,
      //   component: <Agency />,
      // },
      // {
      //   menu_title: "Creators Directory",
      //   pathname: "/creatorsdirectory",
      //   exact: false,
      //   component: <CreatorDirectory />,
      // },
    ],
  },
  // {
  //   menu_title: "Creator",
  //   pathname: "/talents",
  //   exact: false,
  //   component: <TalentDirectory />,
  //   sub_menu: [
  //     {
  //       menu_title: "Find Talent",
  //       pathname: "/talents",
  //       exact: false,
  //       component: <TalentDirectory />,
  //     },
  //     {
  //       menu_title: "Post A Job",
  //       pathname: "/find_jobs",
  //       exact: false,
  //       component: <FindJobs />,
  //     },
  //   ],
  // },
  // {
  //   menu_title: "Agent",
  //   pathname: "/talents",
  //   exact: false,
  //   component: <CastingCalls />,
  //   sub_menu: [
  //     {
  //       menu_title: "Add Talent",
  //       pathname: "/find_jobs",
  //       exact: false,
  //       component: <CastingCalls />,
  //     },
  //     {
  //       menu_title: "Browse Jobs",
  //       pathname: "/castingcalls",
  //       exact: false,
  //       component: <CastingCalls />,
  //     },
  //   ],
  // },
  // {
  //   menu_title: "Talent",
  //   pathname: "/castingcalls",
  //   exact: false,
  //   component: <CastingCalls />,
  //   sub_menu: [
  //     {
  //       menu_title: "Casting Calls",
  //       pathname: "/castingcalls",
  //       exact: false,
  //       component: <CastingCalls />,
  //     },
  //     {
  //       menu_title: "Industry Advice",
  //       pathname: "/findJobs",
  //       exact: false,
  //       component: <Construction />,
  //     },
  //     {
  //       menu_title: "Academy",
  //       pathname: "/findJobs",
  //       exact: false,
  //       component: <Academy />,
  //     },
  //     {
  //       menu_title: "E-Learning",
  //       pathname: "/e_learning",
  //       exact: false,
  //       component: <ELearning />,
  //     },
  //   ],
  // },
  // {
  //   menu_title: "Discover",
  //   pathname: "/discover",
  //   exact: true,
  //   component: <DiscoverPage />,
  //   sub_menu: [],
  // },
  {
    menu_title: "About",
    pathname: "/aboutus",
    exact: false,
    component: <AboutUs />,
    sub_menu: [
      {
        menu_title: "Pricing",
        pathname: "/pricing",
        exact: false,
        component: <Pricing />,
      },
      {
        menu_title: "About Us",
        pathname: "/aboutus",
        exact: false,
        component: <AboutUs />,
      },
      {
        menu_title: "Contact Us",
        pathname: "/contact",
        exact: true,
        component: <ContactUs />,
      },
      {
        menu_title: "FAQ",
        pathname: "/faq",
        exact: false,
        component: <FrequentQuetions />,
      }
    ],
  },
  {
    menu_title: "Contest",
    pathname: "/monologue",
    exact: true,
    component: <Monologue />,
    sub_menu: [],
  }
  ,
];

const footer_content = [
  {
    title: "Talents",
    links: [
      {
        title: "Actors",
        pathname: "/actors",
        exact: false,
        component: <TalentDirectory />,
      },
      {
        title: "Models",
        pathname: "/models",
        exact: false,
        component: <TalentDirectory />,
      },
      {
        title: "Influencers",
        pathname: "/influencers",
        exact: false,
        component: <TalentDirectory />,
      },
      {
        title: "Dancer",
        pathname: "/dancers",
        exact: false,
        component: <TalentDirectory />,
      },
    ],
  },
  {
    title: "Company",
    links: [
      {
        title: "About",
        pathname: "/aboutus",
        exact: false,
        component: <AboutUs />,
        type: "",
      },
      {
        title: "The Team",
        pathname: "/aboutus#theteam",
        exact: false,
        component: <Construction />,
        type: "",
      },
      {
        title: "Work with us",
        pathname: "/contactuss",
        exact: false,
        component: <Construction />,
        type: "",
      },
    ],
  },
  {
    title: "Support",
    links: [
      {
        title: "Contact",
        pathname: "/contact",
        exact: false,
        component: <ContactUs />,
        type: "",
      },
      {
        title: "Pricing",
        pathname: "/pricing",
        exact: false,
        component: <Pricing />,
        type: "",
      },
      {
        title: "FAQ",
        pathname: "/faq",
        exact: false,
        component: <FrequentQuetions />,
        type: "",
      },
    ],
  },
  {
    title: "Download Apps",
    links: [
      {
        title: APPLE,
        pathname: "/apple",
        exact: false,
        component: <Construction />,
        type: "img", //enum img, ""
      },
      {
        title: ANDROID,
        pathname: "/android",
        exact: false,
        component: <Construction />,
        type: "img",
      },
    ],
  },
  // {
  //   title: "Let's Connect",
  //   links: [
  //     {
  //       title: "facbook",
  //       pathname: "/facebook",
  //       exact: false,
  //       component: <Construction />,
  //       type: "",
  //     },
  //     {
  //       title: "Instagram",
  //       pathname: "/instagram",
  //       exact: false,
  //       component: <Construction />,
  //       type: "",
  //     },
  //     {
  //       title: "Twitter",
  //       pathname: "/twitter",
  //       exact: false,
  //       component: <Construction />,
  //       type: "",
  //     },
  //     {
  //       title: "YouTube",
  //       pathname: "/youtube",
  //       exact: false,
  //       component: <Construction />,
  //       type: "",
  //     },
  //   ],
  // },
];

const casting_africa_communities = [
  {
    country: "Ghana",
    code: "233",
    icon: GHANA,
    status: true,
    pathname: "/ghana",
    component: GhanaHome,
  },
  {
    country: "Nigeria",
    icon: NIGERIA,
    code: "234",
    status: true,
    pathname: "/nigeria",
    component: NigeriaHome,
  },
  {
    country: "South Africa",
    icon: SA,
    status: true,
    code: "27",
    pathname: "/southafrica",
    component: SouthAfricaHome,
  },
  {
    country: "Kenya",
    icon: KENYA,
    status: true,
    code: "245",
    pathname: "/kenya",
    component: KenyaHome,
  },
  {
    country: "Namibia",
    icon: NAMIBIA,
    status: true,
    code: "264",
    pathname: "/namibia",
    component: NamibiaHome,
  },
  {
    country: "Uganda",
    icon: UGANDA,
    status: true,
    code: "256",
    pathname: "/uganda",
    component: NamibiaHome,
  },
];

const casting_africa_partners = [
  {
    company_name: "DSTV",
    icon: DSTV,
  },
  {
    company_name: "FilmOne",
    icon: FilmOne,
  },
  {
    company_name: "EbonyLife",
    icon: EbonyLife,
  },
  
  {
    company_name: "PlayNetwork",
    icon: PlayNetwork,
  },
  {
    company_name: "Inkblot",
    icon: Inkblot,
  },
];

const how_it_works = [
  {
    title: "Create Your Profile",
    description:
      "Create your professional talent profile. Upload your headshots, videos and biography.",
  },
  {
    title: "Apply For Jobs",
    description:
      "Browse, shortlist and apply for jobs available on the job board.",
  },
  {
    title: "Get Booked",
    description:
      "Recieve application update, notifications and track the status of every job post",
  },
];

const what_we_do = [
  {
    title: "Talent Discovery",
    description:
      "We host the largest profile of African actors, models, dancers and influencers globally.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-24 w-24"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
        />
      </svg>
    ),
  },
  {
    title: "Talent Development",
    description:
      "We provide simplified courses through our Academy and E-Learning courses, making sure talents are well prepared for oppourtunities",
    icon: (
      <svg
        className="h-24 w-24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 16V20M12 20H16M12 20H8M16 10.5L8 10.5M16 10.5L13 13.5M16 10.5L13 7.5M5 16H19C19.5523 16 20 15.5523 20 15V6C20 5.44772 19.5523 5 19 5H5C4.44772 5 4 5.44771 4 6V15C4 15.5523 4.44772 16 5 16Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "Talent Delivery",
    description:
      "Hundreds of local and international casting professionals scout for talents on CastingAfrica.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-24  w-24"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    ),
  },
];

const talent_professional_info = [
  {
    label: "Actor Category",
    categories: ["Film/Tv", "Stage", "Commercial", "Voice"],
  },
  {
    label: "Model Category",
    categories: [
      "Runway",
      "Body",
      "Dancer",
      "Commercial",
      "Music Video",
      "Ushering",
      "Print",
      "Hair",
    ],
  },
  {
    label: "Dancer Category",
    categories: [
      "Ballet",
      "Contemporary",
      "Jazz",
      "Folk",
      "Tap",
      "Hip Hop",
      "Modern ",
      "Ballroom ",
    ],
  },
  {
    label: "Influencer Category",
    categories: [
      "Mega - 1M + followers",
      "Macro - 500K to 1M followers",
      "Micro - 20K to 500K followers",
      "Nano - 1K to 20K followers",
    ],
  },
];

const talent_job_preference = [
  {
    label: "Actor Jobs I'm interested in",
    categories: [
      "Feature Films",
      "Theatre & Musicals",
      "TV Series",
      "Commercial",
      "Voiceover & Radio",
      "Short/Web Films",
    ],
  },
  {
    label: "Model Jobs I'm interested in",
    categories: [
      "Runway",
      "Body",
      "Commercial",
      "Music Video",
      "Ushering",
      "Print",
      "Hair",
    ],
  },
  {
    label: "Dancer Category",
    categories: [
      "Commercial",
      "Music Video",
      "Event",
      "Film/Tv",
    ],
  },
  {
    label: "Influencer Interest",
    categories: [
      "Automotive",
      "Business",
      "Food",
      "Health and fitness",
      "Travel",
      "Beauty",
      "Fashion",
      "Home and parenting",
      "Gaming and technology",
    ],
  },

  {
    label: "Influencer Channel",
    categories: [
      "Facebook",
      "Instagram",
      "YouTube",
      "Twitter",
      "Snapchat",
      "Tik Tok",
    ],
  },
];

const talent_physical_attributes = [
  {
    label: "Build",
    categories: ["Slim", "Medium", "Muscular", "Curvy"],
  },
  {
    label: "Hair Color",
    categories: ["Black", "Brown", "Grey", "Red"],
  },
  {
    label: "Eye Color",
    categories: ["Black", "Brown", "Grey", "Red"],
  },
  {
    label: "Hair Type",
    categories: ["Short", "Medium", "Long", "Wavy"],
  },
];

const creditandskills = [
  {
    label: "Actor Experience",
    categories: [
      "No previous acting experience",
      "Previous unpaid speaking roles",
      "Previous paid speaking roles",
      "Extra",
    ],
  },
  {
    label: "Model Experience",
    categories: [
      "Beginner, starting out",
      "Part-time model - paid commercial work",
      "Full-time model - paid commercial work",
    ],
  },
  {
    label: "Dancer Experience",
    categories: [
      "Beginner, starting out",
      "Part-time dancer - paid works",
      "Full-time dancer - paid works",
    ],
  },
  {
    label: "Agent",
    categories: ["I don't have an agent", "I want an agent", "I have an agent"],
  },
];

const gh_spotlight_picks = [
  {
    first_name: "Racheal",
    dob: "1990-03-15",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Presenter & Influencer",
    img: RACHEAL,
  },
  {
    first_name: "Tyronne",
    dob: "1992-03-15",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: TYRONNE,
  },
  {
    first_name: "Joel",
    dob: "1997-03-15",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: JOEL,
  },
  {
    first_name: "Joan",
    dob: "1996-04-13",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Dancer & Influencer",
    img: JOAN,
  },
];

const ng_spotlight_picks = [
  {
    first_name: "Uche",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: RACHEAL,
  },
  {
    first_name: "Ngozi",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: TYRONNE,
  },
  {
    first_name: "Adjebola",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Presenter & Influencer",
    img: JOEL,
  },
  {
    first_name: "Ike",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Dancer & Influencer",
    img: JOAN,
  },
];

const community_content = {
  ghana: {
    gh_spotlight_picks,
    jobs: [
      {
        job_title: "Actors Wanted For A Television Series ",
        job_long_description: "",
        job_short_description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
        thumbnail: C1LOGO,
        banner: "",
        region: "Kumasi",
        country: "Ghana",
        company_name: "Apex Agency",
        posted_at: "13/03/2020 9:00:00",
        tags: [
          {
            tag_name: "Premium",
            text_color: "text-black",
            color: "premiumTagColor",
          },
          {
            tag_name: "Paid",
            text_color: "text-white",
            color: "tvTagColor",
          },
          {
            tag_name: "Commercial",
            text_color: "text-black",
            color: "commericalTagColor",
          },
        ],
      },
      {
        job_title: "Female Models Needed ",
        job_long_description: "",
        job_short_description: "",
        thumbnail: C2LOGO,
        banner: "",
        region: "A",
        country: "Ghana",
        company_name: "Alpha Production",
        posted_at: "13/03/2020 9:00:00",
        tags: [
          {
            tag_name: "Premium",
            text_color: "text-black",
            color: "premiumTagColor",
          },
          {
            tag_name: "Paid",
            text_color: "text-white",
            color: "primary",
          },
          {
            tag_name: "Commercial",
            text_color: "text-black",
            color: "commericalTagColor",
          },
        ],
      },
      {
        job_title: "Lifestyle Influencer Needed For Commercial",
        job_long_description: "",
        job_short_description: "",
        thumbnail: C3LOGO,
        banner: "",
        region: "Kumasi",
        country: "Ghana",
        company_name: "Sparrows Production",
        posted_at: "13/03/2020 9:00:00",
        tags: [
          {
            tag_name: "Urgent",
            text_color: "text-black",
            color: "premiumTagColor",
          },
          {
            tag_name: "Paid",
            text_color: "text-white",
            color: "tvTagColor",
          },
          {
            tag_name: "Commercial",
            text_color: "text-black",
            color: "commericalTagColor",
          },
        ],
      },
      {
        job_title: "Actors Wanted For A Television Series ",
        job_long_description: "",
        job_short_description: "",
        thumbnail: C3LOGO,
        banner: "",
        region: "Accra",
        country: "Ghana",
        company_name: "BBA Agency",
        posted_at: "13/03/2020 9:00:00",
        tags: [
          {
            tag_name: "Premium",
            text_color: "text-black",
            color: "premiumTagColor",
          },
          {
            tag_name: "Paid",
            text_color: "text-white",
            color: "primary",
          },
          {
            tag_name: "Tv Series",
            text_color: "text-black",
            color: "tvTagColor",
          },
        ],
      },
    ],
  },
  nigeria: {
    ng_spotlight_picks,
    jobs: [
      {
        job_title: "Actors Wanted For A Television Series ",
        job_long_description: "",
        job_short_description: "",
        thumbnail: C1LOGO,
        banner: "",
        region: "Kumasi",
        country: "Ghana",
        company_name: "Apex Agency",
        posted_at: "13/03/2020 9:00:00",
        tags: [
          {
            tag_name: "Premium",
            text_color: "text-black",
            color: "premiumTagColor",
          },
          {
            tag_name: "Paid",
            text_color: "text-white",
            color: "tvTagColor",
          },
          {
            tag_name: "Commercial",
            text_color: "text-black",
            color: "commericalTagColor",
          },
        ],
      },
      {
        job_title: "Female Models Needed ",
        job_long_description: "",
        job_short_description: "",
        thumbnail: C2LOGO,
        banner: "",
        region: "A",
        country: "Ghana",
        company_name: "Alpha Agency",
        posted_at: "13/03/2020 9:00:00",
        tags: [
          {
            tag_name: "Premium",
            text_color: "text-black",
            color: "premiumTagColor",
          },
          {
            tag_name: "Paid",
            text_color: "text-white",
            color: "primary",
          },
          {
            tag_name: "Commercial",
            text_color: "text-black",
            color: "commericalTagColor",
          },
        ],
      },
      {
        job_title: "Lifestyle Influencer Needed For Commercial",
        job_long_description: "",
        job_short_description: "",
        thumbnail: C3LOGO,
        banner: "",
        region: "Kumasi",
        country: "Ghana",
        company_name: "Sparrow Agency",
        posted_at: "13/03/2020 9:00:00",
        tags: [
          {
            tag_name: "Urgent",
            text_color: "text-black",
            color: "premiumTagColor",
          },
          {
            tag_name: "Paid",
            text_color: "text-white",
            color: "tvTagColor",
          },
          {
            tag_name: "Commercial",
            text_color: "text-black",
            color: "commericalTagColor",
          },
        ],
      },
      {
        job_title: "Actors Wanted For A Television Series ",
        job_long_description: "",
        job_short_description: "",
        thumbnail: C3LOGO,
        banner: "",
        region: "Accra",
        country: "Ghana",
        company_name: "BBA Agency",
        posted_at: "13/03/2020 9:00:00",
        tags: [
          {
            tag_name: "Premium",
            text_color: "text-black",
            color: "premiumTagColor",
          },
          {
            tag_name: "Paid",
            text_color: "text-white",
            color: "primary",
          },
          {
            tag_name: "Tv Series",
            text_color: "text-black",
            color: "tvTagColor",
          },
        ],
      },
    ],
  },
};

const reviews = [
  {
    title: "Review by Priscilla Obeng",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet risus, venenatis at.",
    banner: OBENG,
  },
  {
    title: "Review by Nana Yaa",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet risus, venenatis at.",
    banner: YAA,
  },
  {
    title: "Review by Ben Ansah",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet risus, venenatis at.",
    banner: ANSAH,
  },
];

const talent_filters = [
  {
    filter_type: "Actor",
    count: 4222,
  },
  {
    filter_type: "Model",
    count: 3443,
  },
  {
    filter_type: "Dancer",
    count: 1112,
  },
  {
    filter_type: "Influencer",
    count: 1621,
  },
];

const agent_filters = [
  {
    filter_type: "Agent",
    count: 300,
  },
  {
    filter_type: "Agencies",
    count: 100,
  },
  {
    filter_type: "Manager",
    count: 500,
  },
];

const creator_filters = [
  {
    filter_type: "Casting Director",
    count: 200,
  },
  {
    filter_type: "Director",
    count: 120,
  },
  {
    filter_type: "Producer",
    count: 50,
  },
  {
    filter_type: "Ad Agency",
    count: 200,
  },
];

const sample_talents = [
  {
    first_name: "Uche",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: RACHEAL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ngozi",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: TYRONNE,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Adjebola",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Presenter & Influencer",
    img: JOEL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ike",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Dancer & Influencer",
    img: JOAN,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Uche",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: RACHEAL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ngozi",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: TYRONNE,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Adjebola",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Presenter & Influencer",
    img: JOEL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ike",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Dancer & Influencer",
    img: JOAN,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Uche",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: RACHEAL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ngozi",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: TYRONNE,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Adjebola",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Presenter & Influencer",
    img: JOEL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ike",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Dancer & Influencer",
    img: JOAN,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Uche",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: RACHEAL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ngozi",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: TYRONNE,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Adjebola",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Presenter & Influencer",
    img: JOEL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ike",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Dancer & Influencer",
    img: JOAN,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Uche",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: RACHEAL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ngozi",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: TYRONNE,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Adjebola",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Presenter & Influencer",
    img: JOEL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ike",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Dancer & Influencer",
    img: JOAN,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Uche",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: RACHEAL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ngozi",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: TYRONNE,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Adjebola",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Presenter & Influencer",
    img: JOEL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ike",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Dancer & Influencer",
    img: JOAN,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Uche",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: RACHEAL,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    first_name: "Ngozi",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    talent_type: "Actor, Model & Influencer",
    img: TYRONNE,
    about: {
      physical_attributes: {
        build: "curvy",
        skin_color: "brown",
        eye_color: "brown",
        hair_color: "Black",
        hair_type: "medium",
        dress_size: "UK 12/AUS 12/US 8",
        chest: "92cm/36In",
        height: "170cm/5ft 7in",
        weight: "75kg",
        hips: "63 kg / 138 lbs",
        bust: "63 kg / 138 lbs",
        shoe_size: "63 kg / 138 lbs",
        waist: "73 cm / 29 in",
      },
      credit_and_skills: {
        credit: [
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
          {
            year: 2021,
            project: "Yolo - TV Series",
            role: "Supporting Role",
            company: "Farm House Production",
          },
        ],
        language_spoken: "English, French, Spanish",
        accent: "African, British, Ghanainan",
        modelling_category: "Runway, Music Video, Body part",
        modelling_experience: "Part Time- Paid job",
        dancing_ability: "Beginner",
        dancing_style: "Contemporary ,Mordern",
      },
      influencer: {
        type: "Lifestyle, Automotive",
        channels: {
          instagram: "Nano - 1K to 20K followers",
          tikTok: "Micro - 20K to 500K followers",
          youTube: "Micro - 20K to 500K followers",
          facebook: "Nano - 1K to 20K followers",
        },
      },
      awards: [
        {
          year: 2021,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2020,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2017,
          title: "Ghana Movies Award",
          status: "Won",
          project: "Yolo",
        },
        {
          year: 2016,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
        {
          year: 2015,
          title: "Ghana Movies Award",
          status: "Nominated",
          project: "Yolo",
        },
      ],
      job_preference: {
        acting: "Feature Films, Voiceover & Radio, Commercial",
        modelling: "Runway, Commercial, Music Video",
        dancing: "Event,  Music Video",
        influencer: "Lifestyle, Automotive",
      },
      license_and_passport: {
        driving_license: "yes",
        passport: "yes",
        guild_association: "Actors Guild Ghana (GAG)",
      },
      social_details: [TIKTOK, YOUTUBE, INSTAGRAM, IMDB, FACEBOOK, TWITTER],
    },
    images: [
      PROFILE_1,
      PROFILE_2,
      PROFILE_3,
      PROFILE_4,
      PROFILE_5,
      PROFILE_6,
      PROFILE_7,
      PROFILE_8,
      PROFILE_9,
    ],
    videos: [
      {
        title: "Malta Guiness TVC",
        posted_at: "2021-03-02",
        banner: VIDEO_1,
        video: "",
      },
      {
        title: "Right Now - Monolgue",
        posted_at: "2021-03-02",
        banner: VIDEO_2,
        video: "",
      },
      {
        title: "Butter - Feature Film",
        posted_at: "2021-03-02",
        banner: VIDEO_3,
        video: "",
      },
      {
        title: "Ada Ahaa - Monologue",
        posted_at: "2021-03-02",
        banner: VIDEO_4,
        video: "",
      },
    ],
    audios: [
      {
        title: "MTN - Let's Go Commercial",
        audio: AUDIO_1,
        duration: "0:20",
      },
      {
        title: "Aseda - Audio Book",
        audio: AUDIO_2,
        duration: "15:05",
      },
      {
        title: "Verna Mineral Water Commercial",
        audio: AUDIO_3,
        duration: "0:40",
      },
      {
        title: "Morning Show - Peace FM",
        audio: AUDIO_4,
        duration: "5:03",
      },
    ],
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    resume: [
      {
        title: "Actor Resume",
        pathname: "",
      },
      {
        title: "Comp- Card",
        pathname: "",
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
];

const sample_ads = [
  {
    index: "1",
    banner: VERTICALAD,
    adType: "vertical",
  },
  {
    index: "2",
    banner: VERTICALAD1,
    adType: "vertical",
  },
  {
    index: "3",
    banner: HORIZONTALAD,
    adType: "horizontal",
  },
  {
    index: "4",
    banner: SQUAREAD,
    adType: "square",
  },
  {
    index: "5",
    banner: SQUAREAD,
    adType: "square",
  },
];

const sample_jobs = [
  {
    job_title: "Actors Wanted For A Television Series ",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C1LOGO,
    banner: "",
    region: "Kumasi",
    country: "Ghana",
    company_name: "Apex Agency",
    posted_at: "13/03/2020 9:00:00",
    applicants: sample_talents,
    tags: [
      {
        tag_name: "Premium",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "tvTagColor",
      },
      {
        tag_name: "Commercial",
        text_color: "text-black",
        color: "commericalTagColor",
      },
    ],
  },
  {
    job_title: "Female Models Needed ",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C2LOGO,
    banner: "",
    region: "A",
    country: "Ghana",
    company_name: "Alpha Production",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Premium",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "primary",
      },
      {
        tag_name: "Commercial",
        text_color: "text-black",
        color: "commericalTagColor",
      },
    ],
  },
  {
    job_title: "Lifestyle Influencer Needed For Commercial",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C3LOGO,
    banner: "",
    region: "Kumasi",
    country: "Ghana",
    company_name: "Sparrows Production",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Urgent",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "tvTagColor",
      },
      {
        tag_name: "Commercial",
        text_color: "text-black",
        color: "commericalTagColor",
      },
    ],
  },
  {
    job_title: "Actors Wanted For A Television Series ",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C3LOGO,
    banner: "",
    region: "Accra",
    country: "Ghana",
    company_name: "BBA Agency",
    posted_at: "13/03/2020 9:00:00",
    applicants: sample_talents,
    tags: [
      {
        tag_name: "Premium",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "primary",
      },
      {
        tag_name: "Tv Series",
        text_color: "text-black",
        color: "tvTagColor",
      },
    ],
  },
  {
    job_title: "Actors Wanted For A Television Series ",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C1LOGO,
    banner: "",
    region: "Kumasi",
    country: "Ghana",
    company_name: "Apex Agency",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Premium",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "tvTagColor",
      },
      {
        tag_name: "Commercial",
        text_color: "text-black",
        color: "commericalTagColor",
      },
    ],
  },
  {
    job_title: "Female Models Needed ",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C2LOGO,
    banner: "",
    region: "A",
    country: "Ghana",
    company_name: "Alpha Production",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Premium",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "primary",
      },
      {
        tag_name: "Commercial",
        text_color: "text-black",
        color: "commericalTagColor",
      },
    ],
  },
  {
    job_title: "Lifestyle Influencer Needed For Commercial",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C3LOGO,
    banner: "",
    region: "Kumasi",
    country: "Ghana",
    company_name: "Sparrows Production",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Urgent",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "tvTagColor",
      },
      {
        tag_name: "Commercial",
        text_color: "text-black",
        color: "commericalTagColor",
      },
    ],
  },
  {
    job_title: "Actors Wanted For A Television Series ",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C3LOGO,
    banner: "",
    region: "Accra",
    country: "Ghana",
    company_name: "BBA Agency",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Premium",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "primary",
      },
      {
        tag_name: "Tv Series",
        text_color: "text-black",
        color: "tvTagColor",
      },
    ],
  },
  {
    job_title: "Actors Wanted For A Television Series ",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C1LOGO,
    banner: "",
    region: "Kumasi",
    country: "Ghana",
    company_name: "Apex Agency",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Premium",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "tvTagColor",
      },
      {
        tag_name: "Commercial",
        text_color: "text-black",
        color: "commericalTagColor",
      },
    ],
  },
  {
    job_title: "Female Models Needed ",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C2LOGO,
    banner: "",
    region: "A",
    country: "Ghana",
    company_name: "Alpha Production",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Premium",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "primary",
      },
      {
        tag_name: "Commercial",
        text_color: "text-black",
        color: "commericalTagColor",
      },
    ],
  },
  {
    job_title: "Lifestyle Influencer Needed For Commercial",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C3LOGO,
    banner: "",
    region: "Kumasi",
    country: "Ghana",
    company_name: "Sparrows Production",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Urgent",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "tvTagColor",
      },
      {
        tag_name: "Commercial",
        text_color: "text-black",
        color: "commericalTagColor",
      },
    ],
  },
  {
    job_title: "Actors Wanted For A Television Series ",
    job_long_description:
      "Casting Money Lovers a romantic crime thriller based in Philadelphia with drama, sex, lies, deceit surrounding, and a twisting turning love story.Note: Rated R film. Lyon, Savannah, Lexi, Alisa, and Troy's characters all have impliedsex scenes in this film (kissing, robe dropping on the floor, exposure of breasts) Savannahand Lexi have one together. Do not apply for these roles if you are not willing to act in a sex scene.",
    job_short_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus, sit imperdiet ",
    thumbnail: C3LOGO,
    banner: "",
    region: "Accra",
    country: "Ghana",
    company_name: "BBA Agency",
    posted_at: "13/03/2020 9:00:00",
    applicants: [],
    tags: [
      {
        tag_name: "Premium",
        text_color: "text-black",
        color: "premiumTagColor",
      },
      {
        tag_name: "Paid",
        text_color: "text-white",
        color: "primary",
      },
      {
        tag_name: "Tv Series",
        text_color: "text-black",
        color: "tvTagColor",
      },
    ],
  },
];

const discover_posts = [
  {
    full_name: "Priscilla Agyeman",
    post: "The agony of a woman by Stella Adler",
    post_image: POST_IMG1,
    thumbnail: THUMBNAIL1,
    views: 34,
    likes: 90,
    shares: 50,
    posted_at: "13/03/2020 9:00:00",
    comments: [
      {
        full_name: "Ben Jewels",
        comment:
          "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
        reports: 4,
        posted_at: "13/03/2020 9:00:00",
        replies: [
          {
            full_name: "Ben Jewels",
            comment:
              "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
            reports: 4,
            replies: [{}],
          },
        ],
      },
    ],
  },
  {
    full_name: "Sena Love",
    post: "The beauty of a woman by Stella Adler",
    post_image: POST_IMG2,
    thumbnail: THUMBNAIL2,
    views: 34,
    likes: 90,
    shares: 50,
    posted_at: "13/03/2020 9:00:00",
    comments: [
      {
        full_name: "Ben Jewels",
        comment:
          "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
        reports: 4,
        posted_at: "13/03/2020 9:00:00",
        replies: [
          {
            full_name: "Ben Jewels",
            comment:
              "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
            reports: 4,
            replies: [{}],
          },
        ],
      },
    ],
  },
  {
    full_name: "Priscilla Agyeman",
    post: "The agony of a woman by Stella Adler",
    post_image: POST_IMG3,
    thumbnail: THUMBNAIL1,
    views: 34,
    likes: 90,
    shares: 50,
    posted_at: "13/03/2020 9:00:00",
    comments: [
      {
        full_name: "Ben Jewels",
        comment:
          "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
        reports: 4,
        posted_at: "13/03/2020 9:00:00",
        replies: [
          {
            full_name: "Ben Jewels",
            comment:
              "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
            reports: 4,
            replies: [{}],
          },
        ],
      },
    ],
  },
  {
    full_name: "Sena Love",
    post: "The agony of a woman by Stella Adler",
    post_image: POST_IMG4,
    thumbnail: THUMBNAIL2,
    views: 34,
    likes: 90,
    shares: 50,
    posted_at: "13/03/2020 9:00:00",
    comments: [
      {
        full_name: "Ben Jewels",
        comment:
          "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
        reports: 4,
        posted_at: "13/03/2020 9:00:00",
        replies: [
          {
            full_name: "Ben Jewels",
            comment:
              "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
            reports: 4,
            replies: [{}],
          },
        ],
      },
    ],
  },
  {
    full_name: "Priscilla Agyeman",
    post: "The agony of a woman by Stella Adler",
    post_image: POST_IMG5,
    thumbnail: THUMBNAIL1,
    views: 34,
    likes: 90,
    shares: 50,
    posted_at: "13/03/2020 9:00:00",
    comments: [
      {
        full_name: "Ben Jewels",
        comment:
          "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
        reports: 4,
        posted_at: "13/03/2020 9:00:00",
        replies: [
          {
            full_name: "Ben Jewels",
            comment:
              "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
            reports: 4,
            replies: [{}],
          },
        ],
      },
    ],
  },
  {
    full_name: "Sena Love",
    post: "Bitter Sweet - By Joel Asimeni",
    post_image: POST_IMG1,
    thumbnail: THUMBNAIL2,
    views: 34,
    likes: 90,
    shares: 50,
    posted_at: "13/03/2020 9:00:00",
    comments: [
      {
        full_name: "Ben Jewels",
        comment:
          "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
        reports: 4,
        posted_at: "13/03/2020 9:00:00",
        replies: [
          {
            full_name: "Ben Jewels",
            comment:
              "Pretty amazing performance, I liked how you kept building the emotions throught the scene",
            reports: 4,
            replies: [{}],
          },
        ],
      },
    ],
  },
];

const talent_nav = [
  {
    tab_name: "Photos",
    count: 6,
    component: <Photos />,
  },
  {
    tab_name: "Videos",
    count: 17,
    component: <Videos />,
  },
  {
    tab_name: "Audio",
    count: 5,
    component: <TalentAudio />,
  },
  {
    tab_name: "Resume",
    count: 1,
    component: <Resume />,
  },
  {
    tab_name: "About",
    count: "",
    component: <About />,
  },
  {
    tab_name: "Followers",
    count: 5552,
    component: <Followers />,
  },
  {
    tab_name: "Following",
    count: 23656,
    component: <Following />,
  },
  {
    tab_name: "Recommendations",
    count: 25,
    component: <Recommendations />,
  },
];

const agent_nav = [
  {
    tab_name: "Roster",
    count: 20,
    component: <Roster />,
  },
  {
    tab_name: "Jobs",
    count: 17,
    component: <Jobs />,
  },
  {
    tab_name: "Followers",
    count: 5,
    component: <AgentFollowers />,
  },
  {
    tab_name: "Following",
    count: 1,
    component: <AgentFollowing />,
  },
  {
    tab_name: "Recommendations",
    count: 25,
    component: <AgentRecommendations />,
  },
];

const creator_nav = [
  {
    tab_name: "Jobs",
    count: 17,
    component: <Jobs />,
  },
  {
    tab_name: "ShortList",
    count: 20,
    component: <Roster />,
  },
  {
    tab_name: "Followers",
    count: 5,
    component: <AgentFollowers />,
  },
  {
    tab_name: "Following",
    count: 1,
    component: <AgentFollowing />,
  },
  {
    tab_name: "Recommendations",
    count: 25,
    component: <AgentRecommendations />,
  },
];


const landing_data = [
  {
    type: "Actor",
    background: ACTOR,
    checked: false,
  },
  {
    type: "Model",
    background: MODEL,
    checked: false,
  },
  {
    type: "Dancer",
    background: DANCER,
    checked: false,
  },
  {
    type: "Influencer",
    background: INFLUENCER,
    checked: false,
  },
];

const mock_login = [
  {
    id: 1,
    username: "agent@casting.africa",
    first_name: "Kwasi",
    last_name: "Agypong",
    password: "agent",
    account_type: "AGENT",
    occupation: "Agent",
    img: TYRONNE,
    thumbnail: THUMBNAIL_IMAGE,
    first_time: true,
    code: "233",
    phonenumber: "245334422",
    gender: "MALE",
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
        zipcode: "23341",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
  },
  {
    id: 2,
    username: "creator@casting.africa",
    first_name: "Amina",
    last_name: "Yussif",
    password: "agent",
    account_type: "CREATOR",
    occupation: "Agent",
    img: TYRONNE,
    thumbnail: THUMBNAIL_IMAGE,
    first_time: true,
    code: "233",
    phonenumber: "544134230",
    gender: "FEMALE",
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
        zipcode: "23341",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
  },
  {
    id: 3,
    username: "agnes@gmail.com",
    first_name: "Agnes",
    last_name: "Ohemaa",
    password: "talent",
    account_type: "TALENT",
    talent_typ: ["Actor", "Influence"],
    address: {
      city: "Accra",
      country: "Ghana",
      zipcode: "23341",
    },
    img: TYRONNE,
    thumbnail: THUMBNAIL_IMAGE,
    code: "233",
    phonenumber: "544134230",
    gender: "FEMALE",
    verification_status: true,
  },
];

const talent_dashboard_nav = [
  {
    title: "Media",
    pathname: "/talentMedia",
    component: <TalentMedia />,
  },
  {
    title: "Personal Details",
    pathname: "/talentMedia",
    component: <UserDetails />,
  },
  {
    title: "Location Details",
    pathname: "/talentMedia",
    component: <UserDetails />,
  },
  {
    title: "Professional Details",
    pathname: "/talentMedia",
    component: <UserProfessionalDetails />,
  },
  {
    title: "Physical Attributes",
    pathname: "/talentMedia",
    component: <UserPhysicalAttributes />,
  },
  {
    title: "Job Preference",
    pathname: "/talentMedia",
    component: <UserJobPreference />,
  },
  {
    title: "Credit & Skills",
    pathname: "/talentMedia",
    component: <UserCreditAndSkills />,
  },
  {
    title: "Social Details",
    pathname: "/talentMedia",
    component: "",
  },
];

const sample_agents_directors = [
  {
    first_name: "Eric",
    company_name: "",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    img: AGENT1,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "Your Talent",
    dob: "13/04/1996",
    region: "New York",
    country: "USA",
    job_type: "Talent Agency",
    img: AGENT2,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "United Talent",
    dob: "13/04/1996",
    region: "Lagos",
    country: "Nigeria",
    job_type: "Talent Agency",
    img: AGENT3,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "Creative Arab",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    img: AGENT4,
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "Eric",
    company_name: "",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    img: AGENT1,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "Your Talent",
    dob: "13/04/1996",
    region: "New York",
    country: "USA",
    job_type: "Talent Agency",
    img: AGENT2,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "United Talent",
    dob: "13/04/1996",
    region: "Lagos",
    country: "Nigeria",
    job_type: "Talent Agency",
    img: AGENT3,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "Creative Arab",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    img: AGENT4,
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "Eric",
    company_name: "",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    img: AGENT1,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "Your Talent",
    dob: "13/04/1996",
    region: "New York",
    country: "USA",
    job_type: "Talent Agency",
    img: AGENT2,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "United Talent",
    dob: "13/04/1996",
    region: "Lagos",
    country: "Nigeria",
    job_type: "Talent Agency",
    img: AGENT3,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "Creative Arab",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    img: AGENT4,
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "Eric",
    company_name: "",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    img: AGENT1,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "Your Talent",
    dob: "13/04/1996",
    region: "New York",
    country: "USA",
    job_type: "Talent Agency",
    img: AGENT2,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "United Talent",
    dob: "13/04/1996",
    region: "Lagos",
    country: "Nigeria",
    job_type: "Talent Agency",
    img: AGENT3,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "Creative Arab",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    img: AGENT4,
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "Eric",
    company_name: "",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    img: AGENT1,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "Your Talent",
    dob: "13/04/1996",
    region: "New York",
    country: "USA",
    job_type: "Talent Agency",
    img: AGENT2,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "United Talent",
    dob: "13/04/1996",
    region: "Lagos",
    country: "Nigeria",
    job_type: "Talent Agency",
    img: AGENT3,
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C2LOGO,
      verification_status: true,
    },
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
  {
    first_name: "",
    company_name: "Creative Arab",
    dob: "13/04/1996",
    region: "Accra",
    country: "Ghana",
    job_type: "Talent Agent",
    company_details: {
      company_name: "Superstar Agency",
      address: {
        city: "Accra",
        country: "Ghana",
      },
      website: "www.superstaragency.com",
      company_logo: C1LOGO,
      verification_status: true,
    },
    img: AGENT4,
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
  },
];

// For now just use the array
const company_more_information = [
  {
    id: 1,
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
  {
    id: 2,
    roster: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    jobs: sample_jobs,
    followers: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    following: [
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
      {
        first_name: "Uche",
        dob: "13/04/1996",
        region: "Accra",
        country: "Ghana",
        talent_type: "Actor, Model & Influencer",
        img: RACHEAL,
      },
    ],
    recommendations: [
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
      {
        name: "aida baiden",
        comment:
          "I genuinely think rosemond is an amazing actress and chummy person. I got to work with her on the Suncity set and it was all fun. 👀",
        posted_at: "2021-05-05 9:30:00",
        thumbnail: USER_AVATAR,
      },
    ],
  },
];

const video_statuses = [
  {
    name: "Ama Akos",
    thumbnail: VIDEOTHUMBNAIL1,
  },
  {
    name: "Ama Akos",
    thumbnail: VIDEOTHUMBNAIL2,
  },
  {
    name: "Ama Akos",
    thumbnail: VIDEOTHUMBNAIL1,
  },
  {
    name: "Ama Akos",
    thumbnail: VIDEOTHUMBNAIL2,
  },
];


const callingCodeFlags = {
  233: {
    flag: GHANA,
    currency: "GHS",
    name: 'Ghana',
    status: true,
    pathname: '/ghana',
    callingCode: "233",
  },
  234: {
    flag: NIGERIA,
    currency: "NGN",
    name: 'Nigeria',
    status: true,
    pathname: '/nigeria',
    callingCode: "234",
  },
  27: {
    flag: SA,
    currency: "USD",
    name: 'South Africa',
    status: true,
    pathname: '/southafrica',
    callingCode: "27",
  },
  245: {
    flag: KENYA,
    currency: "USD",
    name: 'Kenya',
    status: true,
    pathname: '/kenya',
    callingCode: "245",
  },
  264: {
    flag: NAMIBIA,
    currency: "USD",
    name: 'Namibia',
    status: true,
    pathname: '/namibia',
    callingCode: "264",
  },
  256: {
    flag: UGANDA,
    currency: "USD",
    name: 'Uganda',
    status: true,
    pathname: '/uganda',
    callingCode: "256",
  },
};
const subsPlan = {
  yearly: 100,
  "bi-annually": 50,
  monthly: 20,
};

const team = [
  {
    image: KWASI,
    name: 'Kwasi Bosiako Antwi',
    position: 'CEO & Casting Lead'
  },
  {
    image: COLE,
    name: 'Cole Baidoo',
    position: 'Chief Technology Officer'
  },
  {
    image: KORI,
    name: 'Samuel Kori',
    position: 'Front End Developer'
  },
  {
    image: PHILIP,
    name: 'Philip',
    position: 'Creative Designer'
  },
  {
    image: DORCAS,
    name: 'Dorcas',
    position: 'Customer Success'
  },
  {
    image: ERIC,
    name: 'Eric Osiakwan',
    position: 'Lead Advisor'
  }
]

const heightRange = [
  "4 feet 48 in",
  "4 feet 1 in",
  "4 feet 2 in",
  "4 feet 3 in",
  "4 feet 4 in",
  "4 feet 5 in",
  "4 feet 6 in",
  "4 feet 7 in",
  "4 feet 8 in",
  "4 feet 9 in",
  "4 feet 10 in",
  "4 feet 11 in",
  "5 feet 60 in",
  "5 feet 1 in",
  "5 feet 2 in",
  "5 feet 3 in",
  "5 feet 4 in",
  "5 feet 5 in",
  "5 feet 6 in",
  "5 feet 7 in",
  "5 feet 8 in",
  "5 feet 9 in",
  "5 feet 10 in",
  "5 feet 11 in",
  "6 feet 1 in",
  "6 feet 2 in",
  "6 feet 3 in",
  "6 feet 4 in",
  "6 feet 5 in",
  "6 feet 6 in",
  "6 feet 7 in",
  "6 feet 8 in",
  "6 feet 9 in",
  "6 feet 10 in",
  "6 feet 11 in",
  "7 feet 1 in",
  "7 feet 2 in",
  "7 feet 3 in",
  "7 feet 4 in",
  "7 feet 5 in",
  "7 feet 6 in",
  "7 feet 7 in",
  "7 feet 8 in",
  "7 feet 9 in",
  "7 feet 10 in",
  "7 feet 11 in",
  "8 feet    96 in",
];

const accents = [
"African", 
"American Indian", 
"Australian", 
"Canadian", 
"Chinese", 
"Dutch", 
"French", 
"German", 
"Greek", 
"Indian", 
"Irish", 
"Italian", 
"Japanese", 
"Latin American", 
"Maori", 
"Mexican", 
"Middle Eastern", 
"New Zealand", 
"Pacific Islander", 
"Russian",
"South African", 
"Spanish",
"UK Cockney",
"UK English", 
"UK Posh", 
"UK Scottish", 
"UK Welsh", 
"USA Boston",
"USA California",
"USA New York",
"USA Southern",
];

const languages = [
  "Danish",
  "German",
  "Ewe",
  "Greek",
  "English",
  "Spanish",
  "French",
  "Xhosa",
  "Ibo",
  "Yoruba",
  "Chinese",
  "Zulu",
  "Twi",
  "Ga",
  "Hausa",
  "Fante",
  "Ewe",
  "Others"
  ];

  const faq_questions = [
    {
      question: 'What is CastingAfrica ?',
      answer: 'CastingAfrica is a  talent directory  and casting platform that connects African talents and global content creators. We have developed robust casting tools that simplifies the casting process and providing all talents with equal job oppourtunities.  '
    },
    {
      question: 'Is CastingAfrica an Agency ?',
      answer: `No, CastingAfrica is not an agency  and we dont represent talent on the platform.
      CastingAfrica is a creative freelancer marketplace that allows talent to connect directly to casting opportunities, and deal directly with casting professionals. We don't take a commission or charge a booking fee for any work that is booked via our site.`
    },
    {
      question: 'Why do I have to pay to signup?',
      answer: 'CastingAfrica is not an agency so we donot charge a commission or booking fee when you get a job via us. You only are rquired to pay for creating and managing your talent profile which you need to apply for the jobs posted on the platform.  You can also use your profile link to apply for jobs oustside of the platform, this makes you more professional and also increases your chance of scoring the casting call.  '
    },
    {
      question: 'Who can join CastingAfrica',
      answer: `African Talents
      We focus on newbie and rising stars more specifically actors, models and influencers in Africa 
      2. Talent Agents & Agencies 
     Talent agents and agencies in Africa can create and manage their talent roosters on the platform, find new talents and apply for jobs for them 
     3.  Casting professional & Content creator
     Local and global content  creators and casting professional can post and manage their casting calls on the platform. They can also browse, shortlist and book talents on the platform with just a few clicks `
    },
    {
      question: 'How does CastingAfrica work?',
      answer: 'Talents signs up and create their professional profiles which showcases their photos, videos, filmographies and biographies and then apply for jobs listings. Content creators on the other hand can post jobs,  browse our directory, shortlist and then book talents they want directly on the website.'
    },
  ] 


  const priceRange = [
    // {
    //   premium: 3,
    //   pro: 2
    // },
    {
      premium: 15,
      pro: 9
    },
    {
      premium: 30,
      pro: 18
    }
  ]

export {
  languages,
  priceRange,
  accents,
  heightRange,
  subsPlan,
  callingCodeFlags,
  navigation_content,
  footer_content,
  casting_africa_communities,
  casting_africa_partners,
  how_it_works,
  what_we_do,
  talent_professional_info,
  talent_job_preference,
  talent_physical_attributes,
  creditandskills,
  community_content,
  reviews,
  talent_filters,
  agent_filters,
  creator_filters,
  sample_talents,
  sample_ads,
  sample_jobs,
  discover_posts,
  talent_nav,
  landing_data,
  mock_login,
  company_more_information,
  agent_nav,
  creator_nav,
  sample_agents_directors,
  video_statuses,
  talent_dashboard_nav,
  team,
  faq_questions
};
