import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  callingCodeFlags,
  casting_africa_communities,
  navigation_content,
} from "../../../config/utilities";
import CASTINGLOGO from "../../../assets/images/casting_africa_logo.png";
import PackageContext from "../../../context/context";
import AuthContext from "../../../context/AuthProvider";
import { Transition } from "@tailwindui/react";
import { NotificationManager } from "react-notifications";
import PROFILE_AVATAR from "../../../assets/images/user_profile.png";
import GH_IMG from "../../../assets/images/ghana.png";
import NG_IMG from "../../../assets/images/nigeria.png";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { GET_TALENT } from "../../../graphql/queries";
import { UserAnalyticsEventTracker } from "../../Temporary";

const Navigation = ({ setPathName }) => {
  const context = useContext(PackageContext);
  const { auth } = useContext(AuthContext);
  const history = useHistory();
  const [showDropDown, setShowDropDown] = useState(true);
  const [showAgentDropDown, setShowAgentDropDown] = useState(true);
  const [user, setUser] = useState({});
  const [flags, setFlags] = useState(Object.entries(callingCodeFlags));
  const [profilePicError, setProfilePicError] = useState("")
  // const [selectedFlag, setSelectedFlag] = useState(callingCodeFlags[context.community]);
  const [showFlagsOption, setShowFlagsOption] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(
    callingCodeFlags[context.community]
  );
  const dropDownRef = useRef();
  const gaEventTracker = UserAnalyticsEventTracker("Navigation")

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("AUTH_USER"));
    setUser(user);

    const thumb = user?.user?.talent.photos?.find((photo) => photo.includes("thumbnail"));
    setProfilePicError( `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${user?.user?.talent.talent_media_path}/pictures/${thumb}`);

    history.listen((e) => {
      const obj = casting_africa_communities.filter(
        (c) => c.pathname === e.pathname
      );
      if (obj.length) {
        setSelectedFlag(callingCodeFlags[obj[0].code]);
        sessionStorage.setItem('community', callingCodeFlags[obj[0].code].name)
      }
    });
  }, []);

 

  const hoverState = () => {
    const child = dropDownRef.current;
  };

  const clickBait = (title) => {
    if (!user) {
      history.push("/login");
    }

    if (user?.account_type === "CREATOR" && title === "Post A Job") {
      history.push("/postjob");
    } else if (user?.account_type === "AGENT" && title === "Add Talent") {
      history.push("/talentSignUp", {
        signupmode: "AGENTSIGNUP",
      });
    }
  };

  return (
    <nav className="fixed w-full  bg-white z-30 top-0 pt-6 pb-6 pr-4">
      {/* small screens */}
      <div className="lg:hidden cursor-pointer  flex flex-row justify-between">
        <Link to="/">
          <img src={CASTINGLOGO} alt="Casting Africa Logo" />
        </Link>

        {showDropDown ? (
          <div className="flex justify-center items-center">
            <div className="mr-1">
              <div className="flex justify-center items-center">
                <div>
                  <img className="w-5" src={selectedFlag.flag} alt="" />
                </div>
                <span
                  onClick={() => setShowFlagsOption(!showFlagsOption)}
                  className="cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </div>
              {showFlagsOption && (
                <div className="absolute">
                   {flags.map(([flagkey, flagvalue], key) => (
                     flagvalue.status &&
                    <img
                      className="w-5 mb-1 cursor-pointer"
                      onClick={() => {
                        setSelectedFlag(flagvalue);
                        history.replace(flagvalue.pathname)
                        setShowFlagsOption(false);
                      }}
                      key={key}
                      src={flagvalue.flag}
                      alt={flagvalue.name}
                    />
                  ))}
                </div>
              )}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 lg:hidden cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={() => setShowDropDown(false)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <div className="mr-1">
              <div className="flex justify-center items-center">
                <div>
                  <img className="w-5" src={selectedFlag.flag} alt="" />
                </div>
                <span
                  onClick={() => setShowFlagsOption(!showFlagsOption)}
                  className="cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </div>
              {showFlagsOption && (
                <div className="absolute">
                   {flags.map(([flagkey, flagvalue], key) => (
                     flagvalue.status &&
                    <img
                      className="w-5 mb-1 cursor-pointer"
                      onClick={() => {
                        setSelectedFlag(flagvalue);
                        history.replace(flagvalue.pathname)
                        setShowFlagsOption(false);
                      }}
                      key={key}
                      src={flagvalue.flag}
                      alt={flagvalue.name}
                    />
                  ))}
                </div>
              )}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 transition cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={() => setShowDropDown(true)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        )}
      </div>

      <div className="hidden lg:flex lg:flex-row lg:items-center lg:justify-around pr-8">
        <div className="cursor-pointer">
          <Link to="/">
            <img src={CASTINGLOGO} alt="Casting Africa Logo" />
          </Link>
        </div>
        <ul className="invisible lg:visible  flex flex-row justify-center w-1/3">
          {navigation_content.map((nav, index) => (
            <li
              ref={dropDownRef}
              onMouseEnter={hoverState}
              className="w-full group flex flex-col items-center justify-center font-bold text-secondary text-sm hover:text-primary"
              key={index}
            >
              {nav.sub_menu.length ? (
                <span className="cursor-pointer">{nav.menu_title}</span>
              ) : (
                <Link
                  onClick={() => context.updatePath(nav.pathname)}
                  to={nav.pathname}
                >
                  {nav.menu_title}
                </Link>
              )}

              <span className="border-2 border-white rounded  group-hover:border-primary "></span>

              {nav.sub_menu.length ? (
                <div className="opacity-0  group-hover:opacity-100 bg-primary transition ease-in duration-500 shadow-md absolute top-16">
                  <ul className="flex flex-col h-full w-full  ">
                    {nav.sub_menu.map((sub_menu, key) => (
                      <li
                        key={key}
                        className="w-full px-4 py-1 text-white hover:bg-gray-100 hover:text-primary h-6 flex items-center justify-start font-bold"
                      >
                        {["Post A Job", "Add Talent"].includes(
                          sub_menu.menu_title
                        ) ? (
                          <span
                            onClick={() => clickBait(sub_menu.menu_title)}
                            className="cursor-pointer"
                          >
                            {sub_menu.menu_title}
                          </span>
                        ) : (
                          <Link to={sub_menu.pathname}>
                            {sub_menu.menu_title}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </li>
          ))}
        </ul>
        {!_.isEmpty(user) ? (
          <div className="flex justify-center items-center ">
            <div className="mr-3">
              <div className="flex justify-center items-center">
                <div>
                  <img className="w-5" src={selectedFlag.flag} alt="" />
                </div>
                <span
                  onClick={() => setShowFlagsOption(!showFlagsOption)}
                  className="cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </div>
              {showFlagsOption && (
                <div className="absolute">
                  {flags.map(([flagkey, flagvalue], key) => (
                    flagvalue.status &&
                    <img
                      className="w-5 mb-1 cursor-pointer"
                      onClick={() => {
                        setSelectedFlag(flagvalue);
                        history.replace(flagvalue.pathname)
                        setShowFlagsOption(false);
                      }}
                      key={key}
                      src={flagvalue.flag}
                      alt={flagvalue.name}
                    />
                  ))}
                </div>
              )}
            </div>
            <LoggedInView
              user={user}
              setShowDropDown={setShowDropDown}
              setShowAgentDropDown={setShowAgentDropDown}
              showAgentDropDown={showAgentDropDown}
              profilePicError={profilePicError}
            />
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <div className="mr-3">
              <div className="flex justify-center items-center">
                <div>
                  <img className="w-5" src={selectedFlag.flag} alt="" />
                </div>
                <span
                  onClick={() => setShowFlagsOption(!showFlagsOption)}
                  className="cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </div>
              {showFlagsOption && (
                <div className="absolute">
                  {flags.map(([flagkey, flagvalue], key) => (
                    flagvalue.status &&
                    <img
                      className="w-5 mb-1 cursor-pointer"
                      onClick={() => {
                        setSelectedFlag(flagvalue);
                        history.replace(flagvalue.pathname)
                        setShowFlagsOption(false);
                      }}
                      key={key}
                      src={flagvalue.flag}
                      alt={flagvalue.name}
                    />
                  ))}
                </div>
              )}
            </div>
            <Link
              onClick={() => {
                context.updatePath("/login")
                gaEventTracker("Login", "Login Button action", "login")
              }}
              to="/login"
              className="invisible lg:visible font-semibold text-gray-400 text-sm"
            >
              Log In
            </Link>
            <Link
              onClick={() => {
                context.updatePath("/signup")
                gaEventTracker("Join Now", "Join now Button action", "Join Now")
              }
              }
              to="/signup"
              className="shadow-xl invisible lg:visible btn ml-5 "
            >
              Join Now
            </Link>
          </div>
        )}
      </div>
      <Transition
        show={!showAgentDropDown}
        enter="transition-opacity duration-700"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-700"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AgentDropDown
          user={user}
          setShowDropDown={setShowDropDown}
          showAgentDropDown={showAgentDropDown}
          setShowAgentDropDown={setShowAgentDropDown}
        />
      </Transition>

      {/* small screen */}
      {
        <Transition
          show={!showDropDown}
          enter="transition-opacity duration-700"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-700"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute shadow-2xl  w-full px-3 flex flex-col items-center transition ease-in-out duration-700">
            {navigation_content.map((nav, index) => (
              <SmallNavItem
                key={index}
                navContent={nav}
                setShowDropDown={setShowDropDown}
              />
            ))}

            {user ? (
              <div className="bg-white w-full py-4">
                <LoggedInView
                  user={user}
                  setShowDropDown={setShowDropDown}
                  setShowAgentDropDown={setShowAgentDropDown}
                  showAgentDropDown={showAgentDropDown}
                  profilePicError={profilePicError}
                />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full bg-white border-b-2 h-16 border-gray-200 group flex flex-col items-center justify-center font-light text-gray-400 text-lg hover:text-primary">
                  <Link
                    onClick={() => {
                      setShowDropDown(true);
                      context.updatePath("/login");
                    }}
                    to="/login"
                    className="font-light text-lg text-blue-500"
                  >
                    Log In
                  </Link>
                </div>
                <div className="w-full mb-0  bg-primary   border-b-2 h-16 border-primary group flex flex-col items-center justify-center font-light text-gray-400 text-lg hover:text-primary">
                  <Link
                    onClick={() => {
                      setShowDropDown(true);
                      context.updatePath("/signup");
                    }}
                    to="/signup"
                    className="text-lg font-light w-28 text-center text-white"
                  >
                    Join Now
                  </Link>
                </div>
              </div>
            )}
          </div>
        </Transition>
      }
    </nav>
  );
};

const LoggedInView = ({
  user,
  setShowDropDown,
  setShowAgentDropDown,
  showAgentDropDown,
  profilePicError
}) => {
  const history = useHistory();

  const handleAccountView = () => {
    setShowDropDown(true);
    setShowAgentDropDown(!showAgentDropDown);
  };

  return (
    <div className="cursor-pointer group-hover flex justify-center items-center">
      <img
        style={{
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          objectFit: "contain",
        }}
        onClick={handleAccountView}
        className="w-10 h-10 rounded-full mr-2"
        src={profilePicError}
        onError={(e) => (e.target.src = PROFILE_AVATAR)}
      />
      <h1
        onClick={handleAccountView}
        className="hover:text-primary text-gray-500"
      >
        My Account
      </h1>
    </div>
  );
};

const AgentDropDown = ({
  user,
  setShowDropDown,
  showAgentDropDown,
  setShowAgentDropDown,
}) => {
  const history = useHistory();
  // const {loading, error, data } = useQuery(GET_TALENT, {
  //   variables: {
  //     talentID: user?.data?.login?.user?.talent?.casting_africa_talent_id
  //   },
  // });

  const handleViewProfile = () => {
    setShowAgentDropDown(!showAgentDropDown);
    history.push("/talentProfile", {
      talent_type: user?.account_type,
      talent_id: user?.user?.talent?.casting_africa_talent_id,
    });
    // Add check to show the agent profile view
  };

  const handleLogoutSignOut = () => {
    setShowDropDown(true);
    sessionStorage.removeItem("AUTH_USER");
    history.push("/");
    window.location.reload();
  };
  return (
    <div className="flex w-full lg:w-44 absolute lg:right-28  flex-col border py-2 bg-primary text-white">
      <span
        onClick={handleViewProfile}
        className="cursor-pointer hover:bg-white hover:text-blue-500 w-full px-2"
      >
        View Profile
      </span>
      {user?.account_type === "AGENT" ? (
        <Link
          onClick={() => setShowAgentDropDown(!showAgentDropDown)}
          to="/manageRooster"
          className="cursor-pointer hover:bg-white hover:text-blue-500 w-full px-2"
        >
          Edit Roster
        </Link>
      ) : (
        ""
      )}
      {user?.account_type === "CREATOR" ? (
        <div className="flex flex-col">
          <Link
            onClick={() => setShowAgentDropDown(!showAgentDropDown)}
            to="/postjob"
            className="cursor-pointer hover:bg-white hover:text-blue-500 w-full px-2"
          >
            Post A Job
          </Link>
          <Link
            onClick={() => setShowAgentDropDown(!showAgentDropDown)}
            to="/managejobs"
            className="cursor-pointer hover:bg-white hover:text-blue-500 w-full px-2"
          >
            Manage Jobs
          </Link>
        </div>
      ) : (
        ""
      )}
      {/* <Link
        onClick={() => setShowAgentDropDown(!showAgentDropDown)}
        to="/talentJobs"
        className="cursor-pointer hover:bg-white hover:text-blue-500 w-full px-2"
      >
        Job Update
      </Link> */}
      <Link
        onClick={() => setShowAgentDropDown(!showAgentDropDown)}
        to="/changePassword"
        className="cursor-pointer hover:bg-white hover:text-blue-500 w-full px-2"
      >
        Change Password
      </Link>
      {user?.account_type === "TALENT" && (
        <Link
          onClick={() => setShowAgentDropDown(!showAgentDropDown)}
          to="/billing"
          className="cursor-pointer hover:bg-white hover:text-blue-500 w-full px-2"
        >
          My Courses
        </Link>
      )}
      <Link
        onClick={() => setShowAgentDropDown(!showAgentDropDown)}
        to="/billing"
        className="cursor-pointer hover:bg-white hover:text-blue-500 w-full px-2"
      >
        Billing
      </Link> 
      <span
        className="cursor-pointer w-full px-2"
        onClick={handleLogoutSignOut}
      >
        Logout
      </span>
    </div>
  );
};

const SmallNavItem = ({ navContent, setShowDropDown }) => {
  const [isActive, setIsActive] = useState(false);
  const context = useContext(PackageContext);

  const show = isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-red-500"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 15l7-7 7 7"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-blue-500"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );

  return (
    <div className="w-full bg-white border-b">
      <div className="h-16 flex flex-row justify-center items-center">
        <Link
          className="text-xl text-blue-500 font-light "
          to={navContent.pathname}
          onClick={() => {
            setShowDropDown(true);
          }}
        >
          {navContent.menu_title}
        </Link>
        <span
          className="absolute right-0 mr-9"
          onClick={() => setIsActive(!isActive)}
        >
          {navContent.sub_menu.length ? show : ""}
        </span>
      </div>

      <Transition
        show={isActive}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ul className=" bg-primary border-t flex flex-col justify-center items-center mb-2 w-full">
          {navContent.sub_menu.length &&
            navContent.sub_menu.map((content, key) => (
              <li
                className="w-full flex justify-center items-center text-lg text-white font-bold h-10 py-1"
                key={key}
              >
                <Link
                  onClick={() => {
                    setShowDropDown(true);
                    context.updatePath(content.pathname);
                  }}
                  to={content.pathname}
                >
                  {content.menu_title}
                </Link>
              </li>
            ))}
        </ul>
      </Transition>
    </div>
  );
};

export default Navigation;