import React, { Fragment, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {  useMutation } from '@apollo/client'
import { ON_BOARD_CREATOR_AGENT } from "../../../../graphql/mutations";

const AgentJobDetails = ({
  next,
  prev,
  professionalForm,
  setProfessionalForm,
  type
}) => {

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [onBoardingCreator, { data, loading, error }] = useMutation(
    ON_BOARD_CREATOR_AGENT
  );

    const completeRef = useRef();


    const handleSubmitToNextPage = (data) => {
      const info = { ...data };
      for (let key in info) {
        professionalForm[type][key] = info[key];
      }
      professionalForm['details']["job_title"] = info['job_title'];


      delete professionalForm[type]["roster_size"];
      delete professionalForm[type]["job_title"];
      
      setProfessionalForm((p) => ({ ...p, ...professionalForm }));
      onBoardingCreator({
        variables: { ...professionalForm },
      });

      if (error) return;

      if (loading) {
        completeRef.current.innerText = "Loading...";
      }
      next();
    };

  return (
    <Fragment>
      <div className="shadow-2xl pb-24 bg-white rounded-2xl md:rounded-3xl w-full md:w-9/12 lg:w-5/12  ">
        {/* Header */}
        <div className="mb-6 h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
          <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
            Professional Info.
          </h1>
          <p className=" w-5/6  md:w-4/6 text-center text-xs md:text-sm mb-4 text-formFont font-gilroy-Light">
            Please tell us what you do
          </p>
        </div>

        {/* Form Body */}

        <div className="px-6 md:px-14">
          <div className="flex-1 px-1 py-1 mb-3 flex flex-col">
            <label
              htmlFor="Job Title"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Job Title
            </label>
            <input
              className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your Job Title"
              defaultValue={professionalForm.details.job_title}
              {...register("job_title", {
                required: "Job title required",
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.job_title?.message}
            </p>
          </div>
        </div>

        <div className="px-6 md:px-14">
          <div className="flex-1 px-1 py-1 mb-3 flex flex-col">
            <label
              htmlFor="Company Name"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Company Name
            </label>
            <input
              className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your Company Name"
              defaultValue={professionalForm.companyDetails.job_title}
              {...register("company_name", {
                required: "Company name required",
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.company_name?.message}
            </p>
          </div>
        </div>

        <div className="px-6 md:px-14">
          <div className="flex-1 px-1 py-1 mb-3 flex flex-col">
            <label
              htmlFor="Company Location"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Company Location
            </label>
            <input
              className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your Company Location"
              defaultValue={professionalForm.companyDetails.job_title}
              {...register("company_location", {
                required: "Company location required",
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors.company_location?.message}
            </p>
          </div>
        </div>

        <div className="px-6 md:px-14">
          <div className="flex-1 px-1 py-1 mb-3 flex flex-col">
            <label
              htmlFor="Company Website"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Company Website
            </label>
            <input
              className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="url"
              placeholder="Your Company Website"
              defaultValue={professionalForm.companyDetails.job_title}
              {...register("company_website", {
                required: false,
              })}
            />
          </div>
        </div>

        <div className="px-6 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Union/Association"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Union/Association
            </label>
            <input
              className="border rounded-md border-gray-300 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your Union/Association"
              defaultValue={professionalForm.details.job_title}
              {...register("union_association", {
                required: false,
              })}
            />
          </div>
        </div>

        <div className="px-14 mt-11 flex  flex-row justify-center items-center">
          <button
            onClick={() => prev()}
            className="bg-primary opacity-60  hover:opacity-100 tranition ease-out duration-700 m-2 flex items-center justify-center font-bold text-white rounded-full py-2 px-14 "
          >
            Back
          </button>

          <button
            onClick={handleSubmit(handleSubmitToNextPage)}
            className="bg-primary opacity-60  hover:opacity-100 tranition ease-out duration-700 m-2 flex items-center justify-center font-bold text-white rounded-full py-2  px-14"
          >
            Complete
          </button>
        </div>

        {/* End of form body */}
      </div>
    </Fragment>
  );
};

export default AgentJobDetails;
