import React, { useContext, useEffect, useState } from "react";
import ProfileFooter from "../profileFooter";
import PackageContext from "../../../../context/context";
import { company_more_information } from "../../../../config/utilities";
import TalentItemCard from "../../Communities/Comps/talentItemCard";
import { Pagination } from "../../Pagination";
import { Recommendationitem } from "../../Communities/Comps";

const Recommendations = () => {
  const context = useContext(PackageContext);
  const [companyDetails, setCompanyDetails] = useState({});
  const [setNext, setSetNext] = useState(8);
  let pagesize = setNext;

  useEffect(() => {
    setCompanyDetails(context.companyDetails);
  }, []);

  return (
    <div className=" flex flex-col">
      <div className="flex flex-col justify-center items-center">
        {companyDetails &&
          companyDetails?.recommendations?.map((recommend, key) => (
            <Recommendationitem recommend={recommend} />
          ))}
      </div>
      <Pagination
        data={companyDetails?.recommendations || []}
        pagesize={8}
        nextSet={setSetNext}
      />
      <ProfileFooter count={6000} />
    </div>
  );
};

export default Recommendations;
