import React from "react";
import { useHistory } from "react-router-dom";

const AdsBanner = ({ color, ads_image }) => {
    const history = useHistory();

    const linkToMonologue = () => {
        history.replace('/monologue');
    }
    return(
        <div onClick={() => linkToMonologue()}  className={`w-full ${color} flex flex-col cursor-pointer items-center py-4 justify-center`}>
            <div
                style={{
                backgroundImage: `url(${ads_image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                }}
                className="w-full lg:bg-70% bg-100%  h-32">
            </div>
        </div>
    )
}

export default AdsBanner;