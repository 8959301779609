import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { countries, phonecodes } from "../../../../config/countries";
import { callingCodeFlags } from "../../../../config/utilities";
import { UPDATE_TALENT } from "../../../../graphql/mutations";

const UserLocation = ({
  next,
  prev,
  handleFormChange,
  setTalentForm,
  talentForm,
  signupmode,
  type,
  step,
  setStep,
}) => {
  const [country, setCountry] = useState(
    talentForm.talentAddress?.country || "Ghana"
  );
  const [phonecode, setPhonecode] = useState(
    talentForm.talentDetails.calling_code || "233"
  );
  const [flabOjs] = useState(Object.values(callingCodeFlags));
  const [flag, setFlag] = useState(
    flabOjs.find((fl) => fl.name === country).flag
  );

  const [citySelected, setCitySelected] = useState("");

  // Manage from global state
  const [logged] = useState(sessionStorage.getItem("AUTH_USER"));

  const [
    updateTalent,
    { data: talent_data, loading: talent_loading, error: talent_error },
  ] = useMutation(UPDATE_TALENT);

  const [talents, setTalents] = useState(
    talentForm?.castingAfricaTalentDetails?.talent_type
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const unsetvalue = (container, setContainer, value) => {
    if (container.includes(value)) {
      const temp = [...container];
      temp.splice(temp.indexOf(value), 1);
      setContainer(temp);
    }
  };

  // useEffect(() => {
  //   if (talentForm.talentType) {
  //     setTalents(talentForm.talentType);
  //   }

  //   if (talentForm.code) {
  //     setFlag(callingCodeFlags[talentForm.code].flag);
  //   }
  // }, []);

  const handleSubmitToNextPage = (data) => {
    if (talents.length === 0) return;

    const info = { ...data };
    for (let key in info) {
      talentForm[type][key] = info[key];
    }

    talentForm["talentAddress"]["country"] = country;
    talentForm["castingAfricaTalentDetails"]["talent_type"] = talents;

    talentForm["talentDetails"]["calling_code"] = phonecode;
    talentForm["talentDetails"]["talent_phonenumbers"] =
      info["talent_phonenumbers"];
    delete talentForm["talentAddress"]["talent_phonenumbers"];

    delete talentForm[type]["talent_type"];
    delete talentForm[type]["region_or_state"];
    delete talentForm[type]["__typename"];
    setTalentForm((p) => ({ ...p, ...talentForm }));
    if (step && signupmode === "TALENTEDIT") {
      setStep(step + 1);
    } else {
      next();
    }
  };

  // Refactor
  const handleSubmitToUpdate = (data) => {
    const updateInfo = {
      ...data,
    };
    let temp = talentForm;
    temp['castingAfricaTalentDetails']['talent_type'] = talents
    temp['talentDetails']['talent_phonenumbers'] = updateInfo['talent_phonenumbers']

    let tempDetails = new Object();
    Object.entries(updateInfo).forEach(([key, value]) => {
        tempDetails[key] = value;
    });

    const socials = Object.entries(temp["talentSocials"]);
    let social_asm = new Object();
    socials.forEach(([key, value]) => {
      social_asm[value["social_media_type"].toLowerCase()] =
        value["social_media_handle"];
    });

    temp["talentAddress"] = tempDetails;
    temp["castingAfricaTalentDetails"] = JSON.stringify(
      talentForm["castingAfricaTalentDetails"]
    );
    temp["talentSocials"] = social_asm;
    delete temp["photos"];
    delete temp["resume"];
    delete temp["videos"];
    delete temp["talentAddress"]["talent_phonenumbers"];

    console.log("temp ", temp);

    updateTalent({
      variables: {
        talentID: JSON.parse(logged)?.user?.talent?.casting_africa_talent_id,
        ...temp,
      },
    })
      .then((data) => {
        NotificationManager.success("Updated successfully");
        window.location.reload();
      })
      .catch((err) => {
        NotificationManager.warning("Profile update failed");
      });
  };


  useEffect(() => {
    console.log(talentForm);
  }, []);

  const proceed = (e) => {
    setTalentForm((pre) => ({
      ...pre,
      ["talentType"]: talents,
      ["code"]: phonecode,
      ["country"]: country,
    }));
    next();
  };

  const retract = (e) => {
    setTalentForm((pre) => ({
      ...pre,
      ["talentType"]: talents,
    }));
    prev();
  };



  useEffect(() => {
    const fls = flabOjs.find((fl) => fl.name === country);
    setFlag(fls.flag);
    setPhonecode(fls.callingCode);
  }, [country]);

  return (
    <div
      className={`md:rounded-3xl w-full pb-20 ${
        signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT"
          ? "md:w-9/12 lg:w-5/12 shadow-2xl  bg-white rounded-2xl"
          : ""
      }`}
    >
      {/* Header */}
      {signupmode === "AGENTSIGNUP" ||
        (signupmode !== "TALENTEDIT" && (
          <div className=" invisible mb-6  h-24 rounded-3xl flex justify-center items-center bg-header ">
            <h1>Header</h1>
          </div>
        ))}

      {/* Form Body */}
      <div className="flex flex-row px-6 md:px-14">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="First Name"
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Talent Type
          </label>
          <div className="flex flex-nowrap  lg:flex-wrap m-0 p-0">
            {["Actor", "Model", "Dancer", "Influencer"].map((talent, key) => (
              <div
                key={key}
                onClick={() => {
                  setTalents([...talents, talent]);
                  unsetvalue(talents, setTalents, talent);
                }}
                className={` ${
                  talents?.includes(talent)
                    ? "bg-primary text-white"
                    : "text-formFont"
                } bg-white shadow-lg cursor-pointer border hover:bg-primary hover:text-white   font-gilroy-Light lg:px-4 px-2  py-1  rounded-3xl border-primary mr-2  mb-1`}
              >
                {talent}
              </div>
            ))}
          </div>
          <p className=" font-gilroy-Light text-red-600 ">
            {talents?.length === 0 && "Select talent type"}
          </p>
        </div>
      </div>

      <div className="px-6 md:px-14">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="Country"
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Country
          </label>
          <select
            className="border rounded-md border-gray-300  font-gilroy-Light focus:outline-none px-2 py-3 "
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
              // setFlag(flabOjs.find(obj => obj.name === e.target.name).name);
              // setPhonecode(e.target.value);
            }}
          >
            {Object.keys(countries).map((count, key) => (
              <option key={key} value={count}>
                {count}
              </option>
            ))}
          </select>
          {/* <p className=" font-gilroy-Light text-red-600 ">
            {errors.country?.message}
          </p> */}
        </div>
      </div>

      <div className="px-6 md:px-14">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="Town/City"
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Town/City
          </label>
          <select
            className="border rounded-md border-gray-300  font-gilroy-Light focus:outline-none px-2 py-3 "
            defaultValue={talentForm.talentAddress.city}
            // onChange={(e) => setCitySelected(e.target.value)}
            {...register("city", {
              required: "State required",
            })}
          >
            <option value="">Select City</option>
            {countries[country].map((state, key) => (
              <option key={key} value={state}>
                {state}
              </option>
            ))}
          </select>
          <p className=" font-gilroy-Light text-red-600 ">
            {errors.city?.message}
          </p>
        </div>
      </div>

      <div className="px-6 md:px-14">
        <div className="flex-1 px-1 py-1  flex flex-col">
          <label
            htmlFor="Zip Code"
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Zip Code
          </label>
          <input
            onChange={handleFormChange("zipcode")}
            className="border rounded-md border-gray-300  font-gilroy-Light focus:outline-none px-2 py-3 "
            type="text"
            placeholder="Your Zip Code"
            defaultValue={talentForm.talentAddress.zip_code}
            {...register("zip_code")}
          />
        </div>
      </div>

      <div className="flex justify-center items-center flex-row ">
        <div className="flex flex-col w-full px-6 md:px-14">
          <label
            htmlFor="Phone number"
            className=" mb-3  font-gilroy-Light text-sm md:text-lg  text-formFont"
          >
            Phone number
          </label>
          <div className="flex">
            <div className="py-2 md:py-3 border-r-0 rounded-tr-none rounded-br-none rounded-lg pl-2 pr-4 flex flex-row items-center justify-between border border-gray-300 font-thin ">
              <img
                className="w-7 lg:w-10 h-7 lg:h-6"
                src={flag}
                alt="Ghana Flag"
              />
              <div
                className="w-full flex justify-center md:mr-4 font-gilroy-Light  focus:outline-none md:px-1"
                name=""
                id=""
              >
                {talentForm.talentDetails.calling_code || phonecode}
              </div>
            </div>
            <input
              onChange={handleFormChange("phonenumber")}
              className="border placeholder-secondary font-gilroy-Light w-full rounded-tl-none rounded-bl-none  rounded-md border-gray-300 font-thin focus:outline-none px-2 py-3 md:py-3 "
              type="tel"
              placeholder=""
              defaultValue={talentForm.talentDetails.talent_phonenumbers}
              {...register("talent_phonenumbers", {
                required: "Phone number is required",
                min: {
                  value: 9,
                  message: "Phone number must be at least 9",
                },
              })}
            />
          </div>
          <p className=" font-gilroy-Light text-red-600 ">
            {errors.talent_phonenumbers?.message}
          </p>
        </div>
      </div>

      {["AGENTSIGNUP", "TALENTEDIT", "TALENTSIGNUP"].includes(signupmode) ? (
        <div className="px-14 mt-20 flex  flex-row justify-center items-center">
          {!logged ? (
            <div className="w-full flex  flex-row justify-center items-center">
              <button
                onClick={() => retract()}
                className="bg-primary opacity-60  hover:opacity-100 tranition ease-out duration-700 m-2 flex items-center justify-center font-bold text-white rounded-full py-3 px-12 lg:px-16 "
              >
                Back
              </button>

              <button
                onClick={handleSubmit(handleSubmitToNextPage)}
                className="bg-primary opacity-60  hover:opacity-100 tranition ease-out duration-700 m-2 flex items-center justify-center font-bold text-white rounded-full py-3  px-12 lg:px-16 "
              >
                Next
              </button>
            </div>
          ) : (
            <button
              onClick={handleSubmit(handleSubmitToUpdate)}
              className=" shadow-2xl  bg-primary opacity-60 hover:opacity-100 tranition ease-out duration-700 m-4 md:m-2 flex items-center justify-center font-bold text-white rounded-full py-4  px-28 "
            >
              {talent_loading ? "Please wait.." : "Update"}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {/* End of form body */}
    </div>
  );
};

export default UserLocation;
