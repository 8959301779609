import React from "react";
import TalentItemCard from "./talentItemCard";
import talentItemCard from "./talentItemCard";
import { community_content } from "../../../../config/utilities";
import { useQuery } from "@apollo/client";
import { SPOTLIGHT_PICKS } from "../../../../graphql/queries";

const Spotlight = ({color}) => {
  const { loading, error, data } = useQuery(SPOTLIGHT_PICKS, {
    variables: {
      country: sessionStorage.getItem("community") || "Ghana",
    },
  });

  return (
    <div className="py-5 lg:py-10 flex justify-start items-center flex-col">
      <h1 className="text-3xl mb-10 flex ">
        Spotlights{" "}
        <span className={` px-5 ml-2 ${color} text-white rounded-xl flex justify-center items-center `}>
          Pick
        </span>
      </h1>
      <div className="lg:w-full flex justify-center flex-wrap">
        {data?.spotLightPicks?.length ? (
          data?.spotLightPicks.map((talent, key) => (
            <TalentItemCard
              key={key}
              width="w-72"
              talent={talent}
              feature_type="talent_feature"
            />
          ))
        ) : (
          <div>
            <h1 className="text-lg text-primary">Finding spotlights ...</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Spotlight;
