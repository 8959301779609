import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TalentItemCard from "../../../Communities/Comps/talentItemCard";
import { Pagination } from "../../../Pagination";

const JobApplicants = () => {
  const history = useHistory();
  const location = useLocation();
  const { selectedJob } = location?.state || {};
  const [setNext, setSetNext] = useState(10);
  let pagesize = 10;

  const handleBack = () => {
    // history.goBack();
    history.push("/managejobs");
  };

  const handleJobEdit = () => {
    history.push("/postJob", {
      mode: "Edit",
    });
  };

  useEffect(() => {
    if (!selectedJob) {
      history.goBack();
    }
  }, []);

  return (
    <div className="w-full mt-24 flex items-center overflow-scroll flex-col py-12 h-full">
      <div className="w-full lg:w-4/6 mb-3">
        <svg
          onClick={handleBack}
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10 19l-7-7m0 0l7-7m-7 7h18"
          />
        </svg>
      </div>
      <div className=" px-2 w-full lg:w-4/6 py-2 flex lg:flex-row flex-col items-center lg:justify-between">
        <div>
          <h1 className="lg:text-4xl text-2xl lg:mb-0 mb-6">{selectedJob?.job_title}</h1>
          <div className="flex lg:flex-row flex-col lg:mb-2 mb-3">
            <div className="lg:mb-1 mb-2">
              {selectedJob?.tags.map((tag, key) => (
                <span
                  key={key}
                  className={`bg-${tag.color} ${tag.text_color} text-sm font-gilroy-Light px-2 py-1 rounded-tr-lg rounded-bl-lg mr-1 `}
                >
                  {tag.tag_name}
                </span>
              ))}
            </div>
            <h1 className="font-gilroy-Light">Expires: 17th November 2022</h1>
          </div>
        </div>

        <button className="border flex justify-center items-center hover:bg-blue-700 bg-primary w-32 h-12 rounded-xl">
          <span className="text-2xl font-gilroy-Light mr-2 text-white">
            Share
          </span>
          <svg
            className="w-5 h-5"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.1479 0.866113C18.7658 0.476675 18.2813 0.203331 17.7504 0.0776877C17.2195 -0.0479556 16.6639 -0.0207656 16.1479 0.156114L1.99785 4.88611C1.42717 5.07291 0.92854 5.43226 0.570805 5.91455C0.21307 6.39684 0.013868 6.97828 0.0006979 7.57862C-0.0124722 8.17895 0.161039 8.76857 0.49728 9.26608C0.83352 9.7636 1.31591 10.1445 1.87785 10.3561L7.11785 12.3561C7.23717 12.4017 7.34581 12.4715 7.437 12.5609C7.52818 12.6504 7.59996 12.7577 7.64785 12.8761L9.64785 18.1261C9.85144 18.6801 10.2209 19.1579 10.7058 19.4943C11.1908 19.8307 11.7676 20.0094 12.3579 20.0061H12.4279C13.0289 19.9952 13.6114 19.7964 14.0938 19.4378C14.5762 19.0791 14.9343 18.5785 15.1179 18.0061L19.8479 3.83611C20.0199 3.32505 20.0455 2.77605 19.9218 2.2512C19.7981 1.72634 19.53 1.24656 19.1479 0.866113ZM17.9979 3.20611L13.2179 17.3861C13.1623 17.5656 13.0509 17.7226 12.8997 17.8341C12.7486 17.9457 12.5657 18.006 12.3779 18.0061C12.1911 18.0092 12.0078 17.9553 11.8524 17.8517C11.697 17.7481 11.5769 17.5997 11.5079 17.4261L9.50785 12.1761C9.36286 11.7946 9.13924 11.4479 8.85153 11.1585C8.56382 10.8691 8.21845 10.6434 7.83785 10.4961L2.58785 8.49611C2.41074 8.43122 2.2585 8.31231 2.15264 8.1562C2.04677 8.00009 1.99262 7.81466 1.99785 7.62611C1.998 7.43826 2.05827 7.25538 2.16983 7.10424C2.28138 6.95309 2.43838 6.84162 2.61785 6.78611L16.7979 2.05611C16.9607 1.98976 17.1392 1.97199 17.3119 2.00494C17.4846 2.03789 17.644 2.12016 17.7709 2.2418C17.8979 2.36344 17.9868 2.51924 18.0271 2.69037C18.0674 2.86151 18.0572 3.04064 17.9979 3.20611Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div className="flex px-2 lg:flex-row flex-col justify-between items-center  w-full lg:w-4/6">
        <div className="flex lg:flex-row flex-col w-full justify-between lg:w-4/6  mt-4 mb-4">
          <div className="flex lg:mb-0 mb-2 shadow-sm items-center border w-full rounded-3xl mr-2">
            <input
              className="w-full px-3  py-2 rounded-tl-3xl outline-none font-gilroy-Light rounded-bl-3xl"
              type="text"
              placeholder="Search Job Applicants"
            />
            <span className="w-20 cursor-pointer border-primary bg-primary py-2 flex justify-center rounded-tr-3xl rounded-br-3xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#FFF"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </span>
          </div>
          <select
            className="lg:w-44 rounded-3xl px-3 font-gilroy-Light "
            name=""
            id=""
          >
            <option value="">Filter by</option>
            <option value="Recently Applied">Recently Applied</option>
            <option value="Verified">Verified</option>
          </select>
        </div>
        <div className="mb-2">
          <button className="rounded-full hover:bg-blue-700 bg-primary text-white px-2 w-36 py-2 font-gilroy-Light mr-2">
            Invite Talent
          </button>
          <button
            onClick={handleJobEdit}
            className="rounded-full hover:bg-blue-700 bg-primary text-white px-2 w-36 py-2 font-gilroy-Light"
          >
            Edit Job
          </button>
        </div>
      </div>
      {/* Short List content */}
      <div
        className={`flex w-4/6 ${
          !selectedJob?.applicants?.length ? "justify-center" : ""
        } flex-wrap`}
      >
        {selectedJob?.applicants?.length ? (
          selectedJob?.applicants
            ?.slice(setNext - pagesize, setNext)
            .map((talent, key) => <TalentItemCard talent={talent} key={key} feature_type="applicant_feature" />)
        ) : (
          <div className="h-96 flex justify-center items-center">
            <h1 className="font-gilroy-Light text-3xl text-center">
              No Applicants found... <br /> Check back later!!
            </h1>
          </div>
        )}
      </div>
      {selectedJob?.applicants?.length ? (
        <Pagination
          data={selectedJob?.applicants}
          pagesize={10}
          nextSet={setSetNext}
        />
      ) : (
        ""
      )}

      <div>
        <button className="rounded-full hover:bg-blue-700 bg-primary text-white px-2 w-36 py-2 font-gilroy-Light mr-2">
          Invite Talent
        </button>
        <button
          onClick={handleJobEdit}
          className="rounded-full hover:bg-blue-700 bg-primary text-white px-2 w-36 py-2 font-gilroy-Light"
        >
          Edit Job
        </button>
      </div>
    </div>
  );
};

export default JobApplicants;
