import React from "react";
import { footer_content } from "../../../config/utilities";
import { Link } from "react-router-dom";
import FOOTERLOGO from "../../../assets/images/casting_africa_logo_footer.png"
import FACEBOOK from "../../../assets/images/icon_facebook.png";
import INSTAGRAM from "../../../assets/images/icon_instagram.png";
import YOUTUBE from "../../../assets/images/icon_youtube.png";
import WHATSAPP from "../../../assets/images/icon_whatsapp.png";

const Footer = () => {
    return (
      <div className="absolute mb-0  w-full">
        <div className="md:flex pt-6 md:flex-row flex justify-center flex-wrap text-white w-full  px-3 md:p-5 bg-primary">
          <div className="hidden lg:flex md:w-1/3 flex-col justify-center items-center">
            <img className="w-3/5" src={FOOTERLOGO} alt="Casting Africa logo" />
          </div>
          {footer_content.map((footer, index) => (
            <div
              className="flex flex-col sm:flex-1 w-3/6  font-light "
              key={index}
            >
              <h1 className="  text-xl lg:text-xl uppercase font-semibold ">
                {footer.title}
              </h1>
              <ul className="flex flex-col   items-center mb-4 mt-4">
                {footer.links.map((link, key) => (
                  <li
                    className="text-sm  w-full lg:text-lg font-gilroy-Light"
                    key={key}
                  >
                    <Link to={link.pathname}>
                      {link.type === "img" ? (
                        <img className="w-36 " src={link.title} alt="" />
                      ) : (
                        link.title
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="flex flex-col sm:flex-1 w-3/6  font-light ">
            <h1 className="text-center text-xl lg:text-xl uppercase font-semibold ">
              LET'S CONNECT
            </h1>
            <ul className="flex flex-col items-center mb-4 mt-4">
              <li className="flex ">
                <a  target="_blank" href="https://www.instagram.com/castingafrica/" ><img className="cursuor-pointer w-10 h-10" src={INSTAGRAM} alt="Instagram" /></a>
                <a target="_blank" href="https://facebook.com/castingafrica"><img className="cursuor-pointer w-10 h-10" src={FACEBOOK} alt="Facebook" /></a>
                <a target="_blank" href="https://wa.me/233505943694"><img className="cursuor-pointer w-10 h-10" src={WHATSAPP} alt="Whatsapp" /></a>
                <a target="_blank" href="https://www.youtube.com/channel/UCIMS6mMAGBmhIbepkVXeUVQ"><img className="cursuor-pointer w-10 h-10" src={YOUTUBE} alt="Youtube" /></a>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-full bg-blue-700 p-2">
          <p className="text-center font-gilroy-Light text-white text-sm lg:text-lg">
            &copy;2021, Casting Africa. All rights reserved
          </p>
        </div>
      </div>
    );
}


export default Footer;