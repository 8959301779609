import React, { useState, useEffect } from "react";
import PROFILEAVATAR from "../../../../assets/images/profile_avatar.jpg";
import { talent_dashboard_nav } from "../../../../config/utilities";
import TalentMedia from "./talentMedia";
import { toggleViews } from "../../../../services/service";
import {
  UserCreditAndSkills,
  UserDetails,
  UserJobPreference,
  UserLocation,
  UserMoreInfo,
  UserPhysicalAttributes,
  UserProfessionalDetails,
} from "../../SignUp/Talents";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_PROFILE_PHOTO,
  UPLOAD_PROFILE_PIC,
} from "../../../../graphql/mutations";
import { GET_TALENT } from "../../../../graphql/queries";
import Swal from "sweetalert2";
import Loader from "../../Loader";
import moment from "moment";

const TalentDashboard = () => {

  const [data, setData] = useState({});
  const [talentForm, setTalentForm] = useState({});
  const [account, setAccount] = useState("");
  const [talentID, setTalentID] = useState(
    sessionStorage.getItem("PROFILE_DATA")
  );
  const [step, setStep] = useState(1);
  const [
    deletePhoto,
    { loading: delete_loading, data: delete_reponse, error: delete_error },
  ] = useMutation(DELETE_PROFILE_PHOTO);
  const [profilePicError, setProfilePicError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(sessionStorage.getItem("AUTH_USER"))
  );

  const [addRemovePhoto, setAddRemovePhone] = useState("add");
  const [profilePicLabel, setProfilePicLabel] = useState("")

  const {
    loading,
    data: msg,
    error,
    refetch,
  } = useQuery(GET_TALENT, {
    variables: {
      talentID: sessionStorage.getItem("PROFILE_DATA"),
    },
    onCompleted: (data) => {
      setData(data);
      let reTalent = new Object();
      reTalent["talentDetails"] = {
        talent_first_name: data?.talent?.talent_first_name,
        talent_last_name: data?.talent?.talent_last_name,
        talent_dob: data?.talent?.talent_dob,
        gender: data?.talent?.gender,
        calling_code: data?.talent?.calling_code,
        talent_phonenumbers: data?.talent?.talent_phonenumbers,
        account_type: data?.talent?.account_type,
        talent_media_path: data?.talent?.talent_media_path,
      };

      reTalent["resume"] = data?.talent?.resume;
      reTalent["photos"] = data?.talent?.photos;
      reTalent["videos"] = data?.talent?.videos;

      reTalent["talentAddress"] = {
        ...data?.talent?.talentAddress,
      };

      reTalent["talentUserData"] = {
        email: data?.talent?.userAccount?.email,
      };

      reTalent["castingAfricaTalentDetails"] = JSON.parse(
        data?.talent?.professionalDetails?.casting_africa_talent_details
      );

      reTalent["talentSocials"] = {
        ...data?.talent?.talentSocials,
      };

      setTalentForm({ ...reTalent });
      setAccount(data?.talent?.account_type);
      setTalentID(data?.talent?.casting_africa_talent_id);
      const thumb = data?.talent.photos?.find((photo) => photo.includes("thumbnail"));
      if (thumb)
      {
        setAddRemovePhone("remove")
        setProfilePicError( `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${isLoggedIn?.user.talent.talent_media_path}/pictures/${thumb}`);
        setProfilePicLabel(thumb);
      }
      setStep(0);
      // if (data?.talentDetails?.talent_media_path && thumb)
    },
    //  pollInterval: 500,
    fetchPolicy: "network-only",
  });

  const location = useLocation();
 
  const { signupmode } = location?.state || "";

  const [hasUpdated, sethasUpdated] = useState(moment.now());


  const [
    uploadProfileImage,
    { loading: load, data: responseData, error: err },
  ] = useMutation(UPLOAD_PROFILE_PIC, {

    onCompleted: (data) => {
    },
  });

  useState(() => {
    console.log("Updated.. refetching..");
    refetch({
      variables: {
        talentID: sessionStorage.getItem("PROFILE_DATA"),
      }
    }).then(res => {
      console.log("refetched");
    }).catch( err => {
      console.log("failed refetch");
    })
  }, [hasUpdated]);


  useEffect(() => {
    const talent = sessionStorage.getItem("PROFILE_DATA");
    setTalentID(talent);
  }, [profilePicError]);

  const handleFormChange = (input, extra) => (e) => {
    setTalentForm((prev) => ({
      ...prev,
      [input]: !extra ? e.target.value : extra,
    }));
  };
  const handleStep = (key) => {
    // if (key > 1) return;
    setStep(key);
  };

  const prevStep = () => {
    if (step < 1) return null;
    setStep(step - 1);
  };

  const multiStep = () => {
    switch (step) {
      case 0:
        return (
          <TalentMedia
            accountT={account}
            talentID={talentID}
            talentForms={talentForm}
          />
        );
      case 1:
        return (
          <UserDetails
            talentForm={talentForm}
            setTalentForm={setTalentForm}
            step={step}
            next={null}
            signupmode={signupmode}
            setStep={setStep}
            sethasUpdated={sethasUpdated}
            type="talentDetails"
          />
        );

      case 2:
        return (
          <UserLocation
            handleFormChange={handleFormChange}
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            next={null}
            signupmode={signupmode}
            prev={prevStep}
            type="talentAddress"
            step={step}
            setStep={setStep}
          />
        );
      case 3:
        return (
          <UserProfessionalDetails
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            next={null}
            signupmode={signupmode}
            prev={prevStep}
            step={step}
            setStep={setStep}
            type="castingAfricaTalentDetails"
          />
        );
      case 4:
        return (
          <UserPhysicalAttributes
            handleFormChange={handleFormChange}
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            next={null}
            signupmode={signupmode}
            prev={prevStep}
            type="castingAfricaTalentDetails"
            step={step}
            setStep={setStep}
          />
        );
      case 5:
        return (
          <UserJobPreference
            handleFormChange={handleFormChange}
            step={step}
            next={null}
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            signupmode={signupmode}
            prev={prevStep}
            type="castingAfricaTalentDetails"
            setStep={setStep}
          />
        );
      case 6:
        return (
          <UserCreditAndSkills
            handleFormChange={handleFormChange}
            step={step}
            next={null}
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            signupmode={signupmode}
            prev={prevStep}
            type="castingAfricaTalentDetails"
            setStep={setStep}
          />
        );
      case 7:
        return (
          <UserMoreInfo
            handleFormChange={handleFormChange}
            step={step}
            setStep={setStep}
            next={null}
            setTalentForm={setTalentForm}
            talentForm={talentForm}
            signupmode={signupmode}
            prev={prevStep}
          />
        );
      default:
        return null;
    }
  };

  if (loading) return <Loader />;

  const uploadProfilePic = (e) => {
    // if (e.target.files[0] && e.target.files[0].size > 1024) return;

    uploadProfileImage({
      variables: {
        profilePic: e.target.files[0],
        talentID: talentID,
        media: "pictures",
        photoType: "thumbnail",
      },
    })
      .then((resp) => {
        refetch({
          talentID: sessionStorage.getItem("PROFILE_DATA"),
        }).then((res) => {
          const { data } = res;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmDeletion = (mediaType, fileName) => {
    Swal.fire({
      title: "Remove profile picture?",
      text: "This action cannot be reverted. Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Continue",
      cancelButtonText: "No",
    }).then((result) => {
      const { user } = isLoggedIn;
      if (result.isConfirmed) {
        deletePhoto({
          variables: {
            talentID: user.talent.casting_africa_talent_id,
            mediaType,
            fileName
          },
        }).then((success) => {
          if (success.data.deleteProfilePicture) {
            refetch({
              talentID: sessionStorage.getItem("PROFILE_DATA"),
            }).then((res) => {
              const { data } = res;
              const thumb = data.talent.photos?.find((photo) => photo.includes("thumbnail"));
              setProfilePicError( `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/${talentForm?.talentDetails?.talent_media_path}/pictures/${thumb}`);
              setProfilePicLabel(thumb);
            });
          }
        });
      }
    });
  };

  return (
    <div className="w-full lg:px-24 mt-28 mb-10  flex flex-col items-center">
      <div className="flex    lg:flex-row flex-col w-full lg:px-0 px-3 ">
        <div className="lg:w-72 flex flex-col  items-center h-full">
          <div className=" mr-4 flex flex-col justify-center lg:mb-0   w-60 lg:w-56 h-64 rounded-xl">
            <img
              style={{
                objectFit: "cover",
                backgrounPosition: "center",
                backgroundSize: "100%",
              }}
              src={profilePicError || PROFILEAVATAR}
              alt=""
              className="h-full"
              onError={(e) => {
                e.target.src = PROFILEAVATAR;
                setAddRemovePhone("add");
                // setProfilePicError(true);
              }}
            />
            {addRemovePhoto === "add" ? (
              <div className="w-full cursor-pointer text-white py-2 rounded-br-xl flex flex-col justify-center items-center rounded-bl-xl bg-primary">
                <span className=" cursor-pointer text-center font-gilroy-Light w-full  ">
                  {!load ? "Add Photo" : "Uploading..."}
                </span>
                <input
                  type="file"
                  accept=".jpg"
                  className="absolute overflow-hidden  w-56 opacity-0  cursor-pointer "
                  onChange={uploadProfilePic}
                />
              </div>
            ) : (
              <div onClick={() => confirmDeletion('pictures', profilePicLabel)} className="w-full cursor-pointer text-white py-2 rounded-br-xl flex flex-col justify-center items-center rounded-bl-xl bg-red-500">
                <span className=" cursor-pointer text-center font-gilroy-Light w-full  ">
                  {!load ? "Remove Photo" : "Removing..."}
                </span>
              </div>
            )}
          </div>
          <div className=" mt-4 lg:mt-10 flex lg:flex-col flex-row lg:justify-start justify-center lg:flex-nowrap flex-wrap">
            {talent_dashboard_nav.map((talent_nav, key) => (
              <div
                key={key}
                onClick={() => handleStep(key)}
                className={`border ${
                  key === step
                    ? "bg-blue-800 font-gilroy-Medium"
                    : "bg-primary font-gilroy-Light"
                } cursor-pointer hover:bg-blue-800 lg:text-lg text-xs  px-4 lg:w-56 text-white text-right lg:px-7  py-2 mb-2 lg:rounded-tl-full lg:rounded-bl-full`}
              >
                {talent_nav.title}
              </div>
            ))}
          </div>
        </div>

        <div className="w-full flex flex-col ">
          {multiStep()}
          {/* {talentForm?.talentDetails?.account_type !== "BASIC" && (
            <div className="w-full flex justify-center">
              <button className="bg-primary font-gilroy-Light px-6 text-white rounded-full py-1">
                Update Profile
              </button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default TalentDashboard;
