import React, { useState, useEffect, useRef } from "react";
import { talent_professional_info } from "../../../../config/utilities";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { UPDATE_TALENT } from "../../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { NotificationManager } from "react-notifications";

const UserProfessionalDetails = ({
  next,
  prev,
  setTalentForm,
  talentForm,
  signupmode,
  type,
  step,
  setStep,
}) => {
  const [actorCategory, setActorCategory] = useState(
    talentForm.castingAfricaTalentDetails.actorCategory
  );
  const [modelCategory, setModelCategory] = useState(
    talentForm.castingAfricaTalentDetails.modelCategory
  );
  const [dancerCategory, setDancerCategory] = useState(
    talentForm.castingAfricaTalentDetails.dancerCategory
  );
  const [influencerCategory, setInfluencerCategory] = useState(
    talentForm.castingAfricaTalentDetails.influencerCategory
  );


  const [
    updateTalent,
    { data: talent_data, loading: talent_loading, error: talent_error },
  ] = useMutation(UPDATE_TALENT);



  const { handleSubmit } = useForm();

  const [logged] = useState(sessionStorage.getItem("AUTH_USER"));

  const [talentTypeSelected, setTalentTypeSelected] = useState(
    talentForm.castingAfricaTalentDetails.talent_type
  );

  const unsetvalue = (container, setContainer, value) => {
    if (container.includes(value)) {
      const temp = [...container];
      temp.splice(temp.indexOf(value), 1);
      eval(setContainer)(temp);
    }
  };

  const dynamicMethodCall = (methodState, methodName, item) => {
    let m_state = eval(_.camelCase(methodState));
    eval(methodName)([...m_state, item]);
    unsetvalue(m_state, methodName, item);
  };

  const handleSubmitToNextPage = (data) => {
    let isFilled = false;
    talentTypeSelected.forEach((talenttype) => {
      if (eval(_.camelCase(`${talenttype}Category`)).length <= 0) {
        isFilled = true;
      }
      talentForm[type][_.camelCase(`${talenttype}Category`)] = eval(
        _.camelCase(`${talenttype}Category`)
      );
    });

    if (isFilled) return;

    setTalentForm((p) => ({ ...p, ...talentForm }));
    if (step && signupmode === "TALENTEDIT") {
      setStep(step + 1);
    } else {
      next();
    }
  };

    // Refactor
    const handleSubmitToUpdate = (data) => {
      

      let temp = talentForm;
      temp['castingAfricaTalentDetails']['actorCategory'] = actorCategory
      temp['castingAfricaTalentDetails']['modelCategory'] = modelCategory
      temp['castingAfricaTalentDetails']['dancerCategory'] = dancerCategory
      temp['castingAfricaTalentDetails']['influencerCategory'] = influencerCategory
  
      // let tempDetails = new Object();
      // Object.entries(updateInfo).forEach(([key, value]) => {
      //     tempDetails[key] = value;
      // });
  
      const socials = Object.entries(temp["talentSocials"]);
      let social_asm = new Object();
      socials.forEach(([key, value]) => {
        social_asm[value["social_media_type"].toLowerCase()] =
          value["social_media_handle"];
      });
  
      // temp["talentAddress"] = tempDetails;
      temp["castingAfricaTalentDetails"] = JSON.stringify(
        talentForm["castingAfricaTalentDetails"]
      );
      temp["talentSocials"] = social_asm;
      delete temp["photos"];
      delete temp["resume"];
      delete temp["videos"];
      delete temp['talentAddress']['__typename']
      delete temp['talentAddress']['region_or_state']
      delete temp["talentAddress"]["talent_phonenumbers"];
  
      console.log("temp ", temp);
  
      updateTalent({
        variables: {
          talentID: JSON.parse(logged)?.user?.talent?.casting_africa_talent_id,
          ...temp,
        },
      })
        .then((data) => {
          NotificationManager.success("Updated successfully");
          window.location.reload();
        })
        .catch((err) => {
          NotificationManager.warning("Profile update failed");
        });
    };

  const retract = () => {
    prev();
  };

  return (
    <>
      <div
        className={`pb-6 md:rounded-3xl w-full
      ${
        signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT"
          ? "md:w-9/12 lg:w-5/12 shadow-2xl  bg-white rounded-2xl"
          : ""
      }
      `}
      >
        {/* Header */}
        {signupmode === "AGENTSIGNUP" ||
          (signupmode !== "TALENTEDIT" && (
            <div className="mb-6  h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
              <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
                Professional Info.
              </h1>
              <p className=" w-5/6  md:w-4/6 text-center text-xs md:text-sm mb-4 text-formFont font-gilroy-Light">
                Filling in all sections will increase your chances of being
                noticed by casting professionals.
              </p>
            </div>
          ))}

        {/* Form Body */}
        {talent_professional_info.map((professions, key) =>
          talentTypeSelected.includes(professions.label.split(" ")[0]) ? (
            <div key={key} className="flex w-full flex-row px-2 md:px-14">
              <div className="flex-1 px-1 py-1  flex flex-col">
                <label
                  htmlFor={professions.label}
                  className="mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
                >
                  {professions.label}
                </label>
                <p className=" font-gilroy-Light text-red-600 ">
                  {eval(_.camelCase(professions.label)).length === 0 &&
                    `Select ${professions.label}`}
                </p>
                <div className="flex flex-wrap">
                  {professions.categories.map((talent, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        dynamicMethodCall(
                          _.camelCase(professions.label).split(" ").join(""),
                          `set${professions.label.split(" ").join("")}`,
                          talent
                        )
                      }
                      className={` ${
                        eval(_.camelCase(professions.label)).includes(talent)
                          ? "bg-primary text-white"
                          : "text-formFont"
                      } bg-white shadow-lg cursor-pointer border hover:bg-primary hover:text-white  font-gilroy-Light px-4 py-2  rounded-3xl border-primary m-2`}
                    >
                      {talent}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        )}

        {["AGENTSIGNUP", "TALENTEDIT", "TALENTSIGNUP"].includes(signupmode) ? (
          <div className="px-14 mt-7 flex  flex-row justify-center items-center">
            {!logged ? (
              <div className="w-full flex  flex-row justify-center items-center">
                <button
                  onClick={() => retract()}
                  className="hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3 px-16 "
                >
                  Back
                </button>

                <button
                  onClick={handleSubmit(handleSubmitToNextPage)}
                  className=" hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3  px-16 "
                >
                  Next
                </button>
              </div>
            ) : (
              <button
                onClick={handleSubmit(handleSubmitToUpdate)}
                className=" shadow-2xl  bg-primary opacity-60 hover:opacity-100 tranition ease-out duration-700 m-4 md:m-2 flex items-center justify-center font-bold text-white rounded-full py-4  px-28 "
              >
                {talent_loading ? "Please wait.." : "Update"}
              </button>
            )}
          </div>
        ) : (
          ""
        )}

        {/* End of form body */}
      </div>
    </>
  );
};

export default UserProfessionalDetails;
