import ReactGA from 'react-ga'

const UserAnalyticsEventTracker = (category, action, label) => {
    const eventTracker = (action, label) => {
        ReactGA.event({
            category, action, label
        })
    }

    return eventTracker;
}

export default UserAnalyticsEventTracker;