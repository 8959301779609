import React, { Fragment } from "react";

const ProfessionalCompletion = ({
  next,
  prev,
  professionalForm,
  setProfessionalForm,
}) => {

  

  return (
    <Fragment>
      <div className="shadow-2xl pb-6 bg-white rounded-2xl md:rounded-3xl w-full md:w-9/12 lg:w-5/12  ">
        {/* Header */}
        <div className="mb-6  h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
          <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
            Thanks {professionalForm.details.first_name} {professionalForm.details.last_name}!
          </h1>
        </div>

        {/* Completion Body */}

        <div className="flex flex-col justify-center items-center">
          <h1 className="text-center md:w-3/6 text-2xl font-gilroy-Light mb-6">
            Please confirm your email to complete your registration.
          </h1>
          <p className="text-center md:w-3/6 mb-9 text-sm font-gilroy-Light">
            Click the button in the email we've just sent to {" "}
            {professionalForm.userData.email} and you'll be on your way!
          </p>
          <div className="w-4/6 border border-black mb-4"></div>
          <p className="text-center text-sm font-gilroy-Light mb-4">
            Didn't get the email?
          </p>
          <p className="text-center text-sm w-5/6 md:w-3/6 font-gilroy-Light">
            Check your spam folder for the email,{" "}
            <span className="text-primary cursor-pointer ">
              send the email again
            </span>
            , or{" "}
            <span className="text-primary cursor-pointer ">
              change your email address
            </span>
            .
          </p>
        </div>

        {/* End of Completion body */}
      </div>
    </Fragment>
  );
};

export default ProfessionalCompletion;
