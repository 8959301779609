import React, { useState } from "react";
import {  casting_africa_communities } from "../config/utilities";
import PackageContext from "./context";

const Provider = (props) => {
  const [pathName, setPathName] = useState(window.location.pathname);
  const [talent, setTalent] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});
  const [auth, setAuth] = useState({});
  const [pdfViewerState, setPdfViewerState] = useState(false);
  const [photoViewer, setPhotoViewer] = useState({});
  const [videoViewer, setVideoViewer] = useState({});
  const [bookMeViewer, setBookMeViewer] = useState(false);
  const [community, setCommunity] = useState(casting_africa_communities.filter(c => c.pathname === window.location.pathname).code || "233");
  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsofuseModal, setTermsofuseModal] = useState(false);
  const [changePlan, setChangePlan] = useState(false);

  return (
    <PackageContext.Provider
      value={{
        data: pathName,
        updatePath: (currentPath) => {
          setPathName(currentPath);
        },
        talent,
        updateTalent: (currentTalent) => {
          setTalent(currentTalent);
        },
        updateTalentLocalState: (currentTalent) => {
          try {
            sessionStorage.setItem("profile_set", JSON.stringify(currentTalent));
          } catch (error) {
            // console.log("setting item failed");
          }
        },
        companyDetails,
        updateCompanyDetails: (company) => {
          setCompanyDetails(company);
        },
        auth,
        setAuth,
        pdfViewerState,
        setPdfViewerState,
        photoViewer,
        setPhotoViewer,
        videoViewer,
        setVideoViewer,
        bookMeViewer,
        setBookMeViewer,
        community,
        setCommunity,
        privacyModal,
        setPrivacyModal,
        termsofuseModal,
        setTermsofuseModal,
        changePlan,
        setChangePlan
      }}
    >
      {props.children}
    </PackageContext.Provider>
  );
};

export default Provider;
