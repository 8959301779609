import React, { useState, useEffect, useRef } from "react";
import PlayerControls from "./PlayerControls";


const SECONDS = 60;

const Player = ({ audio, setSelectedAudio }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);

  const audioRef = useRef(new Audio());
  const intervalRef = useRef();
  const isReady = useRef(false);

  const { duration } = (audioRef && audioRef.current) ? audioRef.current : null;

  let currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";

  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;

//   Handle media changed
  useEffect(() => {
    // if (audioRef && audioRef.current) {
       audioRef.current.pause();
       setSelectedAudio(audio);
       audioRef.current = new Audio(audio.audio);
       setTrackProgress(audioRef.current.currentTime);

      if (isReady) {
        audioRef?.current?.play();
        setIsPlaying(true);
        startTimer();
      } else {
        isReady.current = true;
      }
    // }
  }, [audio]);

//   Handle progress 
  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (audioRef && audioRef.current) {
        if (audioRef.current.ended) {
          audioRef.current.pause();
        } else {
          setTrackProgress(audioRef.current.currentTime);
        }
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    if (audioRef && audioRef.current) {
      clearInterval(intervalRef.current);
      audioRef.current.currentTime = value;
      setTrackProgress(audioRef.current.currentTime);
    }
  };

  useEffect(() => {
   if (audioRef && audioRef.current) {
      if (isPlaying) {
        audioRef?.current?.play();
        setIsPlaying(true);
        startTimer();
      } else {
        audioRef.current.pause();
      }
   }
  }, [isPlaying]);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  const convertTimer = (currentTime) => {
    let minutes = Math.floor(currentTime / SECONDS);
    let seconds = Math.floor(currentTime % SECONDS);
    if (minutes < 0) minutes = `0${String(minutes)}`;
    if (seconds < 0) seconds = `0${String(seconds)}`;
    return `${minutes}:${seconds}`;
  };

  return (
    <div className="w-full">
      <h1 className="mb-3 text-xl lg:text-2xl">{audio?.title}</h1>
      <div className="flex h-14 w-full">
        <PlayerControls isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
        <div className="flex flex-col h-full justify-center ">
          <input
            type="range"
            min="0"
            max={duration ? duration : `${duration}`}
            step={1}
            value={trackProgress}
            onChange={(e) => onScrub(e.target.value)}
            className="ml-2 h-1  w-96"
            style={{
              background: trackStyling,
            }}
          />
          <div className=" font-gilroy-Light text-xs w-full px-4 pt-2 flex justify-between">
            <span>{convertTimer(trackProgress)}</span>
            <span>{convertTimer(duration)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Player;