import { useMutation } from "@apollo/client";
import React, { Fragment, useContext, useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import PackageContext from "../../../../context/context";
import { UPDATE_TALENT } from "../../../../graphql/mutations";
import moment from "moment";
const UserDetails = ({
  next,
  talentForm,
  setTalentForm,
  signupmode,
  type,
  setStep,
  sethasUpdated = moment.now(),
  step,
}) => {
  const context = useContext(PackageContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  const [genderSelected, setGenderSelected] = useState(
    talentForm?.talentDetails?.gender
  );
  const [logged] = useState(sessionStorage.getItem("AUTH_USER"));


  const [
    updateTalent,
    { data: talent_data, loading: talent_loading, error: talent_error },
  ] = useMutation(UPDATE_TALENT);

  const proceed = (event) => {
    next();
  };

  const handleshow = () => {
    return signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT";
  };
  const handleSubmitToNextPage = (data) => {
    const info = {
      ...data,
      gender: genderSelected,
    };
    for (let key in info) {
      talentForm[type][key] = info[key];
      if (key === "email") {
        talentForm["talentUserData"][key] = info[key];
        delete talentForm[type][key];
      }
      if (key === "password") {
        talentForm["talentUserData"][key] = info[key];
        delete talentForm[type][key];
      }
    }

    delete talentForm[type]["confirm_password"];
    setTalentForm((p) => ({ ...p, ...talentForm }));
    if (step && signupmode === "TALENTEDIT") {
      setStep(step + 1);
    } else {
      next();
    }
  };

  const handleSubmitToUpdate = (data) => {
      const updateInfo = {
        ...data,
        gender: genderSelected
      }
      let temp = talentForm;
      temp['talentUserData'] = {email: updateInfo['email']}

      let tempDetails = new Object();
      Object.entries(updateInfo).forEach(([key, value]) => {
        if (key !== "email") {
          tempDetails[key] = value
        }
      })

      const socials = Object.entries(temp['talentSocials']);
      let social_asm = new Object()
      socials.forEach(([key, value]) => {
        social_asm[value['social_media_type'].toLowerCase()] = value['social_media_handle']
      })

      temp['talentDetails'] = tempDetails
      temp['castingAfricaTalentDetails'] = JSON.stringify(talentForm['castingAfricaTalentDetails'])
      temp['talentSocials'] = social_asm;
      delete temp['photos']
      delete temp['resume']
      delete temp['videos']
      delete temp['talentAddress']['__typename']
      delete temp['talentAddress']['region_or_state']


      console.log("temp ", social_asm);

      updateTalent({
        variables: {
          talentID: JSON.parse(logged)?.user?.talent?.casting_africa_talent_id,
          ...temp
        }
      }).then(data => {
        console.log("updated at ", moment.now())
        sethasUpdated(moment.now())
        NotificationManager.success("Updated successfully")
        window.location.reload();
      }).catch(err => {
          NotificationManager.warning("Profile update failed")
      })
      
      
  }

  useEffect(() => {
   console.log(" Update ", talentForm)
  }, [talentForm]);

  return (
    <Fragment>
      <div
        className={`rounded-2xl md:rounded-3xl w-full ${
          signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT"
            ? "md:w-9/12 lg:w-5/12 bg-white shadow-2xl pb-6"
            : ""
        }`}
      >
        {/* Header */}
        {signupmode === "AGENTSIGNUP" ||
          (signupmode !== "TALENTEDIT" && (
            <div className="mb-6 h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
              <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
                Personal Info.
              </h1>
              <p className=" w-5/6  md:w-4/6 text-center text-xs md:text-sm mb-4 text-formFont font-gilroy-Light">
                Filling in all sections will increase your chances of being
                noticed by casting professionals
              </p>
            </div>
          ))}

        {/* Form Body */}
        <div className="flex flex-col px-3 md:flex-row md:px-14">
          <div className=" md:flex-1 md:pr-8 px-1 py-1 flex flex-col">
            <label
              htmlFor="First Name"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              First Name
            </label>
            <input
              className="border shadow-none rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light  focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your first name"
              defaultValue={talentForm.talentDetails?.talent_first_name}
              {...register("talent_first_name", {
                required: "First name is required",
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors?.talent_first_name?.message}
            </p>
          </div>
          <div className=" md:flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Last Name"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Last Name
            </label>
            <input
              className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="text"
              placeholder="Your last name"
              defaultValue={talentForm?.talentDetails?.talent_last_name}
              {...register("talent_last_name", {
                required: "Last name is required",
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors?.talent_last_name?.message}
            </p>
          </div>
        </div>

        <div className="px-3 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="E-mail"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              E-mail
            </label>
            <input
              className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
              type="email"
              placeholder="Your e-mail address"
              defaultValue={talentForm?.talentUserData?.email}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid Email Standard",
                },
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors?.email?.message}
            </p>
          </div>
        </div>

        {signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT" ? (
          <div>
            <div className="px-3 md:px-14">
              <div className="flex-1 px-1 py-1  flex flex-col">
                <label
                  htmlFor="Password"
                  className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
                >
                  Password
                </label>
                <input
                  className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
                  type="password"
                  placeholder="Password"
                  name="password"
                  {...register("password", {
                    required: true,
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                />
                <p className=" font-gilroy-Light text-red-600 ">
                  {errors?.password?.message ||
                    (errors?.password && "Password is required")}
                </p>
              </div>
            </div>

            <div className="px-3 md:px-14">
              <div className="flex-1 px-1 py-1  flex flex-col">
                <label
                  htmlFor="Confirm Password"
                  className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
                >
                  Confirm Password
                </label>
                <input
                  className="border rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none px-2 py-3 "
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  {...register("confirm_password", {
                    required: "Confirm Password",
                    validate: (value) =>
                      value === passwordRef.current ||
                      "Password does not match",
                  })}
                />
                <p className=" font-gilroy-Light text-red-600 ">
                  {errors?.confirm_password?.message ||
                    (errors?.confirm_password && "Confirm password")}
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="px-3 md:px-14">
          <div className="flex-1 px-1 py-1  flex flex-col">
            <label
              htmlFor="Date of Birth"
              className=" mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
            >
              Date of Birth
            </label>
            <input
              className="border  rounded-md border-gray-300 placeholder-gray-400 font-gilroy-Light focus:outline-none md:px-2 md:py-3 "
              type="date"
              placeholder="Date of Birth"
              defaultValue={talentForm?.talentDetails?.talent_dob}
              {...register("talent_dob", {
                required: "Please choose your date of birth",
              })}
            />
            <p className=" font-gilroy-Light text-red-600 ">
              {errors?.talent_dob?.message}
            </p>
          </div>
        </div>

        <div className="px-3 md:px-14 mt-2">
          <span className=" font-gilroy-Light block">Gender</span>
          <div className="flex mt-2">
            <div className="flex mr-6 mb-3 items-center">
              <input
                className="mr-2 p-1 md:p-14 opacity-0 absolute h-8 w-8"
                type="checkbox"
                checked={genderSelected === "MALE"}
                onChange={() => setGenderSelected("MALE")}
              />
              <div
                onClick={() => setGenderSelected("MALE")}
                className="bg-white border rounded-full border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black"
              >
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#1F73F1"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="text-xs font-gilroy-Light">Male</span>
            </div>
            <div className="flex mr-6 mb-3 items-center">
              <input
                className="mr-2 p-1 md:p-14 opacity-0 absolute h-8 w-8"
                type="checkbox"
                checked={genderSelected === "FEMALE"}
                onChange={() => setGenderSelected("FEMALE")}
              />
              <div
                onClick={() => setGenderSelected("FEMALE")}
                className="bg-white border rounded-full border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black"
              >
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#1F73F1"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="text-xs font-gilroy-Light">Female</span>
            </div>
          </div>
        </div>

        <div className="px-3 md:px-14 mt-7 flex justify-center items-center flex-col">
          {["AGENTSIGNUP", "TALENTEDIT", "TALENTSIGNUP"].includes(
            signupmode
          ) ? (
            <div className="flex flex-col">
              {signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT" ? (
                <p className=" text-center text-xs md:text-xs  font-bold">
                  By signing up you agree to our{" "}
                  {/* <Link to="#" className="text-primary">
                  Terms of Service
                </Link>{" "} */}
                  <span
                    onClick={() => context?.setTermsofuseModal(true)}
                    className="text-primary cursor-pointer"
                  >
                    Terms of Service
                  </span>{" "}
                  and
                  {/* <Link to="#" className="text-primary">
                  {" "}
                  Privacy Policy
                </Link> */}
                  <span
                    onClick={() => context.setPrivacyModal(true)}
                    className="text-primary cursor-pointer"
                  >
                    {" "}
                    Privacy Policy
                  </span>
                </p>
              ) : (
                ""
              )}
              {
                !logged ? (
                  <button
                  onClick={handleSubmit(handleSubmitToNextPage)}
                  className=" shadow-2xl  bg-primary opacity-60 hover:opacity-100 tranition ease-out duration-700 m-4 md:m-2 flex items-center justify-center font-bold text-white rounded-full py-4  px-28 "
                >
                  Next
                </button>
                ) : (
                  <button
                  onClick={handleSubmit(handleSubmitToUpdate)}
                  className=" shadow-2xl  bg-primary opacity-60 hover:opacity-100 tranition ease-out duration-700 m-4 md:m-2 flex items-center justify-center font-bold text-white rounded-full py-4  px-28 "
                >
                  {
                    talent_loading ? "Please wait.." : "Update"
                  }
                </button>
                )
              }
             
             

            </div>
          ) : (
            ""
          )}
          {signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT" ? (
            <p className="text-xs  text-formFont font-gilroy-Light">
              Already have an account?
              <Link
                onClick={() => context.updatePath("/login")}
                to="/login"
                className="text-primary font-bold"
              >
                {" "}
                Login
              </Link>
            </p>
          ) : (
            ""
          )}
        </div>
        {context.privacyModal && <PrivacyPolicyModal context={context} />}
        {context.termsofuseModal && <TermsOfServiceModal context={context} />}

        {/* End of form body */}
      </div>
    </Fragment>
  );
};

const PrivacyPolicyModal = ({ context }) => {
  return (
    <div
      onClick={() => context?.setPrivacyModal(false)}
      className="w-full h-full  bg-modalBackground  lg:px-0 px-6 py-16 flex justify-center items-center fixed top-0 z-50 left-0"
    >
      <div className=" bg-white rounded-2xl flex flex-col items-center w-full lg:w-3/6 h-full   ">
        <div className="w-full flex justify-end mr-4 mt-2">
          <svg
            onClick={() => context?.setPrivacyModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#f00"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className=" w-full overflow-y-scroll px-4 py-4 h-screen">
          {/* <div  className="border border-black w-10 h-10 flex justify-center cursor-pointer items-center rounded-full shadow-xl fixed bottom-20    bg-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fff"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </div> */}
          <h1 id="begining" className=" text-2xl mb-2">
            PRIVACY NOTICE
          </h1>
          <h3 className=" font-gilroy-Medium mb-5">
            Last updated July 30, 2021
          </h3>
          <p className=" font-gilroy-Light mb-6 lg:text-sm  text-xs">
            Thank you for choosing to be part of our community at CastingAfrica
            Inc. (“Company“, “we“, “us“, “our“). We are committed to protecting
            your personal information and your right to privacy. If you have any
            questions or concerns about this privacy notice, or our practices
            with regards to your personal information, please contact us at
            info@casting.africa.
          </p>
          <p className=" font-gilroy-Light mb-6 lg:text-sm  text-xs">
            When you visit our website www.casting.africa (the “Website“), and
            more generally, use any of our services (the “Services“, which
            include the Website), we appreciate that you are trusting us with
            your personal information. We take your privacy very seriously. In
            this privacy notice, we seek to explain to you in the clearest way
            possible what information we collect, how we use it and what rights
            you have in relation to it. We hope you take some time to read
            through it carefully, as it is important. If there are any terms in
            this privacy notice that you do not agree with, please discontinue
            use of our Services immediately.
          </p>
          <p className=" font-gilroy-Light mb-6 lg:text-sm  text-xs">
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our Website), as well
            as, any related services, sales, marketing or events.
          </p>
          <p className=" font-gilroy-Medium mb-6">
            Please read this privacy notice carefully as it will help you
            understand what we do with the information that we collect.
          </p>

          <h1 className="mb-4 lg:text-lg sm:text-sm">TABLE OF CONTENTS</h1>

          <ol className="mb-6  px-4">
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt1">WHAT INFORMATION DO WE COLLECT?</span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt2">HOW DO WE USE YOUR INFORMATION?</span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt3">
                {" "}
                WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt4">
                WHO WILL YOUR INFORMATION BE SHARED WITH?
              </span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt5">
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt6">HOW LONG DO WE KEEP YOUR INFORMATION?</span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt7">HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt8">WHAT ARE YOUR PRIVACY RIGHTS?</span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt9">CONTROLS FOR DO-NOT-TRACK FEATURES</span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt10">
                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt11">DO WE MAKE UPDATES TO THIS NOTICE?</span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt12">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </li>
            <li className="mb-1 list-decimal font-gilroy-Light lg:text-sm text-xs text-blue-500">
              <span _href="#chpt13">
                HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </span>
            </li>
          </ol>

          <section className="mb-6 font-gilroy-Light" id="chpt1">
            <h1 className=" font-gilroy-Extrabold mb-4">
              1. WHAT INFORMATION DO WE COLLECT?
            </h1>
            <h2 className="mb-4">Personal information you disclose to us</h2>

            <p className="mb-4">
              <em>
                <strong>In Short:</strong> We collect personal information that
                you provide to us.
              </em>
            </p>

            <p className="mb-4">
              We collect personal information that you voluntarily provide to us
              when you register on the Website, express an interest in obtaining
              information about us or our products and Services, when you
              participate in activities on the Website or otherwise when you
              contact us.
            </p>

            <p className="mb-4">
              The personal information that we collect depends on the context of
              your interactions with us and the Website, the choices you make
              and the products and features you use. The personal information we
              collect may include the following:
            </p>

            <p className="mb-4">
              <strong>Personal Information Provided by You.</strong> We collect
              names; phone numbers; email addresses; usernames; passwords;
              pictures; age; physical details; professional details ; and other
              similar information
            </p>

            <p className="mb-4">
              <strong>Payment Data.</strong> We may collect data necessary to
              process your payment if you make purchases, such as your payment
              instrument number (such as a credit card number), and the security
              code associated with your payment instrument. All payment data is
              stored by Paystack . You may find their privacy notice link(s)
              here:{" "}
              <a
                className="text-blue-500"
                target="_blank"
                href="https://paystack.com/gh/terms"
              >
                Pay Stack
              </a>
            </p>

            <p className="mb-4">
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
            </p>

            <h2>Information automatically collected</h2>
            <p className="mb-4">
              <em>
                <strong>In Short:</strong> Some information — such as your
                Internet Protocol (IP) address and/or browser and device
                characteristics — is collected automatically when you visit our
                Website.
              </em>
            </p>

            <p className="mb-4">
              We automatically collect certain information when you visit, use
              or navigate the Website. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Website and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Website, and for our
              internal analytics and reporting purposes.
            </p>

            <p className="mb-4">
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>

            <p className="mb-4">The information we collect includes:</p>
            <ul className="px-4">
              <li className="mb-6 list-outside list-disc">
                <em>Log and Usage Data.</em> Log and usage data is
                service-related, diagnostic, usage and performance information
                our servers automatically collect when you access or use our
                Website and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type and settings and information
                about your activity in the Website (such as the date/time stamps
                associated with your usage, pages and files viewed, searches and
                other actions you take such as which features you use), device
                event information (such as system activity, error reports
                (sometimes called ‘crash dumps’) and hardware settings).
              </li>
              <li className="mb-6 list-outside list-disc">
                <em>Device Data.</em> We collect device data such as information
                about your computer, phone, tablet or other device you use to
                access the Website. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model Internet service provider
                and/or mobile carrier, operating system and system configuration
                information.
              </li>

              <li className="mb-6 list-outside list-disc">
                <em>Location Data.</em> We collect location data such as
                information about your device’s location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the
                Website. For example, we may use GPS and other technologies to
                collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. Note however, if you choose to opt out, you may not be
                able to use certain aspects of the Services.
              </li>
            </ul>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt2">
            <h1 className=" font-gilroy-Extrabold mb-6">
              2. HOW DO WE USE YOUR INFORMATION?
            </h1>

            <p className="mb-6">
              <em>In Short:</em> We process your information for purposes based
              on legitimate business interests, the fulfillment of our contract
              with you, compliance with our legal obligations, and/or your
              consent.
            </p>

            <p className="mb-6">
              We use personal information collected via our Website for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </p>

            <p className="mb-6">
              We use the information we collect or receive:
            </p>
            <li className="mb-4">
              <strong>To facilitate account creation and logon process.</strong>{" "}
              If you choose to link your account with us to a third-party
              account (such as your Google or Facebook account), we use the
              information you allowed us to collect from those third parties to
              facilitate account creation and logon process for the performance
              of the contract.
            </li>
            <li className="mb-4">
              <strong>To post testimonials.</strong> We post testimonials on our
              Website that may contain personal information. Prior to posting a
              testimonial, we will obtain your consent to use your name and the
              content of the testimonial. If you wish to update, or delete your
              testimonial, please contact us at info@casting.africa and be sure
              to include your name, testimonial location, and contact
              information.
            </li>
            <li className="mb-4">
              <strong>Request feedback.</strong>We may use your information to
              request feedback and to contact you about your use of our Website.
            </li>
            <li className="mb-4">
              <strong>To enable user-to-user communications.</strong> We may use
              your information in order to enable user-to-user communications
              with each user’s consent.
            </li>
            <li className="mb-4">
              <strong>To manage user accounts.</strong> We may use your
              information for the purposes of managing our account and keeping
              it in working order.
            </li>
            <li className="mb-4">
              <strong>To send administrative information to you.</strong> We may
              use your personal information to send you product, service and new
              feature information and/or information about changes to our terms,
              conditions, and policies.
            </li>
            <li className="mb-4">
              <strong>To protect our Services.</strong> We may use your
              information as part of our efforts to keep our Website safe and
              secure (for example, for fraud monitoring and prevention).
            </li>
            <li className="mb-4">
              <strong>
                To enforce our terms, conditions and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
              </strong>
            </li>
            <li className="mb-4">
              <strong>To respond to legal requests and prevent harm.</strong> If
              we receive a subpoena or other legal request, we may need to
              inspect the data we hold to determine how to respond.
            </li>
            <li className="mb-4">
              <strong>Fulfill and manage your orders.</strong>We may use your
              information to fulfill and manage your orders, payments, returns,
              and exchanges made through the Website.
            </li>
            <li className="mb-4">
              <strong>Administer prize draws and competitions.</strong>We may
              use your information to administer prize draws and competitions
              when you elect to participate in our competitions.
            </li>
            <li className="mb-4">
              <strong>
                To deliver and facilitate delivery of services to the user.
              </strong>
              We may use your information to provide you with the requested
              service.
            </li>
            <li className="mb-4">
              <strong>
                To respond to user inquiries/offer support to users.
              </strong>
              We may use your information to respond to your inquiries and solve
              any potential issues you might have with the use of our Services.
            </li>
            <li className="mb-4">
              <strong>
                To send you marketing and promotional communications.
              </strong>
              We and/or our third-party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. For example, when
              expressing an interest in obtaining information about us or our
              Website, subscribing to marketing or otherwise contacting us, we
              will collect personal information from you. You can opt-out of our
              marketing emails at any time (see the{" "}
              <span className="text-blue-500" _href="#chpt8">
                “WHAT ARE YOUR PRIVACY RIGHTS?”
              </span>{" "}
              below).
            </li>
            <li className="mb-4">
              <strong>Deliver targeted advertising to you.</strong>We may use
              your information to develop and display personalized content and
              advertising (and work with third parties who do so) tailored to
              your interests and/or location and to measure its effectiveness.
            </li>
            <li className="mb-4">
              <strong>For other business purposes.</strong>We may use your
              information for other business purposes, such as data analysis,
              identifying usage trends, determining the effectiveness of our
              promotional campaigns and to evaluate and improve our Website,
              products, marketing and your experience. We may use and store this
              information in aggregated and anonymized form so that it is not
              associated with individual end users and does not include personal
              information. We will not use identifiable personal information
              without your consent.
            </li>
            <ul></ul>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt3">
            <h1 className="font-gilroy-Extrabold">
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </h1>
            <p className="mb-4">
              <em>
                <strong>In Short:</strong> We only share information with your
                consent, to comply with laws, to provide you with services, to
                protect your rights, or to fulfill business obligations.
              </em>
            </p>

            <p className="mb-4">
              We may process or share your data that we hold based on the
              following legal basis:
            </p>
            <ul>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                <strong>Consent:</strong> We may process your data if you have
                given us specific consent to use your personal information for a
                specific purpose.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                <strong>Legitimate Interests:</strong> We may process your data
                when it is reasonably necessary to achieve our legitimate
                business interests.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                <strong>Performance of a Contract:</strong>Where we have entered
                into a contract with you, we may process your personal
                information to fulfill the terms of our contract.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                <strong>Legal Obligations: </strong>We may disclose your
                information where we are legally required to do so in order to
                comply with applicable law, governmental requests, a judicial
                proceeding, court order, or legal process, such as in response
                to a court order or a subpoena (including in response to public
                authorities to meet national security or law enforcement
                requirements).
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                <strong>Vital Interests:</strong>We may disclose your
                information where we believe it is necessary to investigate,
                prevent, or take action regarding potential violations of our
                policies, suspected fraud, situations involving potential
                threats to the safety of any person and illegal activities, or
                as evidence in litigation in which we are involved.
              </li>
            </ul>

            <p className="mb-4">
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </p>
            <ul>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                <strong>
                  Vendors, Consultants and Other Third-Party Service Providers.
                </strong>{" "}
                We may share your data with third-party vendors, service
                providers, contractors or agents who perform services for us or
                on our behalf and require access to such information to do that
                work. Examples include: payment processing, data analysis, email
                delivery, hosting services, customer service and marketing
                efforts. We may allow selected third parties to use tracking
                technology on the Website, which will enable them to collect
                data on our behalf about how you interact with our Website over
                time. This information may be used to, among other things,
                analyze and track data, determine the popularity of certain
                content, pages or features, and better understand online
                activity. Unless described in this notice, we do not share,
                sell, rent or trade any of your information with third parties
                for their promotional purposes.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                <strong>Affiliates.</strong> We may share your information with
                our affiliates, in which case we will require those affiliates
                to honor this privacy notice. Affiliates include our parent
                company and any subsidiaries, joint venture partners or other
                companies that we control or that are under common control with
                us.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                <strong>Business Partners.</strong>We may share your information
                with our business partners to offer you certain products,
                services or promotions.
              </li>
            </ul>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt4">
            <h1 className="font-gilroy-Extrabold">
              4. WHO WILL YOUR INFORMATION BE SHARED WITH?
            </h1>
            <p className="mb-4">
              <em>In Short:</em> We only share information with the following
              categories of third parties.
            </p>
            <p className="mb-4">
              We only share and disclose your information with the following
              categories of third parties. If we have processed your data based
              on your consent and you wish to revoke your consent, please
              contact us using the contact details provided in the section below
              titled{" "}
              <span className="text-blue-500" _href="#chpt12">
                “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?“
              </span>
              .
            </p>
            <ul>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Sales & Marketing Tools</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Social Networks</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">User Account Registration & Authentication Services</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Website Hosting Service Providers</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Performance Monitoring Tools</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Product Engineering & Design Tools</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Retargeting Platforms</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Payment Processors</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Finance & Accounting Tools</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Data Storage Service Providers</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Data Analytics Services</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Communication & Collaboration Tools</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Ad Networks</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Cloud Computing Services</li>
            </ul>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt5">
            <h1 className="font-gilroy-Extrabold">
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h1>
            <p className="mb-4">
              <em>
                In Short: We may use cookies and other tracking technologies to
                collect and store your information.
              </em>
            </p>
            <p className="mb-4">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt6">
            <h1 className="font-gilroy-Extrabold">
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h1>
            <p className="mb-4">
              <em>
                <strong>In Short:</strong> We keep your information for as long
                as necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.
              </em>
            </p>
            <p className="mb-4">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            <p className="mb-4">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt7">
            <h1 className="font-gilroy-Extrabold">
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h1>
            <p className="mb-4">
              <em>
                In Short: We aim to protect your personal information through a
                system of organizational and technical security measures.
              </em>
            </p>
            <p className="mb-4">
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security, and improperly collect,
              access, steal, or modify your information. Although we will do our
              best to protect your personal information, transmission of
              personal information to and from our Website is at your own risk.
              You should only access the Website within a secure environment.
            </p>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt8">
            <h1 className="font-gilroy-Extrabold">
              8. WHAT ARE YOUR PRIVACY RIGHTS?
            </h1>
            <p className="mb-4">
              <em>
                <strong>In Short:</strong> You may review, change, or terminate
                your account at any time.
              </em>
            </p>

            <p className="mb-4">
              If you are a resident in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:{" "}
              <a
                target="_blank"
                className="text-blue-500"
                href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
              >
                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              </a>
            </p>
            <p className="mb-4">
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available here:{" "}
              <a
                target="_blank"
                className="text-blue-500"
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
              >
                https://www.edoeb.admin.ch/edoeb/en/home.html.
              </a>
            </p>

            <p className="mb-4">
              If you have questions or comments about your privacy rights, you
              may email us at info@casting.africa.
            </p>

            <h3>Account Information</h3>
            <p className="mb-4">
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Log in to your account settings and update your user account.
              </li>
            </ul>

            <p className="mb-4">
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </p>

            <p className="mb-4">
              <strong>
                <u>Cookies and similar technologies:</u>
              </strong>{" "}
              Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Website. To opt-out of interest-based advertising by
              advertisers on our Website visit{" "}
              <a
                target="_blank"
                className="text-blue-500"
                href="http://www.aboutads.info/choices/"
              >
                http://www.aboutads.info/choices/
              </a>
              .
            </p>

            <p className="mb-4">
              <strong>
                <u>Opting out of email marketing:</u>
              </strong>
              You can unsubscribe from our marketing email list at any time by
              clicking on the unsubscribe link in the emails that we send or by
              contacting us using the details provided below. You will then be
              removed from the marketing email list — however, we may still
              communicate with you, for example to send you service-related
              emails that are necessary for the administration and use of your
              account, to respond to service requests, or for other
              non-marketing purposes. To otherwise opt-out, you may:
            </p>

            <ul>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Access your account settings and update your preferences.</li>
            </ul>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt9">
            <h1 className="font-gilroy-Extrabold">
              9. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h1>
            <p className="mb-4">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.{" "}
            </p>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt10">
            <h1 className="font-gilroy-Extrabold">
              10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h1>
            <p className="mb-4">
              <em>
                <strong>In Short:</strong> Yes, if you are a resident of
                California, you are granted specific rights regarding access to
                your personal information.
              </em>
            </p>

            <p className="mb-4">
              California Civil Code Section 1798.83, also known as the “Shine
              The Light” law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>

            <p className="mb-4">
              If you are under 18 years of age, reside in California, and have a
              registered account with the Website, you have the right to request
              removal of unwanted data that you publicly post on the Website. To
              request removal of such data, please contact us using the contact
              information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Website, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </p>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt11">
            <h1 className="font-gilroy-Extrabold">
              11. DO WE MAKE UPDATES TO THIS NOTICE?{" "}
            </h1>
            <p className="mb-4">
              <em>
                <strong>In Short:</strong> Yes, we will update this notice as
                necessary to stay compliant with relevant laws.
              </em>
            </p>
            <p className="mb-4">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated “Revised” date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt12">
            <h1 className="font-gilroy-Extrabold">
              12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{" "}
            </h1>
            <p className="mb-4">
              If you have questions or comments about this notice, you may email
              us at info@casting.africa or by post to:
            </p>
            <address className=" text-left">
              CastingAfrica Inc. <br /> 256 Chapman Road STE 105-4 <br /> Newark
              19702 <br /> United States
            </address>
          </section>

          <section className="mb-6 font-gilroy-Light" id="chpt13">
            <h1 className="font-gilroy-Extrabold">
              13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h1>
            <p className="mb-4">
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please visit:{" "}
              <a target="_self" className="text-blue-500" href="">
                {" "}
                https://casting.africa/signup
              </a>
              .
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

const TermsOfServiceModal = ({ context }) => {
  return (
    <div
      onClick={() => context?.setTermsofuseModal(false)}
      className="w-full h-full  bg-modalBackground  lg:px-0 px-6 py-16 flex justify-center items-center fixed top-0 z-50 left-0"
    >
      <div className=" bg-white rounded-2xl flex flex-col items-center w-full lg:w-3/6 h-full   ">
        <div className="w-full flex justify-end mr-4 mt-2">
          <svg
            onClick={() => context?.setTermsofuseModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#f00"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="h-screen w-full overflow-y-scroll px-4 py-4">
          <h1 id="begining" className=" text-2xl mb-2">
            PRIVACY NOTICE
          </h1>
          <h3 className=" font-gilroy-Medium mb-5">
            Last updated July 30, 2021
          </h3>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">AGREEMENT TO TERMS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              These Terms of Use constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (“you”)
              and CastingAfrica Inc (“Company“, “we”, “us”, or “our”),
              concerning your access to and use of the
              https://www.casting.africa website as well as any other media
              form, media channel, mobile website or mobile application related,
              linked, or otherwise connected thereto (collectively, the “Site”).
              You agree that by accessing the Site, you have read, understood,
              and agree to be bound by all of these Terms of Use. IF YOU DO NOT
              AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY
              PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
              IMMEDIATELY.
            </p>
            <p className="mb-6 lg:text-sm text-xs" >
              Supplemental terms and conditions or documents that may be posted
              on the Site from time to time are hereby expressly incorporated
              herein by reference. We reserve the right, in our sole discretion,
              to make changes or modifications to these Terms of Use at any time
              and for any reason. We will alert you about any changes by
              updating the “Last updated” date of these Terms of Use, and you
              waive any right to receive specific notice of each such change. It
              is your responsibility to periodically review these Terms of Use
              to stay informed of updates. You will be subject to, and will be
              deemed to have been made aware of and to have accepted, the
              changes in any revised Terms of Use by your continued use of the
              Site after the date such revised Terms of Use are posted.{" "}
            </p>
            <p className="mb-6 lg:text-sm text-xs" >
              The information provided on the Site is not intended for
              distribution to or use by any person or entity in any jurisdiction
              or country where such distribution or use would be contrary to law
              or regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly,
              those persons who choose to access the Site from other locations
              do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are
              applicable.
            </p>
            <p className="mb-6 lg:text-sm text-xs" >
              The Site is not tailored to comply with industry-specific
              regulations (Health Insurance Portability and Accountability Act
              (HIPAA), Federal Information Security Management Act (FISMA),
              etc.), so if your interactions would be subjected to such laws,
              you may not use this Site. You may not use the Site in a way that
              would violate the Gramm-Leach-Bliley Act (GLBA).
            </p>
            <p className="mb-6 lg:text-sm text-xs" >
              All users who are minors in the jurisdiction in which they reside
              (generally under the age of 18) must have the permission of, and
              be directly supervised by, their parent or guardian to use the
              Site. If you are a minor, you must have your parent or guardian
              read and agree to these Terms of Use prior to you using the Site.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">INTELLECTUAL PROPERTY RIGHTS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              Unless otherwise indicated, the Site is our proprietary property
              and all source code, databases, functionality, software, website
              designs, audio, video, text, photographs, and graphics on the Site
              (collectively, the “Content”) and the trademarks, service marks,
              and logos contained therein (the “Marks”) are owned or controlled
              by us or licensed to us, and are protected by copyright and
              trademark laws and various other intellectual property rights and
              unfair competition laws of the United States, international
              copyright laws, and international conventions. The Content and the
              Marks are provided on the Site “AS IS” for your information and
              personal use only. Except as expressly provided in these Terms of
              Use, no part of the Site and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </p>

            <p className="mb-6 lg:text-sm text-xs" >
              Provided that you are eligible to use the Site, you are granted a
              limited license to access and use the Site and to download or
              print a copy of any portion of the Content to which you have
              properly gained access solely for your personal, non-commercial
              use. We reserve all rights not expressly granted to you in and to
              the Site, the Content and the Marks.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">USER REPRESENTATIONS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              By using the Site, you represent and warrant that: (1) all
              registration information you submit will be true, accurate,
              current, and complete; (2) you will maintain the accuracy of such
              information and promptly update such registration information as
              necessary; (3) you have the legal capacity and you agree to comply
              with these Terms of Use; (4) you are not a minor in the
              jurisdiction in which you reside, or if a minor, you have received
              parental permission to use the Site; (5) you will not access the
              Site through automated or non-human means, whether through a bot,
              script or otherwise; (6) you will not use the Site for any illegal
              or unauthorized purpose; and (7) your use of the Site will not
              violate any applicable law or regulation.
            </p>

            <p className="mb-6 lg:text-sm text-xs" >
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Site (or any portion thereof).{" "}
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">USER REGISTRATION</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              You may be required to register with the Site. You agree to keep
              your password confidential and will be responsible for all use of
              your account and password. We reserve the right to remove,
              reclaim, or change a username you select if we determine, in our
              sole discretion, that such username is inappropriate, obscene, or
              otherwise objectionable.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">FEES AND PAYMENT</h1>
            <p className="mb-6 lg:text-sm text-xs" >We accept the following forms of payment:</p>
            <ul className="px-4">
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Visa</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">PayPal</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Discover</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">American Express</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Mastercard</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Mobile Money</li>
            </ul>

            <p className="mb-6 lg:text-sm text-xs" >
              You may be required to purchase or pay a fee to access some of our
              services. You agree to provide current, complete, and accurate
              purchase and account information for all purchases made via the
              Site. You further agree to promptly update account and payment
              information, including email address, payment method, and payment
              card expiration date, so that we can complete your transactions
              and contact you as needed. We bill you through an online billing
              account for purchases made via the Site. Sales tax will be added
              to the price of purchases as deemed required by us. We may change
              prices at any time. All payments shall be in U.S. dollars.
            </p>

            <p className="mb-6 lg:text-sm text-xs" >
              You agree to pay all charges or fees at the prices then in effect
              for your purchases, and you authorize us to charge your chosen
              payment provider for any such amounts upon making your purchase.
              If your purchase is subject to recurring charges, then you consent
              to our charging your payment method on a recurring basis without
              requiring your prior approval for each recurring charge, until you
              notify us of your cancellation.{" "}
            </p>

            <p className="mb-6 lg:text-sm text-xs" >
              We reserve the right to correct any errors or mistakes in pricing,
              even if we have already requested or received payment. We also
              reserve the right to refuse any order placed through the Site.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">CANCELLATION</h1>

            <p className="mb-6 lg:text-sm text-xs" >
              All purchases are non-refundable. You can cancel your subscription
              at any time by logging into your account or contacting us using
              the contact information provided below. Your cancellation will
              take effect at the end of the current paid term.{" "}
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">PROHIBITED ACTIVITIES </h1>

            <p className="mb-6 lg:text-sm text-xs" >
              You may not access or use the Site for any purpose other than that
              for which we make the Site available. The Site may not be used in
              connection with any commercial endeavors except those that are
              specifically endorsed or approved by us.
            </p>

            <p className="mb-6 lg:text-sm text-xs" >As a user of the Site, you agree not to:</p>

            <ul className="px-6">
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Systematically retrieve data or other content from the Site to
                create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission
                from us.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Make any unauthorized use of the Site, including collecting
                usernames and/or email addresses of users by electronic or other
                means for the purpose of sending unsolicited email, or creating
                user accounts by automated means or under false pretenses.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Use a buying agent or purchasing agent to make purchases on the
                Site.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Use the Site to advertise or offer to sell goods and services.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Circumvent, disable, or otherwise interfere with
                security-related features of the Site, including features that
                prevent or restrict the use or copying of any Content or enforce
                limitations on the use of the Site and/or the Content contained
                therein.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Engage in unauthorized framing of or linking to the Site.</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                {" "}
                Make improper use of our support services or submit false
                reports of abuse or misconduct.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive account information such as user
                passwords.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Interfere with, disrupt, or create an undue burden on the Site
                or the networks or services connected to the Site.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">Sell or otherwise transfer your profile.</li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Attempt to impersonate another user or person or use the
                username of another user.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Use any information obtained from the Site in order to harass,
                abuse, or harm another person.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Use the Site as part of any effort to compete with us or
                otherwise use the Site and/or the Content for any
                revenue-generating endeavor or commercial enterprise.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of the Site.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Attempt to bypass any measures of the Site designed to prevent
                or restrict access to the Site, or any portion of the Site.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Harass, annoy, intimidate, or threaten any of our employees or
                agents engaged in providing any portion of the Site to you.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Delete the copyright or other proprietary rights notice from any
                Content.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Copy or adapt the Site’s software, including but not limited to
                Flash, PHP, HTML, JavaScript, or other code.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
                cookies, or other similar devices (sometimes referred to as
                “spyware” or “passive collection mechanisms” or “pcms”).
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                {" "}
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including excessive
                use of capital letters and spamming (continuous posting of
                repetitive text), that interferes with any party’s uninterrupted
                use and enjoyment of the Site or modifies, impairs, disrupts,
                alters, or interferes with the use, features, functions,
                operation, or maintenance of the Site.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Except as may be the result of standard search engine or
                Internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Site, or using or launching any unauthorized script or other
                software.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Disparage, tarnish, or otherwise harm, in our opinion, us and/or
                the Site.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Use the Site in a manner inconsistent with any applicable laws
                or regulations.
              </li>
            </ul>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">USER GENERATED CONTRIBUTIONS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              The Site may invite you to chat, contribute to, or participate in
              blogs, message boards, online forums, and other functionality, and
              may provide you with the opportunity to create, submit, post,
              display, transmit, perform, publish, distribute, or broadcast
              content and materials to us or on the Site, including but not
              limited to text, writings, video, audio, photographs, graphics,
              comments, suggestions, or personal information or other material
              (collectively, “Contributions”). Contributions may be viewable by
              other users of the Site and through third-party websites. As such,
              any Contributions you transmit may be treated as non-confidential
              and non-proprietary. When you create or make available any
              Contributions, you thereby represent and warrant that:
            </p>

            <ol className="px-6">
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                The creation, distribution, transmission, public display, or
                performance, and the accessing, downloading, or copying of your
                Contributions do not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent,
                trademark, trade secret, or moral rights of any third party.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                {" "}
                You are the creator and owner of or have the necessary licenses,
                rights, consents, releases, and permissions to use and to
                authorize us, the Site, and other users of the Site to use your
                Contributions in any manner contemplated by the Site and these
                Terms of Use.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                You have the written consent, release, and/or permission of each
                and every identifiable individual person in your Contributions
                to use the name or likeness of each and every such identifiable
                individual person to enable inclusion and use of your
                Contributions in any manner contemplated by the Site and these
                Terms of Use.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions are not false, inaccurate, or misleading.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions are not unsolicited or unauthorized
                advertising, promotional materials, pyramid schemes, chain
                letters, spam, mass mailings, or other forms of solicitation.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions are not obscene, lewd, lascivious, filthy,
                violent, harassing, libelous, slanderous, or otherwise
                objectionable (as determined by us).
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions do not ridicule, mock, disparage, intimidate,
                or abuse anyone.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions are not used to harass or threaten (in the
                legal sense of those terms) any other person and to promote
                violence against a specific person or class of people.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions do not violate any applicable law,
                regulation, or rule.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions do not violate the privacy or publicity
                rights of any third party.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions do not contain any material that solicits
                personal information from anyone under the age of 18 or exploits
                people under the age of 18 in a sexual or violent manner.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions do not violate any applicable law concerning
                child pornography, or otherwise intended to protect the health
                or well-being of minors.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions do not include any offensive comments that
                are connected to race, national origin, gender, sexual
                preference, or physical handicap.
              </li>
              <li className=" list-decimal mb-4 lg:text-sm text-sm ">
                Your Contributions do not otherwise violate, or link to material
                that violates, any provision of these Terms of Use, or any
                applicable law or regulation.
              </li>
            </ol>

            <p className="mb-6 lg:text-sm text-xs" >
              Any use of the Site in violation of the foregoing violates these
              Terms of Use and may result in, among other things, termination or
              suspension of your rights to use the Site.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">CONTRIBUTION LICENSE</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              By posting your Contributions to any part of the Site or making
              Contributions accessible to the Site by linking your account from
              the Site to any of your social networking accounts, you
              automatically grant, and you represent and warrant that you have
              the right to grant, to us an unrestricted, unlimited, irrevocable,
              perpetual, non-exclusive, transferable, royalty-free, fully-paid,
              worldwide right, and license to host, use, copy, reproduce,
              disclose, sell, resell, publish, broadcast, retitle, archive,
              store, cache, publicly perform, publicly display, reformat,
              translate, transmit, excerpt (in whole or in part), and distribute
              such Contributions (including, without limitation, your image and
              voice) for any purpose, commercial, advertising, or otherwise, and
              to prepare derivative works of, or incorporate into other works,
              such Contributions, and grant and authorize sublicenses of the
              foregoing. The use and distribution may occur in any media formats
              and through any media channels.{" "}
            </p>

            <p className="mb-6 lg:text-sm text-xs" >
              This license will apply to any form, media, or technology now
              known or hereafter developed, and includes our use of your name,
              company name, and franchise name, as applicable, and any of the
              trademarks, service marks, trade names, logos, and personal and
              commercial images you provide. You waive all moral rights in your
              Contributions, and you warrant that moral rights have not
              otherwise been asserted in your Contributions.{" "}
            </p>

            <p className="mb-6 lg:text-sm text-xs" >
              We do not assert any ownership over your Contributions. You retain
              full ownership of all of your Contributions and any intellectual
              property rights or other proprietary rights associated with your
              Contributions. We are not liable for any statements or
              representations in your Contributions provided by you in any area
              on the Site. You are solely responsible for your Contributions to
              the Site and you expressly agree to exonerate us from any and all
              responsibility and to refrain from any legal action against us
              regarding your Contributions.
            </p>

            <p className="mb-6 lg:text-sm text-xs" >
              We have the right, in our sole and absolute discretion, (1) to
              edit, redact, or otherwise change any Contributions; (2) to
              re-categorize any Contributions to place them in more appropriate
              locations on the Site; and (3) to pre-screen or delete any
              Contributions at any time and for any reason, without notice. We
              have no obligation to monitor your Contributions.{" "}
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">SOCIAL MEDIA</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              As part of the functionality of the Site, you may link your
              account with online accounts you have with third-party service
              providers (each such account, a “Third-Party Account”) by either:
              (1) providing your Third-Party Account login information through
              the Site; or (2) allowing us to access your Third-Party Account,
              as is permitted under the applicable terms and conditions that
              govern your use of each Third-Party Account. You represent and
              warrant that you are entitled to disclose your Third-Party Account
              login information to us and/or grant us access to your Third-Party
              Account, without breach by you of any of the terms and conditions
              that govern your use of the applicable Third-Party Account, and
              without obligating us to pay any fees or making us subject to any
              usage limitations imposed by the third-party service provider of
              the Third-Party Account. By granting us access to any Third-Party
              Accounts, you understand that (1) we may access, make available,
              and store (if applicable) any content that you have provided to
              and stored in your Third-Party Account (the “Social Network
              Content”) so that it is available on and through the Site via your
              account, including without limitation any friend lists and (2) we
              may submit to and receive from your Third-Party Account additional
              information to the extent you are notified when you link your
              account with the Third-Party Account. Depending on the Third-Party
              Accounts you choose and subject to the privacy settings that you
              have set in such Third-Party Accounts, personally identifiable
              information that you post to your Third-Party Accounts may be
              available on and through your account on the Site. Please note
              that if a Third-Party Account or associated service becomes
              unavailable or our access to such Third-Party Account is
              terminated by the third-party service provider, then Social
              Network Content may no longer be available on and through the
              Site. You will have the ability to disable the connection between
              your account on the Site and your Third-Party Accounts at any
              time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY
              SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS
              GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
              PROVIDERS. We make no effort to review any Social Network Content
              for any purpose, including but not limited to, for accuracy,
              legality, or non-infringement, and we are not responsible for any
              Social Network Content. You acknowledge and agree that we may
              access your email address book associated with a Third-Party
              Account and your contacts list stored on your mobile device or
              tablet computer solely for purposes of identifying and informing
              you of those contacts who have also registered to use the Site.
              You can deactivate the connection between the Site and your
              Third-Party Account by contacting us using the contact information
              below or through your account settings (if applicable). We will
              attempt to delete any information stored on our servers that was
              obtained through such Third-Party Account, except the username and
              profile picture that become associated with your account.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">SUBMISSIONS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback, or other information regarding the
              Site (“Submissions”) provided by you to us are non-confidential
              and shall become our sole property. We shall own exclusive rights,
              including all intellectual property rights, and shall be entitled
              to the unrestricted use and dissemination of these Submissions for
              any lawful purpose, commercial or otherwise, without
              acknowledgment or compensation to you. You hereby waive all moral
              rights to any such Submissions, and you hereby warrant that any
              such Submissions are original with you or that you have the right
              to submit such Submissions. You agree there shall be no recourse
              against us for any alleged or actual infringement or
              misappropriation of any proprietary right in your Submissions.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">U.S. GOVERNMENT RIGHTS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              Our services are “commercial items” as defined in Federal
              Acquisition Regulation (“FAR”) 2.101. If our services are acquired
              by or on behalf of any agency not within the Department of Defense
              (“DOD”), our services are subject to the terms of these Terms of
              Use in accordance with FAR 12.212 (for computer software) and FAR
              12.211 (for technical data). If our services are acquired by or on
              behalf of any agency within the Department of Defense, our
              services are subject to the terms of these Terms of Use in
              accordance with Defense Federal Acquisition Regulation (“DFARS”)
              227.7202‑3. In addition, DFARS 252.227‑7015 applies to technical
              data acquired by the DOD. This U.S. Government Rights clause is in
              lieu of, and supersedes, any other FAR, DFARS, or other clause or
              provision that addresses government rights in computer software or
              technical data under these Terms of Use.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">SITE MANAGEMENT</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              We reserve the right, but not the obligation, to: (1) monitor the
              Site for violations of these Terms of Use; (2) take appropriate
              legal action against anyone who, in our sole discretion, violates
              the law or these Terms of Use, including without limitation,
              reporting such user to law enforcement authorities; (3) in our
              sole discretion and without limitation, refuse, restrict access
              to, limit the availability of, or disable (to the extent
              technologically feasible) any of your Contributions or any portion
              thereof; (4) in our sole discretion and without limitation,
              notice, or liability, to remove from the Site or otherwise disable
              all files and content that are excessive in size or are in any way
              burdensome to our systems; and (5) otherwise manage the Site in a
              manner designed to protect our rights and property and to
              facilitate the proper functioning of the Site.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">PRIVACY POLICY</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              We care about data privacy and security. By using the Site, you
              agree to be bound by our Privacy Policy posted on the Site, which
              is incorporated into these Terms of Use. Please be advised the
              Site is hosted in the United States. If you access the Site from
              any other region of the world with laws or other requirements
              governing personal data collection, use, or disclosure that differ
              from applicable laws in the United States, then through your
              continued use of the Site, you are transferring your data to the
              United States, and you agree to have your data transferred to and
              processed in the United States.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">COPYRIGHT INFRINGEMENTS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              We respect the intellectual property rights of others. If you
              believe that any material available on or through the Site
              infringes upon any copyright you own or control, please
              immediately notify us using the contact information provided below
              (a “Notification”). A copy of your Notification will be sent to
              the person who posted or stored the material addressed in the
              Notification. Please be advised that pursuant to applicable law
              you may be held liable for damages if you make material
              misrepresentations in a Notification. Thus, if you are not sure
              that material located on or linked to by the Site infringes your
              copyright, you should consider first contacting an attorney.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">TERM AND TERMINATION</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              These Terms of Use shall remain in full force and effect while you
              use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS
              OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
              (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
              REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH
              OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
              TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
              TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR
              ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
              TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.{" "}
            </p>
            <p className="mb-6 lg:text-sm text-xs" >
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">MODIFICATIONS AND INTERRUPTIONS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              We reserve the right to change, modify, or remove the contents of
              the Site at any time or for any reason at our sole discretion
              without notice. However, we have no obligation to update any
              information on our Site. We also reserve the right to modify or
              discontinue all or part of the Site without notice at any time. We
              will not be liable to you or any third party for any modification,
              price change, suspension, or discontinuance of the Site.{" "}
            </p>
            <p className="mb-6 lg:text-sm text-xs" >
              We cannot guarantee the Site will be available at all times. We
              may experience hardware, software, or other problems or need to
              perform maintenance related to the Site, resulting in
              interruptions, delays, or errors. We reserve the right to change,
              revise, update, suspend, discontinue, or otherwise modify the Site
              at any time or for any reason without notice to you. You agree
              that we have no liability whatsoever for any loss, damage, or
              inconvenience caused by your inability to access or use the Site
              during any downtime or discontinuance of the Site. Nothing in
              these Terms of Use will be construed to obligate us to maintain
              and support the Site or to supply any corrections, updates, or
              releases in connection therewith.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">GOVERNING LAW</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              These Terms of Use and your use of the Site are governed by and
              construed in accordance with the laws of the State of Delaware
              applicable to agreements made and to be entirely performed within
              the State of Delaware, without regard to its conflict of law
              principles.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">DISPUTE RESOLUTION</h1>
            <h3 className="font-gilroy-Extrabold mb-4" >Binding Arbitration</h3>
            <p className="mb-6 lg:text-sm text-xs" >
              If the Parties are unable to resolve a Dispute through informal
              negotiations, the Dispute (except those Disputes expressly
              excluded below) will be finally and exclusively resolved by
              binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION,
              YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
              The arbitration shall be commenced and conducted under the
              Commercial Arbitration Rules of the American Arbitration
              Association (“AAA”) and, where appropriate, the AAA’s
              Supplementary Procedures for Consumer Related Disputes (“AAA
              Consumer Rules”), both of which are available at the AAA website
              www.adr.org. Your arbitration fees and your share of arbitrator
              compensation shall be governed by the AAA Consumer Rules and,
              where appropriate, limited by the AAA Consumer Rules. If such
              costs are determined by the arbitrator to be excessive, we will
              pay all arbitration fees and expenses. The arbitration may be
              conducted in person, through the submission of documents, by
              phone, or online. The arbitrator will make a decision in writing,
              but need not provide a statement of reasons unless requested by
              either Party. The arbitrator must follow applicable law, and any
              award may be challenged if the arbitrator fails to do so. Except
              where otherwise required by the applicable AAA rules or applicable
              law, the arbitration will take place in United States, Delaware.
              Except as otherwise provided herein, the Parties may litigate in
              court to compel arbitration, stay proceedings pending arbitration,
              or to confirm, modify, vacate, or enter judgment on the award
              entered by the arbitrator.
            </p>
            <p className="mb-6 lg:text-sm text-xs" >
              If for any reason, a Dispute proceeds in court rather than
              arbitration, the Dispute shall be commenced or prosecuted in the
              state and federal courts located in United States, Delaware, and
              the Parties hereby consent to, and waive all defenses of lack of
              personal jurisdiction, and forum non conveniens with respect to
              venue and jurisdiction in such state and federal courts.
              Application of the United Nations Convention on Contracts for the
              International Sale of Goods and the Uniform Computer Information
              Transaction Act (UCITA) are excluded from these Terms of Use.
            </p>
            <p className="mb-6 lg:text-sm text-xs" >
              In no event shall any Dispute brought by either Party related in
              any way to the Site be commenced more than one (1) years after the
              cause of action arose. If this provision is found to be illegal or
              unenforceable, then neither Party will elect to arbitrate any
              Dispute falling within that portion of this provision found to be
              illegal or unenforceable and such Dispute shall be decided by a
              court of competent jurisdiction within the courts listed for
              jurisdiction above, and the Parties agree to submit to the
              personal jurisdiction of that court.
            </p>
            <h3 className="font-gilroy-Extrabold mb-4" >Restrictions</h3>
            <p className="mb-6 lg:text-sm text-xs" >
              The Parties agree that any arbitration shall be limited to the
              Dispute between the Parties individually. To the full extent
              permitted by law, (a) no arbitration shall be joined with any
              other proceeding; (b) there is no right or authority for any
              Dispute to be arbitrated on a class-action basis or to utilize
              class action procedures; and (c) there is no right or authority
              for any Dispute to be brought in a purported representative
              capacity on behalf of the general public or any other persons.
            </p>
            <h3 className="font-gilroy-Extrabold mb-4" >Exceptions to Arbitration</h3>
            <p className="mb-6 lg:text-sm text-xs" >
              The Parties agree that the following Disputes are not subject to
              the above provisions concerning binding arbitration: (a) any
              Disputes seeking to enforce or protect, or concerning the validity
              of, any of the intellectual property rights of a Party; (b) any
              Dispute related to, or arising from, allegations of theft, piracy,
              invasion of privacy, or unauthorized use; and (c) any claim for
              injunctive relief. If this provision is found to be illegal or
              unenforceable, then neither Party will elect to arbitrate any
              Dispute falling within that portion of this provision found to be
              illegal or unenforceable and such Dispute shall be decided by a
              court of competent jurisdiction within the courts listed for
              jurisdiction above, and the Parties agree to submit to the
              personal jurisdiction of that court.
            </p>

            <h3 className="font-gilroy-Extrabold mb-4" >CORRECTIONS</h3>
            <p className="mb-6 lg:text-sm text-xs" >
              There may be information on the Site that contains typographical
              errors, inaccuracies, or omissions, including descriptions,
              pricing, availability, and various other information. We reserve
              the right to correct any errors, inaccuracies, or omissions and to
              change or update the information on the Site at any time, without
              prior notice.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">DISCLAIMER</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
              THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
              RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
              WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND
              YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
              ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE
              CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
              LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
              INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
              PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
              ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
              USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
              AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION
              OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS,
              VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
              THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
              OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE
              OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
              TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
              WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
              PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
              THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
              APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE
              WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
              ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
              PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
              THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
              JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">LIMITATIONS OF LIABILITY</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
              ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU
              FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION,
              WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO
              US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
              ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
              LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
              OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
              ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
              HAVE ADDITIONAL RIGHTS.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">INDEMNIFICATION</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys’ fees and expenses, made by any third party due to or
              arising out of: (1) your Contributions; (2) use of the Site; (3)
              breach of these Terms of Use; (4) any breach of your
              representations and warranties set forth in these Terms of Use;
              (5) your violation of the rights of a third party, including but
              not limited to intellectual property rights; or (6) any overt
              harmful act toward any other user of the Site with whom you
              connected via the Site. Notwithstanding the foregoing, we reserve
              the right, at your expense, to assume the exclusive defense and
              control of any matter for which you are required to indemnify us,
              and you agree to cooperate, at your expense, with our defense of
              such claims. We will use reasonable efforts to notify you of any
              such claim, action, or proceeding which is subject to this
              indemnification upon becoming aware of it.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">USER DATA</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              We will maintain certain data that you transmit to the Site for
              the purpose of managing the performance of the Site, as well as
              data relating to your use of the Site. Although we perform regular
              routine backups of data, you are solely responsible for all data
              that you transmit or that relates to any activity you have
              undertaken using the Site. You agree that we shall have no
              liability to you for any loss or corruption of any such data, and
              you hereby waive any right of action against us arising from any
              such loss or corruption of such data.
            </p>
          </section>
          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              Visiting the Site, sending us emails, and completing online forms
              constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SITE. You hereby waive any rights or requirements under any
              statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </p>
          </section>
          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">CALIFORNIA USERS AND RESIDENTS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.
            </p>
          </section>

          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">MISCELLANEOUS</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              These Terms of Use and any policies or operating rules posted by
              us on the Site or in respect to the Site constitute the entire
              agreement and understanding between you and us. Our failure to
              exercise or enforce any right or provision of these Terms of Use
              shall not operate as a waiver of such right or provision. These
              Terms of Use operate to the fullest extent permissible by law. We
              may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of
              these Terms of Use is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Terms of Use and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Terms of Use or use of the
              Site. You agree that these Terms of Use will not be construed
              against us by virtue of having drafted them. You hereby waive any
              and all defenses you may have based on the electronic form of
              these Terms of Use and the lack of signing by the parties hereto
              to execute these Terms of Use.
            </p>
          </section>
          <section className=" font-gilroy-Light mb-6">
            <h1 className="font-gilroy-Extrabold mb-4 lg:text-lg text-sm ">CONTACT US</h1>
            <p className="mb-6 lg:text-sm text-xs" >
              In order to resolve a complaint regarding the Site or to receive
              further information regarding use of the Site, please contact us
              at:
            </p>
            <address className="font-gilroy-Extrabold lg:text-lg text-sm">
              CastingAfrica Inc <br /> 256 Chapman Rd, Newark, STE 105-4, USA <br /> Newark,
              DE 19702<br /> United States <br /> Phone: (+233)552711321 <br /> info@casting.africa
            </address>
          </section>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
