import React, { useContext, useState } from "react";
import { ProfileFooter } from ".";
import PackageContext from "../../../context/context";
import TalentItemCard from "../Communities/Comps/talentItemCard";
import { Pagination } from "../Pagination";

const Following = () => {
  const context = useContext(PackageContext);
  const talent = context.talent;
  const [setNext, setSetNext] = useState(8);
  let pagesize = setNext;


  return (
    <div className=" flex flex-col items-center justify-center ">
      <div className="grid md:grid-cols-3 lg:grid-cols-4 ">
        {
          talent?.talentFollowings?.length ? talent?.talentFollowings
          ?.slice(setNext - pagesize, setNext)
          .map((follow, key) => (
            <div key={key}>
              <TalentItemCard
                key={key}
                talent={follow?.following}
                width="w-64"
                feature_type="follow_feature"
              />
            </div>
          )) : "No Followings found"
        }
      </div>
      <Pagination
        data={talent?.talentFollowings || []}
        pagesize={8}
        nextSet={setSetNext}
      />
    </div>
  );
};

export default Following;
