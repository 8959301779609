import { gql } from "@apollo/client";

export const GET_TALENT = gql`
  query getTalent($talentID: ID) {
    talent(talentID: $talentID) {
      casting_africa_talent_id
      talent_first_name
      talent_last_name
      talent_dob
      gender
      calling_code
      talent_phonenumbers
      verification_status
      talent_media_path
      account_type
      account_status
      resume
      photos
      audio
      videos
      talentPayments {
        amount
        expires_on
        payment_plan
        payment_duration
        created_at
      }
      talentFollowers {
        talent {
          casting_africa_talent_id
        }
        follower {
          casting_africa_talent_id
          talent_first_name
          talent_last_name
          talent_media_path
          talent_dob
          gender
          talentFollowers {
            talent_follower_id
          }
          verification_status
          talentAddress {
            city
            country
          }
        }
      }

      talentFollowings {
        talent {
          casting_africa_talent_id
        }
        following {
          casting_africa_talent_id
          talent_first_name
          talent_last_name
          talent_media_path
          talent_dob
          gender
          verification_status
          talentFollowers {
            talent_follower_id
          }
          talentAddress {
            city
            country
          }
        }
      }
      talentAddress {
        street_address
        city
        region_or_state
        zip_code
        country
      }
      professionalDetails {
        casting_africa_talent_details
      }
      talentSocials {
        social_media_type
        social_media_handle
      }
      userAccount {
        email
      }
    }
  }
`;

export const TALENTS = gql`
  query talents ($country: String) {
    talents (country: $country) {
      casting_africa_talent_id
      talent_first_name
      talent_dob
      gender
      verification_status
      talent_media_path
      resume
      photos
      audio
      videos
      professionalDetails {
        casting_africa_talent_details
      }
      talentAddress {
        city
        country
      }
    }
  }
`;

export const SPOTLIGHT_PICKS = gql`
  query spotLightPicks($country: String) {
    spotLightPicks(country: $country) {
      casting_africa_talent_id
      talent_first_name
      talent_dob
      verification_status
      talent_media_path
      resume
      photos
      audio
      videos
      professionalDetails {
        casting_africa_talent_details
      }
      talentAddress {
        city
        country
      }
    }
  }
`;
