import React, { useState, useContext } from "react";

import VERIFICATION from "../../../../assets/images/verification_bade.png";
import YOUTUBE from "../../../../assets/images/youtube_square.png";
import IMDB from "../../../../assets/images/IMDb_square.png";
import FACEBOOK from "../../../../assets/images/facebook_square.png";
import TWITTER from "../../../../assets/images/twitter_square.png";
import INSTAGRAM from "../../../../assets/images/icon_instagram.png";
import PackageContent from "../../../../context/context";


import { toggleViews } from "../../../../services/service";
import { NotificationManager } from "react-notifications";

const ProfileViewHandler = ({ user, nav }) => {
  const context = useContext(PackageContent);
  const [position, setPosition] = useState(0);
  
  return (
    <div>
      <div className="flex flex-col  items-center mb-1">
        {/* Image place holder */}
        <div
          style={{
            backgroundImage: `url(${user?.img})`,
            backgroundRepeat: "no-repeat",
            objectFit: "contain",
            backgroundSize: "100%",
          }}
          className=" w-64 h-80 flex items-end justify-end p-2"
        ></div>
        {/* End of image place holder */}
        {/* user information */}
        <div className="h-60  flex flex-col px-4 cursor-pointer   items-center">
          <div className="flex items-center">
            <h1 className="text-3xl">
              {user?.first_name ? user?.first_name : user?.company_name}
            </h1>
            {user?.company_details?.verification_status && (
              <img className="" src={VERIFICATION} alt="" />
            )}
          </div>

          <div className="flex items-center">
            <span className="border px-4 text-lg rounded-tr-lg rounded-bl-lg bg-primary text-white">
              {user?.job_type}
            </span>
          </div>

          <div className="flex items-center">
            {
              <img
                className="w-10 h-10"
                src={user?.company_details?.company_logo}
                alt=""
              />
            }
            <h1 className=" font-gilroy-Light text-xl">
              {user?.company_details?.company_name}
            </h1>
          </div>

          <div className="flex items-center ">
            <span className="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <span className="mr-1 font-gilroy-Light text-lg">
              {user?.region},
              {user?.country}
            </span>
          </div>

          <div className="flex items-center">
            <h1 className="font-gilroy-Light">
              {user?.company_details?.website}
            </h1>
          </div>

          {/* end of socials */}
          {/* Contacts interaction */}
          <div className="flex items-center justify-center ">
            <div className="border flex cursor-pointer px-4 py-1 mr-1 bg-primary rounded-lg justify-between">
              <span className="mr-2">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.636719 11.1515C0.636719 5.65202 5.03183 0.696167 11.0973 0.696167C17.027 0.696167 21.5161 5.55792 21.5161 11.1202C21.5161 17.5711 16.2545 21.5756 11.0764 21.5756C9.3643 21.5756 7.46428 21.1155 5.94008 20.2164C5.40766 19.8922 4.95875 19.6518 4.38457 19.84L2.27575 20.4673C1.74333 20.6346 1.2631 20.2164 1.4197 19.6518L2.11916 17.3098C2.23399 16.9856 2.21311 16.6406 2.04608 16.3688C1.14826 14.7168 0.636719 12.908 0.636719 11.1515ZM9.71959 11.1515C9.71959 11.8939 10.3146 12.4898 11.0559 12.5003C11.7971 12.5003 12.3921 11.8939 12.3921 11.162C12.3921 10.4197 11.7971 9.82371 11.0559 9.82371C10.3251 9.81326 9.71959 10.4197 9.71959 11.1515ZM14.5333 11.162C14.5333 11.8939 15.1284 12.5003 15.8696 12.5003C16.6108 12.5003 17.2059 11.8939 17.2059 11.162C17.2059 10.4197 16.6108 9.8237 15.8696 9.8237C15.1284 9.8237 14.5333 10.4197 14.5333 11.162ZM6.24241 12.5003C5.51163 12.5003 4.90613 11.8939 4.90613 11.162C4.90613 10.4197 5.50119 9.82371 6.24241 9.82371C6.98363 9.82371 7.57869 10.4197 7.57869 11.162C7.57869 11.8939 6.98363 12.4898 6.24241 12.5003Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span className="text-white font-gilroy-Light">Message</span>
            </div>
            <div  onClick={() => NotificationManager.success("Following Activated", "Casting Africa", 3000) }  className="border flex cursor-pointer border-primary items-center mr-1 px-4 py-1  rounded-lg justify-between">
              <span className="mr-2">
                <svg
                  width="25"
                  height="26"
                  viewBox="0 0 25 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.89563 13.5998C12.765 13.5998 15.065 11.1768 15.065 8.15408C15.065 5.13135 12.765 2.70834 9.89563 2.70834C7.02629 2.70834 4.72623 5.13135 4.72623 8.15408C4.72623 11.1768 7.02629 13.5998 9.89563 13.5998ZM9.89551 16.2665C5.68116 16.2665 2.08301 16.9672 2.08301 19.767C2.08301 22.5656 5.65926 23.2917 9.89551 23.2917C14.1088 23.2917 17.708 22.5909 17.708 19.7911C17.708 16.9914 14.1318 16.2665 9.89551 16.2665ZM20.7266 10.3869H21.9798C22.4956 10.3869 22.9162 10.8304 22.9162 11.3744C22.9162 11.9185 22.4956 12.362 21.9798 12.362H20.7266V13.6374C20.7266 14.1814 20.3071 14.625 19.7902 14.625C19.2743 14.625 18.8537 14.1814 18.8537 13.6374V12.362H17.6027C17.0857 12.362 16.6662 11.9185 16.6662 11.3744C16.6662 10.8304 17.0857 10.3869 17.6027 10.3869H18.8537V9.11259C18.8537 8.56857 19.2743 8.125 19.7902 8.125C20.3071 8.125 20.7266 8.56857 20.7266 9.11259V10.3869Z"
                    fill="#3A77FF"
                  />
                </svg>
              </span>
              <span className="text-primary font-gilroy-Light">Follow</span>
            </div>
            <div className="flex">
              <span className="mr-1">
                <img src={YOUTUBE} alt="" />
              </span>
              <span className="mr-1">
                <img className="w-7" src={INSTAGRAM} alt="" />
              </span>
              <span className="mr-1">
                <img src={IMDB} alt="" />
              </span>
              <span className="mr-1">
                <img src={FACEBOOK} alt="" />
              </span>
              <span>
                <img src={TWITTER} alt="" />
              </span>
            </div>
          </div>
          {/* End of contact interaction */}
        </div>

        {/* End of user information */}
      </div>

      <div className="flex md:flex-nowrap flex-wrap justify-center mb-6">
        {nav.map((tabs, key) => (
          <div
            onClick={() => {
              setPosition(key);
              context.updateCompanyDetails(user)
            }}
            className={`mr-4 ${
              position === key
                ? "text-blue-600 font-gilroy-Extrabold underline"
                : "text-gray-500"
            } text-lg cursor-pointer font-gilroy-Light`}
            key={key}
          >
            <span className="mr-2">{tabs.tab_name}</span>
            <span>{tabs.count.toLocaleString()}</span>
          </div>
        ))}
      </div>
      <div className="">{toggleViews(nav, position)}</div>
    </div>
  );
};

export default ProfileViewHandler;
