import React, { useState } from "react";
import {
  agent_filters,
  sample_agents_directors,
} from "../../../../config/utilities";
import VERTICALAD from "../../../../assets/images/verticalad.png";
import _ from "lodash";
import { Pagination } from "../../Pagination";
import ClientItem from "../../Communities/Comps/clientItem";

const Agency = () => {
  const [showAdvanceFilters] = useState(false);
  const [setNext, setSetNext] = useState(5);
  let pagesize = 3;

  return (
    <div className="w-full flex justify-center py-14 mt-16">
      <div className=" w-full md:px-0 px-2 md:w-4/5 py-4 ">
        <h1 className="text-xl md:text-2xl lg:text-4xl mb-4">
          Agency Directory
        </h1>
        <div className="flex md:flex-nowrap flex-wrap md:overflow-x-hidden  overflow-x-auto  ">
          {agent_filters.map((filter, key) => (
            <div
              key={key}
              className="border cursor-pointer font-gilroy-Light md:text-sm md:mb-0 mb-2 mr-2  flex justify-center rounded-full px-2 md:px-4 lg:px-8 md:py-1 bg-primary text-white hover:font-bold"
            >
              <span>{filter.filter_type}</span>
              <span>({filter.count.toLocaleString()})</span>
            </div>
          ))}
          
        </div>
        {/* More filters */}
        <MoreFilters showAdanceFilters={showAdvanceFilters} />

        <div className="w-full flex mt-4">
          <div className="w-full pb-10 flex flex-col">
            <div className="overflow-y-auto mb-10 w- flex justify-between flex-wrap ">
              {_.chunk(sample_agents_directors, 5)
                .slice(setNext - pagesize, setNext)
                .map((part, key) => (
                  <div key={key} className="w-full flex flex-col">
                    <div className="flex md:flex-nowrap flex-wrap justify-center w-full">
                      {part.map((talent, index) => (
                        <ClientItem
                          key={index}
                          width="w-72"
                          talent={talent}
                          hasType={true}
                        />
                      ))}
                    </div>
                    {key > 0 && key % 2 === 1 ? (
                      <div className="w-full py-10 flex justify-center">
                        <img src={VERTICALAD} alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </div>
            <div className="">
              <Pagination
                data={_.chunk(sample_agents_directors, 4)}
                pagesize={3}
                nextSet={setSetNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MoreFilters = ({ showAdanceFilters }) => {
  return (
    <div>
      <div className="w-full border flex flex-col items-center justify-center py-5 rounded-xl bg-blue-100 mt-4">
        <div className="flex md:flex-row flex-col w-full px-5 mb-4 items-center">
          <input
            type="text"
            className="border w-full rounded-xl px-4 outline-none bg-blue-200 placeholder-gray-600 text-lg py-2 font-gilroy-Light md:mr-4 mb-2"
            placeholder="Keyword"
          />
          <div className=" border  w-full  md:w-36 px-3 flex justify-between md:justify-around  items-center py-2 rounded-lg bg-primary text-white">
            <span className="text-lg font-gilroy-Light">Search</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>

        <div className="flex md:flex-row flex-col mb-2 md:mb-4 w-full px-5 items-center">
          <select
            className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
            name=""
            id=""
          >
            <option value="">Country</option>
          </select>

          <select
            className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
            name=""
            id=""
          >
            <option value="">State/Region</option>
          </select>

          <select
            className="w-full mb-2 md:w-2/6 py-4 md:mr-4 rounded-xl px-4 outline-none font-gilroy-Light text-lg bg-blue-200"
            name=""
            id=""
          >
            <option value="">Category</option>
          </select>
        </div>
      </div>
      {/* Filter output */}
      <div className="flex md:flex-row flex-col items-center justify-between  mt-4">
        <div className="flex justify-center items-center">
          <span className="flex text-lg  mr-2 font-gilroy-Light justify-center items-center">
            <span className="mr-2">Accra</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
          <span className="flex mr-2 text-lg font-gilroy-Light justify-center items-center">
            <span className="mr-2">25- 35</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
          <span className="flex mr-2 text-lg font-gilroy-Light justify-center items-center">
            <span className="mr-2">Male</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
        </div>
        <div>
          <select
            className="py-2  rounded-xl px-2 outline-none font-gilroy-Light text-lg bg-blue-200"
            name=""
            id=""
          >
            <option value="">Best match first</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Agency;
