import React from "react";

const Webinars = () => {
    return (
      <div className="h-full mt-20">
        <h1>Webinars</h1>
      </div>
    );
}


export default Webinars;