import React from "react";
import LOST from "../../assets/images/lost.jpg"

const NotFound = () => {
    return (
        <div className="h-screen mt-24 bg-gray-100 flex flex-col justify-center items-center">
            <img src={LOST} alt="Lost" />
            <h1 className="lg:text-6xl lg:mb-4  px-2 py-2 rounded-xl text-black font-gilroy-Medium">Oops Looks like you lost your way :)</h1>
            <p className="lg:text-4xl text-blue-300 font-gilroy-Light">Don't worry. <a className="underline text-red-300" href="/">Go Back Home</a> </p>
        </div>
    )
}

export default NotFound;