import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useHistory } from "react-router-dom";
import SQUARED from "../../../../assets/images/squaread.png";

const JobDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const [applyState, setApplyState] = useState("");

  const { selectedJob } = location?.state || {};

  const handleApply = () => {
      const loggedUser = JSON.parse(sessionStorage.getItem("AUTH_USER"));
      const type = loggedUser?.account_type;
    if (type === "AGENT") {
      setApplyState("AGENT");
    } else if (type === "CREATOR") {
      setApplyState("CREATOR");
    } else {
      setApplyState("");
      NotificationManager.warning("Join 'Casting Africa' to use our easy apply service.", "Casting Africa");
    }
  };

  useEffect(() => {
    if (!selectedJob) {
      history.goBack();
    } 
  }, []);

  return (
    <div className="w-full h-full mt-24 flex py-6 justify-center overflow-scroll">
      <div className="hidden lg:w-64 lg:flex flex-col  px-4">
        <div className="mb-2 rounded-xl px-3 py-2 h-96 bg-blue-200">
          <h1 className=" text-2xl">Featured Call</h1>
          <div>List of featured calls</div>
        </div>

        <img className="mb-2" src={SQUARED} alt="" />
        <img className="mb-2" src={SQUARED} alt="" />
      </div>
      <div className="px-4 flex flex-col w-full lg:w-3/6 py-6">
        <div className=" w-full  py-2 flex lg:flex-row flex-col items-center lg:justify-between">
          <div>
            <h1 className="text-2xl lg:mb-0 mb-4">{selectedJob?.job_title}</h1>
            <div className="flex lg:flex-row flex-col lg:mb-0 mb-4">
              <div className="lg:mb-0 mb-2">
                {selectedJob?.tags.map((tag, key) => (
                  <span
                    key={key}
                    className={`bg-${tag.color} ${tag.text_color} text-sm font-gilroy-Light px-2 py-1 rounded-tr-lg rounded-bl-lg mr-1 `}
                  >
                    {tag.tag_name}
                  </span>
                ))}
              </div>
              <h1 className="font-gilroy-Light">Expires: 17th November 2022</h1>
            </div>
          </div>

          <button className="border flex justify-center items-center bg-primary w-32 rounded-xl">
            <span className="text-2xl font-gilroy-Light mr-2 text-white">
              Share
            </span>
            <svg
              className="w-5 h-5"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.1479 0.866113C18.7658 0.476675 18.2813 0.203331 17.7504 0.0776877C17.2195 -0.0479556 16.6639 -0.0207656 16.1479 0.156114L1.99785 4.88611C1.42717 5.07291 0.92854 5.43226 0.570805 5.91455C0.21307 6.39684 0.013868 6.97828 0.0006979 7.57862C-0.0124722 8.17895 0.161039 8.76857 0.49728 9.26608C0.83352 9.7636 1.31591 10.1445 1.87785 10.3561L7.11785 12.3561C7.23717 12.4017 7.34581 12.4715 7.437 12.5609C7.52818 12.6504 7.59996 12.7577 7.64785 12.8761L9.64785 18.1261C9.85144 18.6801 10.2209 19.1579 10.7058 19.4943C11.1908 19.8307 11.7676 20.0094 12.3579 20.0061H12.4279C13.0289 19.9952 13.6114 19.7964 14.0938 19.4378C14.5762 19.0791 14.9343 18.5785 15.1179 18.0061L19.8479 3.83611C20.0199 3.32505 20.0455 2.77605 19.9218 2.2512C19.7981 1.72634 19.53 1.24656 19.1479 0.866113ZM17.9979 3.20611L13.2179 17.3861C13.1623 17.5656 13.0509 17.7226 12.8997 17.8341C12.7486 17.9457 12.5657 18.006 12.3779 18.0061C12.1911 18.0092 12.0078 17.9553 11.8524 17.8517C11.697 17.7481 11.5769 17.5997 11.5079 17.4261L9.50785 12.1761C9.36286 11.7946 9.13924 11.4479 8.85153 11.1585C8.56382 10.8691 8.21845 10.6434 7.83785 10.4961L2.58785 8.49611C2.41074 8.43122 2.2585 8.31231 2.15264 8.1562C2.04677 8.00009 1.99262 7.81466 1.99785 7.62611C1.998 7.43826 2.05827 7.25538 2.16983 7.10424C2.28138 6.95309 2.43838 6.84162 2.61785 6.78611L16.7979 2.05611C16.9607 1.98976 17.1392 1.97199 17.3119 2.00494C17.4846 2.03789 17.644 2.12016 17.7709 2.2418C17.8979 2.36344 17.9868 2.51924 18.0271 2.69037C18.0674 2.86151 18.0572 3.04064 17.9979 3.20611Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        {/* Content */}
        <div className="mb-4">
          <div className="border-b py-4">
            <h1 className="font-gilroy-Medium text-xl mb-2">
              Project Description
            </h1>
            <p className="font-gilroy-Light">
              {selectedJob?.job_long_description}
            </p>
          </div>
          <div className="border-b">
            <h1 className="font-gilroy-Medium text-xl mb-2">
              Submission Instructions
            </h1>
            <p className="font-gilroy-Light">
              {selectedJob?.job_long_description}
            </p>
          </div>
          <div className="border-b">
            <h1 className="font-gilroy-Medium text-xl mb-2">Roles</h1>
            <p className="font-gilroy-Light">
              {selectedJob?.job_long_description}
            </p>
          </div>
          <div className="border-b">
            <h1 className="font-gilroy-Medium text-xl mb-2">Renumeration</h1>
            <p className="font-gilroy-Light"> Type: Paid </p>
          </div>
          <div className="border-b">
            <h1 className="font-gilroy-Medium text-xl mb-2">Company</h1>
            <p className="font-gilroy-Light">
              {selectedJob?.company_name}, {selectedJob?.region},{" "}
              {selectedJob?.country}
            </p>
          </div>
          <div className="border-b">
            <h1 className="font-gilroy-Medium text-xl mb-2">Media</h1>
            <p className="font-gilroy-Light"> </p>
          </div>
        </div>

        <div className="flex justify-center ">
          <button
            onClick={handleApply}
            className=" w-44 py-2 mb-10 lg:py-1 text-white rounded-lg bg-primary"
          >
            Apply
          </button>
        </div>
      </div>
      <CreatorJobPopUp applyState={applyState} setApplyState={setApplyState} />
      <AgentJobPopUp applyState={applyState} setApplyState={setApplyState} />
    </div>
  );
};

const AgentJobPopUp = ({ applyState, setApplyState }) => {
  return (
    <div
      style={{
        backgroundColor: `rgba(0,0,0, 0.1)`,
      }}
      className={`${
        applyState === "AGENT" ? "flex" : "hidden"
      } fixed  flex-col justify-center items-center  w-full h-full`}
    >
      <div className="bg-white w-full lg:w-3/6 h-80 flex flex-col lg:px-2 py-2  shadow-2xl">
        <div className="w-full flex justify-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#F00"
            onClick={() => setApplyState("")}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="w-full px-4 h-full flex flex-col justify-center items-center">
          <h1 className="text-xl font-gilroy-Light w-full lg:w-4/6 mb-2">Talent</h1>
          <select className="border rounded-lg py-3 outline-none w-full  lg:w-4/6 mb-4 font-gilroy-Light text-lg px-4 lg:px-2">
            <option value="Jennifer Dadzie">Jennifer Dadzie</option>
            <option value="Peter Awuni">Peter Awuni</option>
            <option value="Prince Henry">Prince Henry</option>
            <option value="Obaa Cecil Agyeman">Obaa Cecil Agyeman</option>
            <option value="Joel Ankrah">Joel Ankrah</option>
          </select>
          <input
            className="border rounded-lg py-3 outline-none w-full lg:w-4/6 mb-4 font-gilroy-Light text-lg px-2"
            type="text"
            placeholder="Insert youtube link of your self tape video"
          />
          <div className="flex justify-center">
            <button className="w-48 bg-primary text-white rounded-full py-3">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CreatorJobPopUp = ({ applyState, setApplyState }) => {
  return (
    <div
      style={{
        backgroundColor: `rgba(0,0,0, 0.1)`,
      }}
      className={`fixed  ${
        applyState === "CREATOR" ? "flex" : "hidden"
      } flex-col justify-center items-center transition duration-150  w-full h-full`}
    >
      <div className="bg-white w-full lg:w-3/6 h-80 flex flex-col px-2 py-2  shadow-2xl">
        <div className="w-full flex justify-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#F00"
            onClick={() => setApplyState("")}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="w-full px-2 h-full flex flex-col justify-center items-center">
          <h1 className="text-xl font-gilroy-Light w-full lg:w-4/6 mb-2">
            Self Tape Video
          </h1>
          <input
            className="border rounded-lg py-3 outline-none w-full lg:w-4/6 mb-4 font-gilroy-Light text-lg px-2"
            type="text"
            placeholder="Insert youtube link of your self tape video"
          />
          <div className="flex justify-center">
            <button className="w-48 bg-primary text-white rounded-full py-3">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
