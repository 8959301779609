import React, { useState } from "react";
import ANNI from "../../assets/images/Monologue/anni_mono.png";
import BADGE from "../../assets/images/Monologue/badge_1.png";
import KENNY from "../../assets/images/Monologue/kenny_winner.png";
import KUKUAA from "../../assets/images/Monologue/kukuaa_mono.png";
import KWASI from "../../assets/images/Monologue/kwasi_mono.png";
import MODE from "../../assets/images/Monologue/mode.png";
import NEXT from "../../assets/images/Monologue/next.png";
import OPEN from "../../assets/images/Monologue/open.png";
import PRIZE from "../../assets/images/Monologue/prize.png";
import HOW_TO from "../../assets/images/Monologue/how_to.png";
import WILMA from "../../assets/images/Monologue/wilma_winner.png";
import MAIN_BG from "../../assets/images/Monologue/man_mono_bg.png";
import WIN from "../../assets/images/Monologue/win.png";
import { NotificationManager } from "react-notifications";
import UserAnalyticsEventTracker from "./userAnalyticsEventTracker";

const Monologue = () => {
  const [isLoggedIn] = useState(
    JSON.parse(sessionStorage.getItem("AUTH_USER"))
  );

  const gaEventTracker = UserAnalyticsEventTracker("Monologue")
  const accessMonologue = () => isLoggedIn && ["PREMIUM", "PRO"].includes(isLoggedIn?.user.talent.account_type) && isLoggedIn?.user.talent.account_status === "ACTIVE"

  const logInToParticipate = () => {
    gaEventTracker("Monologue Download Bait", "Monologue Download Bait Button action", "Monologue Download Bait")
    if (!isLoggedIn)
    {
      NotificationManager.error("Login or Sign up To Access The Sheet", "Casting Africa", 10000);
      return;
    }
      NotificationManager.warning("Upgrade To Pro or Premium To Access The Sheet", "Casting Africa", 10000);
  }
  return (
    <div className="mt-24 h-full mb-10  ">
      <div
        style={{
          backgroundImage: `url(${MAIN_BG})`,
          objectFit: "cover",
        }}
        className="bg-100%  lg:bg-no-repeat flex justify-end items-start"
      >
        <div className="flex flex-col items-center lg:mr-20">
          {/* <img className=" border-2 border-red-500  w-24 m-0 p-0" src={BADGE} alt="" /> */}
          <img className="  " src={WIN} alt="" />
          {/* <div className="border-2 border-red-500  text-white font-gilroy-Light">
               Act | Submit | Win
            </div> */}
        </div>
      </div>

      {/* Content */}
      <div className="w-full flex justify-center  items-center lg:px-0 px-4">
        <div className="lg:w-4/5 h-full flex flex-col items-center">
          <div className="flex lg:flex-row flex-col  lg:px-2 lg:py-5">
            <div className="flex-1 flex lb:mb-0 mb-4 justify-center">
              <img className="w-72  lg:h-auto h-56" src={BADGE} alt="Badge" />
            </div>
            <div className="flex-1">
              <h1 className=" lg:text-left text-center text-2xl lg:text-3xl mb-2">
                Our Why
              </h1>
              <p className=" text-sm lg:text-lg  font-gilroy-Light mb-2">
                We believe developing the next generation of African talents is
                critical to the growth of the African Cinema and hence why we
                are key on providing relevant developmental offerings to our
                members to help prepare them for the opppourtunities that may
                come their way.
              </p>
              <p className=" text-sm lg:text-lg  font-gilroy-Light">
                The monthly contest is focused on challenging African actors
                across the continent and providing talents with the platform to
                showcase their potential to the global market.{" "}
              </p>
            </div>
          </div>

          <div className="flex px-2 py-5 lg:flex-row flex-col">
            <div className="flex-1  flex lg:mb-0 mb-4 justify-center">
              <img className="w-72  lg:h-auto" src={HOW_TO} alt="How to" />
            </div>
            <div className="flex-1 flex flex-col lg:items-start">
              <h1 className=" lg:text-left text-center text-2xl lg:text-3xl mb-2 ">
                How To Apply
              </h1>
              <ul className=" list-disc font-gilroy-Light  text-sm lg:text-lg  mb-2 ml-4">
                <li>Click to download the monologue sheet</li>
                <li>Select a monologue of your choice from the sheet</li>
                <li>
                  Follow all the instruction in the sheet, perform and submit
                </li>
                <li>
                  Send final tape video to info@casting.africa with caption
                  "Monologue Challenge"
                </li>
                <li>Deadline 30th June 2022</li>
              </ul>
              {accessMonologue() ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => gaEventTracker("Monologue", "Monologue download Button action", "Monologue download")}
                  href={`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}storage/Monologue/mono.pdf`}
                  className=" bg-primary text-white px-5 py-1 rounded-full lg:text-left text-center text-xs lg:text-sm  shadow-2xl"
                  download
                >
                  Download Monologue Sheet
                </a>
              ) : (
                <span
                  onClick={() => logInToParticipate()}
                  className=" cursor-pointer bg-primary text-white px-5 py-1 rounded-full lg:text-left text-center text-xs lg:text-sm  shadow-2xl"
                  download
                >
                  Download Monologue Sheet
                </span>
              )}
            </div>
          </div>

          <div className="flex justify-center mt-5">
            <div className="flex mr-4">
              <div>
                <img src={OPEN} alt="" />
              </div>
              <div>
                <h1 className=" text-2xl lg:text-3xl ">Open</h1>
                <p className="font-gilroy-Light text-xs lg:text-lg text-center">
                  African Talents
                </p>
                <p className="font-gilroy-Light text-xs lg:text-lg text-center">
                  in Africa
                </p>
              </div>
            </div>

            <div className="flex mr-4">
              <div>
                <img src={MODE} alt="" />
              </div>
              <div>
                <h1 className=" text-2xl lg:text-3xl ">Mode</h1>
                <p className="font-gilroy-Light text-xs lg:text-lg text-center">
                  Virtual
                </p>
                <p className="font-gilroy-Light text-xs lg:text-lg text-center">
                  (Self Tape)
                </p>
              </div>
            </div>

            <div className="flex">
              <div>
                <img src={PRIZE} alt="" />
              </div>
              <div>
                <h1 className=" text-2xl lg:text-3xl ">$100</h1>
                <p className="font-gilroy-Light text-xs lg:text-lg text-center">
                  Cash Prize
                </p>
                <p className="font-gilroy-Light text-xs lg:text-lg text-center">
                  amoung others
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center py-10">
            <h1 className="text-2xl lg:text-3xl mb-8">The Jury</h1>
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div className="mb-4">
                  <img className="w-52" src={KWASI} alt="" />
                </div>
                <p className=" text-sm lg:text-left text-center lg:text-xl">
                  Kwasi Bosiako Antwi
                </p>
                <p className="font-gilroy-Light lg:text-lg text-xs lg:text-left text-center">
                  Casting Director
                </p>
              </div>

              <div className="flex flex-col items-center mr-4">
                <div className="mb-4">
                  <img className="w-52" src={ANNI} alt="" />
                </div>
                <p className=" text-sm lg:text-left text-center lg:text-xl">
                  Anniwaa Buachie
                </p>
                <p className="font-gilroy-Light lg:text-lg text-xs lg:text-left text-center">
                  Film Director & Producer (UK)
                </p>
              </div>

              <div className="flex flex-col items-center">
                <div className="mb-4">
                  <img className="w-52" src={KUKUAA} alt="" />
                </div>
                <p className=" text-sm lg:text-left text-center lg:text-xl">
                  Kukuaa Eshun
                </p>
                <p className="font-gilroy-Light lg:text-lg text-xs lg:text-left text-center">
                  Film Director & Producer (USA)
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center py-10">
            <h1 className="text-2xl lg:text-3xl mb-8 ">Champions</h1>
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div className="mb-4">
                  <img className="w-52" src={WILMA} alt="Wilma" />
                </div>
                <p className="text-xs lg:text-xl">Wilma Tachie Menson</p>
                <p className=" text-xs lg:text-sm lg:text-left text-center font-gilroy-Light">
                  Actor & Model
                </p>
              </div>

              <div className="flex flex-col items-center mr-4">
                <div className="mb-4">
                  <img className="w-52" src={KENNY} alt="Wilma" />
                </div>
                <p className="text-xs lg:text-xl">Kenny Jim Parku</p>
                <p className=" text-xs lg:text-sm lg:text-left text-center font-gilroy-Light">
                  Actor & Model
                </p>
              </div>

              <div className="flex flex-col items-center">
                <div className="mb-4">
                  <img className="w-52" src={NEXT} alt="Wilma" />
                </div>
                <p className="text-xs lg:text-xl">Who's Next?</p>
                <p className=" text-xs lg:text-sm lg:text-left text-center font-gilroy-Light">
                  Film Director & Producer (USA)
                </p>
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col items-center">
            <h1 className="text-xl lg:text-2xl mb-4">Supported By</h1>
            <div className="flex">
              <img className=" w-24 lg:w-32 mr-4" src={MAX} alt="MX24TV" />
              <img className=" w-24 lg:w-32 mr-4" src={JUMIA} alt="JUMIA" />
              <img
                className=" w-24 lg:w-32 "
                src={NFA}
                alt="National Film Authority"
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Monologue;
