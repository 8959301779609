import React, {useContext} from "react";
import { Link } from "react-router-dom";
import PackageContext from "../../../../context/context";


const Starter = ({ color, btncolor, starter_image }) => {
  const context = useContext(PackageContext);
    return (
      <div
        style={{
          // backgroundImage: `url(${starter_image})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          // backgroundSize: "cover",
        }}
        // className="flex md:h-60 lg:h-104 lg:bg-50% md:bg-30%  flex-col  justify-start items-center"
        // h-80 md:h-72 lg:h-96 xl:h-104
        className={`flex justify-center lg:items-center lg:flex-row flex-col ${color}   mb-6`}
      >
        <div className="flex flex-col lg:items-start items-center lg:py-0 py-4  justify-center">
          <h1 className="flex text-2xl md:text-4xl lg:text-6xl font-bold text-white lg:mt-4 lg:mb-1">
            Connecting Talents
          </h1>
          <h1 className="flex text-2xl md:text-4xl lg:text-6xl font-bold text-white lg:mt-4 lg:mb-1">
            To Global Creators
          </h1>
          <p className=" flex text-xs md:text-l lg:text-3xl font-gilroy-Light text-white mb-4">
            Actors, Models, Dancers & Influencers
          </p>
          <Link onClick={() => context.updatePath("/signup")} to="/signup" className={`px-4 py-2  ${btncolor} font-gilroy-Light text-white rounded-md `}>Join Now</Link>
        </div> 
        <div className="flex justify-center">
           <img className="lg:w-full w-3/4" src={starter_image} alt="" />
        </div>
      </div>
    );
}


export default Starter;