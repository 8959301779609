import React, { useState } from "react";
import { sample_talents } from "../../../../../config/utilities";
import TalentItemCard from "../../../Communities/Comps/talentItemCard";
import { Pagination } from "../../../Pagination";
import { useHistory } from "react-router-dom";

const ManageRooster = () => {
  const [setNext, setSetNext] = useState(5);
  let pagesize = 10;
  const history = useHistory();

  const handleAddNewTalent = () => {
      history.push("/talentSignUp", {
        signupmode: "AGENTSIGNUP",
      });
  }

  return (
    <div className="w-full h-full mt-24 py-6 flex flex-col items-center overflow-scroll ">
      <div className="flex flex-col items-center w-full lg:w-4/6 px-4">
        <div className="flex justify-between w-full">
          <h1 className="font-gilroy-Light text-xl md:text-2xl  ">Manage My Rooster</h1>
          <button onClick={handleAddNewTalent} className="rounded-full  bg-primary text-white font-gilroy-Light px-4 hover:bg-blue-800">
            Add New Talent
          </button>
        </div>
        <div className="flex lg:flex-row flex-col w-full justify-between py-2 mt-4 mb-4">
          <div className="flex lg:mb-0 mb-3 shadow-sm items-center border w-full rounded-3xl mr-2">
            <input
              className="w-full px-3  py-2 rounded-tl-3xl outline-none font-gilroy-Light rounded-bl-3xl"
              type="text"
              placeholder="Search Your Rooster Directory"
            />
            <span className="w-20 cursor-pointer border-primary hover:bg-blue-800 bg-primary py-2 flex justify-center rounded-tr-3xl rounded-br-3xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#FFF"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </span>
          </div>
          <select
            className=" w-full lg:w-44 rounded-3xl px-3 font-gilroy-Light "
            name=""
            id=""
          >
            <option value="">Filter by</option>
            <option value="Recent">Recent</option>
            <option value="Premium">Premium</option>
            <option value="Expired">Expired</option>
          </select>
        </div>
        <div className="w-full flex flex-wrap lg:justify-start justify-center mb-4">
          {sample_talents
            .slice(setNext - pagesize, setNext)
            .map((talent, key) => (
              <div key={key}>
                <TalentItemCard
                  width="w-62"  
                  talent={talent}
                  feature_type="edittalent_feature"
                />
              </div>
            ))}
        </div>
        <Pagination data={sample_talents} pagesize={10} nextSet={setSetNext} />
      </div>
    </div>
  );
};

export default ManageRooster;
