import React, { useState } from "react";
import { talent_job_preference } from "../../../../config/utilities";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { UPDATE_TALENT } from "../../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { NotificationManager } from "react-notifications";

const UserJobPreference = ({
  next,
  prev,
  talentForm,
  setTalentForm,
  signupmode,
  type,
  step,
  setStep,
}) => {
  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [talentTypeSelected, setTalentTypeSelected] = useState(
    talentForm.castingAfricaTalentDetails.talent_type
  );

  const [actorInterest, setActorInterest] = useState(
    talentForm.castingAfricaTalentDetails.actorInterest
  );
  const [modelInterest, setModelInterest] = useState(
    talentForm.castingAfricaTalentDetails.modelInterest
  );
  const [dancerInterest, setDancerInterest] = useState(
    talentForm.castingAfricaTalentDetails.dancerInterest
  );
  const [influencerInterest, setInfluencerInterest] = useState(
    talentForm.castingAfricaTalentDetails.influencerInterest
  );

  const [influencerSocialInterest, setInfluencerSocialInterest] = useState(
    talentForm.castingAfricaTalentDetails.influencerSocialInterest
  );

  const [logged] = useState(sessionStorage.getItem("AUTH_USER"));

  const [
    updateTalent,
    { data: talent_data, loading: talent_loading, error: talent_error },
  ] = useMutation(UPDATE_TALENT);

  const unsetvalue = (container, setContainer, value) => {
    if (container.includes(value)) {
      const temp = [...container];
      temp.splice(temp.indexOf(value), 1);
      eval(setContainer)(temp);
    }
  };

  const dynamicMethodCall = (methodState, methodName, item) => {
    let m_state = eval(_.camelCase(methodState));
    eval(methodName)([...m_state, item]);
    unsetvalue(m_state, methodName, item);
  };

  const handleSubmitToNextPage = (data) => {
    talentForm[type]["actorInterest"] = actorInterest;
    talentForm[type]["modelInterest"] = modelInterest;
    talentForm[type]["dancerInterest"] = dancerInterest;
    talentForm[type]["influencerInterest"] = influencerInterest;
    talentForm[type]["influencerSocialInterest"] = influencerSocialInterest;
    talentForm[type]["dancerInterest"] = dancerInterest;

    let isFilled = false;

    talentTypeSelected.forEach((talenttype) => {
      if (eval(_.camelCase(`${talenttype}Interest`)).length <= 0) {
        isFilled = true;
      }
    });

    if (isFilled) return;

    setTalentForm((p) => ({ ...p, ...talentForm }));
    if (step && signupmode === "TALENTEDIT") {
      setStep(step + 1);
    } else {
      next();
    }
  };


   // Refactor
   const handleSubmitToUpdate = (data) => {
    let temp = talentForm;
    temp['castingAfricaTalentDetails']['talentTypeSelected'] = talentTypeSelected
    temp['castingAfricaTalentDetails']['actorInterest'] = actorInterest
    temp['castingAfricaTalentDetails']['modelInterest'] = modelInterest
    temp['castingAfricaTalentDetails']['dancerInterest'] = dancerInterest
    temp['castingAfricaTalentDetails']['influencerInterest'] = influencerInterest
    temp['castingAfricaTalentDetails']['influencerSocialInterest'] = influencerSocialInterest
    
  

    const socials = Object.entries(temp["talentSocials"]);
    let social_asm = new Object();
    socials.forEach(([key, value]) => {
      social_asm[value["social_media_type"].toLowerCase()] =
        value["social_media_handle"];
    });

    // temp["talentAddress"] = tempDetails;
    temp["castingAfricaTalentDetails"] = JSON.stringify(
      talentForm["castingAfricaTalentDetails"]
    );
    temp["talentSocials"] = social_asm;
    delete temp["photos"];
    delete temp["resume"];
    delete temp["videos"];
    delete temp['talentAddress']['__typename']
    delete temp['talentAddress']['region_or_state']
    delete temp["talentAddress"]["talent_phonenumbers"];

    console.log("temp ", temp);

    updateTalent({
      variables: {
        talentID: JSON.parse(logged)?.user?.talent?.casting_africa_talent_id,
        ...temp,
      },
    })
      .then((data) => {
        NotificationManager.success("Updated successfully");
        window.location.reload();
      })
      .catch((err) => {
        NotificationManager.warning("Profile update failed");
      });
  };

  return (
    <>
      <div
        className={`pb-6  md:rounded-3xl w-full  ${
          signupmode === "AGENTSIGNUP" || signupmode !== "TALENTEDIT"
            ? "md:w-9/12 lg:w-5/12 shadow-2xl  bg-white rounded-2xl"
            : ""
        }`}
      >
        {/* Header */}
        {signupmode === "AGENTSIGNUP" ||
          (signupmode !== "TALENTEDIT" && (
            <div className="mb-6  h-32  rounded-3xl flex flex-col justify-center items-center bg-header ">
              <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2">
                Job Preferrence
              </h1>
              <p className=" w-5/6  md:w-4/6 text-center text-xs md:text-sm mb-4 text-formFont font-gilroy-Light">
                Select jobs you are interested in to help us notify you with
                oppourtunities
              </p>
            </div>
          ))}

        {/* Form Body */}
        {talent_job_preference.map((job, key) =>
          talentTypeSelected.includes(job.label.split(" ")[0]) ? (
            <div key={key} className="flex flex-row px-2 md:px-14">
              <div className="flex-1 px-1 py-1  flex flex-col">
                <label
                  htmlFor="Actor Category"
                  className="mb-3 font-gilroy-Light text-sm md:text-lg  text-formFont"
                >
                  {job.label}
                </label>
                <p className=" font-gilroy-Light text-red-600 ">
                  {eval(_.camelCase(`${job.label.split(" ")[0]}Interest`))
                    ?.length === 0 && `Select ${job.label}`}
                </p>
                <div className="flex flex-wrap">
                  {job.categories.map((talent, index) => (
                    <div
                      onClick={() =>
                        dynamicMethodCall(
                          _.camelCase(`${job.label.split(" ")[0]}Interest`),
                          `set${job.label.split(" ")[0]}Interest`,
                          talent
                        )
                      }
                      key={index}
                      className={` ${
                        eval(
                          `${job.label?.split(" ")[0].toLowerCase()}Interest`
                        ).includes(talent)
                          ? "bg-primary text-white"
                          : "text-formFont"
                      } cursor-pointer hover:bg-primary hover:text-white border text-white font-gilroy-Light px-4 py-2  rounded-3xl border-primary m-1`}
                    >
                      {talent}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        )}

        {["AGENTSIGNUP", "TALENTEDIT", "TALENTSIGNUP"].includes(signupmode) ? (
          <div className="px-14 mt-7 flex  flex-row justify-center items-center">
            {!logged ? (
              <div className="w-full flex  flex-row justify-center items-center">
                <button
                  onClick={() => prev()}
                  className="hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3 px-16 "
                >
                  Back
                </button>

                <button
                  onClick={handleSubmit(handleSubmitToNextPage)}
                  className="hover:opacity-100 tranition ease-out duration-700 bg-primary opacity-60 m-2 flex items-center justify-center font-bold text-white rounded-full py-3  px-16 "
                >
                  Next
                </button>
              </div>
            ) : (
              <button
                onClick={handleSubmit(handleSubmitToUpdate)}
                className=" shadow-2xl  bg-primary opacity-60 hover:opacity-100 tranition ease-out duration-700 m-4 md:m-2 flex items-center justify-center font-bold text-white rounded-full py-4  px-28 "
              >
                {talent_loading ? "Please wait.." : "Update"}
              </button>
            )}
          </div>
        ) : (
          ""
        )}

        {/* End of form body */}
      </div>
    </>
  );
};

export default UserJobPreference;
