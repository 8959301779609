import React, { useContext } from "react";
import CASTINGLOGOICON from "../../assets/images/castingafrica_icon.png";
import BANNERPOSTONE from "../../assets/images/banner_post_1.png";
import BANNERPOSTTWO from "../../assets/images/banner_post_2.png";
import ReactPlayer from "react-player";
import PackageContext from "../../context/context";

import {
  casting_africa_communities,
  casting_africa_partners,
  how_it_works,
  what_we_do,
} from "../../config/utilities";
import { Link } from "react-router-dom";
import { UserAnalyticsEventTracker } from "../Temporary";

const Home = () => {
  // const {community, setCommunity} = useContext(PackageContext);
  const gaEventTracker = UserAnalyticsEventTracker("Home");

  return (
    <PackageContext.Consumer>
      {(context) => (
        <section className="mt-20 mb-6 ">
          <div className="w-full  flex flex-col items-center py-16">
            <img
              className="w-32 lg:w-52 mb-5"
              src={CASTINGLOGOICON}
              alt="Casting Africa Logo"
            />
            <p className=" w-4/5 md:w-2/5 text-xs md:text-lg text-center mb-14 font-bold ">
              Join thousands of African talent around the world who are been
              discovered and connect to local and global opportunities
            </p>
            <p className=" text-sm md:text-lg font-bold mb-5">
              Choose your community
            </p>
            {/*  */}
            <div className=" lg:w-2/5 flex flex-wrap justify-center mb-5">
              {casting_africa_communities.map(
                (community, key) =>
                  community.status && (
                    <Link
                      onClick={() => context.setCommunity(community.code)}
                      key={key}
                      to={community.pathname}
                      className="flex mr-6 mb-8 flex-col justify-center items-center cursor-pointer"
                    >
                      <img
                        className="w-24 md:w-36  mb-3"
                        src={community.icon}
                        alt={community.country}
                      />
                      <span className=" font-gilroy-Light ">
                        {community.country}
                      </span>
                    </Link>
                  )
              )}
            </div>

            {/* <p className="text-sm font-bold mb-5">Communities coming soon</p> */}
            <div className={`hidden  mb-20  w-full justify-around lg:w-102 `}>
              {casting_africa_communities.map(
                (community, key) =>
                  !community.status && (
                    <div
                      key={key}
                      className="flex  lg:mr-6 flex-col justify-center items-center cursor-pointer"
                    >
                      <img
                        className="w-24 md:w-36"
                        src={community.icon}
                        alt={community.country}
                      />
                    </div>
                  )
              )}
            </div>

            <div className="flex md:flex-row flex-col overflow-x-auto justify-center items-center mb-4 w-full overflow-x lg:w-3/5">
              <span className="font-gilroy-Light text-md lg:mb-0 mb-4  lg:block lg:text-xl ">
                We've worked with
              </span>
              <div className={`flex px-4 lg:px-0 lg:ml-10 items-center`}>
                {casting_africa_partners.map((partners, key) => (
                  <img
                    className=" mr-4 w-16 lg:w-32 "
                    key={key}
                    src={partners.icon}
                    alt={partners.company_name}
                  />
                ))}
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgba(58, 119, 255, 0.1)",
            }}
            className="py-6 lg:py-12 flex flex-col items-center"
          >
            <h1 className="font-bold text-lg md:text-2xl mb-5 md:mb-10">
              How it works
            </h1>

            <div className="flex items-center justify-center px-1 md:px-0 ml-2 mr-2  w-11/12  md:w-7/12  mb-10 md:mb-20 ">
              <ReactPlayer
                url="https://youtu.be/LfR5CNYvjMI"
                loop
                // light={BANNERPOSTTWO}
                controls
                pip
                style={{
                  backgroundRepeat: "repeat-x",
                  boxShadow: "0px  10px 5px gray"
                }}
              />
            </div>

            <div
              className={`lg:w-4/6 px-6 lg:px-0 lg:h-56  flex flex-col lg:flex-row`}
            >
              {how_it_works.map((work, key) => (
                <div key={key} className="p-2 flex">
                  <div className="border-2 mr-4 w-5 h-5 md:w-10 md:h-10 flex justify-center items-center px-4 py-4 md:px-4 md:py-2 font-gilroy-Light rounded-full bg-primary text-white">
                    {key + 1}.
                  </div>
                  <div>
                    <h1 className="font-bold  md:text-2xl w-6/12 mb-2">
                      {work.title}
                    </h1>
                    <p className="text-sm md:text-lg md:w-10/12 font-gilroy-Light ">
                      {work.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="py-6 flex flex-col items-center ">
            <h1 className="font-bold text-lg md:text-4xl mb-8">
              We’re Africa’s Home Of Casting
            </h1>
            <div
              className={`px-6 lg:px-0 lg:w-4/6 lg:h-56 flex flex-col lg:flex-row`}
            >
              {what_we_do.map((work, key) => (
                <div key={key} className="p-2 flex">
                  <div className="border-2 mr-4 w-10 h-10 flex justify-center items-center px-2 py-2 font-bold rounded-full bg-primary text-white">
                    {work.icon}
                  </div>
                  <div>
                    <h1 className="font-bold text-lg md:text-xl w-9/12 mb-2">
                      {work.title}
                    </h1>
                    <p className="md:w-10/12 font-gilroy-Light ">
                      {work.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full flex justify-center items-center py-9 ">
            <div className="flex md:flex-row flex-col justify-center items-center">
              <img
                className="md:w-1/2 w-full object-contain md:mr-10 md:px-0 px-6 md:mb-0 mb-10"
                src={BANNERPOSTTWO}
                alt="Banner Post"
              />
              <div className="md:block flex justify-center flex-col items-center">
                <h1 className="font-bold text-center md:text-left  text-sm lg:text-3xl md:w-4/6 mb-4">
                  Book high-paying modeling and acting, dancing & influencing
                  jobs
                </h1>
                <Link
                  onClick={() => {
                    context.updatePath("/signup");
                    gaEventTracker(
                      "Join Now",
                      "Join now Button action",
                      "Join Now"
                    );
                  }}
                  to="/signup"
                  className="btn"
                >
                  Join Now
                </Link>
              </div>
            </div>

            {/* <div className="w-full md:mb-0 mb-10 border border-red-500  md:px-0 px-6 md:w-3/6  flex md:flex-row flex-col items-center justify-center">
              <img className="md:w-1/2" src={BANNERPOSTTWO} alt="Banner Post" />
            </div>
            <div className=" w-full md:w-3/6  flex flex-col items-center  md:items-start justify-center pl-5">
              <h1 className="font-bold text-center md:text-left  text-sm lg:text-3xl md:w-4/6 mb-4">
                Book high-paying modeling, acting, dancing & influencing jobs
              </h1>
              <Link
                onClick={() => context.updatePath("/signup")}
                to="/signup"
                className="btn animate-bounce"
              >
                Join Now
              </Link>
            </div> */}
          </div>
        </section>
      )}
    </PackageContext.Consumer>
  );
};

export default Home;
