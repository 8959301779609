import React, { useState } from "react";
import { Link } from "react-router-dom";
import { faq_questions } from "../../config/utilities";

const FrequentQuetions = () => {
  const [faq, setFaq] = useState(-1);
  return (
    <div className="mt-24 lg:h-screen lg:px-10 px-3 lg:py-10  py-3 bg-gray-100 w-full">
      <div className="bg-white flex flex-col px-3 py-3 lg:py-20 lg:px-20 w-full h-full">
        <div className=" mb-10">
          <h1 className="text-xl lg:text-4xl mb-2">Frequently Asked Questions</h1>
          <p className="font-gilroy-Medium">
            These are the questions we receive more often
          </p>
        </div>
        <div className="flex lg:flex-row flex-col">
          <div className=" w-full lg:w-2/3">
            {faq_questions.map((ques, key) => (
              <div key={key} className="flex flex-col">
                <div className=" border-b  mb-5  pb-3 border-black flex justify-between text-2xl">
                  <span className="font-gilroy-Light lg:text-lg text-sm">{ques.question}</span>
                  <span
                    onClick={() => setFaq(key)}
                    className="font-gilroy-Medium cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="lg:h-6 lg:w-6 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                  </span>
                </div>
                <div
                  className={` ${
                    faq === key ? "flex" : "hidden"
                  } border-b  mb-10 mt-5 pb-3 border-black  justify-between text-2xl`}
                >
                  <span className="font-gilroy-Light lg:text-lg text-sm ">{ques.answer}</span>
                  <span onClick={() => setFaq(-1)} className="font-gilroy-Medium cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="lg:h-6 lg:w-6 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className=" w-full lg:w-1/3 px-3 lg:px-10">
            <div className=" bg-blue-300 w-full h-96 rounded-2xl flex flex-col items-center justify-center">
              <h1 className="text-2xl">Don't see the answer you </h1>
              <h1 className="text-2xl mb-5">need?</h1>

              <p className="font-gilroy-Medium">
                That's ok. Just drop us a message and
              </p>
              <p className="font-gilroy-Medium mb-10">
                we will get back to you ASAP
              </p>

              <Link
                to="/contactus"
                className="bg-primary  px-4 py-2 text-white font-gilroy-Light rounded-lg"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrequentQuetions;
